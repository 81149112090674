import React from 'react'
import ConfirmModal from '../modal'
import {Grid, Cell, Card, CardActions, CardTitle, Icon, Button, ListItem, List, ListItemContent, ListItemAction} from 'react-mdl'

export default class ValidationManagement extends React.Component {
    constructor(props) {
        super(props)
        this.state={
          showDialog : false,
          dialogTitle : '',
          dialogText : '',
        }
    }

    getValidatorEnabledCount(validatorsData, name){
      let counter = 0
      validatorsData.forEach((v) => {
        if (v.validators.find( v => v.name === name)){
          counter ++
        }
      })
      return counter
    }

    buildValidatorEnabledCountByName(validators, validatorsData){
      const validatorNames = validators.map((v)=> v.name)
      const validatorEnabledCounter = {}
      validatorNames.forEach(name => {
         validatorEnabledCounter[name] = this.getValidatorEnabledCount(validatorsData, name)
      })
      return validatorEnabledCounter
    }

    resync(name) {
        this.setState({
          showDialog : true,
          dialogTitle : `Are you sure you want to synchronize ${name} across campaigns?`,
          dialogText : `Synchronize ${name}`,
          onOk : () => {
            this.props.resync(name)
            this.setState({showDialog : false})
          },
        })
    }

    makeDefault(name) {
        this.setState({
          showDialog : true,
          dialogTitle : `Are you sure ${name} should be made default across all campaigns?`,
          dialogText : `Make ${name} default`,
          onOk : () => {
            this.props.putDefaultValidator(name)
            this.setState({showDialog : false})
          },
        })
    }

    hideModal(){
      this.setState({
        showDialog: false,
      })
    }

    removeDefault(name) {
      this.setState({
        showDialog : true,
        dialogTitle : `Are you sure ${name} should be made disabled as default for new campaigns?`,
        dialogText : `No longer apply ${name} as a default`,
        onOk : () => {
          this.props.deleteDefaultValidator(name)
          this.setState({showDialog : false})
        },
        dialogCancel : () => this.setState({showDialog : false}),
      })
    }

    disableAcrossCampaigns(validatorName){
        this.setState({
          showDialog : true,
          dialogTitle : `Are you sure ${validatorName} should be disabled across all campaigns?`,
          dialogText : `Disable ${validatorName} validator across all campaigns`,
          onOk : () => {
            this.props.disableValidator(validatorName)
            this.setState({showDialog : false})
          },
        })
    }

    render() {
      const validatorEnabledCountByName = this.buildValidatorEnabledCountByName(this.props.validators, this.props.validatorsData)
      this.props.validators && this.props.validators.sort((a, b) => {
          if (a.name < b.name) { return -1 }
          if (a.name > b.name) { return 1 }
          return 0
      })

      let valListItems = this.props.validators.map(v => {
          let defaultMap = this.props.defaultCampaignValidators || {}
          let isDefault = defaultMap[v.name]
          isDefault = isDefault || false
          return <ListItem key={v.name}>
              <ListItemContent>
                {v.name}
              </ListItemContent>
              <ListItemAction info="Campaigns enabled count" style={{marginBottom: "11px"}}>
                <span>{ validatorEnabledCountByName[v.name] }</span>
              </ListItemAction>
              <ListItemAction info="">
              </ListItemAction>
              <ListItemAction info="Prevalidation">
                  {v.prevalidation ? <Icon name="star" /> : <Icon name="close" />}
              </ListItemAction>
              <ListItemAction info="Publisher Available">
                  {v.publisherSupported ? <Icon name="star" /> : <Icon name="close" />}
              </ListItemAction>
              <ListItemAction info="Default">
                  {isDefault ? <Icon name="star" /> : <Icon name="close" />}
              </ListItemAction>
              {/* <ListItemAction><Button onClick={() => this.resync(v.name)}>Resync</Button></ListItemAction> */}
              {!isDefault ? <ListItemAction><Button raised style={{minWidth: "175px"}} onClick={() => this.makeDefault(v.name)}>Make Default</Button></ListItemAction>
                          : <ListItemAction><Button raised style={{minWidth: "175px"}}  onClick={() => this.removeDefault(v.name)}>Remove Default</Button></ListItemAction>}
              <ListItemAction>
                <Button
                  disabled={validatorEnabledCountByName[v.name] > 0 ? false : true}
                  raised colored
                  onClick={() => this.disableAcrossCampaigns(v.name)}
                >
                  Disable
                </Button>
              </ListItemAction>
          </ListItem>
      })
      return  <section ref={t => this.contentSection = t}>
        <ConfirmModal
          showDialog={this.state.showDialog}
          contentSection={this.contentSection}
          dialogTitle={this.state.dialogTitle}
          dialogText={this.state.dialogText}
          onOk={ () => this.state.onOk() }
          dialogCancel={ () => this.hideModal() }
        />
        <Card>
          <CardTitle>Validation</CardTitle>
          <CardActions border>
              <Grid>
                  <Cell col={10}>
                      <List className="centeredListItem">
                          {valListItems}
                      </List>
                  </Cell>
              </Grid>
          </CardActions>
      </Card>
      </section>
    }
}