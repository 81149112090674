import React from 'react'
import Editor from '../editor'
import Select from 'react-select'
import FunctionsModal from './functionsModal'
import FieldsModal from './fieldsModal'
import {Grid, Cell, Card, CardActions, CardTitle, Textfield, ListItem, List, Tabs, Tab} from 'react-mdl'

class TemplateEntry {
  constructor(header, body) {
    this.header = header
    this.body = body
  }
}

class TemplateSamples {
  constructor() {
    this.Samples = []
    this.Samples.push(
      new TemplateEntry(
        'CSV minimal',
        `Id, Email, Name, Location
{{range $row := .Records }}{{ $row.Uuid }},{{ $row.User.Email }},{{ $row.User.FirstName }} {{ $row.User.LastName }},"{{ $row.User.City }}, {{if index $.Replace $row.User.State }}{{index $.Replace $row.User.State }}{{else}}{{$row.User.State}}{{end}}, {{ $row.User.Zipcode }}"
{{ end }}`,
      ),
    )
    this.Samples.push(
      new TemplateEntry(
        'CSV full',
        `uuid,email,title,first_name,last_name,address1,address2,city,state,country,zipcode,phone1,phone2,phone3,ip,id,subMktng_id,gender,dob
{{range $row := .Records }}{{ $row.Uuid}},{{ $row.User.Email}},{{ $row.User.Title}},{{ $row.User.FirstName}},{{ $row.User.LastName}},{{ $row.User.Address1}},{{ $row.User.Address2}},{{ $row.User.City}},{{ $row.User.State}},{{ $row.User.Country}},{{ $row.User.Zipcode}},{{ $row.User.Phone}},{{ $row.User.Ip}},{{ $row.MarketingId}},{{ $row.User.Gender}},{{ $row.User.Dob}}
{{ end }}`,
      ),
    )
    this.Samples.push(
      new TemplateEntry(
        'JSON Multiple Records',
        `{
 "leads": [ {{range $index, $row := .SYS_LEADS }}
  {{if gt $index 0}},{{end}}{"uuid":"{{ $row.Uuid}}",
  "email":"{{ $row.User.Email}}",
  "title":"{{ $row.User.Title}}",
  "first_name":"{{ $row.User.FirstName}}",
  "last_name":"{{ $row.User.LastName}}",
  "address1":"{{ $row.User.Address1}}",
  "address2":"{{ $row.User.Address2}}",
  "city":"{{ $row.User.City}}",
  "state":"{{ $row.User.State}}",
  "country":"{{ $row.User.Country}}",
  "zipcode":"{{ $row.User.Zipcode}}",
  "phone":"{{ $row.User.Phone}}",
	"gender":"{{ $row.User.Gender}}",
	"dob":"{{ $row.User.Dob}}",
  "ip":"{{ $row.User.Ip}}",
  "marketing_id":"{{ $row.MarketingId}}"}{{ end }}
 ]
}`,
      ),
    )
    this.Samples.push(
      new TemplateEntry(
        'JSON Single Record',
        `{
 "lead":{  {{$row := index .SYS_LEADS 0 }}
	"uuid":"{{ $row.Uuid}}",
  "email":"{{ $row.User.Email}}",
  "title":"{{ $row.User.Title}}",
  "first_name":"{{ $row.User.FirstName}}",
  "last_name":"{{ $row.User.LastName}}",
  "address1":"{{ $row.User.Address1}}",
  "address2":"{{ $row.User.Address2}}",
  "city":"{{ $row.User.City}}",
  "state":"{{ $row.User.State}}",
  "country":"{{ $row.User.Country}}",
  "zipcode":"{{ $row.User.Zipcode}}",
  "phone":"{{ $row.User.Phone}}",
	"gender":"{{ $row.User.Gender}}",
	"dob":"{{ $row.User.Dob}}",
  "ip":"{{ $row.User.Ip}}",
  "marketing_id":"{{ $row.MarketingId}}"	}
}`,
      ),
    )
  }
}

const TEMPLATE_FIELDS = [
  {label:"Lead UUID", code:"{{$row.Uuid}}"},
  {label:"Email", code:"{{$row.User.Email}}"},
  {label:"Title", code:"{{$row.User.Title}}"},
  {label:"First name", code:"{{$row.User.FirstName}}"},
  {label:"Address1", code:"{{$row.User.Address1}}"},
  {label:"Address2", code:"{{$row.User.Address2}}"},
  {label:"City", code:"{{$row.User.City}}"},
  {label:"State", code:"{{$row.User.State}}"},
  {label:"Zip Code", code:"{{$row.User.Zipcode}}"},
  {label:"Country", code:"{{$row.User.Country}}"},
  {label:"Phone", code:"{{$row.User.Phone}}"},
  {label:"Gender", code:"{{$row.User.Gender}}"},
  {label:"DOB", code:"{{$row.User.Dob}}"},
  {label:"IP", code:"{{$row.User.Ip}}"},
  {label:"Marketing Id", code:"{{$row.MarketingId}}"},

]
  

const TEMPLATEFUNCTIONS = [
  "Md5", 
  "Now", 
  "Sha256", 
  "Trim", 
  "ToLower", 
  "Truncate", 
  "Within date range", 
  "Replacement", 
  "Format unix timestamp",
  "Date format",
  "Unix ts to date",
  "Map key exists",
  "Contains",
  "Accept",
  "Reject",
  "Fail"
]

const templateSamples = new TemplateSamples()
export default class LeadTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFieldsModal : false,
      showFunctionsModal: false,
      selectedTypeName: null,
      selectedFunctionName: null,
      activeTab: 0,
    }
  }

  handleChange(callback){
    callback()
    this.setState(this.state)
  }

  //showFieldsModal(fieldName){
  //  this.setState({
  //    fieldName : true,
  //    selectedTypeName : fieldName
  //  })
  //}

  showFunctionsModal(functionName){
    this.setState({
      showFunctionsModal : true,
      selectedFunctionName : functionName
    })
  }

  closeModal(modalName){
    this.setState({
      [modalName]: false
    })
  }

  render() {
    return <section ref={t => this.contentSection = t}>
      <FunctionsModal
        contentSection={this.contentSection}
        key={`${this.state.selectedFunctionName}-functions`}
        showModal={this.state.showFunctionsModal}
        closeModal={ ()=>this.closeModal('showFunctionsModal') }
        selectedFunctionName={this.state.selectedFunctionName}
      />
      <FieldsModal
        key={`${this.state.selectedTypeName}-fields`}
        contentSection={this.contentSection}
        showModal={this.state.showFieldsModal}
        closeModal={ ()=>this.closeModal('showFieldsModal') }
        selectedTypeName={this.state.selectedTypeName}
      />
      <Card shadow={2} style={{ overflow: "visible" }}>
        <CardTitle> Lead Template </CardTitle>
        <CardActions border>
          <Grid>
            <Cell col={3} style={{zIndex: 1}}>
              <Select
                autosize={false}
                placeholder={"Template"}
                isMulti={false}
                value={{ label: 'Template', value: '' }}
                onChange={(s)=>this.handleChange(()=>this.props.handleLeadTemplateChange(s.value))}
                options={templateSamples.Samples.map((e) => {
                  return { label: e.header, value: e.body }
                })}
              />
            </Cell>
            <Cell col={9}></Cell>
            <Cell col={12}>
              <Textfield
                required
                value={this.props.configuration.fileName}
                onChange={(e)=>this.handleChange(()=>this.props.handleFileNameChange(e.target.value))}
                label="Output Filename"
                floatingLabel
                style={{ width: '200px' }}
              />
            </Cell>
            <Cell col={2}>
                <Tabs
                  activeTab={this.state.activeTab}
                  onChange={tabId => this.setState({ activeTab: tabId })}
                  ripple
                  style={{ width: '100%' }}
                >
                  <Tab>Fields</Tab>
                  <Tab>Functions</Tab>
                </Tabs>
                <section>
                  <div className="content">
                    {this.state.activeTab === 0 ?
                    <List style={{ maxHeight: '230px', overflow: 'auto' }} >
                      { TEMPLATE_FIELDS.map((fieldName, idx)=>{
                        return (
                          <ListItem key={idx} onClick={()=> {
                            var template = this.props.configuration.leadTemplate+fieldName.code
                            this.props.handleLeadTemplateChange(template)
                          }}>{fieldName.label}</ListItem>
                        )
                      })
                    }
                    </List>
                    : <List style={{ maxHeight: '230px', overflow: 'auto' }} >
                      { TEMPLATEFUNCTIONS.map((functionName, idx)=>{
                        return (
                          <ListItem key={idx} onClick={()=> this.showFunctionsModal(functionName)}>{`${functionName} function`}</ListItem>
                        )
                      })
                    }
                    </List>
                    }
                  </div>
                </section>
            </Cell>
            <Cell col={10} style={{zIndex: 0}}>
              <Editor
                style={{ height: '180px' }}
                code={this.props.configuration.leadTemplate}
                onChange={(c)=>this.handleChange(()=>this.props.handleLeadTemplateChange(c))}
              />
            </Cell>
          </Grid>
        </CardActions>
      </Card>
      </section>
  }
}
