import {
  retrieveLeadDeliveryConfig, cancelLeadDeliveryConfig,
  newLeadDeliveryConfig, saveLeadDeliveryConfig, cloneLeadDeliveryConfig,
  testLeadDeliveryConfig,
  deleteLeadDeliveryConfig, ActionTypes,
  retrieveLatestDeliveriesByConfiguration
} from '../../services'
import LeadDeliveryConfig from './config'
import { buildTestLeads } from './testData'
import Modal from 'react-modal'
import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Card, CardTitle, Grid, CardActions, Cell, Button, Icon } from 'react-mdl'
import { Prompt } from 'react-router'

function mapDispatchToProps(dispatch) {
  return {
    runLeadDeliveryTest(testData, verbose) {
      if (!testData) {
        return
      }
      dispatch(testLeadDeliveryConfig(testData, verbose))
    },
    error(msg) {
      dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIGS_ERROR, data: { message: msg } })
    },
    save(config) {
      dispatch(saveLeadDeliveryConfig(config))
    },
    clone(configUUID) {
      dispatch(cloneLeadDeliveryConfig(configUUID))
    },
    delete(campaignUUID, configUUID, scheduleUUID) {
      dispatch(deleteLeadDeliveryConfig(campaignUUID, configUUID, scheduleUUID))
    },
    setConfig() {

    },
    clear() {
      dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIGS_ERROR, data: null })
      dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIG_TEST, data: {} })
    },
    retrieveLatestsDeliveries(configUUID, limit) {
      dispatch(retrieveLatestDeliveriesByConfiguration(configUUID, limit))
    },
    newLeadDeliveryConfig(newLeadFileConfig) {
      dispatch(newLeadDeliveryConfig(newLeadFileConfig))
    },
    cancelLeadDeliveryConfig() {
      dispatch(cancelLeadDeliveryConfig())
    },
    retrieveLeadDeliveryConfig(configUUID) {
      dispatch(retrieveLeadDeliveryConfig(configUUID))
    },
  }
}

class leadDeliveryConfigs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty: false,
      leadDeliveryConfig: null,
    }

    this.props.setIsTabComponentDirty(() => this.state.isDirty)
    this.getLeadDeliverySchedule = () => null
    this.setGetLeadDeliverySchedule = (cb) => {
      this.getLeadDeliverySchedule = cb
    }

    this.props.setConfig(null)
    this.handleEncryptionCertValueChange = this.handleEncryptionCertValueChange.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.leadDeliveryConfig !== state.leadDeliveryConfig) {
      return {
        leadDeliveryConfig: props.leadDeliveryConfig,
      }
    }
    return null
  }

  configSelected(config) {
    //if(config.UUID.length > 0){
    //  this.props.retrieveLatestsDeliveries(config.UUID, 5)
    //}else{
    //  this.props.newLeadDeliveryConfig()
    //}

    this.props.retrieveLeadDeliveryConfig(config.UUID)

    //this.setState({
    //  leadDeliveryConfig: config
    //})
  }

  getDefaultConfig() {
    let defaultConfig = {
      UUID: "",
      campaignUUID: this.props.campaign.UUID,
      name: "new lead delivery config",
      notes: "",
      fileName: "",
      leadTemplate: "",
      responseStatusTemplate: "",
      encryptionCert: {
        key: "",
        value: ""
      },
      fileCompressionType: 0,
      datasourceType: 0,
      datasource: {},
      scheduleType: 0,
      schedule: {},
      senderType: 0,
      sender: {},
    }
    return defaultConfig
  }

  handleNewConfigClick() {
    let newLeadFileConfig = this.getDefaultConfig()
    this.props.newLeadDeliveryConfig(newLeadFileConfig)
  }

  handleScheduleTypeChange(scheduleType) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.scheduleType = scheduleType
    this.updated(leadDeliveryConfig)
  }

  handleNameChange(value) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.name = value
    this.updated(leadDeliveryConfig)
  }

  handleNotesChange(value) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.notes = value
    this.updated(leadDeliveryConfig)
  }

  handleEncryptionCertKeyChange(selected) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.encryptionCert.key = selected.value
    this.updated(leadDeliveryConfig)
  }

  handleEncryptionCertValueChange(value) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.encryptionCert.value = value
    this.updated(leadDeliveryConfig)
  }

  handleStatusChange(template) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.responseStatusTemplate = template
    this.updated(leadDeliveryConfig)
  }

  handleLeadTemplateChange(template) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.leadTemplate = template
    this.updated(leadDeliveryConfig)
  }

  handleFileNameChange(fileName) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.fileName = fileName
    this.updated(leadDeliveryConfig)
  }

  handleSenderTypeChange(senderType) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.senderType = senderType
    this.updated(leadDeliveryConfig)
  }

  handleDatasourceTypeChange(datasourceType) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.datasourceType = datasourceType
    this.updated(leadDeliveryConfig)
  }

  handleFTPIteractionURLChange(url) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.ftp.URL = url
    this.updated(leadDeliveryConfig)
  }

  handleFTPInteractionDirectoryChange(directory) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.ftp.directory = directory
    this.updated(leadDeliveryConfig)
  }

  handleFTPInteractionSecureChange() {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.ftp.secure = !this.state.leadDeliveryConfig.sender.ftp.secure
    this.updated(leadDeliveryConfig)
  }

  handleFTPInteractionUserChange(user) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.ftp.user = user
    this.updated(leadDeliveryConfig)
  }

  handleFTPInteractionPasswordChange(password) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.ftp.password = password
    this.updated(leadDeliveryConfig)
  }

  handleEmailInteractionReplyAddressChange(address) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.email.replyToAddress = address
    this.updated(leadDeliveryConfig)
  }

  handleEmailInteractionToAddressChange(address) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.email.toAddress = address
    this.updated(leadDeliveryConfig)
  }

  handleHttpIntereactionAdd(interaction) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions.push(interaction)

    this.updated(leadDeliveryConfig)
  }

  handleInteractionOrder(interactionIndex, order) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        interaction.order = order
      }
      return interaction
    })
    this.updated(leadDeliveryConfig)
  }

  handleInteractionDelete(index) {
    this.state.leadDeliveryConfig.sender.http.interactionConfig.interactions.splice(index, 1)
    this.state.leadDeliveryConfig.sender.http.interactionConfig.interactions.forEach((i, index) => i.order = index + 1)
  }
 
  handleInteractionPreauthResponseUpdate(interactionIndex, res) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        interaction.preauth.response = res
      }
      return interaction
    })
    this.updated(leadDeliveryConfig)
  }

  handleInteractionPreauthKeyChange(interactionIndex, key) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        interaction.preauth.key = key
      }
      return interaction
    })
    this.updated(leadDeliveryConfig)
  }

  handleInteractionPreauthKeyExpiration(interactionIndex, keyExpiration) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        interaction.preauth.expiration = keyExpiration
      }
      return interaction
    })
    this.updated(leadDeliveryConfig)
  }

  handleInteractionVerbChange(interactionIndex, mode, submode, verb) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig


    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        if (mode && mode.length > 0) {
          console.log("with mode")
          interaction[mode][submode].verb = verb
        } else {
          console.log("without mode")
          interaction[submode].verb = verb
        }
      }
      return interaction
    })

    this.updated(leadDeliveryConfig)
  }

  handleInteractionUrlChange(interactionIndex, mode, submode, url) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        if (mode) {
          interaction[mode][submode].url = url
        } else {
          interaction[submode].url = url
        }
      }
      return interaction
    })

    this.updated(leadDeliveryConfig)
  }

  handleInteractionBodyChange(interactionIndex, mode, submode, body) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) { 
        if (mode) {
          interaction[mode][submode].body = body
        } else {
          interaction[submode].body = body
        }
      }
      return interaction
    })

    this.updated(leadDeliveryConfig)
  }

  handleInteractionHeadersUpdate(interactionIndex, mode, submode, header) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        const headers = (mode && mode.length > 0) ? interaction[mode][submode].headers : interaction[submode].headers
        let h = headers.find(e => e.key === header.Key)

        if(h){
          h.value = header.Value
        }else{
          headers.push(
            { key: header.Key, value: header.Value }
          )
        }
      }
      return interaction
    })

    this.updated(leadDeliveryConfig)
  }

  handleInteractionHeadersDelete(interactionIndex, mode, submode, header) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    let idx = 0
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        const headers = (mode) ? interaction[mode][submode].headers : interaction[submode].headers
        headers.forEach(h => {
          if (h.key === header.Key) {
            headers.splice(idx, 1)
          }
          idx++
        })
      }
      return interaction
    })

    this.updated(leadDeliveryConfig)
  }

  handleInteractionResponseUpdate(interactionIndex, response) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        interaction.response = response
      }
      return interaction
    })

    this.updated(leadDeliveryConfig)
  }

  handleInteractionHeadersSave(interactionIndex, mode, submode, header) {
    const leadDeliveryConfig = this.state.leadDeliveryConfig
    leadDeliveryConfig.sender.http.interactionConfig.interactions = leadDeliveryConfig.sender.http.interactionConfig.interactions.map((interaction, index) => {
      if (index === interactionIndex) {
        let headers = (mode) ? interaction[mode][submode].headers : interaction[submode].headers
        headers = headers || []
        headers.push(
          { key: header.Key, value: header.Value }
        )
        if (mode) {
          interaction[mode][submode].headers = headers
        } else {
          interaction[submode].headers = headers
        }
      }
      return interaction
    })

    this.updated(leadDeliveryConfig)
  }


  saveConfig() {
    const schedule = this.getLeadDeliverySchedule()
    this.props.save(this.state.leadDeliveryConfig, schedule)
    this.setState({
      isDirty: false
    })
  }

  cloneConfig() {
    this.props.clone(this.state.leadDeliveryConfig.UUID)
    this.setState({
      isDirty: false
    })
  }

  deleteLeadDeliveryConfig() {
    const schedule = this.getLeadDeliverySchedule()
    this.props.delete(this.state.leadDeliveryConfig.campaignUUID, this.state.leadDeliveryConfig.UUID, schedule.UUID)
    this.clearChanges()
  }

  clearChanges() {
    this.props.cancelLeadDeliveryConfig()
    this.setState({
      isDirty: false,
      //leadDeliveryConfig: null
    })
  }

  updated(leadDeliveryConfig) {
    const config = leadDeliveryConfig || this.state.leadDeliveryConfig
    this.setState({
      isDirty: true,
      leadDeliveryConfig: config
    })
  }

  clear() {
    this.props.clear()
    this.setState({})
  }

  emptyLead(lead) {
    if (!lead || !lead.user) {
      return true
    }
    let check = (s) => {
      return !s || s.length == 0
    }
    if (check(lead.user.firstName) && check(lead.user.lastName) && check(lead.user.email)) {
      return true
    }
    return false
  }

  test() {
    this.setState({
      showDialog: true,
      dialogTitle: "Test Lead Delivery Configuration",
      dialogText: "",
      onOk: () => {
        const testData = {
          config: this.state.leadDeliveryConfig,
          leads: {
            records: buildTestLeads()
          }
        }
        this.props.runLeadDeliveryTest(testData, this.state.verbose)
      },
      dialogCancel: () => {
        this.setState({
          showDialog: false
        })
      }
    })
  }

  normalizeConfigLookups(configLookups) {
    const normalizedConfigLookups = configLookups.map(e => {
      return { value: e, label: e.name }
    })
    return normalizedConfigLookups
  }

  render() {
    const configUUID = (this.state.leadDeliveryConfig) ? this.state.leadDeliveryConfig.UUID : ''
    return (
      <React.Fragment>
        <Prompt
          when={this.state.isDirty}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <section ref={t => this.contentSection = t}>
          <div className="content">
            <Modal appElement={this.contentSection}
              isOpen={this.state.showDialog}
              onRequestClose={() => this.state.dialogCancel()}
              contentLabel="">
              <Card style={{ maxWidth: "60vw", minWidth: "20vw" }} shadow={2}>
                <CardTitle>{this.state.dialogTitle}</CardTitle>
                <CardActions border>
                  <Grid>
                    <Cell col={12}>
                      {this.state.dialogText}
                    </Cell>
                    <Cell col={12}>
                      <Button
                        raised
                        colored
                        onClick={() => this.clear()}>
                        Clear
                      </Button>
                      <Button
                        style={{ marginLeft: '10px' }}
                        raised
                        colored
                        onClick={() => this.state.onOk()}>
                        Test
                      </Button>
                      <Button
                        style={{ marginLeft: '10px' }}
                        raised
                        colored
                        onClick={() => this.state.dialogCancel()}>
                        Cancel
                      </Button>
                    </Cell>
                    <Cell col={12}>
                      {this.props.leadDeliveryConfigTest.accepted ? <Icon
                        name="check_circle"
                        style={{
                          fontSize: '48px',
                          color: '#4caf50',
                        }}
                        title="New user"
                      /> : ''}
                      {this.props.leadDeliveryConfigError.Err ? <div style={{ color: 'red' }}>{this.props.leadDeliveryConfigError.Err.body && this.props.leadDeliveryConfigError.Err.body.length > 0 ? this.props.leadDeliveryConfigError.Err.body : "Error accessing the permission data API"}</div> : ''}
                      {this.props.leadDeliveryConfigTest.failed ? <div style={{ color: 'red' }}>{this.props.leadDeliveryConfigTest.message}</div> : ''}
                      <div style={{ color: 'gray' }}><pre>{this.props.leadDeliveryConfigTest.debugMessage}</pre></div>
                    </Cell>
                  </Grid>
                </CardActions>
              </Card>
            </Modal>
            <Grid>
              <Cell col={5}>
                <Select
                  placeholder={"Delivery Configurations"}
                  isMulti={false}
                  value={this.state.leadDeliveryConfig ? { label: this.state.leadDeliveryConfig.name, value: this.state.leadDeliveryConfig } : null}
                  onChange={(s) => {
                    this.configSelected(s.value)
                  }}
                  options={this.normalizeConfigLookups(this.props.leadDeliveryConfigLookups)}
                />
              </Cell>
              <Cell col={1}>
                <div style={{ float: 'left' }}>
                  {this.state.leadDeliveryConfig ?
                    <Button colored raised ripple onClick={(() => this.test())}>
                      Test
                    </Button> : <Button colored raised ripple disabled>
                      Test
                    </Button>}
                </div>
              </Cell>
              <Cell col={1}>
                <div style={{ float: 'left' }}>
                    {this.state.leadDeliveryConfig ?
                    <Button colored raised ripple onClick={(() => this.cloneConfig(this.state.leadDeliveryConfig.UUID))}>
                      Clone
                    </Button> : <Button colored raised ripple disabled>
                    Clone
                    </Button>}
                </div>
              </Cell>
              <Cell col={5}>
                <div style={{ float: 'right' }}>
                  {!this.state.isDirty
                    ? <Button style={{ marginLeft: '10px' }} colored raised ripple disabled>
                      <Icon name="save"> Save</Icon>
                    </Button>
                    : <Button
                      style={{ marginLeft: '10px' }}
                      colored
                      raised
                      ripple
                      onClick={() => this.saveConfig()}>
                      <Icon name="save"> Save</Icon>
                    </Button>}
                  {!this.state.leadDeliveryConfig
                    ? <Button style={{ marginLeft: '10px' }} colored raised ripple disabled>
                      <Icon name="cancel">Cancel</Icon>
                    </Button>
                    : <Button
                      style={{ marginLeft: '10px' }}
                      colored
                      raised
                      ripple
                      onClick={() => this.clearChanges()}
                    >
                      <Icon name="cancel">Cancel</Icon>
                    </Button>}
                  {!(this.state.leadDeliveryConfig && this.state.leadDeliveryConfig.UUID)
                    ? <Button style={{ marginLeft: '10px' }} colored raised ripple disabled>
                      <Icon name="delete">delete</Icon>
                    </Button>
                    : <Button
                      style={{ marginLeft: '10px' }}
                      colored
                      raised
                      ripple
                      onClick={() => this.deleteLeadDeliveryConfig()}
                    >
                      <Icon name="delete">delete</Icon>
                    </Button>}
                  {this.state.leadDeliveryConfig
                    ? <Button style={{ marginLeft: '10px' }} colored raised ripple disabled>
                      <Icon name="add_box"> New</Icon> New
                    </Button>
                    : <Button
                      style={{ marginLeft: '10px' }}
                      colored
                      raised
                      ripple
                      onClick={() => this.handleNewConfigClick()}>
                      <Icon name="add_box"> New</Icon> New
                    </Button>
                  }
                </div>
              </Cell>
            </Grid>
            <Grid>
              <Cell col={12}>
                {this.state.leadDeliveryConfig == null ? <React.Fragment><br></br><br></br><br></br><br></br></React.Fragment> : null}
                {this.state.leadDeliveryConfig == null ? null : <LeadDeliveryConfig
                  key={configUUID}
                  campaigns={this.props.campaigns}
                  placements={this.props.placements}
                  leadDeliveryConfig={this.state.leadDeliveryConfig}
                  leadDeliveryConfigIsLoading={this.props.leadDeliveryConfigIsLoading}
                  leadDeliverySchedule={this.props.leadDeliverySchedule}
                  leadDeliveryScheduleIsLoading={this.props.leadDeliveryScheduleIsLoading}
                  updated={() => this.updated()}
                  setGetLeadDeliverySchedule={this.setGetLeadDeliverySchedule}
                  handleNameChange={(e) => this.handleNameChange(e.target.value)}
                  handleNotesChange={(e) => this.handleNotesChange(e.target.value)}
                  handleEncryptionCertKeyChange={(selected) => this.handleEncryptionCertKeyChange(selected)}
                  handleEncryptionCertValueChange={(e) => this.handleEncryptionCertValueChange(e.target.value)}
                  handleStatusChange={(template) => this.handleStatusChange(template)}
                  handleLeadTemplateChange={(template) => this.handleLeadTemplateChange(template)}
                  handleFileNameChange={(filename) => this.handleFileNameChange(filename)}
                  handleSenderTypeChange={(senderType) => this.handleSenderTypeChange(senderType)}
                  handleFTPIteractionURLChange={(url) => this.handleFTPIteractionURLChange(url)}
                  handleFTPInteractionDirectoryChange={(directory) => this.handleFTPInteractionDirectoryChange(directory)}
                  handleFTPInteractionSecureChange={() => this.handleFTPInteractionSecureChange()}
                  handleFTPInteractionUserChange={(user) => this.handleFTPInteractionUserChange(user)}
                  handleFTPInteractionPasswordChange={(password) => this.handleFTPInteractionPasswordChange(password)}
                  handleEmailInteractionReplyAddressChange={(address) => this.handleEmailInteractionReplyAddressChange(address)}
                  handleEmailInteractionToAddressChange={(address) => this.handleEmailInteractionToAddressChange(address)}
                  handleHttpIntereactionAdd={(interaction) => this.handleHttpIntereactionAdd(interaction)}
                  handleInteractionOrder={(interactionIndex, order) => this.handleInteractionOrder(interactionIndex, order)}
                  handleInteractionDelete={(index) => this.handleInteractionDelete(index)}
                  handleInteractionPreauthResponseUpdate={response => this.handleInteractionPreauthResponseUpdate(response)}
                  handleInteractionPreauthKeyChange={(interactionIndex, key) => this.handleInteractionPreauthKeyChange(interactionIndex, key)}
                  handleInteractionPreauthKeyExpiration={(interactionIndex, keyExpiration) => this.handleInteractionPreauthKeyExpiration(interactionIndex, keyExpiration)}
                  handleInteractionVerbChange={(interactionIndex, mode, submode, verb) => this.handleInteractionVerbChange(interactionIndex, mode, submode, verb)}
                  handleInteractionUrlChange={(interactionIndex, mode, submode, verb) => this.handleInteractionUrlChange(interactionIndex, mode, submode, verb)}
                  handleInteractionBodyChange={(interactionIndex, mode, submode, body) => this.handleInteractionBodyChange(interactionIndex, mode, submode, body)}
                  handleInteractionHeadersUpdate={(interactionIndex, mode, submode, header) => this.handleInteractionHeadersUpdate(interactionIndex, mode, submode, header)}
                  handleInteractionHeadersDelete={(interactionIndex, mode, submode, header) => this.handleInteractionHeadersDelete(interactionIndex, mode, submode, header)}
                  handleInteractionHeadersSave={(interactionIndex, mode, submode, header) => this.handleInteractionHeadersSave(interactionIndex, mode, submode, header)}
                  handleInteractionResponseUpdate={(interactionIndex, response) => this.handleInteractionResponseUpdate(interactionIndex, response)}
                  handleScheduleTypeChange={(scheduleType) => this.handleScheduleTypeChange(scheduleType)}

                  handleDatasourceTypeChange={(senderType) => this.handleDatasourceTypeChange(senderType)}
                />}
              </Cell>
            </Grid>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const LeadDeliveryConfigs = connect(null, mapDispatchToProps)(leadDeliveryConfigs)

export default LeadDeliveryConfigs
