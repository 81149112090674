import { Controlled as CodeMirror, } from 'react-codemirror2'
import React from 'react'
require('codemirror/mode/javascript/javascript')


export default class Editor extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let mode = this.props.mode || 'htmlmixed'
    let options = {
      mode: mode,
      gutters: ['CodeMirror-lint-markers'],
      lineNumbers: true,
      lint: true
    }
    return (
      <div className="codeEditor" style={this.props.style}>
        <CodeMirror
          className={this.props.className}
          value={this.props.code}
          onChange={() => {
          }}
          onBeforeChange={(editor, data, value) => {
            this.props.onChange(value)
          }}
          options={options}
        />
      </div>
    )
  }
}
