const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS'
const CUSTOMERS_LOADED = 'CUSTOMERS_LOADED'
const CUSTOMERS_LOAD_ERROR = 'CUSTOMERS_LOAD_ERROR'

export const loadCustomers = () =>
    ({ type: LOAD_CUSTOMERS })

export const customerLoadedSuccess = (data) =>
    ({ type: CUSTOMERS_LOADED, data })

export const customerLoadedError = (error) =>
    ({ type: CUSTOMERS_LOAD_ERROR, error })

let initState = {
    isLoading: false,
    data: null,
    errorText: "",
}
export const customers = (state=initState, action) => {
    switch(action.type) {
        case LOAD_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
                data: null,
                errorText: "",
              }
        case CUSTOMERS_LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                data: null,
                errorText: action.error,
            }
        case CUSTOMERS_LOADED:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                errorText: "",
            }
        default:
            return state
    }
}