import React from 'react'
import { Grid, Cell, Icon, Button, ListItem, ListItemAction } from 'react-mdl'

export class RemovableItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <ListItem key={this.props.UUID}>
      <Grid style={{ marginLeft: 0, width: "100%" }}>
        <Cell col={this.props.secondary ? 8 : 12}>
          <span>{this.props.name}</span>
        </Cell>
        {this.props.secondary ? <Cell col={4}>
          <span>{this.props.secondary}</span>
        </Cell> : null}
      </Grid>
      <ListItemAction>
        <Button
          accent
          onClick={this.props.onRemove}
        >
          <Icon name="delete" />
        </Button>
      </ListItemAction>
    </ListItem>
  }
}