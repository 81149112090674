import React from 'react'
import { Switch, Textfield } from 'react-mdl'

export default class FieldMapping extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fieldMapping: this.props.fieldMapping || { uuid: '', jpath: '', fieldName: '', required: true }
        }
    }

    componentWillReceiveProps(nextProps) {
        let fieldMapping = this.state.fieldMapping
        fieldMapping = nextProps.fieldMapping
        this.setState({ fieldMapping })
    }

    render() {
        return <div>
            <Textfield
                floatingLabel
                label="JPATH"
                onChange={e => {
                    let fieldMapping = this.state.fieldMapping
                    fieldMapping.jpath = e.target.value
                    this.setState({ fieldMapping })
                }}
                value={this.state.fieldMapping.jpath || ''}
            />
            <Textfield
                floatingLabel
                label="Field Name"
                onChange={e => {
                    let fieldMapping = this.state.fieldMapping
                    fieldMapping.fieldName = e.target.value
                    this.setState({ fieldMapping })
                }}
                value={this.state.fieldMapping.fieldName || ''}
            />
            <Switch
                onChange={() => {
                    let fieldMapping = this.state.fieldMapping
                    fieldMapping.required = !this.state.fieldMapping.required
                    this.setState({ fieldMapping })
                }}
                checked={this.state.fieldMapping.required || false}
            >Required Field</Switch>
        </div>
    }
}
