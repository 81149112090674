import React from 'react'
import { Grid, Cell, Textfield, Button, Icon, List, ListItem, ListItemAction } from 'react-mdl'

export default class MapList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: null
    }
  }

  newItem() {
    this.setState({
      selectedItem: { Key: '', Value: '', new: true }
    })
  }

  render() {
    let listItems = this.props.items.map(f => {
      let click = () => {
        this.setState({
          selectedItem: { Value: f.Value, Key: f.Key || f.Value, new: false }
        })
      }
      return (
        <ListItem style={{ width: "100%" }} key={f.Key}>
          <Grid onClick={() => click()} style={{ width: "100%" }}>
            {this.props.hideKey ? null : <Cell style={{
              width: "100%",
            }} col={3}>{f.Key}</Cell>}
            <Cell onClick={() => click()} style={{
              width: "100%",
            }} col={9}>{f.Value}</Cell>
          </Grid>
          <ListItemAction>
            <Button
              accent
              colored
              onClick={() => {
                this.props.delete(f)
              }}
            >
              <Icon name="delete" />
            </Button>
          </ListItemAction>
        </ListItem>
      )
    })
    return (
      <div>
        <label>{this.props.title}</label>
        {this.state.selectedItem
          ? <Grid shadow={2} style={{ width: "100%" }}>
            {!this.props.hideKey
              ? <Textfield
                floatingLabel
                label="Key"
                onChange={(e) => {
                  const selectedItem = this.state.selectedItem
                  selectedItem.Key = e.target.value
                  this.setState({
                    selectedItem: selectedItem
                  })
                }}
                value={this.state.selectedItem.Key || ''}
              />
              : null} <Textfield
              label="Value"
              floatingLabel
              onChange={(e) => {
                const selectedItem = this.state.selectedItem
                selectedItem.Value = e.target.value
                this.setState({
                  selectedItem: selectedItem
                })
              }}
              value={this.state.selectedItem.Value || ''}
            /> <Button
              onClick={() => {
                if (this.state.selectedItem.new) {
                  this.props.save(this.state.selectedItem)
                } else {
                  this.props.update(this.state.selectedItem)
                }
                this.setState({
                  selectedItem: null
                })
              }}
            >
              <Icon name="add_circle" />Save
              </Button>
          </Grid>
          : null}
        <Button onClick={() => this.newItem()}><Icon name="add_box" />New</Button>
        <div className="scrollParent">
          {listItems.length > 0
            ? <div className="scrollBox" style={{ maxHeight: '600px' }}>
              <List>{listItems}</List>
            </div>
            : null}
        </div>
      </div>
    )
  }
}
