import React from 'react'
import Modal from 'react-modal'
import { hex_md5 } from '../../thirdparty/md5'
import sha256 from 'js-sha256'
import moment from 'moment'
import { Grid, Cell, Card, CardActions, CardTitle, Textfield, Button } from 'react-mdl'

const OutgoingFunctions = {
  Md5: {
    func: (input) => hex_md5(input),
    description: "MD5 hash algorithm as defined in RFC 1321",
    command: "md5",
  },

  Now: {
    func: () => moment().format("lll"),
    description: "Current local time",
    command: "now",
    withoutInput: true,
  },

  Sha256: {
    func: (input) => new Buffer(sha256(input)).toString('hex'),
    description: "SHA256 hash algorithms as defined in FIPS 180-4",
    command: "sha256",
  },

  Trim: {
    func: (input) => `>${input.trim()}<`,
    description: "Returns a slice of the text with all leading and trailing white space removed",
    command: "trim_space",
  },

  ToLower: {
    func: (input) => input.toLowerCase(),
    description: "Returns the text with all letters in lower case",
    command: "to_lower",
  },

  Truncate: {
    func: (input, index) => input.substring(index),
    description: "Returns the characters in a text between index and the end, the first charanter is the index 0",
    numericInput: true,
    numericLabel: "Index",
    command: "truncate",
  },

  "Within date range": {
    func: (input, days) => `${moment(input, ["M/D/YYYY"]).unix() < moment().subtract(days, "days").unix()}`,
    description: "Checks if the date is between now and lookback days, date format mm/dd/yyyy",
    numericInput: true,
    numericLabel: "Lookback days",
    command: "within_date_range",
  },

  Replacement: {
    func: (input, find, replace) => input.replace(find, replace),
    description: "Returns the input text with all non-overlapping instances of 'find' subtext replaced by 'replace' subtext",
    extraInputs: 2,
    extraInputLabel: "Text to find",
    command: "replacement",
  },

  "Format unix timestamp": {
    func: (input, format) => new moment.unix(parseInt(input)).format(format).toString(),
    description: "Transforms a Unix timestamp to a specific date format. Format sample : MM/DD/YYYY HH:mm:ss",
    extraInputs: 1,
    extraInputLabel: "Date format",
    command: "format_unix_ts",
  },
  "Date format": {
    func: (input, format) => new moment.unix(parseInt(input)).format(format).toString(),
    description: "Transforms a Unix timestamp to a specific date format. Format sample : MM/DD/YYYY HH:mm:ss",
    extraInputs: 1,
    extraInputLabel: "Date format",
    command: "date_format",
  },
  "Map key exists": {
    func: (input, key) => input[key] !== undefined,
    description: "Detect if a key is part of an object - use to find optional fields and perform logic",
    extraInputs: 1,
    extraInputLabel: "key check",
    command: "map_key_exists",
  },
  Contains: {
    func: (input, val) =>  input.includes(val),
    description: "Check if a value is contained in a string",
    command: "contains",
  },
  Accept: {
    func: (accept_message) => accept_message,
    description: "Accepts a lead with a accepted message",
    command: "accept",
  },
  Reject: {
    func: (rej_message) => rej_message,
    description: "Rejects a lead with a rejection message",
    command: "reject",
  },
  Fail: {
    func: (accept_message) => accept_message,
    description: "Reports a failure to deliver with a message to log",
    command: "fail",
  }
}

export default class FunctionsModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFunctionName: this.props.selectedFunctionName,
      functionData: this.props.selectedFunctionName ? OutgoingFunctions[this.props.selectedFunctionName] : {},
      functionResult: "",
      mainInput: "",
      numericInput: 0,
      extraInput1: "",
      extraInput2: "",
    }
  }

  handleFunctionModalExecution() {
    let secondInput = null
    let thrirdInput = null
    if (this.state.functionData.numericInput) {
      secondInput = this.state.numericInput
    }
    if (this.state.functionData.extraInputs) {
      secondInput = this.state.extraInput1
      thrirdInput = this.state.extraInput2
    }
    const result = this.state.functionData.func(this.state.mainInput, secondInput, thrirdInput)
    this.setState({
      functionResult: result
    })
  }

  handleCloseModal() {
    this.props.closeModal()
    this.setState({
      functionResult: "",
      mainInput: "",
      numericInput: 0,
      extraInput1: "",
      extraInput2: "",
    })
  }

  buildFunctionParameters() {
    if (this.state.functionData.withoutInput) {
      return ""
    }
    let numericInput = ""
    if (this.state.functionData.numericInput) {
      numericInput = ` ${this.state.numericInput}`
    }
    let extraInputs = ""
    if (this.state.functionData.extraInputs) {
      let inputValues = []
      for (let i = 1; i <= this.state.functionData.extraInputs; i++) {
        const value = this.state[`extraInput${i}`]
        inputValues.push(` "${value}"`)
      }
      extraInputs = inputValues.join("")
    }
    return ` "${this.state.mainInput}"${numericInput}${extraInputs}`
  }

  render() {
    return (
      <Modal appElement={this.props.contentSection}
        isOpen={this.props.showModal}
        onRequestClose={() => this.handleCloseModal()} >
        <Card style={{ maxWidth: "20vw", minWidth: "15vw" }} shadow={2}>
          <CardTitle>{this.state.selectedFunctionName} function</CardTitle>
          <CardActions border>
            <Grid>
              <Cell col={12} style={{ textAlign: "center", fontStyle: "italic" }}>
                <div>{this.state.functionData.description}</div>
              </Cell>
              <Cell col={12} style={{ backgroundColor: "#FEFEFE", paddingBottom: "10px" }}>
                <Grid>
                  <Cell col={6}>
                    <Textfield
                      disabled={this.state.functionData.withoutInput}
                      value={this.state.mainInput}
                      label="function input"
                      onChange={(e) => {
                        this.setState({
                          mainInput: e.target.value
                        })
                      }}
                    />
                    {this.state.functionData && this.state.functionData.numericInput ?
                      <Textfield
                        value={this.state.numericInput}
                        label={this.state.functionData.numericLabel}
                        pattern="-?[0-9]*(\.[0-9]+)?"
                        error="Input is not a number!"
                        onChange={(e) => {
                          this.setState({
                            numericInput: e.target.value
                          })
                        }}
                      /> : null
                    }
                    {this.state.functionData &&
                      this.state.functionData.extraInputs ?
                      <div>
                        <Textfield
                          value={this.props.extraInput1}
                          label={this.state.functionData.extraInputLabel}
                          onChange={(e) => {
                            this.setState({
                              extraInput1: e.target.value
                            })
                          }}
                        />
                        {
                          this.state.functionData.extraInputs > 1 ?
                            <Textfield
                              value={this.state.extraInput2}
                              label="text to replace"
                              onChange={(e) => {
                                this.setState({
                                  extraInput2: e.target.value
                                })
                              }}
                            /> : null
                        }
                      </div>
                      : null
                    }
                  </Cell>
                  <Cell col={6} style={{ textAlign: "right" }}>
                    <Button
                      style={{ marginTop: "20px" }}
                      raised
                      colored
                      onClick={() => this.handleFunctionModalExecution()}>
                      Run
                    </Button>
                  </Cell>
                </Grid>
              </Cell>
              {this.state.functionResult ?
                <Cell col={12}>
                  <Cell col={12}>
                    <div style={{ textAlign: "center", border: "1px solid #FEFEFE", padding: "5px", overflowX: "scroll" }}>
                      {this.state.functionResult}
                    </div>
                  </Cell>
                  <Cell col={12}>
                    <div style={{ textAlign: "center", border: "1px solid #FEFEFE", padding: "5px" }}>
                      {`{{${this.state.functionData.command}${this.buildFunctionParameters()}}}`}
                    </div>
                  </Cell>
                </Cell> : null
              }
              <Cell col={12} style={{ textAlign: "center", paddingTop: "5px" }}>
                <Button
                  raised
                  colored
                  onClick={() => this.handleCloseModal()}>
                  Close
                </Button>
              </Cell>
            </Grid>
          </CardActions>
        </Card>
      </Modal>
    )
  }
}