
import React from 'react'
import { Card, CardActions, CardTitle, Button, Icon, ListItem, ListItemAction } from 'react-mdl'

export default class EmailDomainReview extends React.Component{

    constructor(props){
        super(props)
    }

    handleBlockButton(domainHit){
      const newBlockedDomain = {name: domainHit.name, created_by: this.props.user.name}
      this.props.onBlock([newBlockedDomain])
      this.handleReviewedButton(domainHit)
    }

    handleReviewedButton(domainHit){
      domainHit.reviewed = true
      this.props.onReviewed(domainHit)
    }

    sortByHitCount(domainList){
      return domainList.sort((a,b) => b.count - a.count)
    }

    render(){
      const sortedDomainsList = this.sortByHitCount(this.props.emailDomainsReview)
      return (
        <Card shadow={2}>
          <CardTitle>Email domains review</CardTitle>
          <CardActions border style={{padding: "10px"}}>
              <ListItem>
              <span style={{fontWeight: "bold", width: "50%", display:"flex", justifyContent: "space-between"}}>
                <span>Name</span>
                <span>Hits count</span>
              </span>
              </ListItem>
              {sortedDomainsList.map((d, idx)=>{
                  return (
                    <ListItem key={idx} style={{display:"flex", justifyContent: "space-between"}}>
                      <span style={{width: "50%", display:"flex", justifyContent: "space-between"}}>
                        <span style={{width: "50%"}}>{d.name}</span>
                        <span style={{width: "50%", textAlign: "center"}}>{d.count}</span>
                      </span>
                      <ListItemAction>
                          <Button
                          accent
                          onClick={() => this.handleBlockButton(d)}
                          >
                          <Icon name="block" title={"Block this email domain"}/>
                          </Button>
                          <Button
                          onClick={() => this.handleReviewedButton(d)}
                          >
                          <Icon name="visibility" title={"Mark this email domain as reviewed"}/>
                          </Button>
                      </ListItemAction>
                    </ListItem>
                  )
              })}
          </CardActions>
        </Card>
      )
    }
}