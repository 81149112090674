import { retrieveCreative, retrieveCreativePreview, saveCreative } from "../../services"

import FilterList from "../filterList"
import React from "react"
import CreativeProperties from "./properties"
import CustomCreative from "./custom"
import { connect } from "react-redux"
import CreativeFields from "./fieldOrdering"
import { deepClone } from "../../utils"
import { Card, CardTitle, Grid, Cell, Tabs, Tab, Button } from "react-mdl"
import { Prompt } from "react-router"

function mapDispatchToProps(dispatch) {
  return {
    saveCreative(creative, onSave) {
      dispatch(saveCreative(creative, onSave))
    },
    reloadCreative(uuid) {
      dispatch(retrieveCreative(uuid))
    },
    preview(creative) {
      dispatch(retrieveCreativePreview(creative))
    }
  }
}

const creativeTab = 0
const customeTab = 1

class creative extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: creativeTab,
      preview: "",
      creative: deepClone(this.props.creative),
      item: deepClone(this.props.item),
      isDirty: false
    }
    this.props.setIsTabComponentDirty(() => this.state.isDirty)
  }

  handleCampaignSave(creative) {
    if (this.state.item.creativeUUID == creative.uuid) {
      return
    }

    let item = this.state.item
    item.creativeUUID = creative.uuid

    this.props.onSaveItem(this.state.item, [], true)

    this.setState({ item: item, creative: deepClone(creative) })
  }

  handleShiftUpFields(idx) {
    if (idx == 0) {
      return
    }
    let creative = this.state.creative
    creative.fields[idx].order += -1
    creative.fields[idx - 1].order += 1
    this.setState({ creative, isDirty: true })
  }

  handleShiftDownFields(idx) {
    if (idx == this.state.creative.fields.length) {
      return
    }
    let creative = this.state.creative
    creative.fields[idx].order += 1
    creative.fields[idx + 1].order += -1
    this.setState({ creative, isDirty: true })
  }

  handleVisibleChange(idx, visible) {
    let creative = this.state.creative
    creative.fields[idx].visible = visible
    this.setState({ creative, isDirty: true })
  }

  handleTextStringChange(propertyName, fieldName, value) {
    let creative = this.state.creative
    creative[propertyName][fieldName] = value
    this.setState({
      creative,
      isDirty: true
    })
  }

  handleCustomChange(propertyName, value) {
    let creative = this.state.creative
    creative[propertyName] = value
    this.setState({
      creative,
      isDirty: true
    })
  }

  handleSaveCreative() {
    this.props.saveCreative(this.state.creative, creative => this.handleCampaignSave(creative))
    this.setState({
      isDirty: false
    })
  }

  handleReloadCreative() {
    let creative = this.state.creative
    this.props.reloadCreative(this.state.creative.uuid)
    creative = this.props.creative
    this.setState({
      creative,
      isDirty: false
    })
  }

  handleTrustedFormChange(tf) {
    let creative = this.state.creative
    creative.trustedForm = tf
    this.setState({
      creative,
      isDirty: true
    })
  }

  handleTrustedFormKeyChange(tfKey) {
    let creative = this.state.creative
    creative.trustedFormKey = tfKey
    this.setState({ creative, isDirty: true })
  }

  render() {
    let preview = ""
    if (this.props.creativePreview.data) {
      preview = this.props.creativePreview.data.renderedCreative
    }
    const items = this.props.items || []
    const showSearchComponent = items.length ? true : false
    return (
      <React.Fragment>
        <Prompt when={this.state.isDirty} message="You have unsaved changes, are you sure you want to leave?" />
        <Grid>
          <Cell col={4}>
            <Button raised colored onClick={this.props.previewAgent}>
              View in Agent
            </Button>
            <Grid>
              <Cell col={12}>
                <CreativeFields
                  fields={this.state.creative.fields}
                  handleShiftUpFields={idx => this.handleShiftUpFields(idx)}
                  handleShiftDownFields={idx => this.handleShiftDownFields(idx)}
                  handleVisibleChange={(idx, visible) => this.handleVisibleChange(idx, visible)}
                />
              </Cell>
              {showSearchComponent ? (
                <Cell col={12}>
                  <input
                    type="text"
                    style={{ height: "1px", width: "1px", marginLeft: "-250px" }}
                    ref={textarea => (this.textArea = textarea)}
                    value={this.state.selected || ""}
                    onChange={() => {}}
                  />
                  <FilterList
                    label={"Questions"}
                    additionalFieldList={this.props.additionalFieldList}
                    primaryField={this.props.primaryField}
                    selectItem={q => {
                      this.setState({ selected: this.props.selectProp(q) })
                      setTimeout(() => {
                        this.textArea.select()
                        document.execCommand("copy")
                      }, 150)
                      this.props.selectItem(q)
                    }}
                    items={this.props.items}
                  />
                </Cell>
              ) : null}
            </Grid>
          </Cell>
          <Cell col={8}>
            <Card style={{ width: "99%" }} shadow={4}>
              <CardTitle>
                <Tabs
                  activeTab={this.state.activeTab}
                  onChange={tabId => {
                    this.setState({ activeTab: tabId })
                  }}
                  ripple
                  style={{ width: "100%" }}>
                  <Tab>Creative</Tab>
                  <Tab>Custom</Tab>
                </Tabs>
              </CardTitle>
              {this.state.activeTab === customeTab ? (
                <CustomCreative
                  isDirty={this.state.isDirty}
                  key={this.state.creative.uuid}
                  creative={this.state.creative}
                  saveCreative={() => this.handleSaveCreative()}
                  reloadCreative={() => this.handleReloadCreative()}
                  preview={preview}
                  creativePreview={this.props.creativePreview}
                  handleChange={(propertyName, value) => this.handleCustomChange(propertyName, value)}
                />
              ) : (
                <CreativeProperties
                  isDirty={this.state.isDirty}
                  handleTrustedFormKeyChange={tfKey => this.handleTrustedFormKeyChange(tfKey)}
                  handleTrustedFormChange={tf => this.handleTrustedFormChange(tf)}
                  key={this.state.creative.uuid}
                  handleTextStringChange={(propertyName, fieldName, value) =>
                    this.handleTextStringChange(propertyName, fieldName, value)
                  }
                  creative={this.state.creative}
                  saveCreative={() => this.handleSaveCreative()}
                  reloadCreative={() => this.handleReloadCreative()}
                />
              )}
            </Card>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }
}

const Creative = connect(null, mapDispatchToProps)(creative)

export default Creative
