import React from "react"
import { connect } from "react-redux"
import { Card, CardTitle, Grid, Cell, CardActions, Button, Icon, Checkbox, Textfield } from "react-mdl"

const Headline = "Headline"
const Subtext = "Subtext"
const Header = "Header"
const Body = "Body"
const Footer = "Footer"
const Image = "Image"

const textStrings = [Headline, Subtext, Header, Body, Footer]

class CreativeProperties extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Card shadow={2} style={{width: "99%" }}>
        <CardActions>
          <Button
            disabled={!this.props.isDirty}
            raised
            colored
            onClick={() => {
              this.props.saveCreative()
              this.setState(this.state)
            }}>
            <Icon name="save" />
          </Button>
          <Button
            disabled={!this.props.isDirty}
            raised
            colored
            onClick={() => {
              this.props.reloadCreative()
              this.setState(this.state)
            }}>
            <Icon name="restore" />
          </Button>
          <Grid style={{ width: "100%" }}>
            <Cell col={6}>
              <Checkbox
                ripple
                label={"Trusted Form"}
                checked={this.props.creative.trustedForm || false}
                onChange={() => {
                  this.props.handleTrustedFormChange(!this.props.creative.trustedForm)
                  this.setState(this.state)
                }}
              />
              {this.props.creative.trustedForm ? (
                <Textfield
                  floatingLabel
                  label={"Trusted Form Key"}
                  onChange={e => {
                    this.props.handleTrustedFormKeyChange(e.target.value)
                    this.setState(this.state)
                  }}
                  value={this.props.creative.trustedFormKey || ""}
                />
              ) : null}
            </Cell>
          </Grid>
        </CardActions>
        <Card shadow={2}>
          <Grid style={{ width: "100%" }}>
            <Cell col={6}>
              <Card>
                <CardTitle>Text Strings</CardTitle>
                {textStrings.map(t => (
                  <Textfield
                    floatingLabel
                    style={{ width: "100%" }}
                    key={t}
                    label={t}
                    onChange={e => {
                      this.props.handleTextStringChange("textStrings", t, e.target.value)
                      this.setState(this.state)
                    }}
                    value={this.props.creative.textStrings[t] || ""}
                  />
                ))}
              </Card>
            </Cell>
            <Cell col={6}>
              <Card>
                <CardTitle>Image URL</CardTitle>
                <Textfield
                  rows={3} 
                  key={Image}
                  label={Image}
                  style={{}}
                  onChange={e => {
                    this.props.handleTextStringChange("imageUrls", Image, e.target.value)
                    this.setState(this.state)
                  }}
                  value={this.props.creative.imageUrls[Image] || ""}
                />
              </Card>
            </Cell>
          </Grid>
        </Card>
      </Card>
    )
  }
}

const Properties = connect()(CreativeProperties)

export default Properties
