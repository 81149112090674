import React from 'react'
import Editor from '../editor'
import {Grid, Cell, Card, CardActions, CardTitle, ListItem, List} from 'react-mdl'

export default class SentStatus extends React.Component {
  constructor(props) {
    super(props)
  }

  handleChange(callback){
    callback()
    this.setState(this.state)
  }

  render() {
    let availableFields = []
    this.props.configuration.sender.http.interactionConfig.interactions.forEach(i => {
      if (!i.response.responseFieldMappings) {
        return
      }
      i.response.responseFieldMappings.forEach(fm => {
        availableFields.push(<ListItem key={fm.fieldName}>{fm.fieldName}</ListItem>)
      })
    })
    return <Card shadow={2} style={{ height: '100%' }}>
      <CardTitle> Response Status Mapping </CardTitle>
      <CardActions border>
        <Grid>
          <Cell col={3}>
            <p>example: (index .DataBucket &quot;FieldNameHere&quot;)</p>
            <List style={{ maxHeight: '230px', overflow: 'auto' }} >
              {availableFields}
            </List>
          </Cell>
          <Cell col={9}>
            <Editor
              style={{ height: '180px' }}
              code={this.props.configuration.responseStatusTemplate || '{{if eq (index .SOME_KEY) "SOME_VALUE"}}{{accept "all good"}}{{else}}{{reject "expected value not found"}}{{end}}'}
              onChange={(t)=>this.handleChange(()=>this.props.handleStatusChange(t))}
            />
          </Cell>
        </Grid>
      </CardActions>
    </Card>
  }
}
