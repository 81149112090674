import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import Editor from '../editor'
import MapList from '../maplist'
import { Grid, Cell, Textfield } from 'react-mdl'


const VERBS = [
      'GET',
      'POST',
      'PUT',
      'HEAD',
      'TRACE',
      'OPTIONS',
      'CONNECT',
      'PATCH',
    ]

class RequestForm extends React.Component{
    constructor(props){
        super(props)
        this.state = {}
    }

    handleHeaderChange(header, cb){
        cb(header)
        this.setState({})
    }

    render(){
        const headers = this.props.requestHeaders || []
        return (
            <Grid>
              <Cell col={3} style={{ paddingLeft: "40px" }}>
                <Select
                  placeholder={"Verb"}
                  isMulti={false}
                  value={VERBS[this.props.requestVerb] ? { label: VERBS[this.props.requestVerb], value: this.props.requestVerb } : null}
                  onChange={this.props.handleVerbChange}
                  options={VERBS.map((e, idx) => {
                    return { label: e, value: idx }
                  })}
                />
              </Cell>
              <Cell col={9}>
                <Textfield
                  floatingLabel
                  label="URL"
                  style={{width:"100%"}}
                  onChange={this.props.handleUrlChange}
                  value={ this.props.requestUrl || '' }
                />
              </Cell>
              <Cell col={8}>
                Body
                <Editor
                  code={ this.props.requestBody || '' }
                  onChange={this.props.handleBodyChange}
                  onBlur={this.props.update}
                />
              </Cell>
              <Cell col={4}>
                <MapList
                  title="Headers"
                  update={(h) => this.handleHeaderChange(h, this.props.handleHeaderUpdate)}
                  delete={(h) => this.handleHeaderChange(h, this.props.handleHeaderDelete)}
                  save={(h) => this.handleHeaderChange(h, this.props.handleHeaderSave)}
                  items={headers.map(
                    e => {
                      return { Key: e.key, Value: e.value }
                    },
                  )}
                />
              </Cell>
              {this.props.children}
            </Grid>
        )
    }
}

const requestForm = connect(null, null)(RequestForm)

export default requestForm
