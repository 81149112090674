import {
  replaceBlockedEmail,
  appendBlockedEmail,
  deleteBlockedEmail,
  deleteAllBlockedEmails,
  updateBlockedEmail,
  retrieveBlockedEmail,
  validateBlockedEmail,
} from '../../services'

import Blocklist from '../blocklist'
import React from 'react'
import { connect } from 'react-redux'


function mapDispatchToProps(dispatch) {
  return {
    addEmails: function (newEmails, replace) {
      if (replace) {
        dispatch(replaceBlockedEmail(newEmails))
      } else {
        dispatch(appendBlockedEmail(newEmails))
      }
    },
    validate: function (email) {
      dispatch(validateBlockedEmail(email))
    },
    deleteEmail: function (emailName) {
      dispatch(deleteBlockedEmail(emailName))
    },
    deleteAllEmails: function () {
      dispatch(deleteAllBlockedEmails())
    },
    updateEmail: function (email) {
      dispatch(updateBlockedEmail(email))
    },
    retrieveBlockedEmail: function (page) {
      dispatch(retrieveBlockedEmail(page))
    },
  }
}

const DEFAULT_PAGE_NUMBER = 1

class blockedEmails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editableEmail: null,
      newEmails: '',
      page: 1,
      newEmailsReplace: false,
    }
  }

  componentDidMount(){
    this.props.retrieveBlockedEmail(DEFAULT_PAGE_NUMBER)
  }

  render() {
    return (<Blocklist
      setName="Email"
      addValues={this.props.addEmails}
      updateValue={this.props.updateEmail}
      deleteAll={this.props.deleteAllEmails}
      deleteValue={this.props.deleteEmail}
      colName="address"
      colDisplay="Address"
      user={this.props.user}
      values={this.props.emails}
      validate={(email) => {
        this.props.validate(email)
      }}
      retrievePage={ page => this.props.retrieveBlockedEmail(page) }
      validationResult={this.props.blockedEmailResult || {}}
      setIsTabComponentDirty={this.props.setIsTabComponentDirty}
    />)
  }
}

const BlockedEmails = connect(null, mapDispatchToProps)(blockedEmails)

export default BlockedEmails
