import {
    customerLoadedSuccess,
    customerLoadedError,
    loadCustomers
} from '../reducers/customerReducer'
import { ActionTypes} from "../services" 

import { isNotFoundError, retrieve, save, getOpts, exceptionHandling, getUrlFor } from '.'
const customerURL = getUrlFor("customers", "customers")
const keyClockAccountURL = getUrlFor("customers", "create_account")

export function retrieveCustomersThunk(uuid) {
  return function (dispatch, getState) {
    dispatch(loadCustomers())
    return retrieve(customerURL, 0, 0, uuid, dispatch, getState())
      .then(res =>
        dispatch(customerLoadedSuccess(res.records)
        )
      ).catch(e =>{
        if (!isNotFoundError(e)){
          exceptionHandling(e, dispatch, "Getting customers")
        }
        if(e.statusText.lenght > 0){
          dispatch(customerLoadedError(`Getting customers: ${e.statusText}`))
        }else{
          dispatch(customerLoadedError(`Getting customers`))
        }
      } 
      )
  }
}

export function saveCustomerThunk(customer) {
  return function (dispatch, getState) {
    const verb = customer.UUID ? "PUT" : "POST"
    const url = customer.UUID ? `${customerURL}${customer.UUID}` : customerURL
    return save(getOpts(dispatch, customer, verb, url, null, null, getState()))
      .then(() => {
        dispatch(retrieveCustomersThunk())
      }).catch(e => exceptionHandling(e, dispatch, "Saving customer"))
  }
}

export function createKeyCloakAccountThunk(placementUUID, customerUUID, contact) {
  return function (dispatch, getState) {
    const verb = "POST"
    const url = `${keyClockAccountURL}${customerUUID}/${placementUUID}`
    return save(getOpts(dispatch, contact, verb, url, null, null, getState()))
      .then((res) => {
        dispatch(retrieveCustomersThunk())
        dispatch( 
        dispatch({ type: ActionTypes.KEYCLOAK_CREATE_USER_LOADED, data: res }))
      }).catch(e => exceptionHandling(e, dispatch, "Creating Key Cloak User"))
  }
}