import React from "react"
import { connect } from "react-redux"
import moment from 'moment'
import Stats from "../campaign/stats"
import Select from "react-select"
import TimeOfDayAvailabilityByCampaign from "./detailsTimeOfDayAvailabilityByCampaign"
import ValidationsByCampaign from "./detailsValidationsByCampaign"
import InitialCampaign from "./initialCampaign"
import RevenueShare from "./detailsRevenueShare"
import RevenueShareByCampaign from "./detailsRevenueShareByCampaign"
import OverallCapByCampaign from "./overallCapByCampaign"
import DailyCapByCampaign from "./detailsDailyCapByCampaign"
import { deepClone } from "../../utils"
import CriteriaFilterOverride from "./criteriaFilterOverride"
import LeadAcceptanceByCampaign from "./leadAcceptanceByCampaign"
import FilterList from "../filterList"
import {
  Grid,
  Cell,
  Card,
  CardActions,
  CardTitle,
  Textfield,
  Icon,
  Button,
  ListItem,
  CardText,
  List,
  Switch,
  CardMenu,
  Spinner
} from "react-mdl"
import { Prompt } from "react-router"
import DatePicker from "react-datepicker"
import AssociatedCampaigns from "./associatedCampaigns"

const ACCEPT_AREAS = [
  { label: "RADIO", value: 0 },
  { label: "BUTTON", value: 1 },
  { label: "BUTTONS", value: 2 },
  { label: "CHECKBOX", value: 3 },
  { label: "SELECT", value: 4 },
  { label: "PRESELECTED", value: 5 }
]

const DISPLAY_SIZES = [
  { label: "No-Images", value: 0 },
  { label: "Small (80x40)", value: 1 },
  { label: "Medium (120x90)", value: 2 },
  { label: "Large (350x250)", value: 3 }
]

const DEFAULT_PAGE_SIZE = 5
const DEFAULT_PAGE_LIMIT = 5

function detailStateUpdate(placement, associatedCampaignFilter) {
  placement.campaignLeadCaps = placement.campaignLeadCaps || []
  placement.defaultRevenueShare = placement.defaultRevenueShare || []
  placement.brokeredRevenueShare = placement.brokeredRevenueShare || []
  placement.campaignDaysTimeAvailability = placement.campaignDaysTimeAvailability || []
  placement.campaignOverallLeadCap = placement.campaignOverallLeadCap || []
  return {
    placement: placement,
    brokeredCampaign: {},
    dailyCapCampaign: {},
    timeOfDayAvailabilityCampaign: {},
    brokeredShare: {},
    rolledUpShare: true,
    status: placement.status,
    share: {},
    customer: {},
    associatedCampaignFilter: associatedCampaignFilter,
    showAssociatedCampaignFilter: false
  }
}

class placementDetail extends React.Component {
  constructor(props) {
    super(props)
    this.isModal = props.isModal || false
    this.props.setGetPlacement(() => {
      let placement = this.state.placement
      placement.pageSize = parseInt(this.state.placement.pageSize)
      this.setState({ placement })
      return this.state.placement
    })

    this.state = detailStateUpdate(deepClone(this.props.placement), this.props.associatedCampaignFilter)
    this.props.updatePlacementName(this.props.placement.name)
  }

  handleRankingPreferencesChange(v) {
    const { placement } = this.state
    let idx = placement.rankingService.indexOf(v.name)
    if (idx == -1) {
      idx = placement.rankingService.indexOf(v.value)
    }
    if (idx >= 0) {
      placement.rankingService.splice(idx, 1)
    } else {
      placement.rankingService.push(v.value)
    }
    this.props.placementUpdated()
    this.setState({
      placement: placement
    })
  }

  handleNameChange(name) {
    let placement = this.state.placement
    placement.name = name
    this.props.updatePlacementName(this.state.placement.name)
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleStatusChange() {
    let placement = this.state.placement
    placement.activated = !this.state.placement.activated
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleHideKnownFieldsChange() {
    let placement = this.state.placement
    placement.hideKnownFields = !this.state.placement.hideKnownFields
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleButtonPerOfferChange() {
    let placement = this.state.placement
    placement.buttonsPerOffer = !this.state.placement.buttonsPerOffer
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleIsMicrositeChange() {
    let placement = this.state.placement
    placement.isMicrosite = !this.state.placement.isMicrosite
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleCustomerChange(value) {
    let placement = this.state.placement
    placement.customerUUID = value
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleEditPageSizeButton() {
    let placement = this.state.placement
    placement.pageSize = DEFAULT_PAGE_SIZE
    this.props.placementUpdated()
    this.setState({ placement: placement, editSize: true })
  }

  handlePageSizeChange(size) {
    let placement = this.state.placement
    placement.pageSize = size
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleEditPageLimitButton() {
    let placement = this.state.placement
    placement.pageLimit = DEFAULT_PAGE_LIMIT
    this.props.placementUpdated()
    this.setState({ placement: placement, editLimit: true })
  }

  handlePageLimitChange(limit) {
    let placement = this.state.placement
    placement.pageLimit = limit
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleAcceptAreaChange(acceptArea) {
    let placement = this.state.placement
    placement.acceptArea = acceptArea
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleDisplaySizeChange(value) {
    let placement = this.state.placement
    placement.displaySize = value
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleRedirectUrlChange(url) {
    let placement = this.state.placement
    placement.redirectURL = url
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleSubmitButtonTextChange(text) {
    let placement = this.state.placement
    placement.submitButtonText = text
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleSkipButtonTextChange(text) {
    let placement = this.state.placement
    placement.skipButtonText = text
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleTerminationGenericMsgChange(message) {
    let placement = this.state.placement
    placement.terminationGenericMessage = message
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleTerminationErrorMsgChange(message) {
    let placement = this.state.placement
    placement.terminationErrorMessage = message
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleTerminationSkipperMsg(message) {
    let placement = this.state.placement
    placement.terminationSkipperMessage = message
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleTerminationTakerMsg(message) {
    let placement = this.state.placement
    placement.terminationTakerMessage = message
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleCustomSettings(customSettings) {
    let placement = this.state.placement
    placement.customSettings = customSettings
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleRevenueShareAdd(share) {
    let placement = this.state.placement
    if (!placement.defaultRevenueShare) {
      placement.defaultRevenueShare = []
    }
    placement.defaultRevenueShare.push(share)
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleRevenueShareUpdate(share, index) {
    let placement = this.state.placement
    placement.defaultRevenueShare[index] = share
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleRevenueShareDelete(index) {
    let placement = this.state.placement

    placement.defaultRevenueShare.splice(index, 1)

    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleAssociatedCampaignsChange(list){
    let placement = this.state.placement
    placement.whitelistCampaignAssociations = list
    this.props.placementUpdated()
    this.setState({ placement })
  }
  
  handleRevenueShareByCampaignChange(list) {
    let placement = this.state.placement
    placement.brokeredRevenueShare = list
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleCampaignOverallCapChange(list) {
    let placement = this.state.placement
    placement.campaignOverallLeadCap = list
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleSaveCampaignTimeOfDayAvailability(campaignTimeOfDayAvailability) {
    let placement = deepClone(this.state.placement)
    let campaignTimeOfDayAvailabilityList = placement.campaignDaysTimeAvailability.filter(
      c => c.campaignUuid != campaignTimeOfDayAvailability.campaignUuid
    )
    campaignTimeOfDayAvailabilityList.push(campaignTimeOfDayAvailability)
    placement.campaignDaysTimeAvailability = campaignTimeOfDayAvailabilityList
    this.props.placementUpdated()
    this.setState({ placement: placement })
  }

  handleDeleteCampaignTimeOfDayAvailability(campaignTimeOfDayAvailability) {
    let placement = this.state.placement
    let campaignTimeOfDayAvailabilityList = this.state.placement.campaignDaysTimeAvailability.filter(
      c => c.campaignUuid != campaignTimeOfDayAvailability.campaignUuid
    )
    placement.campaignDaysTimeAvailability = campaignTimeOfDayAvailabilityList
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleAddInitialCampaign(campaignUUId) {
    let placement = this.state.placement
    placement.initialCampaignUUID = campaignUUId
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleRemoveInitialCampaign() {
    let placement = this.state.placement
    placement.initialCampaignUUID = null
    this.props.placementUpdated()
    this.setState({ placement })
  }

  handleDailyCapByCampaignChange(leadCaps) {
    let placement = this.state.placement
    placement.campaignLeadCaps = leadCaps
    this.props.placementUpdated()
    this.setState(placement)
  }

  handleCriteriaFilterByCampaignChange(criteriaFilters) {
    if (this.state.placement.UUID) {
      this.props.saveAssociatedCriteriaFilters(criteriaFilters)
    } else {
      this.props.savePlacementAndCriteria(criteriaFilters)
    }
    this.props.placementUpdated()
    this.setState({ associatedCriteriaFilters: criteriaFilters })
  }

  handleCriteriaFilterByCampaignDelete(associatedCriteriaFilter) {
    this.props.deleteAssociatedCriteriaFilters(associatedCriteriaFilter)
    this.props.placementUpdated()
  }


  renderRankingPreferences() {
    return (
      <Card shadow={2} style={{ overflow: "visible", width: "100%" }}>
        <CardTitle>
          <span className="mdl-card__title-text">Ranking Preferences</span>
        </CardTitle>
        <List>
          {this.props.rankers
            .filter(r => r.name && r.name.length > 0)
            .map(r => {
              let isChecked = (this.state.placement.rankingService || []).findIndex(pr => pr == r.value) >= 0
              const itemId = r.name.split(" ").join("-")
              return (
                <ListItem key={r.name}>
                  <Grid style={{ width: "100%" }}>
                    <Cell col={8}>{r.name}</Cell>
                    <Cell id={`${itemId}-cell`} col={4}>
                      <Switch
                        id={`${itemId}-check`}
                        onChange={() => this.handleRankingPreferencesChange(r)}
                        checked={isChecked}
                      />
                    </Cell>
                  </Grid>
                </ListItem>
              )
            })}
        </List>
      </Card>
    )
  }

  renderCustomerSelect(customerUUID) {
    if(this.props.customers?.isLoading)return <div><Spinner singleColor /></div>
    const customer = this.props.customers?.data?.find(e=> e.UUID === customerUUID)
    if(this.props.customers?.errorText?.length > 0){
      return <div>Customer:{customerUUID} <div style={{ color: "red" }}>Err:{this.props.customers?.errorText}</div></div>
    }
    
    return <Select
      id="publisher-select"
      placeholder={"Choose Publisher"}
      isMulti={false}
      value={customer ? { label: customer.name, value: customer.UUID } : null}
      onChange={val => this.handleCustomerChange(val.value)}
      options={this.props.customers?.data?.filter(c => c.type === (this.props.placement.isBrokered ? 2 : 1)).map(c => {
        return { label: c.name, value: c.UUID }
      })}
    />
  }

  displayAssociatedCampaignFilterList() {
    this.setState({
      showAssociatedCampaignFilter: true
    })
  }

  hideAssociatedCampaignFilterList() {
    this.setState({
      showAssociatedCampaignFilter: false
    })
  }

  associatedCampaignFilterSelected(campaign) {
    this.setState({
      associatedCampaignFilter: campaign,
      showAssociatedCampaignFilter: false
    })
  }

  removeAssociatedCampaigFilter() {
    this.setState({
      associatedCampaignFilter: null,
      showAssociatedCampaignFilter: false
    })
  }

  normalizeDate(date) {
    let resultDate = parseInt(date)
    if (!resultDate || isNaN(resultDate)) {
      return null
    }
    return moment.unix(resultDate)
  }

  handleAssociationInactiveToggle() {
    let placement = this.state.placement
    let whitelistItemIdx = placement.whitelistCampaignAssociations.findIndex(e=>e.campaignUuid == this.state.associatedCampaignFilter)
    placement.whitelistCampaignAssociations[whitelistItemIdx].inactive = !placement.whitelistCampaignAssociations[whitelistItemIdx].inactive

    this.props.placementUpdated()
    this.setState(placement)
  }

  handleAssociationEndDateChange(date) {
    date = moment(date)

    let placement = this.state.placement
    let whitelistItemIdx = placement.whitelistCampaignAssociations.findIndex(e=>e.campaignUuid == this.state.associatedCampaignFilter)
    placement.whitelistCampaignAssociations[whitelistItemIdx].endDate = date ? date.unix() : 0

    this.props.placementUpdated()
    this.setState(placement)
  }

  handleScreenshotToggle() {
    let placement = this.state.placement
    let whitelistItemIdx = placement.whitelistCampaignAssociations.findIndex(e=>e.campaignUuid == this.state.associatedCampaignFilter)
    placement.whitelistCampaignAssociations[whitelistItemIdx].screenShot = !placement.whitelistCampaignAssociations[whitelistItemIdx].screenShot

    this.props.placementUpdated()
    this.setState(placement)
  }

  handleIgnoreExpirationToggle() {
    let placement = this.state.placement
    let whitelistItemIdx = placement.whitelistCampaignAssociations.findIndex(e=>e.campaignUuid == this.state.associatedCampaignFilter)
    placement.whitelistCampaignAssociations[whitelistItemIdx].ignoreLocalTimeExpiration = !placement.whitelistCampaignAssociations[whitelistItemIdx].ignoreLocalTimeExpiration

    this.props.placementUpdated()
    this.setState(placement)
  }

  handleTestLeadToggle() {
    let placement = this.state.placement
    let whitelistItemIdx = placement.whitelistCampaignAssociations.findIndex(e=>e.campaignUuid == this.state.associatedCampaignFilter)
    placement.whitelistCampaignAssociations[whitelistItemIdx].testLead = !placement.whitelistCampaignAssociations[whitelistItemIdx].testLead

    this.props.placementUpdated()
    this.setState(placement)
  }

  handleLiveToggle() {
    let placement = this.state.placement
    let whitelistItemIdx = placement.whitelistCampaignAssociations.findIndex(e=>e.campaignUuid == this.state.associatedCampaignFilter)
    placement.whitelistCampaignAssociations[whitelistItemIdx].live = !placement.whitelistCampaignAssociations[whitelistItemIdx].live

    this.props.placementUpdated()
    this.setState(placement)
  }

  renderAssociationSettings(){
    let whitelistItem = this.state.placement.whitelistCampaignAssociations.find((c) => c.campaignUuid == this.state.associatedCampaignFilter)
    
    if(!whitelistItem){
      whitelistItem = {
        campaignUuid: this.state.associatedCampaignFilter,
        inactive: true,
        testLead: false,
        live: false,
        endDate: moment().unix(),
        screenShot: false,
        ignoreLocalTimeExpiration: false,
        createTime: moment().unix(),
      }
      this.state.placement.whitelistCampaignAssociations.push(whitelistItem)
    }

    let endDate = this.normalizeDate(whitelistItem.endDate)
    
    return <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
    <CardTitle expand>
      <div style={{fontSize: "22px", fontWeight: "300"}}>Whitelist</div>
    </CardTitle>
    <CardActions border>
      <Grid style={{ padding: "1px" }}>
        <Cell col={2}>
          <Switch
            onChange={() => this.handleAssociationInactiveToggle()}
            checked={!whitelistItem.inactive}
          >Active</Switch>
        </Cell>
        <Cell col={4}>
          End Date: <DatePicker
            isClearable={true}
            dateFormat="MM/dd/yyyy"
            selected={!endDate ? null : endDate.toDate()}
            withPortal
            onChange={dateMoment => this.handleAssociationEndDateChange(dateMoment)}
          />
        </Cell>
        <Cell col={2}>
          <Switch
            onChange={() => this.handleIgnoreExpirationToggle()}
            checked={whitelistItem.ignoreLocalTimeExpiration}
          >Ignore expiration</Switch>
        </Cell>
        <Cell col={2}>
          <Switch
            onChange={() => this.handleScreenshotToggle()}
            checked={whitelistItem.screenShot}
          >Screen shot</Switch>
        </Cell>
        <Cell col={2}>
          <Switch
            onChange={() => this.handleTestLeadToggle()}
            checked={whitelistItem.testLead}
          >Test Lead</Switch>
        </Cell>
        <Cell col={2}>
          <Switch
            onChange={() => this.handleLiveToggle()}
            checked={whitelistItem.live}
          >Live</Switch>
        </Cell>
      </Grid>
    </CardActions>
  </Card>
  }

  setIsTabComponentDirty(){}

  renderCampaignAssociations() {
    let filterCampaign = this.props.campaigns.find(e => e.UUID === this.state.associatedCampaignFilter)
    return (
      <Card shadow={2} style={{ overflow: "visible", width: "100%", paddingBottom: "122px" }}>
        <CardTitle expand>
          <div style={{ fontSize: "24px" }}>Campaign Associations</div>
          {filterCampaign ? (
            <div style={{ marginLeft: "20px", padding: "10px", backgroundColor: "#d3d3d354" }}>
              <div style={{ width: "150%", display: "flex", justifyContent: "spece-between", alignItems: "center" }}>
                <div>Filtering by </div>
                <div style={{ marginLeft: "10px", color: "#009abf" }}>
                  <strong>{filterCampaign.name}</strong>
                </div>
                <div style={{ margin: "0px 10px" }}>{" - "}</div>
                <div style={{ color: "grey" }}>{filterCampaign.shortCode}</div>
                <div style={{}}>
                  <Button onClick={() => this.removeAssociatedCampaigFilter()}>
                    <Icon name="close">Close</Icon>
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </CardTitle>
        <CardMenu>
          <Button onClick={() => this.displayAssociatedCampaignFilterList()}>
            <Icon name="filter_list">Add</Icon>
          </Button>
        </CardMenu>
        <CardActions border>
          <Grid>
            {this.state.showAssociatedCampaignFilter ? (
              <Cell col={12}>
                <Button onClick={() => this.hideAssociatedCampaignFilterList()}>
                  <Icon name="close">Close</Icon>
                </Button>
                <FilterList
                  label="Campaign list..."
                  selected={this.state.associatedCampagnFilter ? this.state.associatedCampaignFilter : null}
                  additionalFieldList={["UUID", "shortCode"]}
                  title="Associated Campaigns"
                  selectItem={p => this.associatedCampaignFilterSelected(p)}
                  items={this.props.campaigns}
                />
              </Cell>
            ) : null}

            {this.isModal ? (
              <Cell col={12}>
                {this.renderAssociationSettings()}
              </Cell>
            ) : <Cell col={12}>
                <AssociatedCampaigns
                  key={4}
                  placement={this.state.placement}
                  placements={this.props.placements}
                  campaigns={this.props.campaigns}
                  onChange={(list) => this.handleAssociatedCampaignsChange(list)}
                  setIsTabComponentDirty={this.setIsTabComponentDirty}
                />
              </Cell>}

            <Cell col={6}>
              <RevenueShareByCampaign
                placement={this.state.placement}
                list={this.state.placement.brokeredRevenueShare}
                campaignUUID={this.props.campaignUUID}
                campaigns={this.props.campaigns}
                onChange={(list) => this.handleRevenueShareByCampaignChange(list)}
                filter={this.state.associatedCampaignFilter ? this.state.associatedCampaignFilter : null}
              />
            </Cell>
            <Cell col={6}>
              <DailyCapByCampaign
                key={this.state.placement.campaignLeadCaps.map(c => c.cap).join()}
                placement={this.state.placement}
                campaigns={this.props.campaigns}
                onSave={leadCaps => this.handleDailyCapByCampaignChange(leadCaps)}
                filter={this.state.associatedCampaignFilter ? this.state.associatedCampaignFilter : null}
                dailyCapCounts={this.props.dailyCapCounts}
                retrieveAssociationDailyCapCount={(campaignUuid) => this.props.retrieveAssociationDailyCapCount(campaignUuid)}
                onDelete={leadCaps => this.handleDailyCapByCampaignChange(leadCaps)}></DailyCapByCampaign>
            </Cell>
            <Cell col={6}>
              <CriteriaFilterOverride
                zips={this.props.zips}
                placement={this.state.placement}
                associatedCriteria={this.props.associatedCriteria}
                associatedCriteriaSaveMsg={this.props.associatedCriteriaSaveMsg}
                resetAssociatedCriteriaSaveMsg={this.props.resetAssociatedCriteriaSaveMsg}
                campaigns={this.props.campaigns}
                campaignCriteria={this.props.campaignCriteria}
                onSave={associatedCriteria => this.handleCriteriaFilterByCampaignChange(associatedCriteria)}
                onDelete={associatedCriteria => this.handleCriteriaFilterByCampaignDelete(associatedCriteria)}
                retrieveCampaignCriteria={this.props.retrieveCampaignCriteria}
                filter={this.state.associatedCampaignFilter ? this.state.associatedCampaignFilter : null}
              />
            </Cell>
            <Cell col={6}>
              <LeadAcceptanceByCampaign
                associatedLeadAcceptance={this.props.associatedLeadAcceptance}
                placement={this.props.placement}
                campaigns={this.props.campaigns}
                savePlacementAssociatedLeadAcceptances={this.props.savePlacementAssociatedLeadAcceptances}
                filter={this.state.associatedCampaignFilter ? this.state.associatedCampaignFilter : null}
                deleteAssociatedLeadAcceptance={this.props.deleteAssociatedLeadAcceptance}></LeadAcceptanceByCampaign>
            </Cell>
            <Cell col={6}>
              <TimeOfDayAvailabilityByCampaign
                key={
                  this.state.placement.campaignDaysTimeAvailability
                    ? this.state.placement.campaignDaysTimeAvailability.length
                    : ""
                }
                placement={this.state.placement}
                campaigns={this.props.campaigns}
                filter={this.state.associatedCampaignFilter ? this.state.associatedCampaignFilter : null}
                onSave={schedule => this.handleSaveCampaignTimeOfDayAvailability(schedule)}
                onDelete={schedule =>
                  this.handleDeleteCampaignTimeOfDayAvailability(schedule)
                }></TimeOfDayAvailabilityByCampaign>
            </Cell>
            {this.state.placement.isBrokered ? (
              <Cell col={6}>
                <OverallCapByCampaign
                  placement={this.state.placement}
                  list={this.state.placement.campaignOverallLeadCap}
                  campaigns={this.props.campaigns}
                  onChange={overallCaps => this.handleCampaignOverallCapChange(overallCaps)}
                  retrieveAssociationOverallCapCount={(campaignUuid, effectiveDate) => this.props.retrieveAssociationOverallCapCount(campaignUuid, effectiveDate)}
                  filter={this.state.associatedCampaignFilter ? this.state.associatedCampaignFilter : null}
                  currentCapCounts={this.props.currentCapCounts}
                />
              </Cell>
            ) : null}
            <Cell col={6}>
              <ValidationsByCampaign
                key={
                  this.props.associatedAddressValidations
                    ? this.props.associatedAddressValidations.length
                    : ""
                }
                associatedAddressValidations={this.props.associatedAddressValidations}
                placement={this.state.placement}
                campaigns={this.props.campaigns}
                filter={this.state.associatedCampaignFilter ? this.state.associatedCampaignFilter : null}
                onSave={this.props.saveAddressValidations}
              >
              </ValidationsByCampaign>
            </Cell>
          </Grid>
        </CardActions>
      </Card>
    )
  }

  renderAdquirePlacement() {
    return (
      <Grid style={{ width: "100%", paddingLeft: "0px", paddingRight: "0px" }}>
        <Cell col={12} style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }}>
          <Grid style={{ width: "100%", paddingLeft: "0px", paddingRight: "0px" }}>
            <Cell col={12} style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }}>
              <Card shadow={2} style={{ overflow: "visible", width: "100%" }}>
                <CardTitle>
                  <div
                    style={{ width: "100%", display: "flex", justifyContent: "spece-between", alignItems: "center" }}>
                    <Textfield
                      required
                      style={{ width: "75%", marginRight: "10px" }}
                      floatingLabel
                      label="Placement Name"
                      onChange={e => this.handleNameChange(e.target.value)}
                      value={this.state.placement.name || ""}
                    />
                    <div style={{ width: "25%", margin: "5px 10px" }}>
                      {!this.props.isNew && <Stats
                        stats={this.props.itemStats}
                        retrieve={() => this.props.retrievePlacementStats(this.props.placement.UUID)}
                      />}
                    </div>
                  </div>
                </CardTitle>
                <CardText>
                  <div id="placementUUID">{this.state.placement.UUID}</div>
                  {this.state.placement.legacyID}
                </CardText>
                <CardActions border>
                  <Grid style={{ width: "100%" }}>
                    <Cell col={2}>
                      <Switch onChange={() => this.handleStatusChange()} checked={this.state.placement.activated}>
                        Active
                      </Switch>
                    </Cell>
                    <Cell col={2} id="broker-change-cell">
                      Brokered: {this.state.placement.isBrokered ? "Yes" : "No"}
                    </Cell>
                    <Cell col={4}>
                      <Grid style={{ width: "100%" }}>
                        <Cell col={6}>
                          <Switch
                            onChange={() => this.handleHideKnownFieldsChange()}
                            checked={this.state.placement.hideKnownFields ? true : false}>
                            Hide Known Fields
                        </Switch>
                        <Switch
                          onChange={() => this.handleButtonPerOfferChange()}
                          checked={this.state.placement.buttonsPerOffer ? true : false}>
                          Buttons Per Offer
                        </Switch>  
                        </Cell>  
                      <Cell col={6}>
                        <Switch
                          onChange={() => this.handleIsMicrositeChange()}
                          checked={this.state.placement.isMicrosite ? true : false}>
                          Is Microsite
                        </Switch>  
                      </Cell>  
                      </Grid>
                    </Cell>
                    <Cell col={4}>{this.renderCustomerSelect(this.state.placement.customerUUID)}</Cell>
                    <Cell col={4}>
                      {!this.state.editSize && this.state.placement.pageSize == 0 ? (
                        <Button raised onClick={() => this.handleEditPageSizeButton()}>
                          Set Ads Per Page
                        </Button>
                      ) : (
                        <Textfield
                          pattern="^(?![\s\S])|([0-9]+)"
                          error="Input is not a number!"
                          style={{ width: "100%" }}
                          floatingLabel
                          label="Ads Per Page"
                          onChange={e => this.handlePageSizeChange(e.target.value)}
                          value={this.state.placement.pageSize}
                        />
                      )}
                    </Cell>
                    <Cell col={4}>
                      {!this.state.editLimit && this.state.placement.pageLimit == 0 ? (
                        <Button raised onClick={() => this.handleEditPageLimitButton()}>
                          Set Page Limit
                        </Button>
                      ) : (
                        <Textfield
                          pattern="^(?![\s\S])|([0-9]+)"
                          error="Input is not a number!"
                          style={{ width: "100%" }}
                          floatingLabel
                          label="Page Limit"
                          onChange={e => this.handlePageLimitChange(e.target.value)}
                          value={this.state.placement.pageLimit}
                        />
                      )}
                    </Cell>
                    <Cell col={4}>
                      <Select
                        placeholder={"Accept Area"}
                        isMulti={false}
                        value={ACCEPT_AREAS[this.state.placement.acceptArea]}
                        onChange={selected => this.handleAcceptAreaChange(selected.value)}
                        options={ACCEPT_AREAS}
                      />
                    </Cell>
                    <Cell col={4}>
                      <Select
                        placeholder={"Display Size"}
                        isMulti={false}
                        value={DISPLAY_SIZES[this.state.placement.displaySize]}
                        onChange={s => this.handleDisplaySizeChange(s.value)}
                        options={DISPLAY_SIZES}
                      />
                    </Cell>
                    <Cell col={6}>
                      <Textfield
                        style={{ width: "100%" }}
                        floatingLabel
                        label="Redirect URL"
                        onChange={e => this.handleRedirectUrlChange(e.target.value)}
                        value={this.state.placement.redirectURL || ""}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        style={{ width: "100%" }}
                        floatingLabel
                        label="Submit Button Text"
                        onChange={e => this.handleSubmitButtonTextChange(e.target.value)}
                        value={this.state.placement.submitButtonText || "Submit"}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        style={{ width: "100%" }}
                        floatingLabel
                        label="Skip Button Text"
                        onChange={e => this.handleSkipButtonTextChange(e.target.value)}
                        value={this.state.placement.skipButtonText || "Skip"}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        style={{ width: "100%" }}
                        floatingLabel
                        label="Termination Msg"
                        onChange={e => this.handleTerminationGenericMsgChange(e.target.value)}
                        value={this.state.placement.terminationGenericMessage || ""}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        style={{ width: "100%" }}
                        floatingLabel
                        label="Termination Error Msg"
                        onChange={e => this.handleTerminationErrorMsgChange(e.target.value)}
                        value={this.state.placement.terminationErrorMessage || ""}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        style={{ width: "100%" }}
                        floatingLabel
                        label="Termination Skipper Msg"
                        onChange={e => this.handleTerminationSkipperMsg(e.target.value)}
                        value={this.state.placement.terminationSkipperMessage || ""}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        style={{ width: "100%" }}
                        floatingLabel
                        label="Termination Taker Msg"
                        onChange={e => this.handleTerminationTakerMsg(e.target.value)}
                        value={this.state.placement.terminationTakerMessage || ""}
                      />
                    </Cell>

                    <Cell col={4} className="customSettings">
                      <Textfield
                        value={this.state.placement.customSettings}
                        floatingLabel
                        label="Legacy Agent Custom Settings"
                        onChange={e => this.handleCustomSettings(e.target.value)}
                      />
                    </Cell>
                  </Grid>
                </CardActions>
              </Card>
            </Cell>
          </Grid>
          <Grid style={{ width: "100%", paddingLeft: "0px", paddingRight: "0px" }}>
            <Cell col={4}>
              <RevenueShare
                list={this.state.placement.defaultRevenueShare}
                onAdd={share => this.handleRevenueShareAdd(share)}
                onDelete={idx => this.handleRevenueShareDelete(idx)}
                onUpdate={(share, idx) => this.handleRevenueShareUpdate(share, idx)}
              />
            </Cell>
            <Cell col={4}>{this.renderRankingPreferences()}</Cell>
            <Cell col={4}>
              <InitialCampaign
                key={this.state.placement.initialCampaignUUID}
                initialCampaignUUID={this.state.placement.initialCampaignUUID}
                campaigns={this.props.campaigns}
                selectInitialCampaign={uuid => this.handleAddInitialCampaign(uuid)}
                removeInitialCampaign={() => this.handleRemoveInitialCampaign()}
              />
            </Cell>
            {!this.props.isNew && <Cell col={12}>{this.renderCampaignAssociations()}</Cell>}
          </Grid>
        </Cell>
      </Grid>
    )
  }

  renderBrokeredPlacement() {
    return (
      <Grid style={{ padding: "0px" }}>
        <Cell col={12}>
          <Grid>
            <Cell col={8}>
              <Card shadow={2} style={{ overflow: "visible", width: "100%" }}>
                <CardTitle style={{ paddingBottom: "0px" }}>
                  <Textfield
                    required
                    style={{ width: "100%", marginRight: "15px" }}
                    floatingLabel
                    label="Placement Name"
                    onChange={e => this.handleNameChange(e.target.value)}
                    value={this.state.placement.name || ""}
                  />
                </CardTitle>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <CardText style={{ width: "65%" }}>
                    <div id="placementUUID">{this.state.placement.UUID}</div>
                    {this.state.placement.legacyID}
                  </CardText>
                  <div style={{ marginRight: "30px", marginBottom: "10px", width: "35%", minHeight: "68px" }}>
                    {!this.props.isNew && <Stats
                      stats={this.props.itemStats}
                      retrieve={() => this.props.retrievePlacementStats(this.props.placement.UUID)}
                    />}
                  </div>
                </div>
                <CardActions border>
                  <Grid style={{ width: "100%" }}>
                    <Cell col={2}>
                      <Switch onChange={() => this.handleStatusChange()} checked={this.state.placement.activated}>
                        Active
                      </Switch>
                    </Cell>
                    <Cell col={2}>
                      Brokered: {this.state.placement.isBrokered ? "Yes" : "No"}
                    </Cell>
                    <Cell col={4}>{this.renderCustomerSelect(this.state.placement.customerUUID)}</Cell>
                  </Grid>
                </CardActions>
              </Card>
            </Cell>
            <Cell col={4}>
              <RevenueShare
                list={this.state.placement.defaultRevenueShare}
                onAdd={share => this.handleRevenueShareAdd(share)}
                onDelete={idx => this.handleRevenueShareDelete(idx)}
                onUpdate={(share, idx) => this.handleRevenueShareUpdate(share, idx)}
                absoluteByDefault={true}
              />
            </Cell>
          </Grid>
          {!this.props.isNew &&
            <Grid>
              <Cell col={12}>{this.renderCampaignAssociations()}</Cell>
            </Grid>}
        </Cell>
      </Grid>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Prompt when={this.props.isDirtyValue} message="You have unsaved changes, are you sure you want to leave?" />
        {this.isModal ? this.renderCampaignAssociations() : this.state.placement.isBrokered ? this.renderBrokeredPlacement() : this.renderAdquirePlacement()}
      </React.Fragment>
    )
  }
}

const PlacementDetail = connect(null, null)(placementDetail)

export default PlacementDetail
