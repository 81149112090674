import React from 'react'
import Select, { components } from 'react-select'
import { Grid, Cell } from "react-mdl"
import { activeDates } from "../../utils"

const isActive = item => {
  return (
    !item.isArchived &&
    !item.isPaused &&
    activeDates(item)
  )
}
const isArchived = item => {
  return item.isArchived
}
const isIPV6Allowed = item => {
  return item.ipv6Allowed
}

export class CampaignSelector extends React.Component {
  constructor(props) {
    super(props)

    this.isMulti = this.props.isMulti == undefined ? true : this.props.isMulti

    this.state = {
      isActiveFilter: this.props.isActiveFilter,
      isArchivedFilter: this.props.isArchivedFilter,
      isIPv6Allowed: this.props.isIPv6Allowed,
    }
  }

  onSelectedHandler(s) {
    this.props.onChange(s)
  }

  render() {
    let { isActiveFilter, isArchivedFilter, isIPv6Allowed } = this.state

    let filteredCampaigns = this.props.campaigns.filter(e => {
      if (isActiveFilter && !isActive(e)) return false
      if (isArchivedFilter && !isArchived(e)) return false
      if (isIPv6Allowed && !isIPV6Allowed(e)) return false
      return true
    })

    let campaignOptions = filteredCampaigns.map(e => {
      return { value: e.UUID, label: e.name + " - " + e.shortCode }
    })
    campaignOptions.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : 0)


    const Menu = props => {
      return (
        <React.Fragment>
          <div onMouseDown={(e) => { e.preventDefault(); e.stopPropagation() }}>
            <Grid>
              <Cell col={4}>
                <input
                  type="checkbox" name="campaign_is-active-filter"
                  checked={this.state.isActiveFilter}
                  onChange={() => {
                    this.setState({
                      isActiveFilter: !this.state.isActiveFilter,
                    })
                  }}
                /> Is Active
              </Cell>
              <Cell col={4}>
                <input
                  type="checkbox" name="campaign_is-archived-filter"
                  checked={this.state.isArchivedFilter}
                  onChange={() => {
                    this.setState({
                      isArchivedFilter: !this.state.isArchivedFilter,
                    })
                  }}
                /> Is Archived
              </Cell>
              <Cell col={4}>
                <input
                  type="checkbox" name="campaign_is-ipv6allowed-filter"
                  checked={this.state.isIPv6Allowed}
                  onChange={() => {
                    this.setState({
                      isIPv6Allowed: !this.state.isIPv6Allowed,
                    })
                  }}
                /> Is IPv6 Allowed
              </Cell>
            </Grid>
          </div>
          <components.Menu {...props}>{props.children}</components.Menu>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Select
          isMulti={this.isMulti}
          closeMenuOnSelect={this.isMulti ? false : true}
          value={this.props.selectedCampaigns || []}
          onChange={s => this.onSelectedHandler(s)}
          options={campaignOptions}
          components={{ Menu }}
        />
      </React.Fragment>
    )
  }
}
