import {
  removePlacementGroupMember,
  savePlacementToGroup,
} from '../../services'
import React from 'react'
import { connect } from 'react-redux'
import CampaignWhitelist from './campaignWhitelist'
import {Grid, Cell} from 'react-mdl'
import { Collapsible } from "../common/components"


function mapDispatchToProps(dispatch) {
  return {
    removeGroupMember: function (uuid) {
      dispatch(removePlacementGroupMember(uuid))
    },
    savePlacementToGroup: function (targetPlacement, group) {
      dispatch(savePlacementToGroup(targetPlacement, group))
    },
  }
}

class associatedCampaigns extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCollapse: false,
    }
  }

  updatePlacementCampaignWhitelist(whitelist){
    this.props.onChange(whitelist)
    this.setState({})
  }

  handleRemoveGroupMember(uuid){
    this.props.removeGroupMember(uuid)
  }

  render() {
    return (
          <Collapsible fontSize={'24'} title="Campaign Whitelist" defaultOpen={this.state.isCollapsed} onToggle={() => this.setState({isCollapsed: !this.state.isCollapsed})} onClick={() => this.onToggle(this.state.isCollapsed)}>
            <Grid>
            <Cell col={12}>
            <CampaignWhitelist
              whitelist={this.props.placement.whitelistCampaignAssociations}
              campaigns={this.props.campaigns}
              update={(whitelist) => this.updatePlacementCampaignWhitelist(whitelist)}
              setIsTabComponentDirty={this.props.setIsTabComponentDirty}
            />
            </Cell>
          </Grid>
          </Collapsible>
    )
  }
}

const AssociatedCampaigns = connect(null, mapDispatchToProps)(associatedCampaigns)
export default AssociatedCampaigns
