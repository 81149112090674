import React from 'react'
import Modal from 'react-modal'
import {Grid, Cell, Card, CardActions, CardTitle, Icon, Button, CardMenu} from 'react-mdl'

const fieldModalRowStyles = {
  width: "50%",
  float: "left",
  textAlign: "center",
  marginBottom: "10px",
}

const ownTypeModalRowStyle = {
  ...fieldModalRowStyles,
  marginBottom: "0px",
}

const fieldModalHeaderStyles = {
  ...fieldModalRowStyles,
  borderBottom: "1px solid grey",
  marginBottom: "20px",
  paddingBottom: "5px",
  fontWeight: "bold",
}

function normalizeFieldType(fieldTypeName){
  switch (fieldTypeName){
    case "bool" :
    case "string": return "text"
    case "uint64":
    case "int32":
    case "uint32":
    case "int64": return "number"
  }
  return fieldTypeName
}

function isTypePrimitive(fieldType){
  return (fieldType.includes("text") || fieldType.includes("number"))
}

function retrieveFields(objectRoot, typeName){
  const protoObject = objectRoot.lookupType(typeName)
  const fields = Object.keys(protoObject.fields).map((field)=>{
    const fieldData = protoObject.fields[field]
    const objectType = {}
    objectType.name = `${field[0].toUpperCase()}${field.substring(1, field.length)}`
    objectType.type = `${normalizeFieldType(fieldData.type)} ${(fieldData.rule === "repeated" ? "list" : "")}`
    objectType.isPrimitive = isTypePrimitive(objectType.type)
    objectType.protoType = fieldData.type
    return objectType
  })
  return fields
}

export default class FieldsModal extends React.Component {
    constructor(props){
      super(props)
      const {selectedTypeName} = this.props
      const fields = selectedTypeName ? retrieveFields(`leads.${selectedTypeName}`) : []
      this.state = {
          initialTypeName: selectedTypeName,
          typeName: selectedTypeName,
          fields: fields,
          fieldsHistory: [],
      }
    } 

    handleCloseModal(){
      this.props.closeModal()
      this.setState({
        fieldsHistory: [],
        typeName: this.state.initialTypeName,
        fields: retrieveFields(`leads.${this.state.initialTypeName}`) 
      })
    }
  
    redirectFieldsModal(previousFieldName, typeName){
      const fieldsHistory = this.state.fieldsHistory
      fieldsHistory.push(previousFieldName)
      this.state.
      this.setState({
        fieldsHistory: fieldsHistory,
        typeName: typeName,
        fields: retrieveFields(`leads.${typeName}`) 
      })
    }

    backToPreviousFieldsModal(){
      const fieldsHistory = this.state.fieldsHistory
      const previousTypeName = fieldsHistory.pop()
      this.setState({
        fieldsHistory: fieldsHistory,
        typeName: previousTypeName,
        fields: retrieveFields(`leads.${previousTypeName}`) 
      })
    }

    render(){
      return (
        <Modal appElement={this.props.contentSection}
            isOpen={this.props.showModal}
            onRequestClose={ ()=>this.handleCloseModal()} >
            <Card style={{ maxWidth: "35vw", minWidth: "25vw" }} shadow={2}>
            <CardTitle>{this.state.typeName} fields</CardTitle>
            <CardMenu>
                { this.state.fieldsHistory.length > 0 ?
                    <Button
                    onClick={() => this.backToPreviousFieldsModal()}>
                    <Icon name="keyboard_backspace"/>
                    </Button>
                    : null
                }
            </CardMenu>
            <CardActions border>
                <Grid>
                <Cell col={12} style={{backgroundColor: "#FEFEFE", paddingBottom: "10px"}}>
                    <Cell col={12} >
                    <div style={fieldModalHeaderStyles} ><span >Name</span></div>
                    <div style={fieldModalHeaderStyles} ><span >Type</span></div>
                    </Cell>
                    {this.state.fields.map((f, idx)=>{
                    return (
                        <Cell key={idx} col={12} >
                        <div style={fieldModalRowStyles}>{f.name}</div>
                        { f.isPrimitive ?
                            <div style={fieldModalRowStyles}>{f.type}</div> :
                            <div style={ownTypeModalRowStyle}>
                            <p
                            onClick={()=> this.redirectFieldsModal(this.state.typeName, f.protoType)}
                            style={{marginBottom: "10px", fontSize: "16px", lineHeight: "normal", cursor: "pointer", color: "#00556f", fontStyle: "italic"}}
                            >
                            {f.type}
                            </p>
                            </div>
                        }
                        </Cell>
                    )
                    })}
                </Cell>
                <Cell col={12} style={{textAlign: "center", paddingTop: "5px"}}>
                    <Button
                    raised
                    colored
                    onClick={ ()=>this.handleCloseModal() }>
                    Close
                    </Button>
                </Cell>
                </Grid>
            </CardActions>
            </Card>
        </Modal>
      )
    }
}