import React from 'react'
import RequestForm from '../common/requestForm'
import {Grid, Cell, Card, CardActions, CardTitle, Textfield, Icon, Button, Tab, Tabs} from 'react-mdl'
import HttpResponse from './response'

const DEFAULT_EXPIRATION_VALUE = 5

export default class HttpPreauth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      rolledUp: true
    }
  }

  toggleRollUp(e) {
    e.preventDefault()
    this.setState({
      rolledUp: !this.state.rolledUp
    })
  }

  updated() {
    this.props.updated()
  }

  handleChange(callback){
    callback()
    this.setState({})
  }

  render() {
    const {interactionIndex} = this.props
    this.elements = [
        <RequestForm
            key={0}
            requestVerb={this.props.preauth.request.verb}
            requestUrl={this.props.preauth.request.url}
            requestBody={this.props.preauth.request.body}
            requestHeaders={this.props.preauth.request.headers}
            handleVerbChange={(s)=>this.handleChange(()=>this.props.handleInteractionVerbChange(interactionIndex,'preauth', 'request', s.value))}
            handleUrlChange={(e)=>this.handleChange(()=>this.props.handleInteractionUrlChange(interactionIndex, 'preauth', 'request', e.target.value))}
            handleBodyChange={(b)=>this.handleChange(()=>this.props.handleInteractionBodyChange(interactionIndex, 'preauth', 'request', b))}
            handleHeaderUpdate={(h)=>this.handleChange(()=>this.props.handleInteractionHeadersUpdate(interactionIndex, 'preauth', 'request', h))}
            handleHeaderDelete={(h)=>this.handleChange(()=>this.props.handleInteractionHeadersDelete(interactionIndex, 'preauth', 'request', h))}
            handleHeaderSave={(h)=>this.handleChange(()=>this.props.handleInteractionHeadersSave(interactionIndex, 'preauth', 'request',h))}
        />,
        <HttpResponse 
        key={2} shadow={1} 
        response={this.props.preauth.response} 
        updated={(res) =>{
          this.props.handleInteractionPreauthResponseUpdate(interactionIndex, res)} }/>,

    ]
    return <Card shadow={this.props.shadow}>
      <CardTitle>HTTP PreAuth</CardTitle>
      <CardActions border>
        <Grid>
          <Cell col={6}>
            <Textfield
              floatingLabel
              label="key"
              onChange={(e)=>this.handleChange(()=>this.props.handleInteractionPreauthKeyChange(this.props.interactionIndex, e.target.value))}
              value={this.props.interaction.preauth.key || ''}
            />
            <Textfield
              floatingLabel
              label="Key Expiration (seconds)"
              onChange={(e)=>this.handleChange(()=>this.props.handleInteractionPreauthKeyExpiration(this.props.interactionIndex, e.target.value))}
              value={this.props.interaction.preauth.expiration || DEFAULT_EXPIRATION_VALUE}
            />
          </Cell>
          <Cell col={12}>
            <Button
              colored
              raised
              ripple
              onClick={e => this.toggleRollUp(e)}>
              {this.state.rolledUp ? 'Configure' : <Icon name='keyboard_arrow_up' />}
            </Button>
            {this.state.rolledUp ?
              <div></div>
              : <div>
                <Tabs
                  activeTab={this.state.activeTab}
                  onChange={tabId => {
                    this.setState({
                      activeTab: tabId
                    })
                  }}
                  ripple
                  style={{ width: '100%' }}>
                  <Tab>Request</Tab>
                  <Tab>Response</Tab>
                </Tabs>
                <section>
                  <div className="content">
                    {this.elements[this.state.activeTab]}
                  </div>
                </section>
              </div>}
          </Cell>
        </Grid>
      </CardActions>
    </Card>
  }
}
