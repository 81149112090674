import React from "react"
import FilterHeader from "../filterHeader.js"
import { List, Icon, Checkbox, ListItem, Tooltip } from "react-mdl"

function paginate(array, size, currentPage) {
  if (array.length < (currentPage - 1) * size) {
    return paginate(array, size, --currentPage)
  }
  return array.slice(0, currentPage * size)
}

export default class FilterList extends React.Component {
  constructor(props) {
    super(props)
    const filterCheckbox = this.props.checkboxFilters ? true : false
    this.state = {
      filter: "",
      filtered: [],
      page: 1,
      uuidLabel: this.props.uuidLabel || "UUID",
      additionalFieldList: this.props.additionalFieldList || [],
      displayCheckboxFilters: filterCheckbox,
      filterCheckBox: filterCheckbox ? this.props.checkboxFilterDefaults : null
    }
    this.predicate = this.predicate.bind(this)
  }

  predicate(item) {
    if (this.state.filter.length == 0) return true
    if (this.props.simple) {
      return item.includes(this.state.filter)
    }
    const data = this.props.additionalFieldList.map(f => item[f]).concat([item[this.props.primaryField || "name"]])

    return data.some(i => (i ? i.toLowerCase().includes(this.state.filter.trim()) : false))
  }

  clicky(item, e) {
    if (this.props.selectItem) {
      this.props.selectItem(item, e)
    } else if (this.props.onDelete) {
      this.props.onDelete(item)
    }
  }

  normalizeItems(items) {
    items.map(item => {
      return this.props.additionalFieldList.map(f => item[f]).concat([item[this.props.primaryField || "name"]])
    })
  }

  selectItemsToDisplay(items) {
    if (this.state.displayCheckboxFilters) {
      this.state.filterCheckBox.forEach((applyFilter, idx) => {
        if (applyFilter) {
          items = items.filter(this.props.checkboxFilters[idx])
        }
      })
    }
    const filtered = items.filter(this.predicate)
    return paginate(filtered, 100, this.state.page)
  }

  scroll(scrollTop) {
    let liHeight = 45
    let page = parseInt(scrollTop / liHeight / 33)
    page++
    if (page != this.state.page) {
      this.setState({
        page: page
      })
    }
  }

  handleFilterHeaderChange(filter) {
    this.setState({
      filter: filter.toLowerCase()
    })
  }

  handleCheckboxFilterChange(idx) {
    const { filterCheckBox } = this.state
    filterCheckBox[idx] = !filterCheckBox[idx]
    this.setState({
      filterCheckBox: filterCheckBox
    })
  }

  render() {
    const items = this.selectItemsToDisplay(this.props.items)
    return (
      <div style={this.props.style}>
        {!this.props.hideHeader ? (
          <FilterHeader
            label={this.props.label}
            hidePage={true}
            value={this.state.filter || ""}
            onChange={ev => this.handleFilterHeaderChange(ev.target.value)}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {this.state.displayCheckboxFilters
                ? this.props.checkboxFilters.map((f, idx) => {
                  return (
                    <div key={idx} style={{ marginBottom: "20px" }}>
                      <Checkbox
                        ripple
                        label={this.props.checkboxFilterLabels[idx] || ""}
                        checked={this.state.filterCheckBox[idx]}
                        onChange={() => this.handleCheckboxFilterChange(idx)}
                      />
                    </div>
                  )
                })
                : null}
            </div>
          </FilterHeader>
        ) : null}
        <div className="scrollParent">
          <div className="filterListDiv scrollBox" onScroll={e => {
            this.scroll(e.target.scrollTop)
          }}>
            <List id={`${this.props.title}-resultsList`} style={{ whiteSpace: "normal", width: "100%" }}>
              {items.map((item, idx) => {
                let icon = {}
                if (this.props.getIcon) {
                  icon = this.props.getIcon(item)
                }
                return (
                  <ListItem
                    className={this.props.selected == item[this.state.uuidLabel] ? "is-selected" : ""}
                    onMouseDown={(e) => this.clicky(item, e)}

                    key={idx}>
                    <div className="container">
                      <div className="item">
                        <span className="primaryText">
                          {this.props.simple ? item : item[this.props.primaryField || "name"]}
                        </span>
                        {this.state.additionalFieldList.filter(f => f !== this.state.uuidLabel).map(field => {
                          return (
                            <span
                              key={item.UUID + field}
                              className="mdl-card__subtitle-text"
                              style={{ padding: "12px", fontSize: "65%" }}>
                              {item[field]}
                            </span>
                          )
                        })}
                      </div>
                      <div className="item push-right">
                        {this.props.getIcon ? (
                          <Tooltip label={<span>{icon.msg}</span>}>
                            <Icon name={icon.icon} />
                          </Tooltip>
                        ) : null}
                        {this.props.onDelete ? (
                          <Tooltip label={<span>Delete</span>}>
                            <Icon name="delete" onClick={() => this.props.onDelete(item)} />
                          </Tooltip>
                        ) : null}
                      </div>
                    </div>
                  </ListItem>
                )
              })}
            </List>
          </div>
        </div>
      </div>
    )
  }
}
