import React from 'react'
import {ListItem} from 'react-mdl'

export default class Draggable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { data: { items: [] } }
  }
  sort(items, dragging) {
    var data = this.state.data
    data.items = items
    data.dragging = dragging
    this.setState({ data: data })
  }
  dragEnd() {
    this.sort(this.props.items, undefined)
    this.props.onEnd(this.props.items)
  }
  dragStart(e) {
    this.dragged = Number(e.currentTarget.dataset.id)
    e.dataTransfer.effectAllowed = 'move'

    // Firefox requires calling dataTransfer.setData for the drag to properly work
    e.dataTransfer.setData('text/html', null)
  }
  dragOver(e) {
    e.preventDefault()

    var over = e.currentTarget
    var dragging = this.state.data.dragging
    var from = isFinite(dragging) ? dragging : this.dragged
    var to = Number(over.dataset.id)
    var items = this.props.items
    items.splice(to, 0, items.splice(from, 1)[0])
    this.sort(items, to)
  }
  render() {
    return <div>{this.props.items.map((item, i) => {
      var dragging = i == this.state.data.dragging
        ? 'dragging pd-drag-ready'
        : 'pd-drag-ready'
      return (
        <ListItem
          data-id={i}
          className={dragging}
          key={i}
          draggable="true"
          onDragEnd={e => this.dragEnd(e)}
          onDragOver={e => this.dragOver(e)}
          onDragStart={e => this.dragStart(e)}
        >
          {item.element}
        </ListItem>
      )
    })}</div>
  }
}
