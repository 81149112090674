import React from 'react'
import Editor from '../editor'
import {Grid, Cell, Card, CardActions, ListItem, List, Switch} from 'react-mdl'

export default class WinbackLogic extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let availableFields = []
    this.props.winback.interactions.interactions.forEach(i => {
      if (!i.response.responseFieldMappings) {
        return
      }
      i.response.responseFieldMappings.forEach(fm => {
        availableFields.push(<ListItem key={fm.fieldName}>{fm.fieldName}</ListItem>)
      })
    })
    return <Card shadow={2} style={{ height: '100%' }}>
      <CardActions border>
        <Switch
          onChange={() => this.props.handleWinbackIfTrueToggle()}
          checked={this.props.winback.winbackIfTrue}
        >{this.props.winback.winbackIfTrue ? 'True Response Enables Winbacks' : 'True Response Excludes Winbacks'} </Switch>
        <Grid>
          <Cell col={3}>
            <p>example: (index .DataBucket &quot;FieldNameHere&quot;)</p>
            <List style={{ maxHeight: '230px', overflow: 'auto' }} >
              {availableFields}
            </List>
          </Cell>
          <Cell col={9}>
            <Editor
              style={{ height: '180px' }}
              code={this.props.winback.logicTemplate || '{{if eq (index .SOME_KEY) "SOME_VALUE"}}true{{else}}anything but true{{end}}'}
              onChange={(c) => this.props.handleWinbackLogicTemplateChange(c)}
            />
          </Cell>
        </Grid>
      </CardActions>
    </Card>
  }
}