import React from 'react'
import PaginatedTable from './paginatedTable.js'
import ConfirmModal from './modal'
import {Grid, Cell, Card, CardActions, CardTitle, Textfield, Icon, Button, Switch} from 'react-mdl'
import { Prompt } from 'react-router'

const testResultstyle = {
  textAlign: "center",
  backgroundColor: "#F0F8FF",
  padding: "10px",
  display:"flex",
  justifyContent: "space-between",
  alignItems: "center",
  minWidth: "200px"
}

export default class BlockList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newValues: '',
      page: 1,
      newValuesReplace: false,
      testValue: '',
      testedValue: '',
    }
    this.props.setIsTabComponentDirty(() => this.state.newValues.length > 0)
  }

  dialogCancel() {
    this.setState({
      showDialog : false,
      dialogTitle : '',
      emailName : '',
    })
  }

  normalizeValues(newValues){
    return newValues.trim().split(/[\s,]+/)
      .filter(d => {
        return d.length > 0
      })
      .map(d => {
        let obj = { "createdBy": this.props.user.name }
        obj[this.props.colName] = d
        return obj
      })
  }

  handleShowModal(){
    this.setState({
      showDialog : true,
    })
  }

  handleSaveButton(){
    const newValues = this.normalizeValues(this.state.newValues)
    this.props.addValues(newValues, this.state.newValueReplace)
    this.setState({
      newValues : '',
    })
  }

  handleModalOk(){
    const newValues = this.normalizeValues(this.state.newValues)
    this.props.addValues(newValues, this.state.newValueReplace)
    this.dialogCancel()
    this.setState({
      newValues : '',
    })
  }

  handleValueChange(value){
    this.setState({
      newValues : value,
    })
  }

  handleReplaceToggle(){
    this.setState({
      newValueReplace : !this.state.newValueReplace
    })
  }

  handleTestEntryValueChange(value){
    this.setState({
      testValue : value,
    })
  }

  handlePageChange(page){
    this.props.values.data = []
    this.props.retrievePage(page)
    this.setState({
      page : page,
    })
  }

  handleTestEntryValue(){
    this.props.validate(this.state.testValue)
    this.setState({
      testedValue: this.state.testValue,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Prompt
          when={this.state.newValues.length > 0}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <section ref={t => (this.contentSection = t)}>
          <div className="content">
            <ConfirmModal
              showDialog={this.state.showDialog}
              contentSection={this.contentSection}
              dialogTitle={`Replace All ${this.props.setName}!`}
              dialogText={`You are about to replace all ${this.props.setName}s with the ${this.normalizeValues(this.state.newValues).length} ${this.props.setName} you've entered. Are you sure you want to do it?`}
              onOk={ () => this.handleModalOk() }
              dialogCancel={ () => this.dialogCancel() }
            />
            <Grid>
              <Cell col={4}>
                <Grid  style={{paddingTop: "0px"}}>
                  <Cell col={12}>
                    <Card shadow={2}>
                      <CardTitle>Bulk Insert</CardTitle>
                      <CardActions border>
                        <Grid>
                          <Cell col={12}>
                            <Textfield
                              floatingLabel
                              label="Comma, space, or new-line delimited list"
                              onChange={ e => this.handleValueChange(e.target.value) }
                              value={this.state.newValues || ""}
                              rows={6}
                            />
                            <Switch
                              onChange={ () => this.handleReplaceToggle() }
                              checked={this.state.newValueReplace || false}>
                              {`Replace ${this.props.setName}s`}
                            </Switch>
                            <div className="mdl-cell mdl-cell--12-col">
                              <Button
                                onClick={ () => this.state.newValueReplace ? this.handleShowModal() : this.handleSaveButton() }
                                raised ripple colored
                                disabled={this.state.newValues.length == 0}>
                                  <Icon name="save" /> Save
                              </Button>
                            </div>
                          </Cell>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={12}>
                    <Card shadow={2}>
                      <CardTitle>Test Entry</CardTitle>
                      <CardActions border>
                        <Grid>
                          <Cell col={8}>
                            <Textfield
                              floatingLabel
                              label={`Test ${this.props.setName}`}
                              onChange={ e => this.handleTestEntryValueChange(e.target.value) }
                              value={this.state.testValue}
                            />
                          </Cell>
                          <Cell col={3} style={{marginRight: "10px"}}>
                              <Button
                                onClick={() => this.handleTestEntryValue()}
                                disabled={this.state.testValue.length == 0}
                                raised
                                ripple
                                colored>
                                  <Icon name="check" /> Test
                              </Button>
                          </Cell>
                          <Cell col={12}>
                            {this.props.validationResult.createdDate && this.state.testedValue
                              ? this.props.validationResult.validationResult.isValid
                                  ?<div style={Object.assign({border: "1px solid green"}, testResultstyle)}>
                                  <span style={{color: "green", marginRight: "20px"}}> <strong>Valid</strong></span>
                                  <span>{`"${this.state.testedValue}" not found in blacklist`}</span>
                                </div>
                                :<div>
                                  <div style={Object.assign({border: "1px solid red"}, testResultstyle)}>
                                    <div style={{color: "red", marginRight: "20px"}}><strong>Invalid</strong></div>
                                    <div>{`"${this.state.testedValue}" found in blacklist`}</div>
                                  </div>
                                  <div>{
                                  this.props.validationResult.validationResult.substring ? <ul>
                                    {
                                    this.props.validationResult.validationResult.substring.map((s, idx) => <li key={idx}>{s}</li>)
                                    }
                                  </ul>
                                  : null
                                  }</div>
                                </div>
                              : null}
                          </Cell>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Cell>
                </Grid>
              </Cell>
              <Cell col={8}>
                <Grid>
                  <Card shadow={2}>
                    <CardTitle> Blocked {`${this.props.setName}s`}</CardTitle>
                    <CardActions border>
                      <PaginatedTable
                        page={this.state.page}
                        numOfPages={Math.ceil(this.props.values.available / 350)}
                        pageChange={ page => this.handlePageChange(page) }
                        onChange={(obj, datapoint, newValue) => {
                          let idx = this.props.values.indexOf(obj)
                          this.props.value.data[idx][datapoint] = newValue
                        }}
                        columns={[this.props.colDisplay, "NOTE", "ADDED BY", "ADDED ON"]}
                        dataPoints={[this.props.colName, "note", "createdBy", "createdAt"]}
                        data={this.props.values.data || []}
                        onDelete={this.props.deleteValue}
                        itemName={this.props.setName}
                      />
                    </CardActions>
                  </Card>
                </Grid>
              </Cell>
            </Grid>
          </div>
        </section>
      </React.Fragment>
    )
  }
} 
