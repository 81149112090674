import React from "react"
import Modal from "react-modal"
import FilterList from "../filterList"
import SearchForm from "../zip/searchForm"
import {
  Tooltip,
  Card,
  CardTitle,
  Tabs,
  List,
  ListItem,
  Tab,
  Grid,
  Cell,
  Textfield,
  Switch,
  Button,
  Icon,
  IconButton,
  CardActions
} from "react-mdl"
import { Collapsible } from "../common/components"

const successfulStyle = {
  color: "blue",
  backgroundColor: "#d6eaf8",
  border: "1px solid blue"
}

const failureStyle = {
  color: "red",
  backgroundColor: "#fadbd8",
  border: "1px solid red"
}

const AGE_OPTION = 0
const GENDER_OPTION = 1
const PHONE_OPTION = 2
const DOMAIN_OPTION = 3
const STATE_OPTION = 4
const ZIP_OPTION = 5
const BLANKENTRY = "BLANK"

function getCriteriaDetails(criteriaFilter) {
  if (!criteriaFilter) {
    return ""
  }
  return <Grid>
    {Object.keys(criteriaFilter).map(crk => {
      return <React.Fragment key={crk + "tlFragment"} >
        <Cell key={crk} col={12}><b>{crk}</b></Cell>
        {Object.keys(criteriaFilter[crk]).map(incexl => {
          if (criteriaFilter[crk][incexl].length == 0) {
            return null
          }
          return <React.Fragment key={crk + incexl} >
            <Cell key={crk + incexl} col={2}>{incexl}</Cell>
            <Cell key={crk + incexl + "data"} col={10}>{criteriaFilter[crk][incexl].join(", ")}</Cell>
          </React.Fragment>
        })}
      </React.Fragment>
    })}
  </Grid>
}
class CriteriaFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      leftAge: "",
      rightAge: "",
      phone: "",
      domain: "",
      zip: "",
      showZipSeachDialog: false
    }
  }

  addBlank(criteriaName, criteria, criteriaType) {
    if (criteria[criteriaType].indexOf(BLANKENTRY) < 0) {
      const isInclude = criteriaType === "include" ? true : false
      this.props.onChange(criteriaName, isInclude, BLANKENTRY)
    }
  }

  handleStateChange(fieldName, value) {
    this.setState({
      [fieldName]: value
    })
  }

  handleAddAgeCriteriaButton() {
    const val = this.state.leftAge + "-" + this.state.rightAge
    this.props.onChange("age", this.state.ageInclude, val)
    this.setState({
      leftAge: "",
      rightAge: ""
    })
  }

  handleAllGenderChange() {
    this.props.onRemove("gender", "exclude", 0)
  }

  handleMaleGenderChange() {
    this.props.onRemove("gender", "exclude", 0)
    this.props.onChange("gender", false, "m")
  }

  handleFemaleGenderChange() {
    this.props.onRemove("gender", "exclude", 0)
    this.props.onChange("gender", false, "f")
  }

  handleAddPhoneButton() {
    this.props.onChange("phone", this.state.phoneInclude, this.state.phone)
    this.handleStateChange("phone", "")
  }

  handleAddDomainButton() {
    this.props.onChange("email_domain", this.state.domainInclude, this.state.domain)
    this.handleStateChange("domain", "")
  }

  handleAddZipCriteriaButton() {
    const zipCodes = this.state.zip.split(",")
    zipCodes.forEach(zip => {
      this.props.onChange("zip", this.state.zipInclude, zip)
    })

    this.setState({
      zip: ""
    })
  }

  handleIncludedSelectState(state, stateCriteria) {
    const index = state.include.indexOf(stateCriteria.code)
    this.props.onStateChange("include", index)
  }

  handleExcludeSelectState(state, stateCriteria) {
    const index = state.exclude.indexOf(stateCriteria.code)
    this.props.onStateChange("exclude", index)
  }

  dialogCancel() {
    this.setState({
      showZipSeachDialog: false
    })
  }

  showDialog() {
    this.setState({
      showZipSeachDialog: true
    })
  }

  render() {
    const ageCriteria = this.props.criteriaFilter.age || { include: [], exclude: [] }
    const genderCriteria = this.props.criteriaFilter.gender || { include: [], exclude: [] }
    const phoneCriteria = this.props.criteriaFilter.phone || { include: [], exclude: [] }
    const emailDomainCriteria = this.props.criteriaFilter.email_domain || { include: [], exclude: [] }
    const stateCriteria = this.props.criteriaFilter.state || { include: [], exclude: [] }
    const zipCriteria = this.props.criteriaFilter.zip || { include: [], exclude: [] }
    let control = <div />
    switch (this.state.activeTab) {
      case AGE_OPTION: {
        const ageOriginalCriteria =
          this.props.originalCriteria && this.props.originalCriteria.criteriaFilter["age"]
            ? this.props.originalCriteria.criteriaFilter["age"]
            : null
        control = (
          <div>
            <Grid>
              <Cell col={2}>
                <Textfield
                  required={true}
                  type="number"
                  floatingLabel
                  label="From"
                  onChange={e => this.handleStateChange("leftAge", e.target.value)}
                  value={this.state.leftAge}
                  maxLength={3}
                />
              </Cell>
              <Cell col={2}>
                <Textfield
                  required={true}
                  type="number"
                  floatingLabel
                  label="To"
                  onChange={e => this.handleStateChange("rightAge", e.target.value)}
                  value={this.state.rightAge}
                  maxLength={3}
                />
              </Cell>
              <Cell col={3} offset={1} style={{ padding: "8px 0px" }} id="include-exclude-switch-cell">
                <Switch
                  onChange={() => this.handleStateChange("ageInclude", !this.state.ageInclude)}
                  checked={this.state.ageInclude || false}>
                  Include
                </Switch>
              </Cell>
              <Cell col={4}>
                <Button id="add-age-criteria-button" onClick={() => this.handleAddAgeCriteriaButton()}>
                  <Icon name="add_circle" />
                </Button>
              </Cell>
            </Grid>
            <Grid>
              <Cell col={ageOriginalCriteria ? 6 : 12} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "49%" }}>
                  <h6>Include</h6>
                  <Button colored raised ripple onClick={() => this.addBlank("age", ageCriteria, "include")}>
                    INCLUDE BLANK
                  </Button>
                  <List id="criteria-filter-age-include-list">
                    {ageCriteria.include.map((ageInclusiveCF, index) => {
                      return (
                        <ListItem key={ageInclusiveCF + "inclusive"}>
                          <Grid>
                            <Cell style={{ marginTop: "15%" }} col={6}>
                              <span>{ageInclusiveCF}</span>
                            </Cell>
                            <Cell col={6}>
                              <Button onClick={() => this.props.onRemove("age", "include", index)}>
                                <Icon name="delete" />
                              </Button>
                            </Cell>
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
                <div style={{ width: "49%" }}>
                  <h6>Exclude</h6>
                  <Button colored raised ripple onClick={() => this.addBlank("age", ageCriteria, "exclude")}>
                    EXCLUDE BLANK
                  </Button>
                  <List id="criteria-filter-age-exclude-list">
                    {ageCriteria.exclude.map((ageExclusiveCF, index) => {
                      return (
                        <ListItem key={ageExclusiveCF + "exclusive"}>
                          {" "}
                          <Grid>
                            <Cell col={6}>{ageExclusiveCF}</Cell>
                            <Cell col={3}>
                              <Button onClick={() => this.props.onRemove("age", "exclude", index)}>
                                <Icon name="delete" />
                              </Button>
                            </Cell>
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
              </Cell>
              {ageOriginalCriteria ? (
                <Cell col={6}>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                    {"Campaign Criteria"}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Included</h6>
                      <hr />
                      <List>
                        {ageOriginalCriteria.include.map(ageInclusiveCF => {
                          return (
                            <div key={ageInclusiveCF + "inclusive"}>
                              <div>{ageInclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Excluded</h6>
                      <hr />
                      <List>
                        {ageOriginalCriteria.exclude.map(ageExclusiveCF => {
                          return (
                            <div key={ageExclusiveCF + "exclusive"}>
                              <div>{ageExclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                  </div>
                </Cell>
              ) : null}
            </Grid>
          </div>
        )
        break
      }
      case GENDER_OPTION: {
        let label = "All Genders Allowed"
        let campaignLabel = label
        if (genderCriteria.exclude.length > 0) {
          label = genderCriteria.exclude[0] == "m" ? "Female Only" : "Male Only"
        }
        if (genderCriteria.include.length > 0) {
          label = genderCriteria.include[0] != "m" ? "Female Only" : "Male Only"
        }
        const genderOriginalCriteria =
          this.props.originalCriteria && this.props.originalCriteria.criteriaFilter["gender"]
            ? this.props.originalCriteria.criteriaFilter["gender"]
            : null
        if (genderOriginalCriteria && genderOriginalCriteria.exclude.length > 0) {
          campaignLabel = genderOriginalCriteria.exclude[0] == "m" ? "Female Only" : "Male Only"
        }
        if (genderOriginalCriteria && genderOriginalCriteria.include.length > 0) {
          campaignLabel = genderOriginalCriteria.include[0] != "m" ? "Female Only" : "Male Only"
        }
        control = (
          <Card shadow={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: genderOriginalCriteria ? "59%" : "99%", padding: "0 10px", textAlign: "center" }}>
                <h6>Target Gender</h6>
                <h5 id="criteria-filter-target-gender-label">{label}</h5>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "25px" }}>
                  <Button
                    id="criteria-filter-gender-all-button"
                    colored
                    raised
                    onClick={() => this.handleAllGenderChange(genderCriteria)}>
                    Allow All
                  </Button>
                  <Button
                    id="criteria-filter-gender-male-button"
                    colored
                    raised
                    onClick={() => this.handleMaleGenderChange(genderCriteria)}>
                    Exclude Male
                  </Button>
                  <Button
                    id="criteria-filter-gender-female-button"
                    colored
                    raised
                    onClick={() => this.handleFemaleGenderChange(genderCriteria)}>
                    Exclude Female
                  </Button>
                </div>
              </div>
              {genderOriginalCriteria ? (
                <div style={{ width: "39%", padding: "0 10px", textAlign: "center" }}>
                  <h6>Campaign Target Gender</h6>
                  <h5 style={{ backgroundColor: "#e0e0e0", padding: "10px" }}>{campaignLabel}</h5>
                </div>
              ) : null}
            </div>
          </Card>
        )
        break
      }
      case PHONE_OPTION: {
        const phoneOriginalCriteria =
          this.props.originalCriteria && this.props.originalCriteria.criteriaFilter["phone"]
            ? this.props.originalCriteria.criteriaFilter["phone"]
            : null
        control = (
          <div>
            <Grid>
              <Cell col={4}>
                <Textfield
                  required={true}
                  floatingLabel
                  label="Areacode"
                  onChange={e => this.handleStateChange("phone", e.target.value)}
                  value={this.state.phone}
                  maxLength={3}
                />
              </Cell>
              <Cell col={3} offset={1} style={{ padding: "8px 0px" }} id="criteria-filter-areacode-switch-cell">
                <Switch
                  onChange={() => this.handleStateChange("phoneInclude", !this.state.phoneInclude)}
                  checked={this.state.phoneInclude || false}>
                  Include
                </Switch>
              </Cell>
              <Cell col={4}>
                <Button id="criteria-filter-areacode-button" onClick={() => this.handleAddPhoneButton()}>
                  <Icon name="add_circle" />
                </Button>
              </Cell>
            </Grid>
            <Grid>
              <Cell col={phoneOriginalCriteria ? 6 : 12} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "49%" }}>
                  <h6>Include</h6>
                  <Button colored raised ripple onClick={() => this.addBlank("phone", phoneCriteria, "include")}>
                    INCLUDE BLANK
                  </Button>
                  <List id="criteria-filter-areadcode-include-list">
                    {phoneCriteria.include.map((phoneInclusiveCF, index) => {
                      return (
                        <ListItem key={phoneInclusiveCF + "inclusive"}>
                          <Grid>
                            <Cell col={6}>{phoneInclusiveCF}</Cell>
                            <Cell col={3}>
                              <Button onClick={() => this.props.onRemove("phone", "include", index)}>
                                <Icon name="delete" />
                              </Button>
                            </Cell>
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
                <div style={{ width: "49%" }}>
                  <h6>Exclude</h6>
                  <Button colored raised ripple onClick={() => this.addBlank("phone", phoneCriteria, "exclude")}>
                    EXCLUDE BLANK
                  </Button>
                  <List id="criteria-filter-areadcode-exclude-list">
                    {phoneCriteria.exclude.map((phoneExclusiveCF, index) => {
                      return (
                        <ListItem key={phoneExclusiveCF + "exclusive"}>
                          <Grid>
                            <Cell col={6}>{phoneExclusiveCF}</Cell>
                            <Cell col={3}>
                              <Button onClick={() => this.props.onRemove("phone", "exclude", index)}>
                                <Icon name="delete" />
                              </Button>
                            </Cell>
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
              </Cell>
              {phoneOriginalCriteria ? (
                <Cell col={6}>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                    {"Campaign Criteria"}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Included</h6>
                      <hr />
                      <List>
                        {phoneOriginalCriteria.include.map(phoneInclusiveCF => {
                          return (
                            <div key={phoneInclusiveCF + "inclusive"}>
                              <div>{phoneInclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Excluded</h6>
                      <hr />
                      <List>
                        {phoneOriginalCriteria.exclude.map(phoneExclusiveCF => {
                          return (
                            <div key={phoneExclusiveCF + "exclusive"}>
                              <div>{phoneExclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                  </div>
                </Cell>
              ) : null}
            </Grid>
          </div>
        )
        break
      }
      case DOMAIN_OPTION: {
        const domainOriginalCriteria =
          this.props.originalCriteria && this.props.originalCriteria.criteriaFilter["email_domain"]
            ? this.props.originalCriteria.criteriaFilter["email_domain"]
            : null
        control = (
          <div>
            <Grid>
              <Cell col={4}>
                <Textfield
                  required={true}
                  floatingLabel
                  label="Domain"
                  onChange={e => this.handleStateChange("domain", e.target.value)}
                  value={this.state.domain}
                  maxLength={200}
                />
              </Cell>
              <Cell col={3} offset={1} style={{ padding: "8px 0px" }} id="criteria-filter-domain-switch-cell">
                <Switch
                  onChange={() => this.handleStateChange("domainInclude", !this.state.domainInclude)}
                  checked={this.state.domainInclude || false}>
                  Include
                </Switch>
              </Cell>
              <Cell col={4}>
                <Button id="criteria-filter-domain-add-button" onClick={() => this.handleAddDomainButton()}>
                  <Icon name="add_circle" />
                </Button>
              </Cell>
            </Grid>
            <Grid>
              <Cell col={domainOriginalCriteria ? 6 : 12} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "49%" }}>
                  <h6>Include</h6>
                  <Button
                    colored
                    raised
                    ripple
                    onClick={() => this.addBlank("email_domain", emailDomainCriteria, "include")}>
                    INCLUDE BLANK
                  </Button>

                  <List id="criteria-filter-domain-include-list">
                    {emailDomainCriteria.include.map((domainInclusiveCF, index) => {
                      return (
                        <ListItem key={domainInclusiveCF + "inclusive"}>
                          <Grid key={domainInclusiveCF + "inclusive"}>
                            <Cell col={6}>{domainInclusiveCF}</Cell>
                            <Cell col={3}>
                              <Button onClick={() => this.props.onRemove("email_domain", "include", index)}>
                                <Icon name="delete" />
                              </Button>
                            </Cell>
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
                <div style={{ width: "49%" }}>
                  <h6>Exclude</h6>
                  <Button
                    colored
                    raised
                    ripple
                    onClick={() => this.addBlank("email_domain", emailDomainCriteria, "exclude")}>
                    EXCLUDE BLANK
                  </Button>
                  <List id="criteria-filter-domain-exclude-list">
                    {emailDomainCriteria.exclude.map((domainExclusiveCF, index) => {
                      return (
                        <ListItem key={domainExclusiveCF + "exclusive"}>
                          <Grid key={domainExclusiveCF + "exclusive"}>
                            <Cell col={6}>{domainExclusiveCF}</Cell>
                            <Cell col={3}>
                              <Button onClick={() => this.props.onRemove("email_domain", "exclude", index)}>
                                <Icon name="delete" />
                              </Button>
                            </Cell>
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
              </Cell>
              {domainOriginalCriteria ? (
                <Cell col={6}>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                    {"Campaign Criteria"}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Included</h6>
                      <hr />
                      <List>
                        {domainOriginalCriteria.include.map(domainInclusiveCF => {
                          return (
                            <div key={domainInclusiveCF + "inclusive"}>
                              <div>{domainInclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Excluded</h6>
                      <hr />
                      <List>
                        {domainOriginalCriteria.exclude.map(domainExclusiveCF => {
                          return (
                            <div key={domainExclusiveCF + "exclusive"}>
                              <div>{domainExclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                  </div>
                </Cell>
              ) : null}
            </Grid>
          </div>
        )
        break
      }
      case STATE_OPTION: {
        const stateOriginalCriteria =
          this.props.originalCriteria && this.props.originalCriteria.criteriaFilter["state"]
            ? this.props.originalCriteria.criteriaFilter["state"]
            : null
        control = (
          <div>
            <Grid>
              <Cell col={stateOriginalCriteria ? 6 : 12} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "49%" }}>
                  <h6 style={{ marginBottom: "52px" }}>Include</h6>
                  <FilterList
                    label="Included States"
                    primaryField={["code"]}
                    additionalFieldList={[]}
                    title="Included"
                    selectItem={st => this.handleIncludedSelectState(stateCriteria, st)}
                    items={stateCriteria.include.map(st => {
                      return { code: st, name: st }
                    })}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <h6>Exclude</h6>
                  <Button
                    colored
                    raised
                    ripple
                    onClick={() => {
                      this.addBlank("state", stateCriteria, "exclude")
                    }}>
                    EXCLUDE BLANK
                  </Button>
                  <FilterList
                    label="Excluded States"
                    primaryField={["code"]}
                    additionalFieldList={[]}
                    title="Excluded"
                    selectItem={st => this.handleExcludeSelectState(stateCriteria, st)}
                    items={stateCriteria.exclude.map(st => {
                      return { code: st, name: st }
                    })}
                  />
                </div>
              </Cell>
              {stateOriginalCriteria ? (
                <Cell col={6}>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                    {"Campaign Criteria"}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Included</h6>
                      <hr />
                      <List>
                        {stateOriginalCriteria.include.map(stateInclusiveCF => {
                          return (
                            <div key={stateInclusiveCF + "inclusive"}>
                              <div>{stateInclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Excluded</h6>
                      <hr />
                      <List>
                        {stateOriginalCriteria.exclude.map(stateExclusiveCF => {
                          return (
                            <div key={stateExclusiveCF + "exclusive"}>
                              <div>{stateExclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                  </div>
                </Cell>
              ) : null}
            </Grid>
          </div>
        )
        break
      }
      case ZIP_OPTION: {
        const zipOriginalCriteria =
          this.props.originalCriteria && this.props.originalCriteria.criteriaFilter["zip"]
            ? this.props.originalCriteria.criteriaFilter["zip"]
            : null
        control = (
          <div>
            <Grid>
              <Cell col={6}>
                <Textfield
                  floatingLabel
                  label="Zip Codes, Comma delimited list"
                  onChange={e => this.handleStateChange("zip", e.target.value)}
                  value={this.state.zip}
                  maxLength={200}
                />
              </Cell>
              <Cell col={3} style={{ padding: "8px 0px" }} id="criteria-filter-zip-switch-cell">
                <Switch
                  onChange={() => this.handleStateChange("zipInclude", !this.state.zipInclude)}
                  checked={this.state.zipInclude || false}>
                  Include
                </Switch>
              </Cell>
              <Cell col={2}>
                <Button id="criteria-filter-zip-add-button" onClick={() => this.handleAddZipCriteriaButton()}>
                  <Icon name="add_circle" />
                </Button>
              </Cell>
              <Cell col={1}>
                <Tooltip label="Zip Search Tool">
                  <IconButton name="search" colored onClick={() => this.showDialog()} />
                </Tooltip>
              </Cell>
            </Grid>
            <Grid>
              <Cell col={zipOriginalCriteria ? 6 : 12} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "49%" }}>
                  <h6>Include</h6>
                  <Button colored raised ripple onClick={() => this.addBlank("zip", zipCriteria, "include")}>
                    INCLUDE BLANK
                  </Button>
                  <List id="criteria-filter-zip-included-list">
                    {zipCriteria.include.map((zipInclusiveCF, index) => {
                      return (
                        <ListItem key={zipInclusiveCF + "inclusive"}>
                          <Grid>
                            <Cell style={{ marginTop: "15%" }} col={6}>
                              <span>{zipInclusiveCF}</span>
                            </Cell>
                            <Cell col={6}>
                              <Button onClick={() => this.props.onRemove("zip", "include", index)}>
                                <Icon name="delete" />
                              </Button>
                            </Cell>
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
                <div style={{ width: "49%" }}>
                  <h6>Exclude</h6>
                  <Button colored raised ripple onClick={() => this.addBlank("zip", zipCriteria, "exclude")}>
                    EXCLUDE BLANK
                  </Button>
                  <List id="criteria-filter-zip-excluded-list">
                    {zipCriteria.exclude.map((zipExclusiveCF, index) => {
                      return (
                        <ListItem key={zipExclusiveCF + "exclusive"}>
                          {" "}
                          <Grid>
                            <Cell col={6}>{zipExclusiveCF}</Cell>
                            <Cell col={3}>
                              <Button onClick={() => this.props.onRemove("zip", "exclude", index)}>
                                <Icon name="delete" />
                              </Button>
                            </Cell>
                          </Grid>
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
              </Cell>
              {zipOriginalCriteria ? (
                <Cell col={6}>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                    {"Campaign Criteria"}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Included</h6>
                      <hr />
                      <List>
                        {zipOriginalCriteria.include.map(zipInclusiveCF => {
                          return (
                            <div key={zipInclusiveCF + "inclusive"}>
                              <div>{zipInclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                    <div style={{ width: "45%", backgroundColor: "#e0e0e0", padding: "0px 5px", textAlign: "center" }}>
                      <h6>Excluded</h6>
                      <hr />
                      <List>
                        {zipOriginalCriteria.exclude.map(zipExclusiveCF => {
                          return (
                            <div key={zipExclusiveCF + "exclusive"}>
                              <div>{zipExclusiveCF}</div>
                            </div>
                          )
                        })}
                      </List>
                    </div>
                  </div>
                </Cell>
              ) : null}
            </Grid>
            <section ref={t => (this.contentSection = t)}>
              <Modal
                appElement={this.contentSection}
                isOpen={this.state.showZipSeachDialog}
                onRequestClose={() => this.dialogCancel()}
                contentLabel="">
                <Card style={{ maxWidth: "60vw", minWidth: "20vw" }} shadow={2}>
                  <CardActions style={{ textAlign: "right" }}>
                    <Button onClick={() => this.dialogCancel()}>
                      <Icon name="close" />
                    </Button>
                  </CardActions>
                  <SearchForm zips={this.props.zips} />
                </Card>
              </Modal>
            </section>
          </div>
        )
        break
      }
    }
    let saveMessageStyles = { padding: "5px 10px" }
    if (this.props.associatedCriteriaSaveMessage) {
      saveMessageStyles = this.props.associatedCriteriaSaveMessage.isSuccessful
        ? Object.assign(saveMessageStyles, successfulStyle)
        : Object.assign(saveMessageStyles, failureStyle)
    }
    this.props.associatedCriteriaSaveMessage ? this.props.resetAssociatedCriteriaSaveMsg() : () => { }
    return (
      <Card style={{ width: "100%" }} shadow={2} id="criteria-filter-card">
        <CardTitle style={{ padding: "19px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid>
            <Cell col={12}>
              <div style={{ padding: "5px 10px", fontSize: "24px" }}>Criteria</div>
            </Cell>
            <Cell col={12}>
              <div style={saveMessageStyles}>
                {this.props.associatedCriteriaSaveMessage ? this.props.associatedCriteriaSaveMessage.message : null}
              </div>
            </Cell>
            <Cell col={12}>
              <Collapsible title="Details">
                {getCriteriaDetails(this.props.criteriaFilter)}
              </Collapsible>
            </Cell>
          </Grid>
        </CardTitle>
        <Tabs
          activeTab={this.state.activeTab}
          onChange={tabId => this.handleStateChange("activeTab", tabId)}
          ripple
          style={{ width: "100%" }}>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Age</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Gender</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Areacode</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Email Domain</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>State</span>
          </Tab>
          <Tab style={{ textTransform: "none" }}>
            <span style={{ fontSize: "18px" }}>Zip</span>
          </Tab>
        </Tabs>
        <div style={{ margin: "5px" }}>{control}</div>
      </Card >
    )
  }
}

export default CriteriaFilter
