import Sender from "./configSender"
import Scheduler from "./configScheduler"
import Dataset from "./configDatasource"
import React from "react"
import { connect } from "react-redux"
import LeadTemplate from "./leadTemplate"
import Select from "react-select"
import { Grid, Cell, Card, CardActions, CardTitle, Textfield, Switch, CardMenu } from "react-mdl"

const ENCRYPTION_KEYS = [
  { value: "other", label: "Other Certificate" },
  { value: "pgp", label: "PGP Certificate" }
]

const senderTypes = [
  { label: "HTTP", value: 0, code: "http" },
  { label: "FTP", value: 1, code: "ftp" },
  { label: "EMAIL", value: 2, code: "email" }
]

const scheduleTypes = [
  { label: "Real Time", value: 0, code: "realtime" },
  { label: "Recurring", value: 1, code: "recurring" },
  { label: "On-Demand", value: 2, code: "on_demand" }
]

class leadDeliveryConfig extends React.Component {
  constructor(props) {
    super(props)

    let defaultSchedule = {
      isActive: false,
      retryPolicy: {
        intervalValue: 1,
        intervalUnit: 1,
        maxRetries: 3
      },
      realtime: null,
      recurring: null,
    }


    this.state = {
      leadDeliverySchedule: this.props.leadDeliveryConfig.schedule || defaultSchedule
    }
    this.props.setGetLeadDeliverySchedule(() => {
      return this.state.leadDeliverySchedule
    })
  }

  handleScheduleIsActiveChange() {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    leadDeliverySchedule.isActive = !this.state.leadDeliverySchedule.isActive
    this.updated(leadDeliverySchedule)
  }

  handleScheduleStartDateChange(startDate) {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    leadDeliverySchedule.recurring.startDate = startDate
    this.updated(leadDeliverySchedule)
  }

  handleScheduleLocationChange(timezone) {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    leadDeliverySchedule.recurring.timezone = timezone
    this.updated(leadDeliverySchedule)
  }

  handleScheduleDaysChange(dayCode, isIncluded) {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    if (isIncluded) {
      leadDeliverySchedule.recurring.dayOfWeek.push(dayCode)
    } else {
      const index = this.state.leadDeliverySchedule.recurring.dayOfWeek.indexOf(dayCode)
      leadDeliverySchedule.recurring.dayOfWeek.splice(index, 1)
    }
    this.updated(leadDeliverySchedule)
  }

  handleScheduleHoursChange(hours) {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    leadDeliverySchedule.recurring.timeOfDay.hours = hours || 0
    this.updated(leadDeliverySchedule)
  }

  handleScheduleMinutesChange(minutes) {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    leadDeliverySchedule.recurring.timeOfDay.minutes = parseInt(minutes) || 0
    this.updated(leadDeliverySchedule)
  }

  handleScheduleMaxRetriesChange(retries) {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    leadDeliverySchedule.retryPolicy.maxRetries = retries
    this.updated(leadDeliverySchedule)
  }

  handleScheduleIntervalValueChange(intervalValue) {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    leadDeliverySchedule.retryPolicy.intervalValue = intervalValue
    this.updated(leadDeliverySchedule)
  }

  handleScheduleIntervalUnitChange(unit) {
    const leadDeliverySchedule = this.state.leadDeliverySchedule
    leadDeliverySchedule.retryPolicy.intervalUnit = parseInt(unit)
    this.updated(leadDeliverySchedule)
  }

  updated(leadDeliverySchedule) {
    this.props.updated()
    this.setState({
      leadDeliverySchedule: leadDeliverySchedule
    })
  }

  handleChange(callBack) {
    callBack()
    this.setState(this.state)
  }

  getEncrytionKey(key) {
    return ENCRYPTION_KEYS.filter(k => k.value === key)[0]
  }

  render() {
    const isLoading = this.props.leadDeliveryConfigIsLoading || this.props.leadDeliveryScheduleIsLoading
    if (isLoading) {
      return "loading ..."
    }
    let senderType = senderTypes.find(e => e.value == this.props.leadDeliveryConfig.senderType)
    let scheduleType = scheduleTypes.find(e => e.value == this.props.leadDeliveryConfig.scheduleType)

    return (
      <section>
        <div className="content">
          <Grid>
            <Cell col={12}>
              <Card shadow={2}>
                <CardTitle> Configuration Details </CardTitle>
                <CardMenu>
                  <Switch onChange={() => this.handleScheduleIsActiveChange()} checked={this.state.leadDeliverySchedule.isActive}>
                    Enabled
                  </Switch>
                </CardMenu>
                <CardActions border>
                  <Grid>
                    <Cell col={4}>
                      <Textfield
                        required
                        value={this.props.leadDeliveryConfig.name}
                        onChange={e => this.handleChange(() => this.props.handleNameChange(e))}
                        label="Configuration name..."
                        floatingLabel
                        style={{ width: "200px" }}
                      />
                      <div className="mdl-card__subtitle-text" style={{ fontSize: "75%" }}>
                        {this.props.leadDeliveryConfig.UUID}
                      </div>
                    </Cell>
                    <Cell col={8}>
                      <Textfield
                        value={this.props.leadDeliveryConfig.notes || ""}
                        maxRows={5}
                        rows={2}
                        onChange={e => this.handleChange(() => this.props.handleNotesChange(e))}
                        label="Notes"
                        floatingLabel
                        style={{ width: "100%" }}
                      />
                    </Cell>
                    <Cell col={12}>
                      <Grid>
                        <Cell col={4}>
                          <Select
                            placeholder={"Certificate Type"}
                            isMulti={false}
                            value={this.getEncrytionKey(this.props.leadDeliveryConfig.encryptionCert.key)}
                            onChange={s => this.handleChange(() => this.props.handleEncryptionCertKeyChange(s))}
                            options={ENCRYPTION_KEYS}
                          />
                        </Cell>
                        <Cell col={8}>
                          <Textfield
                            value={this.props.leadDeliveryConfig.encryptionCert.value || ""}
                            onChange={e => this.handleChange(() => this.props.handleEncryptionCertValueChange(e))}
                            label="Certificate Value"
                            floatingLabel
                            style={{ width: "100%" }}
                          />
                        </Cell>
                      </Grid>
                    </Cell>
                    <Cell col={12}>
                      <br />
                    </Cell>
                  </Grid>
                </CardActions>
              </Card>
            </Cell>
            <Cell col={12}>
              <Dataset
                leadDeliveryConfig={this.props.leadDeliveryConfig}
                handleDatasourceTypeChange={this.props.handleDatasourceTypeChange}
                campaigns={this.props.campaigns}
                placements={this.props.placements}
              />
            </Cell>
            <Cell col={12}>
              <Scheduler
                scheduleType={scheduleType}
                scheduleTypes={scheduleTypes}
                leadDeliverySchedule={this.state.leadDeliverySchedule}

                handleScheduleTypeChange={this.props.handleScheduleTypeChange}
                handleScheduleStartDateChange={startDate => this.handleScheduleStartDateChange(startDate)}
                handleScheduleLocationChange={location => this.handleScheduleLocationChange(location)}
                handleScheduleDaysChange={(dayCode, isIncluded) => this.handleScheduleDaysChange(dayCode, isIncluded)}
                handleScheduleHoursChange={hours => this.handleScheduleHoursChange(hours)}
                handleScheduleMinutesChange={minutes => this.handleScheduleMinutesChange(minutes)}
                handleScheduleMaxRetriesChange={retries => this.handleScheduleMaxRetriesChange(retries)}
                handleScheduleIntervalValueChange={intervalValue => this.handleScheduleIntervalValueChange(intervalValue)}
                handleScheduleIntervalUnitChange={unit => this.handleScheduleIntervalUnitChange(unit)}
              />
            </Cell>
            <Cell col={12}>
              <LeadTemplate
                configuration={this.props.leadDeliveryConfig}
                handleLeadTemplateChange={this.props.handleLeadTemplateChange}
                handleFileNameChange={this.props.handleFileNameChange}
              />
            </Cell>
            <Cell col={12}>
              <Sender
                senderType={senderType}
                senderTypes={senderTypes}
                leadDeliveryConfig={this.props.leadDeliveryConfig}

                handleStatusChange={this.props.handleStatusChange}

                handleSenderTypeChange={this.props.handleSenderTypeChange}
                handleFTPIteractionURLChange={this.props.handleFTPIteractionURLChange}
                handleFTPInteractionDirectoryChange={this.props.handleFTPInteractionDirectoryChange}
                handleFTPInteractionSecureChange={this.props.handleFTPInteractionSecureChange}
                handleFTPInteractionUserChange={this.props.handleFTPInteractionUserChange}
                handleFTPInteractionPasswordChange={this.props.handleFTPInteractionPasswordChange}
                handleEmailInteractionReplyAddressChange={this.props.handleEmailInteractionReplyAddressChange}
                handleEmailInteractionToAddressChange={this.props.handleEmailInteractionToAddressChange}
                handleHttpIntereactionAdd={this.props.handleHttpIntereactionAdd}
                handleInteractionOrder={this.props.handleInteractionOrder}
                handleInteractionDelete={this.props.handleInteractionDelete}
                handleInteractionPreauthKeyChange={this.props.handleInteractionPreauthKeyChange}
                handleInteractionPreauthKeyExpiration={this.props.handleInteractionPreauthKeyExpiration}
                handleInteractionVerbChange={this.props.handleInteractionVerbChange}
                handleInteractionUrlChange={this.props.handleInteractionUrlChange}
                handleInteractionBodyChange={this.props.handleInteractionBodyChange}
                handleInteractionHeadersUpdate={this.props.handleInteractionHeadersUpdate}
                handleInteractionHeadersDelete={this.props.handleInteractionHeadersDelete}
                handleInteractionHeadersSave={this.props.handleInteractionHeadersSave}
                handleInteractionResponseUpdate={this.props.handleInteractionResponseUpdate}
              />
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }
}

const LeadDeliveryConfig = connect(null, null)(leadDeliveryConfig)

export default LeadDeliveryConfig
