import React from "react"
import { Icon, Textfield, Button } from "react-mdl"
import { Collapsible } from "./common/components"

export default class FilterHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCollapsed: true,
    }
  }

  getFilterTitle() {
    return "Filters"
  }
  onToggle() {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    })
  }

  render() {
    const label = this.props.label ? this.props.label : "SEARCH!"
    return (
      <div>
        {!this.props.hidePage ? (
          <div>
            <Button accent onClick={() => this.props.pageChange(1)}>
              <Icon name="first_page" />
            </Button>
            <Button
              accent
              onClick={() => {
                this.props.pageChange(this.props.page - (this.props.page <= 1 ? 0 : 1))
              }}>
              <Icon name="chevron_left" />
            </Button>
            ({this.props.page} | {this.props.numOfPages})
            <Button
              accent
              onClick={() => {
                this.props.pageChange(this.props.page + (this.props.page >= this.props.numOfPages ? 0 : 1))
              }}>
              <Icon name="chevron_right" />
            </Button>
            <Button accent onClick={() => this.props.pageChange(this.props.numOfPages)}>
              <Icon name="last_page" />
            </Button>
          </div>
        ) : null}
        <Textfield
          value={this.props.searchText}
          onChange={e => this.props.onChange(e)}
          label={label}
          floatingLabel
          style={{ width: "100%" }}
        />
        <Collapsible
          defaultOpen={!this.state.isCollapsed}
          onToggle={(flag) => this.onToggle(flag)}
          onClick={() => this.onToggle(this.state.isCollapsed)}
          title={this.getFilterTitle()}>
          <React.Fragment>{this.props.children}</React.Fragment>
        </Collapsible>
      </div>
    )
  }
}
