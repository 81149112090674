import {
  deleteBucket,
  retrieveBuckets,
  newBucket,
  updateBucket,
} from '../../services'

import React from 'react'
import { connect } from 'react-redux'
import { deepClone } from '../../utils'
import {List, Grid, Cell, Card, CardActions, CardTitle, Textfield, Icon, Button, Menu, ListItem} from 'react-mdl'
import { Prompt } from 'react-router'

function mapDispatchToProps(dispatch) {
  return {
    loadBuckets(type) {
      dispatch(retrieveBuckets(type))
    },
    deleteBucket(bucket, type) {
      dispatch(deleteBucket(bucket, type))
    },
    newBucket(bucket, type) {
      dispatch(newBucket(bucket, type))
    },
    updateBucket(bucket, type) {
      dispatch(updateBucket(bucket, type))
    },
  }
}

const EMAIL_TYPE_VALUE = 0
const AGE_TYPE_VALUE = 1
const GENDER_TYPE_VALUE = 2
const REGION_TYPE_VALUE = 3
const newEmptyBucket = { bucket: [], bucketName: '' }

class bucketManager extends React.Component {
  constructor(props) {
    super(props)
    this.types = {
      EMAIL: EMAIL_TYPE_VALUE,
      AGE: AGE_TYPE_VALUE,
      GENDER: GENDER_TYPE_VALUE,
      REGION: REGION_TYPE_VALUE,
    }
    this.state = {
      bucket: null,
      isBucketSelected: false,
      type: EMAIL_TYPE_VALUE,
    }
    this.props.setIsTabComponentDirty(() => this.isSaveButtonEnabled())
  }

  componentDidMount(){
    this.props.loadBuckets('EMAIL')
  }

  handleBucketFieldChange(fieldName, value){
    const {bucket} = this.state
    bucket[fieldName] = value
    this.setState({
      bucket: bucket,
    })
  }

  handleBucketValueChange(value, index){
    const {bucket} = this.state
    bucket.bucket[index] = value
    this.setState({
      bucket: bucket,
    })
  }

  handleBucketValueRemove(index){
    const {bucket} = this.state
    bucket.bucket.splice(index, 1)
    this.setState({
      bucket: bucket,
    })
  }

  newBucket(){
    this.setState({
      isBucketSelected: true,
      bucket: deepClone(newEmptyBucket),
    })
  }
  
  selectBucketType(type){
    this.props.loadBuckets(type)
    this.setState({
      type: this.types[type],
      bucket: newEmptyBucket,
      isBucketSelected: false,
    })
  }

  selectBucket(bucket){
    const selectedBucket = deepClone(bucket)
    selectedBucket.bucket.sort((a, b) => (a > b) ? 1 : 0)
    this.setState({
      isBucketSelected: true,
      bucket: selectedBucket,
    })
  }

  handleSaveBucketButton(){
    const normalizedBucket = this.normalizeBucket(this.state.bucket)
    const type = Object.keys(this.types).find( t =>
                    this.types[t] === normalizedBucket.demographicType,
    )
    if (!normalizedBucket.id){
      this.props.newBucket(normalizedBucket, type)
      return
    }
    this.props.updateBucket(normalizedBucket, type)
    this.setState({
      type: normalizedBucket.demographicType,
    })
  }

  deleteBucket() { 
    this.props.deleteBucket(
      this.state.bucket,
      Object.keys(this.types).find(
        t =>
          this.types[t] == this.state.bucket.demographicType,
      ),
    )
    this.setState({
      bucket: newEmptyBucket,
      isBucketSelected: false,
    })
  }

  normalizeBucket(bucket){
    bucket.bucket = bucket.bucket.filter((b) => b)
    return bucket
  }

  add() {
    const {bucket} = this.state
    bucket.bucket.push('')
    this.setState({
      bucket: bucket,
    })
  }

  cancel() {
    this.setState({
      isBucketSelected: false,
      bucket: newEmptyBucket,
    })
  }

  isSaveButtonEnabled(){
    return this.state.bucket && this.state.bucket.bucketName.length > 0
  }

  renderBucketEditorBody(bucketType){
    return(
      <Grid style={{ width: "100%" }}>
        <Cell col={6}>
          <Textfield
            value={this.state.bucket.bucketName || ''}
            onChange={e => this.handleBucketFieldChange('bucketName', e.target.value)}
            label="Name"
            floatingLabel
            style={{ width: '200px' }}
          />
          <Textfield
            pattern="-?[0-9]*(\.[0-9]+)?"
            error="Input is not a number!"
            value={this.state.bucket.bucketWeight || ''}
            onChange={e => this.handleBucketFieldChange('bucketWeight', parseInt(e.target.value))}
            label="Weight"
            floatingLabel
            style={{ width: '200px' }}
          />
          <Button id="edit-type-dropdown">
            {bucketType|| 'BUCKET TYPE'}
            <Icon name="more_vert" />
          </Button>
          <Menu ripple target="edit-type-dropdown">
            {Object.keys(this.types).map(t => {
              return (
                <ListItem
                  key={t}
                  onClick={() => this.handleBucketFieldChange('demographicType', this.types[t])} >
                  {t}
                </ListItem>
              )
            })}
          </Menu>
          <List style={{minHeight: "110px"}}>
            {this.state.bucket.bucket.map((b, i) => {
              return (
                <ListItem key={i}>
                  <Grid style={{ marginLeft: '0px' }}>
                    <Cell col={4}>
                      <Textfield
                        value={this.state.bucket.bucket[i] || ''}
                        onChange={(e) => this.handleBucketValueChange(e.target.value, i)}
                        label="Enter Value"
                        floatingLabel
                        style={{ width: '200px' }}
                      />
                    </Cell>
                    <Cell col={4}>
                      <Button
                        id="remove-bucket"
                        onClick={() => this.handleBucketValueRemove(i)}
                      >
                        <Icon name="remove_circle" />
                      </Button>
                    </Cell>
                    <Cell col={4}>
                    </Cell>
                  </Grid>
                </ListItem>
              )
            })}
            <div style={{marginTop: "5px" }}>
              <Button
                id="add-bucket"
                onClick={() => this.add()}>
                <Icon name="add_circle" />
              </Button>
            </div>
          </List>
        </Cell>
        <Cell col={6} style={{display: "flex", justifyContent: "space-between"}}>
          <Button
            disabled={!this.isSaveButtonEnabled()}
            onClick={() => this.handleSaveBucketButton()}
          >
            <Icon name="save" /> Save
          </Button>
          <Button
            disabled={!(this.state.bucket.id)}
            onClick={() => {
              this.deleteBucket()
            }}
          >
            <Icon name="delete" /> Delete Bucket
          </Button>
          <Button onClick={() => this.cancel()}>
            <Icon name="cancel" />
            Close Bucket editor
          </Button>
        </Cell>
      </Grid>
    )
  }

  render() {
    let selectionType = Object.keys(this.types).find(
      t => this.types[t] == this.state.type,
    )
    let bucketType = this.state.bucket ? Object.keys(this.types).find(
      t => this.types[t] == this.state.bucket.demographicType,
    ) : undefined
    const sortedBuckets = this.props.buckets.sort((a, b) => (a.bucketName > b.bucketName) ? 1 : 0)
    return (
      <React.Fragment>
        <Prompt
            when={this.isSaveButtonEnabled()}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        <section>
          <div className="content">
            <Grid>
              <Cell col={4}>
                <Card shadow={2}>
                  <CardTitle>Bucket Selection</CardTitle>
                  <CardActions border>
                    <Button id="type-dropdown">
                      {selectionType || 'CHOOSE TYPE'}
                      <Icon name="more_vert" />
                    </Button>
                    <Menu ripple target="type-dropdown">
                      {Object.keys(this.types).map(t => {
                        return (
                          <ListItem
                            key={this.types[t]}
                            onClick={() => this.selectBucketType(t)} >
                            {t}
                          </ListItem>
                        )
                      })}
                    </Menu>
                    <List>
                      <Grid style={{ width: "100%" }}>
                        <Cell col={4}>
                          <span>Name</span>
                        </Cell>
                        <Cell col={3}>
                          <span>Weight</span>
                        </Cell>
                        <Cell col={5}>
                          <span>Values</span>
                        </Cell>
                      </Grid>
                      {sortedBuckets.map(b => {
                        return (
                          <ListItem
                            className={
                              this.state.bucket && this.state.bucket.id == b.id ? 'is-selected' : ''
                            }
                            key={b.id}
                            onClick={() => this.selectBucket(b)}
                          >
                            <Grid style={{ width: "100%" }}>
                              <Cell col={4}>
                                <span>{b.bucketName}</span>
                              </Cell>
                              <Cell col={2}>
                                <span>{b.bucketWeight}</span>
                              </Cell>
                              <Cell col={6}>
                                <span>{b.bucket.join(', ')}</span>
                              </Cell>
                            </Grid>
                          </ListItem>
                        )
                      })}
                    </List>
                  </CardActions>
                </Card>
              </Cell>
              <Cell col={8}>
                <Card style={{ width: '100%', marginLeft: '8px'}} shadow={2}>
                  <CardTitle style={{display:"flex", justifyContent: "space-between"}}>
                    <span >
                      Bucket Editor
                    </span>
                    <Button
                      onClick={() => this.newBucket()}
                      raised
                      ripple
                      colored
                      >
                      <Icon name="add_box" /> New Bucket
                    </Button>
                  </CardTitle>
                  <CardActions border>
                    {!this.state.isBucketSelected
                      ? <h5 className="text-center">
                        {'Select a bucket from the "Bucket Selection" to edit or click "New Bucket" to create a new one'}
                        </h5>
                      : this.renderBucketEditorBody(bucketType)}
                  </CardActions>
                </Card>
              </Cell>
            </Grid>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const BucketManager = connect(null, mapDispatchToProps)(
  bucketManager,
)

export default BucketManager
