import {
  addBadWord,
  deleteBadWord,
  retrieveBadWords,
  testBadWord,
} from '../../services'

import FilterList from '../filterList'
import ConfirmModal from '../modal'
import React from 'react'
import { connect } from 'react-redux'
import { debounce } from '../../utils'
import {Grid, Cell, Card, CardActions, CardTitle, Switch, Textfield, Icon, Button} from 'react-mdl'
import { Prompt } from 'react-router'

function mapDispatchToProps(dispatch) {
  return {
    addWordToList: function (newWord) {
      dispatch(addBadWord(newWord))
    },
    deleteWordFromList: function (word) {
      dispatch(deleteBadWord(word))
    },
    testBadWord: function (test) {
      dispatch(testBadWord(test))
    },
    retrieveBadWords: function(){
      dispatch(retrieveBadWords())
    },
  }
}

class badWords extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testString: '',
      fullMatch: false,
      wordToDelete: '',
      badword: '',
    }
    this.props.setIsTabComponentDirty(() => !this.isSaveBadWordButtonDisabled())
  }

  componentDidMount(){
    this.props.retrieveBadWords()
  }

  handleTestStringChange(value){
    debounce(this, this.test, 500)()
    this.setState({
      testString: value
    })
  }

  handleNewBadwordChange(value){
    this.setState({
      badword: value,
    })
  }

  handleFullMatchChange(){
    this.setState({
      fullMatch : !this.state.fullMatch
    })
  }

  handleSaveBadwordButton(){
    if (this.state.fullMatch && this.props.badwords.words.FullMatch.some(word => this.state.badword === word)) {
      alert(`"${this.state.badword}" is already in the bad word list.`)
      return
    } else if (!this.state.fullMatch && this.props.badwords.words.PartialMatch.some(word => this.state.badword === word)) {
      alert(`"${this.state.badword}" is already in the bad word list.`)
      return
    }
    if (
      this.state.badword.length < 3 &&
      !confirm(
        `"${this.state.badword}" is less than 3 characters, are you sure you want to add it?`,
      )
    ) {
      return
    }
    this.props.addWordToList({word: this.state.badword, fullMatch: this.state.fullMatch})
    this.setState({
      badword: '',
    })
  }

  test() {
    this.props.testBadWord(this.state.testString)
  }

  deleteWord(word) {
    this.setState({
      wordToDelete: word,
      showDialog: true,
    })
  }

  buildResultString(){
    const testResult = !this.props.badwords.testResult.Result ? ' contains a bad word. ' : ' is clean. '
    return ` The string "${this.state.testString}" ${testResult} (${this.props.badwords.testResult.Message ? this.props.badwords.testResult.Message : "Valid"})
    `
  }

  handleModalOk(){
    this.props.deleteWordFromList(this.state.wordToDelete)
    this.resetModal()
  }

  resetModal(){
    this.setState({
      wordToDelete: '',
      showDialog: false,
    })
  }

  isSaveBadWordButtonDisabled(){
    return !this.state.badword.length > 0
  }

  render() {
    this.props.badwords.words.FullMatch.sort()
    this.props.badwords.words.PartialMatch.sort()
    return (
      <React.Fragment>
        <Prompt
            when={!this.isSaveBadWordButtonDisabled()}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        <section ref={t => (this.contentSection = t)}>
          <ConfirmModal
            showDialog={this.state.showDialog}
            contentSection={this.contentSection}
            dialogTitle={"Are you sure?"}
            dialogText={`Delete ${this.state.wordToDelete}`}
            onOk={ () => this.handleModalOk() }
            dialogCancel={ () => this.resetModal() }
          />
          <div className="content">
            <Grid>
              <Cell col={4}>
                <Grid>
                  <Cell col={12}>
                    <Card shadow={2}>
                      <CardTitle>Test String For Bad Words</CardTitle>
                      <CardActions border>
                        <Grid>
                          <Cell col={12}>
                            <Textfield
                              floatingLabel
                              label="Test String..."
                              onChange={e => this.handleTestStringChange(e.target.value)}
                              value={this.state.testString || ""}
                            />
                          </Cell>
                          <Cell col={12}>
                            {this.state.testString ? (
                              <div style={{ textAlign: "center" }}>
                                <p>
                                  <Icon
                                    name={
                                      this.props.badwords.isLoading
                                        ? "hourglass_empty"
                                        : !this.props.badwords.testResult.Result
                                        ? "warning"
                                        : "mood"
                                    }
                                  />
                                </p>
                                <p>{this.props.badwords.isLoading ? "" : this.buildResultString()}</p>
                              </div>
                            ) : (
                              <div />
                            )}
                          </Cell>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Cell>
                  <Cell col={12}>
                    <Card shadow={2}>
                      <CardTitle>Add a New String</CardTitle>
                      <CardActions border>
                        <Grid>
                          <Cell col={12}>
                            <Textfield
                              floatingLabel
                              label="Add New Bad Word"
                              onChange={e => this.handleNewBadwordChange(e.target.value)}
                              value={this.state.badword}
                            />
                            <Switch onChange={() => this.handleFullMatchChange()} checked={this.state.fullMatch || false}>
                              Full Word Match
                            </Switch>
                            <div className="mdl-cell mdl-cell--12-col">
                              <Button
                                disabled={this.isSaveBadWordButtonDisabled()}
                                onClick={() => this.handleSaveBadwordButton()}
                                raised ripple colored>
                                  <Icon name="save" /> Save
                              </Button>
                            </div>
                          </Cell>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Cell>
                </Grid>
              </Cell>
              <Cell col={8}>
                <Grid>
                  <Card shadow={2}>
                    <CardTitle> Bad Words List</CardTitle>
                    <CardActions border>
                      <Grid>
                        <Cell col={6}>
                          <h6>Full Match</h6>
                          <FilterList
                            style={{ height: "100vh" }}
                            simple={true}
                            onDelete={c => this.deleteWord(c)}
                            items={this.props.badwords.words.FullMatch}
                          />
                        </Cell>
                        <Cell col={6}>
                          <h6>Partial Match</h6>
                          <FilterList
                            simple={true}
                            onDelete={c => this.deleteWord(c)}
                            items={this.props.badwords.words.PartialMatch}
                          />
                        </Cell>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Cell>
            </Grid>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const BadWords = connect(null, mapDispatchToProps)(badWords)

export default BadWords
