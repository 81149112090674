import {
  retrievePlacements,
  savePlacement,
  newPlacement,
  loadAssociatedTags,
  loadTagsTaxonomy,
  resetAssociatedTags,
  saveCreative,
  retrieveCreative,
  retrieveAgentSourceURL,
  isDirty,
  updatePlacement,
  resetPlacement,
  updateCreative,
  loadAssociatedCriteria,
  saveAssociatedCriteriaFilters,
  deleteAssociatedCriteriaFilters,
  savePlacementAssociatedLeadAcceptances,
  deleteAssociatedLeadAcceptance,
  loadAssociatedLeadAcceptance,
  retrieveCampaignCriteria,
  openAgent,
  retrievePlacementStats,
  resetAssociatedCriteriaSaveMsg,
  saveAddressValidationPlacementAssociatedConfigs,
  setPlacementManagerValidationErrors,
  retrieveAssociationOverallCapCount, 
  retrieveAssociationDailyCapCount,
} from "../../services"
import Modal from "react-modal"
import { retrieveCustomersThunk } from "../../services/customerService"

import PlacementCreative from "./placementCreative"
import PlacementDetail from "./details"
import PlacementGroups from './groups'
import TagManager from "../tagging/manager"
import React from "react"
import { connect } from "react-redux"
import { Grid, Button, Icon, Cell, Card, CardActions, Tab, Tabs, Tooltip } from "react-mdl"
import { findAssociationCurrentRate, findAssociationCurrentCap } from '../../utils/entityLedgers'

function mapStateToProps(state) {
  return {
    isDirtyValue: state.isDirty,
    isNew: (state.placementManager.placement && state.placementManager.placement.UUID) ? false : true,
    placementManager: state.placementManager,
    placementData: state.placementData,
    placements: state.placementData.records,
    associatedAddressValidationsSettings: state.addressValidationPlacementAssociatedConfigs,
    creative: state.creative,
    rankers: state.rankers,
    customers: state.customers,
    campaignData: state.campaignData,
    tags: state.tags,
    tagMasterList: state.tagMasterList,
    associatedTags: state.associatedTags,
    creativePreview: state.creativePreview,
    group: state.group,
    agentSourceURL: state.agentSourceURL,
    associatedCriteria: state.associatedCriteria,
    associatedCriteriaSaveMsg: state.associatedCriteriaSaveMsg,
    user: state.user,
    zips: state.zips,
    associatedLeadAcceptance: state.associatedLeadAcceptance,
    itemStats: state.itemStats,
    campaignCriteria: state.campaignCriteria
  }
}

function mapDispatchToProps(dispatch) {
  return {
    update(placement) {
      dispatch(updatePlacement(placement))
    },
    reset() {
      dispatch(resetPlacement())
    },
    savePlacement(p, callbacks, campaignUUID) {
      const cbs = callbacks || []
      dispatch(savePlacement(p, cbs, campaignUUID))
    },
    newPlacement(isBrokered) {
      dispatch(resetAssociatedTags())
      dispatch(newPlacement(isBrokered))
    },
    //setCrud: function (crud) {
    //  dispatch(setCrud(crud))
    //},
    isDirty: function () {
      dispatch(isDirty(true))
    },
    isNotDirty: function () {
      dispatch(isDirty(false))
    },
    loadTagsTaxonomy() {
      dispatch(loadTagsTaxonomy())
    },
    loadAssociatedTags(placementUUID) {
      dispatch(loadAssociatedTags(placementUUID))
    },
    loadAssociatedCriteria(placementUUID) {
      dispatch(loadAssociatedCriteria(placementUUID))
    },
    retrievePlacements(placementUUID, avoidRedirection) {
      dispatch(retrievePlacements(placementUUID, avoidRedirection))
    },
    openAgent(placementUUID, user, campaigns) {
      dispatch(openAgent(placementUUID, user, campaigns))
    },
    saveCreative(creative, placement) {
      dispatch(
        saveCreative(creative, c => {
          placement.creativeUUID = c.uuid
          dispatch(savePlacement(placement, []))
          dispatch(retrieveCreative(c.uuid))
        })
      )
    },
    reloadCreative(uuid) {
      dispatch(retrieveCreative(uuid))
    },
    handleCssChange(creative, newCSS) {
      creative.css = newCSS
      dispatch(updateCreative(creative))
    },
    saveAssociatedCriteriaFilters(criteriaFilters) {
      dispatch(saveAssociatedCriteriaFilters(criteriaFilters))
    },
    deleteAssociatedCriteriaFilters(associatedCriteriaFilter) {
      dispatch(deleteAssociatedCriteriaFilters(associatedCriteriaFilter))
    },
    saveAddressValidations(placementUUID, associatedAddressValidations) {
      dispatch(saveAddressValidationPlacementAssociatedConfigs(placementUUID, associatedAddressValidations))
    },
    handleTextStringChange(creative, textfield, value) {
      creative.textStrings[textfield] = value
      dispatch(updateCreative(creative))
    },
    retrieveAgentURL: function () {
      dispatch(retrieveAgentSourceURL())
    },
    loadAssociatedLeadAcceptance: function (placementUUID) {
      dispatch(loadAssociatedLeadAcceptance(placementUUID))
    },
    savePlacementAssociatedLeadAcceptances: function (placementUUID, associatedLeadAcceptance) {
      dispatch(savePlacementAssociatedLeadAcceptances(placementUUID, associatedLeadAcceptance))
    },
    deleteAssociatedLeadAcceptance: function (associatedLeadAcceptance) {
      dispatch(deleteAssociatedLeadAcceptance(associatedLeadAcceptance))
    },
    retrieveCampaignCriteria(campaignUUID) {
      dispatch(retrieveCampaignCriteria(campaignUUID))
    },
    resetAssociatedCriteriaSaveMessage() {
      dispatch(resetAssociatedCriteriaSaveMsg())
    },
    retrievePlacementStats(placementUUID) {
      dispatch(retrievePlacementStats(placementUUID))
    },
    retrieveCustomers() {
      dispatch(retrieveCustomersThunk())
    },
    retrieveAssociationOverallCapCount(placementUuid, campaignUuid, effectiveDate) {
      dispatch(retrieveAssociationOverallCapCount(placementUuid, campaignUuid, effectiveDate))
    },
    retrieveAssociationDailyCapCount(placementUuid, campaignUuid) {
      dispatch(retrieveAssociationDailyCapCount(placementUuid, campaignUuid))
    },
    setPlacementManagerValidationErrors: function (errors) {
      dispatch(setPlacementManagerValidationErrors(errors))
    },
  }
}

class placementEditor extends React.Component {
  constructor(props) {
    super(props)

    this.isModal = props.isModal || false

    this.setGetPlacement = getPlacement => {
      this.getPlacement = getPlacement
    }
    this.addSavePlacementCallBack = cb => {
      this.savePlacementCallBacks.push(cb)
    }
    this.savePlacementCallBacks = []
    this.getSavePlacementCallBacks = () => this.savePlacementCallBacks
    this.getPlacement = () => null
    this.state = {
      activeTab: 0,
      placementName: "",
      seed: Math.random(),
      tabSeed: Math.random(),
      showPlacementTypeModal: false,
      placementNotFound: false,
    }
    this.updatePlacementName = this.updatePlacementName.bind(this)
    this.setIsTabComponentDirty = isTabComponentDirty => (this.isTabComponentDirty = isTabComponentDirty)
    this.isTabComponentDirty = () => null
  }

  checkIsDirty(activeTab) {
    const isDirtyMap = {
      "0": () => this.props.isDirtyValue,
      "1": () => this.isTabComponentDirty(),
      "2": () => this.isTabComponentDirty(),
      "3": () => this.isTabComponentDirty()
    }
    return isDirtyMap[activeTab]()
  }

  openAgent() {
    this.props.openAgent(this.props.placementManager.placement.UUID, {}, [])
  }

  updatePlacementName(name) {
    this.setState({ placementName: name })
  }

  placementSelected(placementUUID) {
    if (
      this.checkIsDirty(this.state.activeTab) &&
      !confirm("You have unsaved changes, are you sure you want to leave?")
    ) {
      this.props.closeLookup()
      return
    }
    this.props.retrievePlacements(placementUUID, this.isModal)
    this.props.loadAssociatedTags(placementUUID)
    this.props.loadAssociatedCriteria(placementUUID)
    this.props.retrievePlacementStats(placementUUID)
    this.setState({
      activeTab: 0,
    })
    this.props.closeLookup()
  }

  componentDidMount() {
    Modal.setAppElement('body')
    
    let crudOptions = {
      onSave: () => {
        let placement = this.getPlacement()
        let validationErrors = []
        
        if(placement.isBrokered){
          if(this.props.associatedCampaignFilter){
            let associationCurrentRateEntry = findAssociationCurrentRate(placement.brokeredRevenueShare, this.props.associatedCampaignFilter)
            if(!associationCurrentRateEntry){
              validationErrors.push("'Current rate' is not defined")
            }


            let associationCurrentCapEntry = findAssociationCurrentCap(placement.campaignOverallLeadCap, this.props.associatedCampaignFilter)
            if(!associationCurrentCapEntry){
              validationErrors.push("'Current cap' is not defined")
            }


          }else{

            placement.whitelistCampaignAssociations?.forEach(whitelistItem => {
              if(!placement.brokeredRevenueShare.find(e=>e.campaignUuid == whitelistItem.campaignUuid)){
                validationErrors.push("'Current rate' is not defined - campaignUUID:"+whitelistItem.campaignUuid)
              }
              if(!placement.campaignOverallLeadCap.find(e=>e.campaignUuid == whitelistItem.campaignUuid)){
                validationErrors.push("'Current cap' is not defined - campaignUUID:"+whitelistItem.campaignUuid)
              }
            })

            placement.brokeredRevenueShare.forEach(e => {
              if(!placement.whitelistCampaignAssociations.find(e=>e.campaignUuid)){
                return
              }
              
              let associationCurrentRateEntry = findAssociationCurrentRate(placement.brokeredRevenueShare, e.campaignUuid)
              if(!associationCurrentRateEntry){
                validationErrors.push("'Current rate' is not defined - campaignUUID:"+e.campaignUuid)
              }
            })
        
            placement.campaignOverallLeadCap.forEach(e => {
              if(!placement.whitelistCampaignAssociations.find(e=>e.campaignUuid)){
                return
              }
              
              let associationCurrentCapEntry = findAssociationCurrentCap(placement.campaignOverallLeadCap, e.campaignUuid)
              if(!associationCurrentCapEntry){
                validationErrors.push("'Current cap' is not defined - campaignUUID:"+e.campaignUuid)
              }
            })
          }
        }
    
        if(placement.isBrokered){
          placement.brokeredRevenueShare.forEach(e => {
            e.revenueShare.forEach(rs =>{
              if(!rs.isAbsolute){
                validationErrors.push("Relative rates are not allowed in brokered associations - campaignUUID:"+e.campaignUuid)
              }
            })
          })
        }

        this.props.setPlacementManagerValidationErrors(validationErrors)
        
        if(validationErrors.length > 0){
          return
        }

        this.props.savePlacement(this.getPlacement(), this.getSavePlacementCallBacks(), this.props.associatedCampaignFilter)
        this.savePlacementCallBacks = []
      },
      onCancel: () => {
        if (this.props.placementManager.placement.UUID) {
          this.props.retrievePlacements(this.props.placementManager.placement.UUID, this.isModal)
          this.setState({
            seed: Math.random()
          })
        } else {
          this.props.reset()
        }
      },
      onNew: () => {
        if (
          this.checkIsDirty(this.state.activeTab) &&
          !confirm("You have unsaved changes, are you sure you want to leave?")
        ) {
          this.setState({ activeTab: this.state.activeTab, tabSeed: Math.random() })
          return
        }
       // this.props.reset()

        this.setState({
          showPlacementTypeModal: true,
        })
      },
      visible: true
    }
    this.props.setCrudHandler(crudOptions)
    this.props.isNotDirty()

    if (this.props.match.params.uuid) {
      this.props.retrievePlacements(this.props.match.params.uuid, this.isModal)
      this.props.loadAssociatedTags(this.props.match.params.uuid)
      this.props.loadAssociatedCriteria(this.props.match.params.uuid)
      this.props.loadAssociatedLeadAcceptance(this.props.match.params.uuid)
      this.props.retrievePlacementStats(this.props.match.params.uuid)
      this.props.loadTagsTaxonomy()
      this.props.retrieveAgentURL()
      this.props.retrieveCustomers()
    } else if (this.props.isNew) {
      this.props.retrievePlacements(this.props.match.params.uuid, this.isModal)
      this.props.retrieveAgentURL()
      this.props.retrieveCustomers()
    } else {
      this.props.loadAssociatedTags(this.props.placementManager.placement.UUID)
      this.props.loadAssociatedCriteria(this.props.placementManager.placement.UUID)
      this.props.loadAssociatedLeadAcceptance(this.props.placementManager.placement.UUID)
      this.props.retrievePlacementStats(this.props.placementManager.placement.UUID)
      this.props.reloadCreative(this.props.placementManager.placement.creativeUUID)
      this.props.loadTagsTaxonomy()
      this.props.retrieveAgentURL()
      this.props.retrieveCustomers()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.placementUUID !== this.props.placementUUID) {
      this.placementSelected(this.props.placementUUID)
    }
  }

  savePlacementAndCriteria(criteriaFilters) {
    const cb = uuid => {
      criteriaFilters.placementUuid = uuid
      this.props.saveAssociatedCriteriaFilters(criteriaFilters)
    }
    this.props.savePlacement(this.getPlacement(), [cb])
  }


  handleTabChange(tabId) {
    if (
      this.checkIsDirty(this.state.activeTab) &&
      !confirm("You have unsaved changes, are you sure you want to leave?")
    ) {
      this.setState({ activeTab: this.state.activeTab, tabSeed: Math.random() })
      return
    }
    this.props.isNotDirty()
    this.setState({ activeTab: tabId })
  }

  handleClosePlacementTypeModal() {
    this.setState({ showPlacementTypeModal: false })
  }

  handleChooseBrokeredPlacementType() {
    this.props.newPlacement(true)
    this.setState({ showPlacementTypeModal: false, placementName: "" })
    this.props.closeLookup()
  }

  handleChooseNonBrokeredPlacementType() {
    this.props.newPlacement(false)
    this.setState({ showPlacementTypeModal: false, placementName: "" })
    this.props.closeLookup()
  }

  renderCreatePlacementPopup(){
    return <Modal appElement={this.contentSection}
    isOpen={this.state.showPlacementTypeModal}
    onRequestClose={this.handleSessionPopupClose}
    contentLabel="Placement Type"
  >
    <Card shadow={2}>
      <CardActions style={{ textAlign: "right" }}>
        <Button onClick={() => this.handleClosePlacementTypeModal()}>
          <Icon name="close" />
        </Button>
      </CardActions>
      <Grid>
        <Cell col={6}>
          <Button id="new-brokered-placement-button" onClick={() => this.handleChooseBrokeredPlacementType()} raised ripple colored>
            Brokered
          </Button>
        </Cell>
        <Cell col={6}>
          <Button id="new-non-brokered-placement-button" onClick={() => this.handleChooseNonBrokeredPlacementType()} raised ripple colored>
            Non_Brokered
          </Button>
        </Cell>
      </Grid>
    </Card>
  </Modal>
  }

  render() {
    if (this.props.placementData.isLoading) {
      return <div style={{ textAlign: "center" }}>Loading placement ...</div>
    }
    if (!this.props.placementManager.placement) {
      return <div style={{ textAlign: "center" }}>
        {this.renderCreatePlacementPopup()}
      </div>
    }

    if (this.props.associatedCampaignFilter) {
      if (this.props.campaignData.isLoading) return <div style={{ textAlign: "center" }}>Loading placement (retrieving campaign) ...</div>

      let filterCampaign = this.props.campaignData.records.find(e => e.UUID === this.props.associatedCampaignFilter)
      if (!filterCampaign) {
        return <div style={{ textAlign: "center" }}>Campaign not found</div>
      }
    }
    let tabs = <Tabs
      key={this.state.tabSeed}
      activeTab={this.state.activeTab}
      onChange={tabId => this.handleTabChange(tabId)}
      ripple
      style={{ width: "100%" }}>
      <Tab tabId={0}>DETAILS</Tab>
      <Tab tabId={1} style={{ display: this.props.isNew ? "none" : "block" }}>TAGS</Tab>
      <Tab tabId={2} style={{ display: this.props.isNew || this.props.placementManager.placement.isBrokered ? "none" : "block" }}>CREATIVE</Tab>
      <Tab tabId={3} style={{ display: this.props.isNew ? "none" : "block" }}>GROUPS</Tab>
    </Tabs>
    if (this.isModal) {
      tabs = <Tabs
        key={this.state.tabSeed}
        activeTab={this.state.activeTab}
        onChange={tabId => this.handleTabChange(tabId)}
        ripple
        style={{ width: "100%" }}>
        <Tab tabId={0}>DETAILS</Tab>
      </Tabs>
    }
    return (
      <section ref={t => this.contentSection = t}> 
        {this.renderCreatePlacementPopup()}
        <Grid style={{ width: "95%", paddingLeft: "0px", paddingRight: "0px" }}>
          <Cell col={12}>
            {this.props.placementManager.placement ? (
              <section>
                {this.props.placementManager.validationErrors && this.props.placementManager.validationErrors.length > 0 && <div>
                  <Grid style={{ marginTop: "-25px", paddding: "8px 0px" }}>
                    <Cell col={6} style={{color: "red"}}>
                      <b>Errors:</b>
                      <ul>
                        {this.props.placementManager.validationErrors.map((e, i)=>{
                          return <li key={`validation-error-${i}`} >{e}</li>
                        })}
                      </ul>
                    </Cell>
                  </Grid>
                </div>}
                <div>
                  <Grid style={{ marginTop: "-25px", paddding: "8px 0px" }}>
                    <Cell col={12}>
                    <Tooltip label={this.props.placementManager.placement.isLegacy ? "Legacy placement" : "New-system placement"}>
                      <h3>{this.state.placementName}</h3>
                    </Tooltip>
                    </Cell>
                  </Grid>
                </div>
                <div onClick={() => this.props.toggleList(false)}>
                  {tabs}
                  <section>
                    {this.state.activeTab == 0 && <PlacementDetail
                      isModal={this.isModal}
                      isDirtyValue={this.props.isDirtyValue}
                      isNew={this.props.isNew}
                      key={`${this.props.placementManager.placement.UUID}-${this.state.seed}`}
                      setGetPlacement={this.setGetPlacement}
                      savePlacementAndCriteria={cf => this.savePlacementAndCriteria(cf)}
                      addSavePlacementCallBack={this.addSavePlacementCallBack}
                      updatePlacementName={this.updatePlacementName}
                      associatedLeadAcceptance={this.props.associatedLeadAcceptance}
                      saveAssociatedCriteriaFilters={this.props.saveAssociatedCriteriaFilters}
                      deleteAssociatedCriteriaFilters={this.props.deleteAssociatedCriteriaFilters}
                      placement={this.props.placementManager.placement}
                      associatedAddressValidations={this.props.associatedAddressValidationsSettings}
                      saveAddressValidations={this.props.saveAddressValidations}
                      rankers={this.props.rankers}
                      customers={this.props.customers}
                      associatedCampaignFilter={this.props.associatedCampaignFilter}
                      campaigns={this.props.campaignData.records}
                      tags={this.props.tags}
                      tagMasterList={this.props.tagMasterList}
                      placementUpdated={this.props.isDirty}
                      associatedCriteria={this.props.associatedCriteria}
                      associatedCriteriaSaveMsg={this.props.associatedCriteriaSaveMsg}
                      resetAssociatedCriteriaSaveMsg={this.props.resetAssociatedCriteriaSaveMessage}
                      zips={this.props.zips}
                      campaignCriteria={this.props.campaignCriteria}
                      savePlacementAssociatedLeadAcceptances={this.props.savePlacementAssociatedLeadAcceptances}
                      deleteAssociatedLeadAcceptance={this.props.deleteAssociatedLeadAcceptance}
                      retrieveCampaignCriteria={this.props.retrieveCampaignCriteria}
                      itemStats={this.props.itemStats}
                      retrievePlacementStats={this.props.retrievePlacementStats}
                      dailyCapCounts={this.props.dailyCapCounts}
                      currentCapCounts={this.props.currentCapCounts}
                      retrieveAssociationOverallCapCount={(campaignUuid, effectiveDate) => this.props.retrieveAssociationOverallCapCount(this.props.placementManager.placement.UUID, campaignUuid, effectiveDate)}
                      retrieveAssociationDailyCapCount={(campaignUuid) => this.props.retrieveAssociationDailyCapCount(this.props.placementManager.placement.UUID, campaignUuid)}
                      group={this.props.group}
                    />}
                    {this.state.activeTab == 1 && <TagManager
                      key={1}
                      item={this.props.placementManager.placement}
                      itemType={"placement"}
                      tags={this.props.tagMasterList}
                      associatedTags={this.props.associatedTags}
                      setIsTabComponentDirty={this.setIsTabComponentDirty}
                    />}
                    {this.state.activeTab == 2 && <PlacementCreative
                      key={`creative-${this.props.placementManager.placement.UUID}-${this.state.seed}`}
                      creative={this.props.creative}
                      saveCreative={c => this.props.saveCreative(c, this.props.placementManager.placement)}
                      reloadCreative={() => this.props.reloadCreative(this.props.placementManager.placement.creativeUUID)}
                      handleCssChange={this.props.handleCssChange}
                      handleTextStringChange={this.props.handleTextStringChange}
                      setIsTabComponentDirty={this.setIsTabComponentDirty}
                      previewAgent={() => this.openAgent()}
                    />}
                    {this.state.activeTab == 3 && <PlacementGroups
                      key={4}
                      placement={this.props.placementManager.placement}
                      placements={this.props.placements}
                      group={this.props.group}
                      savePlacement={this.props.savePlacement}
                      setIsTabComponentDirty={this.setIsTabComponentDirty}
                    />}
                  </section>
                </div>
              </section>
            ) : null}
          </Cell>
        </Grid>
      </section>
    )
  }
}

const PlacementEditor = connect(mapStateToProps, mapDispatchToProps)(placementEditor)

export default PlacementEditor
