import React from 'react'
import { groupBy } from '../../utils/index'
import FilterList from '../filterList'
import {Grid, Cell, Card, CardActions, CardTitle, Textfield, Icon, Button, ListItem, List, Switch, FABButton, CardMenu} from 'react-mdl'


export default class WinbackCampaigns extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCampaign : null,
    }
  }

  removeGroupMember(campaign) {
    let idx = this.props.winbackCampaigns.findIndex(wb => wb.externalMappingID == campaign.externalMappingID && wb.campaignUUID == campaign.campaignUUID)
    this.props.winbackCampaigns.splice(idx, 1)
    this.props.updated()
  }

  add(wbCampaign) {
    let idx = this.props.winbackCampaigns.findIndex(cm => !cm.campaignUUID || cm.campaignUUID.length == 0)
    if (idx >= 0) {
      this.props.winbackCampaigns.splice(idx, 1)
    }
    this.props.winbackCampaigns.push(wbCampaign)
    this.props.updated()
  }

  newCampaignGroup() {
    let idx = this.props.winbackCampaigns.findIndex(cm => !cm.campaignUUID || cm.campaignUUID.length == 0)
    if (idx >= 0) {
      return
    }
    this.props.winbackCampaigns.push({
      externalMappingID: "New External ID"
    })
    this.setState(this.state)
  }

  render() {
    return <div>
      <Button
        colored
        raised
        ripple
        onClick={() => this.newCampaignGroup()}>
        <Icon name="add_box">New Group</Icon> New Campaign Group
          </Button>
      <Grid>
        {Array.from(groupBy(this.props.winbackCampaigns, wbc => wbc.externalMappingID)).map((wbg) => {
          return (
            <Cell key={wbg[0]} col={4}>
              <CampaignGroup
                removeGroupMember={gm => this.removeGroupMember(gm)} 
                add={(wbCampaign) => this.add(wbCampaign)} 
                campaigns={this.props.campaigns} 
                campaignGroup={wbg} />
            </Cell>
          )
        })}
      </Grid>
    </div>
  }
}

class CampaignGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCampaign: null,
    }
  }

  addCampaign(winbackCampaign) {
    this.props.add(winbackCampaign)
    this.setState({
      selectedCampaign: null
    })
  }

  campaignSelected(campaign) {
    const selectedCampaign = {
      campaignUUID: campaign.UUID,
      weight: this.props.campaignGroup[1].length + 1,
      externalMappingID: this.props.campaignGroup[0],
      isWinBack: false
    }
    this.setState({
      adding: false,
      selectedCampaign: selectedCampaign
    })
  }

  handleExternalIdChange(value){
    this.props.campaignGroup[0] = value
    this.props.campaignGroup[1].forEach(cg => cg.externalMappingID = this.props.campaignGroup[0])
    this.setState(this.state)
  }

  render() {
    if (this.props.campaigns.length == 0) {
      return null
    }
    let externalID = ''
    let campaignGroup = []
    if (this.props.campaignGroup.length > 1) {
      externalID = this.props.campaignGroup[0]
      campaignGroup = this.props.campaignGroup[1]
    }
    return <Card style={{ width: '100%' }} shadow={2}>
      <CardTitle> <Textfield
        value={externalID}
        floatingLabel
        label="External ID"
        onChange={e => this.handleExternalIdChange(e.target.value)}
      /></CardTitle>
      <CardMenu>
        {this.state.selectedCampaign ? <FABButton
          onClick={() => {
            this.addCampaign({
              externalMappingID: externalID,
              weight: this.state.selectedCampaign.weight,
              isWinBack: this.state.selectedCampaign.isWinBack,
              campaignUUID: this.state.selectedCampaign.campaignUUID
            })
          }}
          mini
          colored
          className="fabPos">
          <Icon name="save" />
        </FABButton> : <FABButton
          onClick={() => {
            this.setState({
              adding : true
            })
          }}
          mini
          colored
          className="fabPos">
            <Icon name="add" />
          </FABButton>}
      </CardMenu>
      <CardActions style={{ maxHeight: '240px', overflowY: 'scroll' }}>
        {this.state.selectedCampaign ? <div>
          <span>{this.props.campaigns.find(x => x.UUID == this.state.selectedCampaign.campaignUUID).name}</span>
          <Textfield
            value={this.state.selectedCampaign.weight}
            floatingLabel
            label="Weight"
            onChange={e => {
              const {selectedCampaign} = this.state 
              selectedCampaign.weight = e.target.value
              this.setState({
                selectedCampaign : selectedCampaign
              })
            }}
          />
          <Switch
            onChange={() => {
              const {selectedCampaign} = this.state 
              selectedCampaign.isWinBack = !this.state.selectedCampaign.isWinBack
              this.setState({
                selectedCampaign : selectedCampaign
              })
            }}
            checked={this.state.selectedCampaign.isWinBack || false}
          >Winback Campaign</Switch>
        </div> : <List className="fullWidth">
            {campaignGroup.map(groupMember => {
              let campaign = this.props.campaigns.find(x => x.UUID == groupMember.campaignUUID)
              if (!campaign) {
                if (this.state.adding) {
                  return null
                }
                return (
                  <ListItem key={groupMember.externalMappingID} onClick={() => {
                    this.setState({
                      adding : true
                    })
                  }}>
                    <Grid>
                      <Cell col={12}>
                        <span>{`Add To ${externalID}`}</span>
                      </Cell>
                    </Grid>
                  </ListItem>
                )
              }
              return (
                <ListItem key={groupMember.campaignUUID + groupMember.externalMappingID}>
                  <Grid>
                    <Cell col={10}>
                      <span>{campaign.name} {groupMember.isWinBack ? <Icon name="star_rate" /> : <Icon name="" />}</span>
                    </Cell>
                    <Cell col={2}>
                      <Button
                        accent
                        onClick={() => {
                          this.props.removeGroupMember(groupMember)
                        }}>
                        <Icon name="delete" />
                      </Button>
                    </Cell>
                  </Grid>
                </ListItem>
              )
            })}
          </List>}
      </CardActions>
      {this.state.adding
        ? <CardActions border>
          <FilterList
            label="Campaign list..."
            selected={{}}
            additionalFieldList={['UUID', 'shortCode']}
            title="Campaigns"
            selectItem={c => this.campaignSelected(c)}
            items={this.props.campaigns.filter(
              c => !this.props.campaignGroup.some(
                mem => mem.campaignUUID == c.UUID,
              ),
            )}
          />
        </CardActions>
        : <div />}
    </Card>
  }
}