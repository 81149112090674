import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Textfield, Button, Icon, Grid, Cell, Switch } from 'react-mdl'

export default class LeadCapItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.propsToState(props)
  }

  propsToState(props) {
    let newState = {
      capValue: props.share.value,
      effectiveDate: moment.unix(parseInt(props.share.effectiveDate)),
    }

    newState.isWarningCustomized = props.share.warningConfigs !== undefined && props.share.warningConfigs != null ? true:false
    
    if(props.share.warningConfigs){
      newState.warningInactive = props.share.warningConfigs.inactive
      newState.warningTriggerPercentage = props.share.warningConfigs.triggerPercentage
      newState.warningRemindEveryNLeads = props.share.warningConfigs.remindEveryNLeads
      newState.warningCustomPercentTimestamp = props.share.warningConfigs.timestamp
    }else{
      newState.warningInactive = false
      newState.warningTriggerPercentage = 95
      newState.warningRemindEveryNLeads = 20
      newState.warningCustomPercentTimestamp = 0
    }
    
    return newState
  }

  componentDidUpdate(prevProps) {
    if (prevProps.share !== this.props.share) {
      this.setState({
        ...this.propsToState(this.props),
      })
    }
  }

  handleChange(callback) {
    callback()
  }

  handleSaveButton() {
    const normalizedValue = parseInt(this.state.capValue)
    
    let warningConfigs = null
    if(this.state.isWarningCustomized){
      warningConfigs = {
        inactive: this.state.warningInactive,
        triggerPercentage: this.state.warningTriggerPercentage,
        remindEveryNLeads: this.state.warningRemindEveryNLeads,
      }
    }
    
    this.props.onSave(
      normalizedValue,
      this.state.effectiveDate.unix().toString(),
      this.state.warningCustomPercentTimestamp,
      warningConfigs,
    )
  }

  render() {
    let disabled = this.state.effectiveDate < moment() && !this.props.share.isNew
    return (
      <div>
        <Grid style={{ width: "100%" }}>
          <Cell col={4}>
            <Grid>
              <Cell col={12}>
                <p>&nbsp;</p>
              </Cell>
              <Cell col={12}>
                <span style={{ display: 'inline-block', width: '40px' }}>{' '}</span>
                <Textfield
                  value={this.state.capValue}
                  pattern={"(^[0-9]+)"} // eslint-disable-line
                  error={`Must be an integer`}
                  onChange={(e) => {
                    this.setState({ capValue: e.target.value })
                  }}
                  label="Overall Cap"
                  floatingLabel
                  style={{ width: '100px' }}
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell col={4}>
            <Grid>
              <Cell col={12}>
                <Button
                  id="save-cap-button"
                  disabled={disabled}
                  onClick={() => this.handleSaveButton()}>
                  <Icon name="save" />
                </Button>

                {<Button
                  id="delete-cap-item-button"
                  disabled={disabled}
                  onClick={() => {
                    this.props.onDelete(this.props.selectedIndex)
                  }}>
                  <Icon name="delete" />
                </Button>}

                <Button onClick={this.props.onCancel}>
                  <Icon name="close">Close</Icon>
                </Button>

              </Cell>
              <Cell col={12} id="cap-effective-date-cell">
                <span className="floatLabel">Effective Date</span>
                <DatePicker
                  className="thin-date-picker"
                  isClearable={false}
                  dateFormat="MM/dd/yyyy"
                  selected={!this.state.effectiveDate ? null : this.state.effectiveDate.toDate()}
                  disabled={disabled}
                  minDate={moment().toDate()}
                  onChange={(d) => {
                    this.setState({ effectiveDate: moment(d) })
                  }}
                  withPortal
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell col={4}>
            <Grid style={{ width: "100%" }}>
              <Cell col={12}>
                <Switch
                  disabled={disabled}
                  onChange={() => {
                    this.setState({ isWarningCustomized: !this.state.isWarningCustomized })
                  }}
                  checked={this.state.isWarningCustomized}>
                  Customized warning
                </Switch>
              </Cell>
              {this.state.warningCustomPercentTimestamp > 0 && <Cell col={12}>
                Warning sent on: {moment.unix(parseInt(this.state.warningCustomPercentTimestamp)).format('MM/DD/YYYY, h:mm a')}
                </Cell>}
              <Cell col={12}>
                {this.state.isWarningCustomized && this.renderWarningConfigs(disabled)}
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </div>
    )
  }

  renderWarningConfigs(disabled){
    return <Grid style={{ width: "100%" }}>
      <Cell col={12}>
        <Textfield
          disabled={disabled}
          value={this.state.warningTriggerPercentage}
          pattern={"(^[0-9]+)"}
          error={`Must be an integer`}
          onChange={(e) => {
            this.setState({ warningTriggerPercentage: e.target.value })
          }}
          label="Trigger Percentage"
          floatingLabel
          style={{ width: "100%" }}
        />
      </Cell>
      <Cell col={12}>
        <Switch
          disabled={disabled}
          onChange={() => {
            this.setState({ warningInactive: !this.state.warningInactive })
          }}
          checked={this.state.warningInactive}>
          Inactive
        </Switch>
      </Cell>
    </Grid>
  }
}
