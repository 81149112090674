import React from 'react'
import HttpPreauth from './preauth'
import { UUID } from '../../utils'
import RequestForm from '../common/requestForm'
import { Grid, Cell, Card, CardActions, Textfield, Icon, Button, Tab, Tabs } from 'react-mdl'
import HttpResponse from './response'

const INITIAL_INDEX = -1

class HttpInteraction extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0,
      rolledUp: true
    }

    this.handleTabChange = this.handleTabChange.bind(this)
  }

  toggleRollUp(e) {
    e.preventDefault()
    this.setState({
      rolledUp: !this.state.rolledUp
    })
  }

  handleTabChange(tabIndex) {
    this.setState({
      activeTab: tabIndex
    })
  }

  handleChange(callback) {
    callback()
    this.setState({})
  }

  render() {

    const { interactionIndex } = this.props
    this.elements = [
      <HttpPreauth
        key={0}
        {...this.props}
        shadow={1}
        preauth={this.props.interaction.preauth}
      />,
      <RequestForm
        key={1}
        requestVerb={this.props.interaction.request.verb}
        requestUrl={this.props.interaction.request.url}
        requestBody={this.props.interaction.request.body}
        requestHeaders={this.props.interaction.request.headers}
        handleVerbChange={(s) => this.handleChange(() => this.props.handleInteractionVerbChange(interactionIndex, '', 'request', s.value))}
        handleUrlChange={(e) => this.handleChange(() => this.props.handleInteractionUrlChange(interactionIndex, '', 'request', e.target.value))}
        handleBodyChange={(b) => this.handleChange(() => this.props.handleInteractionBodyChange(interactionIndex, '', 'request', b))}
        handleHeaderUpdate={(h) => this.handleChange(() => this.props.handleInteractionHeadersUpdate(interactionIndex, '', 'request', h))}
        handleHeaderDelete={(h) => this.handleChange(() => this.props.handleInteractionHeadersDelete(interactionIndex, '', 'request', h))}
        handleHeaderSave={(h) => this.handleChange(() => this.props.handleInteractionHeadersSave(interactionIndex, '', 'request', h))}
      />,

      <HttpResponse key={2} shadow={1} response={this.props.interaction.response} updated={(res) => this.props.handleInteractionResponseUpdate(interactionIndex, res)} />,
      // key={1}
      // requestVerb={this.props.interaction.response.verb}
      // requestUrl={this.props.interaction.response.url}
      // requestBody={this.props.interaction.response.body}
      // requestHeaders={this.props.interaction.response.headers}
      // handleVerbChange={(s) => this.handleChange(() => this.props.handleInteractionVerbChange(interactionIndex, '', 'response', s.value))}
      // handleUrlChange={(e) => this.handleChange(() => this.props.handleInteractionUrlChange(interactionIndex, '', 'response', e.target.value))}
      // handleBodyChange={(b) => this.handleChange(() => this.props.handleInteractionBodyChange(interactionIndex, '', 'response', b))}
      // handleHeaderUpdate={(h) => this.handleChange(() => this.props.handleInteractionHeadersUpdate(interactionIndex, '', 'response', h))}
      // handleHeaderDelete={(h) => this.handleChange(() => this.props.handleInteractionHeadersDelete(interactionIndex, '', 'response', h))}
      // handleHeaderSave={(h) => this.handleChange(() => this.props.handleInteractionHeadersSave(interactionIndex, '', 'response', h))}
    ]
    return <Card shadow={this.props.shadow} style={{ width: '100%' }}>
      <CardActions border>
        <Grid>
          <Cell col={12}>
            <Textfield
              floatingLabel
              label="Call Position"
              onChange={e => this.handleChange(() => this.props.handleInteractionOrder(this.props.interactionIndex, e.target.value))}
              value={this.props.interaction.order}
            />
            <Button
              colored
              raised
              ripple
              onClick={() => this.props.delete(this.props.interactionIndex)}
            >
              <Icon name="delete">delete</Icon>
            </Button>
          </Cell>
          <Cell col={12} style={{ width: '100%' }}>
            <Button
              colored
              raised
              ripple
              onClick={e => this.toggleRollUp(e)}>
              {this.state.rolledUp ? 'Configure' : <Icon name='keyboard_arrow_up' />}
            </Button>
            {this.state.rolledUp ?
              <div></div>
              :
              <div>
                <Tabs
                  activeTab={this.state.activeTab}
                  onChange={this.handleTabChange}
                  ripple
                  style={{ width: '100%' }}
                >
                  <Tab>Preauth</Tab>
                  <Tab>Request</Tab>
                  <Tab>Response</Tab>
                </Tabs>
                <section>
                  <div className="content">
                    {this.elements[this.state.activeTab]}
                  </div>
                </section>
              </div>
            }
          </Cell>
        </Grid>
      </CardActions>
    </Card>
  }
}

export default class HttpInteractionManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedInteractionIndex: INITIAL_INDEX,
    }

    this.handleInteractionDelete = this.handleInteractionDelete.bind(this)
  }

  selectInteraction(idx) {
    this.setState({
      selectedInteractionIndex: idx
    })
  }

  newInteraction() {
    const newLength = this.props.interactionConfig.interactions.length + 1
    const newIteraction = {
      uuid: UUID(),
      order: newLength,
      request: { uuid: UUID() },
      response: { uuid: UUID() },
      preauth: { uuid: UUID(), request: {}, response: {} }
    }
    this.props.handleHttpIntereactionAdd(newIteraction)
    this.selectInteraction(newLength - 1)
  }

  back() {
    this.setState({
      selectedInteractionIndex: INITIAL_INDEX
    })
  }

  trunc(val, limit) {
    if (limit > limit) {
      val = val.substr(0, limit) + '...'
    }
    return val
  }

  describe(interaction) {
    let message = '#' + interaction.order
    if (interaction.preauth && interaction.preauth.request && interaction.preauth.request.url) {
      message += ` | Preauth${this.trunc(interaction.preauth.request.url, 20)}`
    }
    if (interaction.request && interaction.request.url) {
      message += ` | Request: ${this.trunc(interaction.request.url, 20)}`
    }
    if (interaction.response) {
      message += ` | Check ${interaction.response.checkType == 0 ? "Body" : interaction.response.checkType == 1 ? "Status" : "Header"}`
    }
    if (message.length == ('#' + interaction.order).length) {
      message += ` - ${interaction.uuid}`
    }
    return message
  }

  handleInteractionDelete(index) {
    this.props.handleInteractionDelete(index)
    this.back()
  }

  preauthIcon(interaction) {
    let open = 'lock_open'
    let lock = 'lock'
    let error = 'error'
    if (!interaction.preauth || !interaction.preauth.request || !interaction.preauth.request.url || interaction.preauth.request.url.length == 0) {
      return open
    }
    if (!interaction.preauth.response
      || !interaction.preauth.response.responseFieldMappings || interaction.preauth.response.responseFieldMappings.length == 0
      || interaction.preauth.response.key || interaction.preauth.response.expiration) {
      return error
    }
    return lock
  }

  requestIcon(interaction) {
    let filled = 'check_circle'
    let error = 'notification_important'
    if (!interaction.request || !interaction.request.url || interaction.request.url.length == 0) {
      return error
    }
    return filled
  }

  responseIcon(interaction) {
    let filled = 'check_circle'
    let error = 'notification_important'
    if (!interaction.response
      || !interaction.response.responseFieldMappings || interaction.response.responseFieldMappings.length == 0
      || interaction.response.key || interaction.response.expiration) {
      return error
    }
    return filled
  }

  render() {
    return <Card shadow={2}>
      <CardActions>
        {this.state.selectedInteractionIndex > INITIAL_INDEX ?
          <Button
            colored
            raised
            ripple
            onClick={() => this.back()}>
            <Icon name="keyboard_arrow_left">Back</Icon> Back
          </Button> :
          <Button
            colored
            raised
            ripple
            onClick={() => this.newInteraction()}>
            <Icon name="add_box">New</Icon> New Interaction
          </Button>}
        {this.state.selectedInteractionIndex > INITIAL_INDEX
          ? <HttpInteraction
            {...this.props}
            interaction={this.props.interactionConfig.interactions[this.state.selectedInteractionIndex]}
            interactionIndex={this.state.selectedInteractionIndex}
            delete={this.handleInteractionDelete}
          />
          : <Grid>
            <Cell col={3}>
              Call Position
            </Cell>
            <Cell col={3}>
              Preauth
            </Cell>
            <Cell col={3}>
              Request
            </Cell>
            <Cell col={3}>
              Response
            </Cell>
            {this.props.interactionConfig.interactions.map((interaction, idx) => {
              return <Cell className="hoverable" col={12} key={interaction.uuid} onClick={() => this.selectInteraction(idx)}>
                <Grid>
                  <Cell col={3}>
                    {interaction.order}
                  </Cell>
                  <Cell col={3}>
                    <Icon name={this.preauthIcon(interaction)} />
                  </Cell>
                  <Cell col={3}>
                    <Icon name={this.requestIcon(interaction)} />
                  </Cell>
                  <Cell col={3}>
                    <Icon name={this.responseIcon(interaction)} />
                  </Cell>
                </Grid>
              </Cell>
            })}
          </Grid>}
      </CardActions>
    </Card>
  }
}
