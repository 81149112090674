import React from "react"
import { connect } from "react-redux"
import DatePicker from "react-datepicker"
import moment from "moment"
import Select from "react-select"
import { Grid, Cell, Card, CardActions, CardTitle, Textfield, Checkbox, CardMenu } from "react-mdl"

const INTERVAL_UNITS = [
  { label: "HOURS", value: "1", code:"hour"},
  { label: "MINUTES", value: "2", code:"minute"},
  { label: "SECONDS", value: "3", code:"second"}
]

const DAYS = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
}

class scheduler extends React.Component {
  constructor(props) {
    super(props)
    props.leadDeliverySchedule.retryPolicy = props.leadDeliverySchedule.retryPolicy || {intervalValue: "0",intervalUnit: 1, maxRetries: "0"}
    this.state = {}
  }

  //handleUnitChange(unit) {
  //  this.props.handleScheduleIntervalUnitChange(unit)
  //  this.setState(this.state)
  //}

  handleChange(callback) {
    callback()
    this.setState(this.state)
  }

  render() {

    let selectedIntervalUnit = INTERVAL_UNITS.find(e => e.value == this.props.leadDeliverySchedule.retryPolicy.intervalUnit)

    let schedule = "Invalid selection"
    switch (this.props.scheduleType.code) {
      case "recurring":
        schedule = <RecurringScheduler {...this.props} />
        break
      case "realtime":
        schedule = null
        break
      case "on_demand":
        schedule = null
        break
    }

    return (
      <div>
        <Card shadow={2} style={{ overflow: "visible" }}>
          <CardTitle> Scheduler </CardTitle>
          <CardMenu>
            
          </CardMenu>
          <CardActions border>
          <Grid>
              <Cell col={4}>
              <Select
                  placeholder={"Schedule Type"}
                  isMulti={false}
                  value={this.props.scheduleType}
                  onChange={(s)=>this.props.handleScheduleTypeChange(s.value)}
                  options={this.props.scheduleTypes}
                />
              </Cell>
              <Cell col={8}></Cell>
              <Cell col={12}>
                {schedule}
              </Cell>
              {this.props.scheduleType.code !== "on_demand" && <Cell col={12}>
                <Card shadow={2} style={{ overflow: "visible" }}>
                <CardTitle> Retry Policy </CardTitle>
                <CardMenu></CardMenu>
                <CardActions border>
                  <Grid>
                    <Cell col={4}>
                      <Textfield
                        pattern="-?[0-9]*(\.[0-9]+)?"
                        error="Input is not a number!"
                        value={this.props.leadDeliverySchedule.retryPolicy.maxRetries || "0"}
                        floatingLabel
                        label="Max. Retries"
                        style={{ width: "100px" }}
                        onChange={e =>
                          this.handleChange(() => this.props.handleScheduleMaxRetriesChange(parseInt(e.target.value) || 0))
                        }
                      />
                    </Cell>
                    <Cell col={4}>
                      <Textfield
                        pattern="-?[0-9]*(\.[0-9]+)?"
                        error="Input is not a number!"
                        value={this.props.leadDeliverySchedule.retryPolicy.intervalValue}
                        floatingLabel
                        label="Interval value"
                        style={{ width: "100px" }}
                        onChange={e =>
                          this.handleChange(() => this.props.handleScheduleIntervalValueChange(parseInt(e.target.value) || 0))
                        }
                      />
                    </Cell>
                    <Cell col={4}>
                      <Select
                        placeholder={"Interval unit"}
                        isMulti={false}
                        value={selectedIntervalUnit}
                        onChange={s => this.props.handleScheduleIntervalUnitChange(s.value)}
                        options={INTERVAL_UNITS}
                      />
                    </Cell>
                  </Grid>
                </CardActions>
              </Card>
              </Cell>}
            </Grid>
          </CardActions>
        </Card>
      </div>
    )
  }
}


class RecurringScheduler extends React.Component {
  constructor(props) {
    super(props)
    
    let currentTime = moment()
    let defaultSchedule = {
      timezone: "America/New_York",
      startDate: {
        year: currentTime.year(),
        month: currentTime.month() + 1,
        day: currentTime.date()
      },
      dayOfWeek: [1, 2, 3, 4, 5, 6, 7],
      timeOfDay: {
        hours: 8,
        minutes: 30
      },
    }

    props.leadDeliverySchedule.recurring = props.leadDeliverySchedule.recurring || defaultSchedule

    this.state = { 
      ampm: this.props.scheduleType == 'recurring' ? this.getampm(props.leadDeliverySchedule.recurring.timeOfDay.hours).value:null,
    }
  }

  normalizeDate(date) {
    if (date.year == 0 && date.month == 0 && date.day == 0) {
      return null
    }
    return moment(`${date.year}-${date.month}-${date.day}`, ["YYYY-M-D"])
  }

  normalizeHour(hour) {
    let normalizedHour = hour || 0
    if (normalizedHour > 12) {
      normalizedHour -= 12
    }
    return normalizedHour
  }

  getampm(hour) {
    const value = hour >= 12 ? "PM" : "AM"
    return { label: value, value: value }
  }

  handleDateChange(date) {
    date = moment(date)
    const startDate = {}
    startDate.year = date ? date.year() : 0
    startDate.month = date ? date.month() + 1 : 0
    startDate.day = date ? date.date() : 0
    this.props.handleScheduleStartDateChange(startDate)
    this.setState(this.state)
  }

  handleHoursChange(value) {
    const hourChange = this.state.ampm === "PM" ? 12 : 0
    this.props.handleScheduleHoursChange(parseInt(value) + hourChange)
    this.setState(this.state)
  }

  handleAmPmChange(value) {
    const hourChange = value === "PM" ? 12 : -12
    const newHours = this.props.leadDeliverySchedule.recurring.timeOfDay.hours + hourChange
    this.props.handleScheduleHoursChange(newHours)
    this.setState({
      ampm: value
    })
  }

  handleChange(callback) {
    callback()
    this.setState(this.state)
  }

  render() {
    const normalizedWeeksdays = Object.keys(DAYS).map(d => {
      const value = this.props.leadDeliverySchedule.recurring.dayOfWeek.indexOf(parseInt(d)) > -1 ? true : false
      return { Day: d, Value: value }
    })
    let effectiveDate = this.normalizeDate(this.props.leadDeliverySchedule.recurring.startDate)
    return (
      <div>
        <Grid>
          <Cell col={6}>
            <Grid>
              <Cell col={12}>
                <label className="floatLabel">Start Date</label>
                <DatePicker
                  isClearable={true}
                  dateFormat="MM/dd/yyyy"
                  selected={!effectiveDate ? null : effectiveDate.toDate()}
                  withPortal
                  onChange={date => this.handleDateChange(date)}
                />
                <div className="mdl-card__subtitle-text" style={{ fontSize: "75%" }}>
                  {this.props.leadDeliverySchedule.recurring.UUID}
                </div>
              </Cell>
              <Cell col={12}>
                <Select
                  placeholder={"Time Zone"}
                  isMulti={false}
                  value={
                    this.props.leadDeliverySchedule.recurring.timezone
                      ? {
                        label: this.props.leadDeliverySchedule.recurring.timezone,
                        value: this.props.leadDeliverySchedule.recurring.timezone
                      }
                      : null
                  }
                  onChange={s => this.handleChange(() => this.props.handleScheduleLocationChange(s.value))}
                  options={[
                    { label: "America/New_York", value: "America/New_York" },
                    { label: "UTC", value: "UTC" }
                  ]}
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell col={6} style={{ paddingLeft: "70px", paddingTop: "25px" }}>
            <Grid>
              <Cell col={6}>
                {normalizedWeeksdays.map((d, i) => {
                  return (
                    <Checkbox
                      key={d.Day}
                      ripple
                      label={DAYS[d.Day]}
                      checked={d.Value || false}
                      onChange={() =>
                        this.handleChange(() => this.props.handleScheduleDaysChange(i + 1, !d.Value))
                      }
                    />
                  )
                })}
              </Cell>
              <Cell col={6}>
                <Grid>
                  <Cell col={4}>
                    <Textfield
                      pattern="-?[0-9]*(\.[0-9]+)?"
                      error="Input is not a number!"
                      value={this.normalizeHour(this.props.leadDeliverySchedule.recurring.timeOfDay.hours)}
                      floatingLabel
                      label="Hour"
                      style={{ width: "100px" }}
                      onChange={e => this.handleHoursChange(e.target.value)}
                    />
                  </Cell>
                  <Cell col={4}>
                    <Textfield
                      pattern="-?[0-9]*(\.[0-9]+)?"
                      error="Input is not a number!"
                      value={this.props.leadDeliverySchedule.recurring.timeOfDay.minutes || "0"}
                      floatingLabel
                      label="Minute"
                      style={{ width: "100px" }}
                      onChange={e =>
                        this.handleChange(() => this.props.handleScheduleMinutesChange(e.target.value))
                      }
                    />
                  </Cell>
                  <Cell col={4}>
                    <Select
                      placeholder={"AM/PM"}
                      isMulti={false}
                      value={this.getampm(this.props.leadDeliverySchedule.recurring.timeOfDay.hours)}
                      onChange={s => this.handleAmPmChange(s.value)}
                      options={[
                        { label: "AM", value: "AM" },
                        { label: "PM", value: "PM" }
                      ]}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </div>
    )
  }
}



const Scheduler = connect(null, null)(scheduler)

export default Scheduler
