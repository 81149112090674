import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Textfield, Switch, Button, Icon, Grid, Cell } from 'react-mdl'
import { normalizeRevenueShare } from '../../utils'

export default class RevShareItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.makeShare(props)
  }

  makeShare(props) {
    let current = props.share
    let epoch = current ? current.effectiveDate : moment().startOf('day').unix().toString()
    let epochNum = parseInt(epoch)
    let effectiveDate = moment().startOf('day')
    if (current && current.effectiveDate && !isNaN(epochNum) && epochNum != 0) {
      effectiveDate = moment.unix(epochNum)
    }

    return {
      revShare: (!current || isNaN(current.isAbsolute)) ? '' : normalizeRevenueShare(current.isAbsolute, current.tenthOfCent),
      effectiveDate: effectiveDate,
      isAbsolute: this.props.isBrokered ? true : current.isAbsolute,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.share !== this.props.share) {
      this.setState({
        ...this.makeShare(this.props),
      })
    }
  }

  handleChange(callback) {
    callback()
  }

  handleSaveButton() {
    const normalizedValue = parseInt((this.state.revShare * (this.state.isAbsolute ? 1000 : 10)))
    this.props.onSave(
      normalizedValue,
      this.state.effectiveDate.startOf('day').unix().toString(),
      this.state.isAbsolute
    )
  }

  handleIsAbsoluteChange() {
    const isAbsolute = !this.state.isAbsolute
    const revShare = (isAbsolute ? this.state.revShare / 100 : this.state.revShare * 100).toFixed(isAbsolute ? 3 : 1)
    this.setState({
      revShare: revShare,
      isAbsolute: isAbsolute,
    })
  }

  render() {
    return (
      <div>
        <Grid style={{ width: "100%" }}>
          <Cell col={6} id="revenue-unit-switch-cell">
            <Switch
              disabled={this.props.isBrokered}
              onChange={() => this.handleIsAbsoluteChange()}
              checked={this.state.isAbsolute}>
              Is Absolute
            </Switch>
          </Cell>
          <Cell col={6}>
            <Button
              id="save-revenue-button"
              disabled={this.state.effectiveDate < moment().startOf('day')}
              onClick={() => this.handleSaveButton()}>
              <Icon name="save" />
            </Button>

            {!this.props.isNew && <Button
              id="delete-revenue-item-button"
              disabled={this.state.effectiveDate < moment().startOf('day')}
              onClick={() => {
                this.props.onDelete(this.props.selectedIndex)
              }}>
              <Icon name="delete" />
            </Button>}

            <Button onClick={this.props.onCancel}>
              <Icon name="close">Close</Icon>
            </Button>

          </Cell>
          <Cell col={6}>
            <span style={{ display: 'inline-block', width: '40px' }}>{this.state.isAbsolute ? '$' : ' '}</span>
            <Textfield
              value={this.state.revShare}
              pattern={this.state.isAbsolute ? "^([0-9]*[1-9][0-9]*(\.[0-9]+)?|0|[0]*\.[0-9]*[1-9][0-9]*)$" : "(^[0-9]+(\.[0-9]{1})?)"} // eslint-disable-line
              error={`Must be a number with ${this.state.isAbsolute ? "three" : "one"} digit after decimal point!`}
              onChange={(e) => {
                this.setState({ revShare: e.target.value })
              }}
              label="Rate"
              floatingLabel
              style={{ width: '100px' }}
            />
            <span>{this.state.isAbsolute ? ' ' : '%'}</span>
          </Cell>
          <Cell col={6} id="revenue-effective-date-cell">
            <span className="floatLabel">Effective Date</span>
            <DatePicker
              className="thin-date-picker"
              isClearable={false}
              dateFormat="MM/dd/yyyy"
              selected={!this.state.effectiveDate ? null : this.state.effectiveDate.toDate()}
              start_date={!this.state.effectiveDate ? null : this.state.effectiveDate.toDate()}
              onChange={(d) => {
                this.setState({ effectiveDate: !d ? null : moment(d) })
              }}
              withPortal
            />
          </Cell>
        </Grid>
      </div>
    )
  }
}
