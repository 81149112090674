import React from 'react'
import FilterList from '../filterList'
import CustomerForm from './customerForm'
import { deepClone } from '../../utils'
import { Grid, Cell, Card, CardActions, CardTitle, Spinner, Icon, Button } from 'react-mdl'
import { Prompt } from 'react-router'

export default class Customers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCustomer: null,
    }
    this.props.isNotDirty()
  }

  componentDidMount() {
    let crudOptions = {
      onSave: () => this.saveCustomer(),
      onCancel: () => {
        this.handleOnCancel()
      },
      onNew: () => {
        this.loadNewCustomer()
      },
      visible: true,
    }
    this.props.setCrud(crudOptions)
  }

  saveCustomer() {
    this.props.isNotDirty()
    this.props.saveCustomer(this.state.selectedCustomer)
  }

  createKeyCloakAccount(placementUUID, customerUUID, contact) {
    this.props.createKeyCloakAccount(placementUUID, customerUUID, contact)
  }

  handleOnCancel() {
    this.props.isNotDirty()
    this.setState({
      selectedCustomer: null
    })
  }

  loadNewCustomer() {
    this.props.isNotDirty()
    const newCustomer = { name: "", contacts: [] }
    this.setState({
      selectedCustomer: newCustomer,
    })
  }

  handleCustomerTypeChange(type) {
    this.props.isDirty()
    const customer = this.state.selectedCustomer
    customer.type = type
    this.setState({
      selectedCustomer: customer
    })
  }
  handleCustomerNameChange(name) {
    this.props.isDirty()
    const customer = this.state.selectedCustomer
    customer.name = name
    if (customer.name.length == 0) {
      this.props.isNotDirty()
    }
    this.setState({
      selectedCustomer: customer
    })
  }

  handleCustomerDescriptionChange(description) {
    this.checkIsDirty()
    const customer = this.state.selectedCustomer
    customer.description = description
    this.setState({
      selectedCustomer: customer
    })
  }

  handleCustomerAddContact(contact) {
    this.checkIsDirty()
    const customer = this.state.selectedCustomer
    customer.contacts.push(contact)
    this.setState({
      selectedCustomer: customer
    })
  }

  handleCustomerUpdateContact(contact, index) {
    this.checkIsDirty()
    const customer = this.state.selectedCustomer
    customer.contacts[index] = contact
    this.setState({
      selectedCustomer: customer
    })
  }

  handleRemoveContact(index) {
    this.checkIsDirty()
    const customer = this.state.selectedCustomer
    customer.contacts.splice(index, 1)
    this.setState({
      selectedCustomer: customer
    })
  }

  checkIsDirty() {
    if (this.state.selectedCustomer.name.length == 0) {
      this.props.isNotDirty()
    } else {
      this.props.isDirty()
    }
  }

  customerSelected(customer) {
    this.props.isNotDirty()
    this.setState({
      selectedCustomer: deepClone(customer)
    })
  }

  renderFilterList() {
    if(this.props.customers.isLoading){
      return <div><Spinner singleColor /></div>
    }

    if(this.props.customers.errorText != ""){
      return <div>Error:{this.props.customers.errorText}</div>
    }

    if(!this.props.customers.data){
      return <div></div>
    }

    return <FilterList
      label="Customers list..."
      selected={this.state.selectedCustomer ? this.state.selectedCustomer.UUID : null}
      uuidLabel={'UUID'}
      additionalFieldList={['description']}
      title="Customers"
      selectItem={c => this.customerSelected(c)}
      items={this.props.customers.data.filter(e => e.type == this.props.customerType)}
      getIcon={c => c.type === 3 ? { label: "Sales Person", icon: "headset_mic" } : c.type === 2 ? { msg: "Distribution Partner", icon: "groups" } : c.type === 1 ? { msg: "Earnpath Partner", icon: "account_circle" } : { msg: "Customer", icon: "paid" }}
    />
  }

  render() {
    let header = this.state.selectedCustomer ? this.state.selectedCustomer.UUID ? '' : 'New customer' : 'Please select a contact'
    return (
      <React.Fragment>
        <Prompt
          when={this.props.isDirtyValue}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <section ref={t => this.contentSection = t}>
          <div className="content">
            <Grid style={{ width: "100%" }}>
              <Cell col={4}>
                <Card shadow={2} className="mdl-cell mdl-cell--12-col" >
                  <CardTitle>
                    <h2 className="mdl-card__title-text">Contacts List</h2>
                    <Button onClick={() => this.loadNewCustomer()}><Icon name="add_box" />New</Button>
                  </CardTitle>
                  <CardActions border>
                    {this.renderFilterList()}
                  </CardActions>
                </Card>
              </Cell>
              <Cell col={8}>
                <Grid>
                  <Cell col={6}>
                    <h3>{header}</h3>
                  </Cell>
                </Grid>
                {this.state.selectedCustomer ?
                  <section>
                    <CustomerForm
                      keycloakResults={this.state.keycloakResults}
                      customer={this.state.selectedCustomer}
                      createKeyCloakAccount={(placementUUID, customerUUID, contact) => this.createKeyCloakAccount(placementUUID, customerUUID, contact)}
                      handleCustomerNameChange={(name) => this.handleCustomerNameChange(name)}
                      handleCustomerTypeChange={(t) => this.handleCustomerTypeChange(t)}
                      placements={this.props.placements}
                      handleCustomerDescriptionChange={(d) => this.handleCustomerDescriptionChange(d)}
                      addNewContact={(contact) => this.handleCustomerAddContact(contact)}
                      updateContact={(contact, index) => this.handleCustomerUpdateContact(contact, index)}
                      handleRemoveContact={(index) => this.handleRemoveContact(index)}
                      isDirty={this.props.isDirty}
                    />
                  </section>
                  : null
                }
              </Cell>
            </Grid>
          </div>
        </section>
      </React.Fragment>
    )
  }
}