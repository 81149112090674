import React from 'react'
import { ListItem, Card, CardActions, CardMenu, Grid, Cell, Textfield, Button, Icon } from "react-mdl"
import Select from 'react-select'
import { deepClone } from '../../utils'
import Modal from "react-modal"
import FilterList from "../filterList"

export default class CustomerForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      placementPicker: false,
      keycloakResultPopup: false,
      confirmCreate: false,
      contact: null,
      index: -1,
      customerTypes: [{ label: "Customer", value: 0 }, { label: "Distribution Partner", value: 2 }, { label: "Earnpath Partner", value: 1 }, { label: "Sales Person", value: 3 }]
    }
  }

  createExternalAccount(placementUUID, customerUUID, contact) {
    this.props.createKeyCloakAccount(placementUUID, customerUUID, contact)
  }

  newContact() {
    this.props.isDirty()
    const newContact = { name: '', phone: '', email: '' }
    this.setState({
      contact: newContact,
    })
  }

  handleContactSelected(contact, index) {
    this.setState({
      contact: deepClone(contact),
      index: index,
    })
  }

  handleContactPropertyChange(propertyName, value) {
    const contact = this.state.contact
    contact[propertyName] = value
    this.setState({
      contact: contact,
    })
  }

  closeContactForm() {
    this.setState({
      contact: null,
      index: -1,
    })
  }

  saveContact() {
    if (this.state.index == -1) {
      this.props.addNewContact(this.state.contact)
    } else {
      this.props.updateContact(this.state.contact, this.state.index)
    }
    this.closeContactForm()
  }

  deleteContact() {
    this.props.handleRemoveContact(this.state.index)
    this.closeContactForm()
  }

  isCorrectEmail(email) {
    if (email.length == 0) {
      return true
    }
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  isSaveButtonEnabled() {
    return this.state.contact && this.state.contact.name.length > 0 && this.isCorrectEmail(this.state.contact.email) ? false : true
  }

  dialogCancel() {
    this.setState({
      placementPicker: false
    })
  }

  render() {
    return (
      <Card shadow={2} style={{ minWidth: "300px", width: "98%", height: "100%" }}>
        <Modal
          appElement={this.props.parent}
          isOpen={this.state.placementPicker || this.state.confirmCreate || this.state.keycloakResultPopup}
          onRequestClose={() => this.dialogCancel()}
          contentLabel="Placement Access"
        >
          <Card shadow={2}>
              <CardMenu>
                <Button
                  style={{ position: "fixed", backgroundColor: "white", right: "5%",  zIndex: "2" }}
                  onClick={() => this.dialogCancel()}
                  >
                  <Icon name="close" />
                </Button>
              </CardMenu>
              <CardActions style={{ textAlign: "right" }}>
                <div style={{ textAlign: "center"}}>
                {this.state.placementPicker && <React.Fragment>
                  <FilterList
                    label="Placements"
                    additionalFieldList={['name', 'UUID']}
                    primaryField={'name'}
                    title="Placements"
                    selectItem={p => {
                      this.setState({ placementPicker: false, selectedPlacement: p, confirmCreate: true })
                    }}
                    items={this.props.placements.filter(p => {
                      return p.customerUUID == this.props.customer.UUID
                    })}
                  />
                </React.Fragment>}
                {this.state.confirmCreate && <React.Fragment>
                  <h4>Allow {this.state.contact.email} access to external reporting for {this.state.selectedPlacement.name}?</h4>
                  <Button raised ripple colored onClick={() => {
                    this.createExternalAccount(this.state.selectedPlacement.UUID, this.props.customer.UUID, this.state.contact)
                    this.setState({ keycloakResultPopup: true, confirmCreate: false })
                  }}>Ok</Button>
                  <Button onClick={() => this.setState({ selectedPlacement: {}, confirmCreate: false })}>Cancel</Button>
                </React.Fragment>}
                {this.state.keycloakResultPopup && this.props.keycloakResults && <React.Fragment>
                  <span>{this.props.keycloakResults.message}</span>
                </React.Fragment>}
              </div>
            </CardActions>
          </Card>
        </Modal>
        <CardActions border>
          <Grid>
            <Cell col={6}>
              <Textfield
                required
                label="Contact name"
                floatingLabel
                style={{ width: "100%" }}
                value={this.props.customer.name}
                onChange={evt => this.props.handleCustomerNameChange(evt.target.value)}
              />
            </Cell>
            <Cell col={6}>
              <Select
                id="customer-type-select"
                placeholder={"Contact Type"}
                isMulti={false}
                value={this.state.customerTypes.find(d => d.value == this.props.customer.type)}
                onChange={val => this.props.handleCustomerTypeChange(val.value)}
                options={this.state.customerTypes}
              />
            </Cell>
            <Cell col={12}>
              <Textfield
                label="Description"
                floatingLabel
                style={{ width: "100%" }}
                value={this.props.customer.description}
                onChange={evt => this.props.handleCustomerDescriptionChange(evt.target.value)}
              />
            </Cell>
            <Cell col={12}><strong>Contacts</strong>
              <Button
                id="add-contact-button"
                onClick={() => this.newContact()}>
                <Icon name="add_circle" />
              </Button>
            </Cell>
            <Cell col={12}>
              {this.state.contact ?
                <Grid style={{ width: "100%" }}>
                   <Cell col={2}><Button raised ripple colored id="add-external-account-button" onClick={() => this.setState({ placementPicker: true })}>Create External Reporting Login</Button></Cell>
                  <Cell col={3}>
                    <Textfield
                      required
                      label="Contact name"
                      floatingLabel
                      style={{ width: "100%" }}
                      value={this.state.contact.name}
                      onChange={evt => this.handleContactPropertyChange('name', evt.target.value)}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      label="Phone"
                      floatingLabel
                      style={{ width: "100%" }}
                      value={this.state.contact.phone}
                      onChange={evt => this.handleContactPropertyChange('phone', evt.target.value)}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      pattern="(.+)@(.+){2,}\.(.+){2,}"
                      error="Wrong email format"
                      label="email"
                      floatingLabel
                      style={{ width: "100%" }}
                      value={this.state.contact.email}
                      onChange={evt => this.handleContactPropertyChange('email', evt.target.value)}
                    />
                  </Cell>
                  <Cell col={3} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Button
                      id="save-contact-button"
                      disabled={this.isSaveButtonEnabled()}
                      onClick={() => this.saveContact()}>
                      <Icon name="save" />
                    </Button>
                    {this.state.index != -1 ?
                      <Button
                        id="delete-contact-button"
                        onClick={() => this.deleteContact(this.state.index)}>
                        <Icon name="delete" />
                      </Button>
                      : null
                    }
                    <Button
                      onClick={() => this.closeContactForm()}>
                      <Icon name="close" />
                    </Button>
                  </Cell>
                </Grid>
                : null
              }
            </Cell>
            <Cell col={12}>
              <Grid id="contact-list">
                <Cell col={12}>
                  <Grid>
                    <Cell col={4}>{"Name"}</Cell>
                    <Cell col={4}>{"Phone"}</Cell>
                    <Cell col={4}>{"Email"}</Cell>
                  </Grid>
                </Cell>
                {this.props.customer.contacts.map((contact, index) => {
                  return (
                    <Cell key={index} col={12}>
                      <ListItem
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleContactSelected(contact, index)}
                      >
                        <Grid style={{ width: '100%', padding: "0" }}>
                          <Cell col={4}>{contact.name}</Cell>
                          <Cell col={4}>{contact.phone}</Cell>
                          <Cell col={4}>{contact.email}</Cell>
                        </Grid>
                      </ListItem>
                    </Cell>
                  )
                })}
              </Grid>
            </Cell>
          </Grid>
        </CardActions>
      </Card>
    )
  }
}