
import moment from 'moment'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { Card, CardTitle, CardActions, Textfield, Switch } from 'react-mdl'

export default class DummyLead extends React.Component {
  constructor(props) {
    super(props)
  }
  normalizeDate(date) {
    let resultDate = parseInt(date)
    if (!resultDate || isNaN(resultDate)) {
      return null
    }
    return moment.unix(resultDate).toDate()
  }
  render() {
    return <Card style={{ maxWidth: "60vw", minWidth: "20vw" }}>
      <CardTitle>Lead Data</CardTitle>
      <CardActions border>
        <Textfield
          floatingLabel
          label="First Name"
          onChange={e => {
            this.props.lead.user.firstName = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.firstName || ''}
        />
        <Textfield
          floatingLabel
          label="Last Name"
          onChange={e => {
            this.props.lead.user.lastName = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.lastName || ''}
        />
        <Textfield
          floatingLabel
          label="Title"
          onChange={e => {
            this.props.lead.user.title = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.title || ''}
        />
        <Textfield
          floatingLabel
          label="Email"
          onChange={e => {
            this.props.lead.user.email = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.email || ''}
        />
        <Textfield
          floatingLabel
          label="Phone"
          onChange={e => {
            this.props.lead.user.phone = parseInt(e.target.value)
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.phone || ''}
        />
        <Textfield
          floatingLabel
          label="IP"
          onChange={e => {
            this.props.lead.user.ip = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.ip || ''}
        />
        <Textfield
          floatingLabel
          label="Address 1"
          onChange={e => {
            this.props.lead.user.address1 = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.address1 || ''}
        />
        <Textfield
          floatingLabel
          label="Address 2"
          onChange={e => {
            this.props.lead.user.address2 = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.address2 || ''}
        />
        <Textfield
          floatingLabel
          label="City"
          onChange={e => {
            this.props.lead.user.city = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.city || ''}
        />
        <Textfield
          floatingLabel
          label="State"
          onChange={e => {
            this.props.lead.user.state = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.state || ''}
        />
        <Textfield
          floatingLabel
          label="Country"
          onChange={e => {
            this.props.lead.user.country = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.country || ''}
        />
        <Textfield
          floatingLabel
          label="Zip"
          onChange={e => {
            this.props.lead.user.zipcode = e.target.value
            this.props.update(this.props.lead)
          }}
          value={this.props.lead.user.zipcode || ''}
        />
        <ReactDatePicker
          isClearable={true}
          dateFormat="MM/dd/yyyy"
          selected={this.normalizeDate(this.props.lead.user.dob)}
          withPortal
          onChange={date => {
            this.props.lead.user.dob = date.getTime() / 1000
            this.props.update(this.props.lead)
          }}
        />

        <label className="floatLabel">DOB</label>
        <Switch
          onChange={() => {
            if (this.props.lead.user.gender === 'f') {
              this.props.lead.user.gender = 'm'
            } else {
              this.props.lead.user.gender = 'f'
            }
            this.props.update(this.props.lead)
          }}
          checked={this.props.lead.user.gender === 'f'}
        >{this.props.lead.user.gender === 'f' ? 'Female' : 'Male'}</Switch>
      </CardActions>
    </Card>
  }
}