import React from "react"
import FilterList from "../filterList"
import { deepClone } from "../../utils"
import CriteriaFilter from "../criteria/criteriaFilter"
import { ListItemContent, List, Card, CardMenu, Button, Icon, ListItem, CardTitle, Grid, Tooltip, IconButton, CardActions, Cell } from "react-mdl"
import { USStates } from "../../utils/states"

const USstatesCodes = new USStates().States.map(c => c.code)

const associatedListItemStyle = {
  width: "75px",
  textAlign: "center",
}

export default class CriteriaFilterOverride extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newItem: false,
      isListRolledUp: true,
      selectedItem: null
    }
    this.maxListItemsToDisplay = 3
  }

  convertCriteriaListFromStateToProps(associatedCriteria) {
    delete associatedCriteria["id"]
    delete associatedCriteria["campaignName"]
    delete associatedCriteria["campaignShortcode"]
    return associatedCriteria
  }

  componentDidUpdate(prevProps) { 
    if (this.props.campaigns.length > 0 && this.props.campaigns.length !== prevProps.campaigns.length) {
      this.setState(
        {
          list: this.convertCriteriaListFromPropsToState(deepClone(this.props.associatedCriteria.records))
        }
      )
    }
    if(this.props.associatedCriteria != prevProps.associatedCriteria){
      this.setState(
        {
          newItem: false,
          isListRolledUp: true,
          selectedItem: null
        }
      )
    }
  }

  convertCriteriaListFromPropsToState(propList) {
    if (!propList) {
      return []
    }
    let list = propList.map((rs, x) => {
      let camp = this.props.campaigns.find(c => c.UUID == rs.campaignUuid)
      rs.id = "criteria-override:" + x
      if (camp) {
        rs.campaignName = camp.name
        rs.campaignShortcode = camp.shortCode
      } else {
        rs.campaignName = "NA"
        rs.campaignShortcode = "NA"
      }
      return rs
    })
    return list
  }

  getCampaignsNotAlreadySelected(campaigns, scheduleList) {
    let selectedCampaignUuids = scheduleList.map(a => a.campaignUuid)
    return campaigns.filter(c => !selectedCampaignUuids.includes(c.UUID))
  }

  handleSelectCampaign(campaign){
    let selectedItem = this.state.selectedItem
    selectedItem.campaignUuid = campaign.UUID
    selectedItem.campaignName = campaign.name
    this.props.retrieveCampaignCriteria(campaign.UUID)
    this.setState({selectedItem: selectedItem})
  }

  renderCampaignSelector(associatedCriterias) {
    let {campaigns} = this.props
    if(this.props.filter)campaigns = campaigns.filter(e=>e.UUID === this.props.filter)
    return (
      <Grid>
        <Cell col={12}>
          <Button
            onClick={() => {
              this.setState({selectedItem: null})
            }}>
            <Icon name="close">Close</Icon>
          </Button>
        </Cell>
        <Cell col={12}>
          <FilterList
            label="Campaign list"
            selected={this.state.selectedItem.campaignUuid}
            additionalFieldList={["UUID", "shortCode"]}
            title="Campaigns"
            selectItem={c => this.handleSelectCampaign(c)}
            items={this.getCampaignsNotAlreadySelected(campaigns, associatedCriterias)}
          />
        </Cell>
      </Grid>
    )
  }

  handleCriteriaFilterChange(criteriaName, isInclude, value) {
    const criteriaType = isInclude ? "include" : "exclude"
    let selectedItem = this.state.selectedItem
    let currentCriteriaFilter = selectedItem.criteriaFilter[criteriaName] || { exclude: [], include: [] }
    let criterias = currentCriteriaFilter[criteriaType] || []
    criterias.push(value)
    currentCriteriaFilter[criteriaType] = criterias
    selectedItem.criteriaFilter[criteriaName] = currentCriteriaFilter
    this.setState({selectedItem: selectedItem})
  }

  handleCriteriaFilterStateChange(criteriaType, index){
    let selectedItem = this.state.selectedItem
    const removedItem = selectedItem.criteriaFilter["state"][criteriaType].splice(index, 1)[0]
    selectedItem.criteriaFilter["state"][criteriaType].sort()
    const otherCriteriaType = criteriaType === "include" ? "exclude" : "include"
    selectedItem.criteriaFilter["state"][otherCriteriaType].push(removedItem)
    selectedItem.criteriaFilter["state"][otherCriteriaType].sort()
    this.setState({
      selectedItem: selectedItem,
    })
  }

  handleCriteriaFilterRemove(criteriaName, criteriaType, index) { 
    let selectedItem = this.state.selectedItem  
    selectedItem.criteriaFilter[criteriaName][criteriaType].splice(index, 1)
    this.setState({
      selectedItem: selectedItem,
    })
  }

  handleZipCriteriaFilterChange(zipCodes, isInclude) {
    const criteriaType = isInclude ? "include" : "exclude"
    let selectedItem = this.state.selectedItem
    let zipCriteriaFilter = selectedItem.criteriaFilter.zip || { exclude: [], include: [] }
    zipCriteriaFilter[criteriaType] = zipCodes
    selectedItem.criteriaFilter.zip = zipCriteriaFilter
    this.setState({selectedItem: selectedItem})
  }

  renderDetails(associatedCriteriaSaveMessage) {
    return (
      <Grid>
        <Cell col={12}>{this.state.selectedItem.campaignName}</Cell>
        <Cell col={12}>
          <Grid>
            <Cell col={3}>
              <Button
                id="criteria-save-button"
                onClick={() => {
                  let criteriaClone = deepClone(this.state.selectedItem)
                  this.props.onSave(this.convertCriteriaListFromStateToProps(criteriaClone))
                }}>
                <Icon name="save">Save</Icon>
              </Button>

                <Button
                  id="criteria-close-form-button"
                  onClick={() => {
                    this.setState({newItem:false, selectedItem: null })
                  }}>
                  <Icon name="close">Close</Icon>
                </Button> 
            </Cell>
            <Cell col={12}>
              <CriteriaFilter
                associatedCriteriaSaveMessage={associatedCriteriaSaveMessage}
                criteriaFilter={this.state.selectedItem.criteriaFilter}
                originalCriteria={this.props.campaignCriteria}
                onChange={(criteriaName, isInclude, value) =>
                  this.handleCriteriaFilterChange(criteriaName, isInclude, value)
                }
                onRemove={(criteriaName, criteriaType, index) =>
                  this.handleCriteriaFilterRemove(criteriaName, criteriaType, index)
                }
                onStateChange={(criteriaType, index) =>
                  this.handleCriteriaFilterStateChange(criteriaType, index)
                }
                resetAssociatedCriteriaSaveMsg={this.props.resetAssociatedCriteriaSaveMsg}
                onZipChange={(zipCodes, isInclude) => this.handleZipCriteriaFilterChange(zipCodes, isInclude)}
                zips={this.props.zips}
              />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    )
  }

  summarizeCriteria(criteria, rs) {
    const age = "age"
    const gender = "gender"
    const emailDomain = "email_domain"
    const phone = "phone"
    const state = "state"
    const zip = "zip"
    let excludeExists = critType => criteria[critType] && criteria[critType].exclude.length > 0
    let includeExists = critType => criteria[critType] && criteria[critType].include.length > 0
    let createLock = rules => {
      return (
        <Tooltip
          label={
            <Grid>
              {rules.length > 5 ? rules.slice(0,5).map(r => (
                <Cell col={12} key={r}>{r}</Cell>
              )).concat([<Cell col={12} key={"tbc"}>...</Cell>]) : rules.map(r => (
                <Cell col={12} key={r}>{r}</Cell>
              ))}
            </Grid>
          }>
          <IconButton name="lock" />
        </Tooltip>
      )
    }
    let createElement = name => {
      let rules = []
      if (excludeExists(name)) {
        rules = rules.concat(
          criteria[name].exclude.map(r => {
            return `Exclude: ${r}`
          })
        )
      }
      if (includeExists(name)) {
        rules = rules.concat(
          criteria[name].include.map(r => {
            return `Include: ${r}`
          })
        )
      }
      if (rules.length == 0) {
        return <IconButton name="lock_open" />
      }
      return createLock(rules)
    }
    let criteriaElements = []
    let ageElement = createElement(age)
    if (ageElement) {
      criteriaElements.push(ageElement)
    }
    let genderElement = createElement(gender)
    if (genderElement) {
      criteriaElements.push(genderElement)
    }
    let emailDomainElement = createElement(emailDomain)
    if (emailDomainElement) {
      criteriaElements.push(emailDomainElement)
    }
    let phoneElement = createElement(phone)
    if (phoneElement) {
      criteriaElements.push(phoneElement)
    }
    let stateElement = createElement(state)
    if (stateElement) {
      criteriaElements.push(stateElement)
    }
    let zipElement = createElement(zip)
    if (zipElement) {
      criteriaElements.push(zipElement)
    }

    let row = rs
    return criteriaElements.map((c, i) => {
      return (
        <div key={`associated-crit${i}`} style={associatedListItemStyle}>
          {c}
        </div>
      )
    }).concat([<div key={"remove"+row.placementUuid+row.campaignUuid}>
      <IconButton
        name="delete"
        onClick={(e) => {
          e.stopPropagation()
          this.props.onDelete(this.convertCriteriaListFromStateToProps(rs))
        }}>
      </IconButton>
      </div>])
  }

  applyFilter(list){
    return this.props.filter ? list.filter((c) => c.campaignUuid == this.props.filter) : list
  }

  renderCriteriaList(associatedCriterias) {
    const filteredList = this.applyFilter(associatedCriterias)
    let list = filteredList.slice(0, this.state.isListRolledUp ? this.maxListItemsToDisplay : associatedCriterias.length)
    let head = (
      <ListItem
        twoLine
        style={{paddingLeft: "16px"}}
        key={"head"} >
        <ListItemContent>
          <div style={{display:"flex", justifyContent: "space-between"}}>
            <div style={{width: "33%"}}>Campaign</div>
            <div style={associatedListItemStyle}>Age</div>
            <div style={associatedListItemStyle}>Gender</div>
            <div style={associatedListItemStyle}>Domain</div>
            <div style={associatedListItemStyle}>Phone</div>
            <div style={associatedListItemStyle}>State</div>
            <div style={associatedListItemStyle}>Zip</div>
            <div style={{minWidth: "32px", width: "32px"}}>&nbsp;</div>
          </div>
        </ListItemContent>
      </ListItem>
    )
    return (
      <List id="criteria-by-campaign-list">
        {list.length > 0 ? [head].concat(
          list.map((rs) => {
            if(rs.campaignShortcode == "NA"){
              return false
            }
            let content = this.summarizeCriteria(rs.criteriaFilter, rs)
            return (
              <ListItem
                style={{paddingLeft: "16px"}}
                twoLine
                key={rs.id}
                onClick={() => {
                  this.props.retrieveCampaignCriteria(rs.campaignUuid)
                  this.setState({newItem: false, selectedItem: deepClone(rs)})
                }}>
                <ListItemContent subtitle={rs.campaignShortcode}>
                  <div style={{display:"flex", justifyContent: "space-between"}}>
                    <div style={{width: "33%", marginTop: "8px"}}>{rs.campaignName}</div>
                    {content}
                  </div>
                </ListItemContent>
              </ListItem>
            )
          })
        ) : null}
      </List>
    )
  }

  renderRollUpAndDownButton() {
    return (
      <Button
        onClick={e => {
          this.setState(prevState => ({
            ...prevState,
            isListRolledUp: !prevState.isListRolledUp
          }))
          e.preventDefault()
        }}>
        {" "}
        <Icon name={this.state.isListRolledUp ? "keyboard_arrow_down" : "keyboard_arrow_up"} />
      </Button>
    )
  }

  render() {
    let showDetails = this.state.selectedItem && this.state.selectedItem.campaignUuid
    let showCampaignSelector = this.state.selectedItem && !this.state.selectedItem.campaignUuid
    const associatedCriterias = this.props.associatedCriteria.records
      ? this.convertCriteriaListFromPropsToState(deepClone(this.props.associatedCriteria.records))
      : []
    return (
      <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
        <CardTitle expand>
          <div style={{fontSize: "22px", fontWeight: "300"}}>Criteria by Campaign</div>
          {this.props.filter ?
            <div style={{display:"flex", justifyContent: "left", alignItems: "center", marginLeft: "15px", padding: "5px", color: "#009abf"}}>
              <div>Filtered</div>
              <div><Icon name="priority_hight" /></div>
            </div> : null}
        </CardTitle>
        <CardMenu>
          <Button
            id="new-criteria-button"
            onClick={() => {
              this.setState({
                newItem: true, 
                selectedItem: { 
                  placementUuid: this.props.placement.UUID, 
                  criteriaFilter: {
                    state: {include: deepClone(USstatesCodes), exclude: []},
                    age:{include:[], exclude:[]},
                    email_domain:{include:[], exclude:[]},
                    phone:{include:[], exclude:[]},
                    gender:{include:[], exclude:[]},
                    zip:{include:[], exclude:[]},
                  },
                }})
            }}>
            <Icon name="add_box">New</Icon>
          </Button>
        </CardMenu>
        <CardActions border>
          {showCampaignSelector ? this.renderCampaignSelector(associatedCriterias) : null}
          {showDetails ? this.renderDetails(this.props.associatedCriteriaSaveMsg) : null}
          {!showCampaignSelector ? this.renderCriteriaList(associatedCriterias) : null}
          {associatedCriterias.length > this.maxListItemsToDisplay ? this.renderRollUpAndDownButton() : <div></div>}
        </CardActions>
      </Card>
    )
  }
}
