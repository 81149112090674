import moment from "moment"
import 'moment-timezone'
import AWS from "aws-sdk"
import OIDC from "../thirdparty/openidconnect.js"
import { v4 } from "uuid"
import history from "../history"
import {
  loadedDeliverableViewer,
  openSaveDialogDeliverableViewer,
  closeSaveDialogDeliverableViewer,
  setBulkStatusUpdateResultsDeliverableViewer,
} from '../reducers/deliverableViewerReducer'
import { findAssociationDailyCap, findAssociationCurrentCap } from '../utils/entityLedgers'

const apiversion = "apiv1"
const baseURL = process.env.NODE_ENV == "production" ? "https://manager-edge.adquire.com" : "https://manager-edge-d.adquire.com"

export function getUrlFor(serviceName, resource) {
  return `${baseURL}/${apiversion}/${serviceName}/${resource}/`
}

const agentTemplateURL = `${baseURL}/agent/index.html`
export const DISABLE_SNACK = "-1"
export const AVOID_DISABLE_IS_DIRTY = true
const NULLUUID = "00000000-0000-0000-0000-000000000000"
const fileSuccess = "File Uploaded successfully"
const fileError = "Error Uploading File"
const tagListURL = getUrlFor("tagging", "tags")
const tagAssociationURL = getUrlFor("tagging", "association")
const tagChildrenListURL = getUrlFor("tagging", "children")
const dataURL = getUrlFor("priority-campaign-rank", "data")
const bucketURL = getUrlFor("campaign-ranking-by-demographic", "buckets")
const questionURL = getUrlFor("questions", "questions")
const reorderQuestionsURL = getUrlFor("questions", "reorder-questions")
const surveyURL = getUrlFor("questions", "survey")
//const questionURL = "http://localhost:9001/questions/"
const campaignURL = getUrlFor("campaigns", "campaigns")
const campaignGroupURL = getUrlFor("campaigns", "campaign-groups")
const leadAcceptanceAssociationURL = getUrlFor("campaign-sorting", "associated-lead-acceptance")
const placementLeadAcceptanceAssociationURL = getUrlFor("campaign-sorting", "batch-placement-associated-configurations")
const criteriaAssociationURL = getUrlFor("criteria-filtering", "associated-criteria")
const criteriaURL = getUrlFor("criteria-filtering", "criteria")

const optinCountsURL = getUrlFor("optin-count", "counts")
const leadCountsURL = getUrlFor("lead-count", "counts")

const defaultValidationURL = getUrlFor("campaigns", "default-validators")
const validationDataURL = getUrlFor("campaigns", "validation-data")
const disableValidationURL = getUrlFor("campaigns", "disable-validator")
const valcorURL = getUrlFor("validation-coordinator", "validators")
const placementsURL = getUrlFor("placements", "placements")
const placementsGroupURL = getUrlFor("placements", "placement-groups")
const placementsAssociations = getUrlFor("placements", "campaign-associations")
const hashLeadURL = getUrlFor("lead-deduplication", "bulk-hash-leads")
const campaignRequestURL = getUrlFor("campaign-request", "campaigns")
const creativeURL = getUrlFor("creatives", "creatives")
const testCreativeURL = getUrlFor("creatives", "testcreative")
const prepingURL = getUrlFor("preping", "pre-ping")
const prepingPreviewURL = getUrlFor("preping", "preview")
const winbackURL = getUrlFor("winback", "winback-configuration")
const winbackTestURL = getUrlFor("winback", "winback")
const badwordsURL = getUrlFor("bad-words", "bad-words")
const testBadwordsURL = getUrlFor("bad-words", "test-word")
const zipCodesURL = getUrlFor("zip-in-radius", "zips")
const leadsURL = getUrlFor("leads", "leads")

const sessionByEmailURL = getUrlFor("session", "email")
const sessionBySessionURL = getUrlFor("session", "session")
const traceSessionURL = getUrlFor("session-trace", "sessiontraces")
const rankersURL = getUrlFor("campaign-sorting", "rankers")

const blockedAreaCodeValidateURL = getUrlFor("areacode-blacklist", "validate")
const blockedAreaCodeURL = getUrlFor("areacode-blacklist", "areacodes")
const blockedAreaCodeBatchUpdateURL = getUrlFor("areacode-blacklist", "batchUpdate")
const blockedAreaCodeBatchDeleteURL = getUrlFor("areacode-blacklist", "batchDelete")

const blockedEmailDomainsURL = getUrlFor("blocked-email-domains", "domains")
const blockedEmailDomainsBatchUpdateURL = getUrlFor("blocked-email-domains", "batchUpdate")
const blockedEmailDomainsBatchDeleteURL = getUrlFor("blocked-email-domains", "batchDelete")
const emailDomainsReviewURL = getUrlFor("blocked-email-domains", "domain-hits")

const blockedEmailValidateURL = getUrlFor("email-blacklist", "validate")
const blockedEmailURL = getUrlFor("email-blacklist", "emails")
const blockedEmailBatchUpdateURL = getUrlFor("email-blacklist", "batchUpdate")
const blockedEmailBatchDeleteURL = getUrlFor("email-blacklist", "batchDelete")

const emailValidationConfigurationURL = getUrlFor("email-validation", "configuration")

const customValidationConfigurationURL = getUrlFor("custom-validation", "configuration")
const customValidationTestURL = getUrlFor("custom-validation", "test")

const addressValidationDefaultConfigURL = getUrlFor("address-validation", "default-configuration")
const addressValidationCampaignConfigURL = getUrlFor("address-validation", "campaign-configurations")
const addressValidationBatchPlacementAssociatedConfigsURL = getUrlFor("address-validation", "batch-placement-associated-configurations")

const darwinGroupListURL = getUrlFor("darwin-rank", "groups")
const darwinGroupMemberListURL = getUrlFor("darwin-rank", "placement-group-members")

const unauthErrorMessage = "Unauthorized"
const agentSourceURLFolder = process.env.NODE_ENV == "production" ? "prod" : "dev"

const deliverableManagerSenderURL = getUrlFor("deliverable-manager", "sender")
const deliverableManagerDeliveriesURL = getUrlFor("deliverable-manager", "deliveries")
const deliverableManagerDeliverablesURL = getUrlFor("deliverable-manager", "deliverables")
const deliverableManagerConfigsURL = getUrlFor("deliverable-manager", "configurations")
const deliverableManagerCloneConfigURL = getUrlFor("deliverable-manager", "clone-configuration")
const deliverableManagerTestURL = getUrlFor("deliverable-manager", "test")
const deliverableManagerDeliverableViewerURL = getUrlFor("deliverable-manager", "deliverable-viewer")

const personURL = getUrlFor("customers", "customers")

//const deliverableManagerSenderURL =  "http://localhost:9001/sender/"
//const deliverableManagerDeliveriesURL =  "http://localhost:9001/deliveries/"
//const deliverableManagerDeliverablesURL =  "http://localhost:9001/deliverables/"
//const deliverableManagerConfigsURL = "http://localhost:9001/configurations/"
//const deliverableManagerCloneConfigURL = "http://localhost:9001/clone-configuration/"
//const deliverableManagerTestURL = "http://localhost:9001/test/"
//const deliverableManagerDeliverableViewerURL = 'http://localhost:9001/deliverable-viewer/'

export const newCampaignTemplate = {
  leadCounts: [],
  rates: [],
  contractedLeadCaps: [],
  creativeUUID: NULLUUID,
  customerUUID: NULLUUID,
  welcomeEmailCreative: {
    uuid: NULLUUID,
  }
}

export const ActionTypes = {
  DEFAULT_VALIDATORS_LOADED: Symbol(),
  IS_DIRTY: Symbol(),
  PREPINGPREVIEW_LOADED: Symbol(),
  LEAD_TRANSFER_CONFIGURATIONS_LOADED: Symbol(),
  JOB_SELECTED: Symbol(),
  SNACK_UPDATED: Symbol(),
  CRUD_UPDATED: Symbol(),
  CAMPAIGN_REQUESTS_LOADED: Symbol(),
  CAMPAIGN_REQUEST_SELECTED: Symbol(),
  RESET_CAMPAIGN_REQUEST: Symbol(),
  CAMPAIGN_SELECTED: Symbol(),
  CAMPAIGN_NOT_FOUND: Symbol(),
  CAMPAIGN_UPDATED: Symbol(),
  CAMPAIGNS_START_LOADING: Symbol(),
  CAMPAIGNS_LOADED: Symbol(),
  CAMPAIGN_MAP_LOADED: Symbol(),
  CAMPAIGNS_BATCH_LOADED: Symbol(),
  CREATIVE_UPDATED: Symbol(),
  CREATIVES_LOADED: Symbol(),
  CREATIVEPREVIEW_UPDATED: Symbol(),
  SALESPEOPLE_LOADED: Symbol(),
  RANKERS_LOADED: Symbol(),
  PLACEMENT_MANAGER_OPEN_LOOKUP_LIST: Symbol(),
  PLACEMENT_MANAGER_CLOSE_LOOKUP_LIST: Symbol(),
  PLACEMENT_SELECTED: Symbol(),
  PLACEMENT_UNSELECTED: Symbol(),
  PLACEMENT_NOT_FOUND: Symbol(),
  SET_PLACEMENT_MANAGER_VALIDATION_ERROR: Symbol(),
  PLACEMENT_RESET: Symbol(),
  PLACEMENT_UPDATED: Symbol(),
  PLACEMENTS_START_LOADING: Symbol(),
  PLACEMENTS_LOADED: Symbol(),
  ASSOCIATION_PLACEMENT_SELECTED: Symbol(),
  ASSOCIATION_PLACEMENT_SAVED: Symbol(),
  ASSOCIATION_PLACEMENT_UNSELECTED: Symbol(),
  ASSOCIATION_PLACEMENT_NOT_FOUND: Symbol(),
  ASSOCIATION_CAMPAIGN_SELECTED: Symbol(),
  ASSOCIATION_CAMPAIGN_UNSELECTED: Symbol(),
  ASSOCIATION_CAMPAIGN_NOT_FOUND: Symbol(),
  ASSOCIATION_RESET: Symbol(),
  ASSOCIATION_START_LOADING: Symbol(),
  ASSOCIATION_LOADED: Symbol(),
  ASSOCIATION_LOADING_ERROR: Symbol(),
  ASSOCIATION_DAILY_CAP_COUNT_START_LOADING: Symbol(),
  ASSOCIATION_DAILY_CAP_COUNT_LOADED: Symbol(),
  ASSOCIATION_DAILY_CAP_COUNT_ERROR: Symbol(),
  ASSOCIATION_CURRENT_CAP_COUNT_START_LOADING: Symbol(),
  ASSOCIATION_CURRENT_CAP_COUNT_LOADED: Symbol(),
  ASSOCIATION_CURRENT_CAP_COUNT_ERROR: Symbol(),
  WINBACK_CAMPAIGN_UPDATED: Symbol(),
  WINBACK_ERROR: Symbol(),
  WINBACK_LOADED: Symbol(),
  WINBACK_UPDATED: Symbol(),
  VALIDATORS_LOADED: Symbol(),
  QUESTIONS_LOADED: Symbol(),
  QUESTION_IS_DIRTY: Symbol(),
  BADWORDS_LOADED: Symbol(),
  TESTING_BADWORD_LOADING: Symbol(),
  GROUP_SELECTED: Symbol(),
  DATASETS_LOADED: Symbol(),
  INSPECTOR_UPDATED: Symbol(),
  BUCKETS_LOADED: Symbol(),
  CUSTOM_VALIDATION_RESULTS_LOADED: Symbol(),
  CUSTOM_VALIDATION_LOADED: Symbol(),
  USER_LOADED: Symbol(),
  JWT_LOADED: Symbol(),
  ZIPS_LOADED: Symbol(),
  TAGS_LOADED: Symbol(),
  LEAD_HISTORY_LOADED: Symbol(),
  TAGMASTERLIST_LOADED: Symbol(),
  TAGCHILDRENLIST_LOADED: Symbol(),
  ALTPARENTTAG_LOADED: Symbol(),
  TAG_LOADING: Symbol(),
  ASSOCIATEDCRITERIA_LOADED: Symbol(),
  ASSOCIATEDCRITERIA_SAVE_MSG: Symbol(),
  ASSOCIATEDCRITERIA_SAVE_MSG_RESET: Symbol(),
  ASSOCIATEDTAGS_LOADED: Symbol(),
  ASSOCIATEDTAGS_RESET: Symbol(),
  ALTPARENTTAG_RESET: Symbol(),
  ALTPARENTTAG_UNLOAD: Symbol(),
  BLOCKED_EMAIL_DOMAINS_LOADED: Symbol(),
  BLOCKED_EMAILS_LOADED: Symbol(),
  BLOCKED_EMAIL_VALIDATION_RESULT: Symbol(),
  BLOCKED_EMAIL_VALIDATION_START: Symbol(),
  BLOCKED_AREA_CODE_VALIDATION_RESULT: Symbol(),
  BLOCKED_AREA_CODES_LOADED: Symbol(),
  LEAD_DELIVERY_CONFIGS_ERROR: Symbol(),
  LEAD_DELIVERY_CONFIGS_LOADED: Symbol(),
  LEAD_DELIVERY_SCHEDULE_LOADED: Symbol(),
  LEAD_DELIVERY_SCHEDULE_START_LOADING: Symbol(),
  LEAD_DELIVERY_SCHEDULE_SET: Symbol(),
  LEAD_DELIVERY_CONFIG_LOADED: Symbol(),
  LEAD_DELIVERY_CONFIG_START_LOADING: Symbol(),
  LEAD_DELIVERY_CONFIG_NEW: Symbol(),
  LEAD_DELIVERY_CONFIG_CANCEL: Symbol(),
  LEAD_DELIVERY_CONFIG_TEST: Symbol(),
  LEAD_DELIVERY_LATEST_DELIVERIES_STARTED_LOADING: Symbol(),
  LEAD_DELIVERY_LATEST_DELIVERIES_LOADED: Symbol(),
  EMAIL_VALIDATION_LOADED: Symbol(),
  PUB_PREVAL_REPORT_BY_DATE_LOADED: Symbol(),
  PUB_PREVAL_BY_MESSAGE_REPORT_LOADED: Symbol(),
  DARWIN_GROUP_LIST_LOADED: Symbol(),
  DARWIN_GROUP_LOADED: Symbol(),
  DARWIN_GROUP_MEMBER_LIST_LOADED: Symbol(),
  DARWIN_ALL_GROUPS_MEMBERS_LIST_LOADED: Symbol(),
  START_SUPPRESSION_LIST_FILE_UPLOAD: Symbol(),
  FINISH_SUPPRESSION_LIST_FILE_UPLOAD: Symbol(),
  UPDATE_SUPPRESSION_LIST_FILE_UPLOAD_BAR: Symbol(),
  RESET_SUPPRESSION_LIST_UPLOAD: Symbol(),
  UPDATE_SUPPRESSION_UPLOAD_ERRORS: Symbol(),
  VALIDATORS_DATA_LOADED: Symbol(),
  EMAIL_DOMAINS_LIST_LOADED: Symbol(),
  KEYCLOAK_CREATE_USER_LOADED: Symbol(),
  AGENT_SOURCE_FILE_LOADED: Symbol(),
  AGENT_SOURCE_URL_LOADED: Symbol(),
  ADDRESS_VALIDATION_DEFAULT_CONFIG_LOADED: Symbol(),
  ADDRESS_VALIDATION_CAMPAIGN_CONFIG_LOADED: Symbol(),
  ADDRESS_VALIDATION_CAMPAIGN_CONFIG_RESET: Symbol(),
  ADDRESS_VALIDATION_PLACEMENT_ASSOCIATED_CONFIGS_LOAD_START: Symbol(),
  ADDRESS_VALIDATION_PLACEMENT_ASSOCIATED_CONFIGS_LOAD_SUCCESS: Symbol(),
  ADDRESS_VALIDATION_PLACEMENT_ASSOCIATED_CONFIGS_RESET: Symbol(),
  ASSOCIATED_LEAD_ACCEPTANCE_CONFIGS_LOAD_SUCCESS: Symbol(),
  ASSOCIATED_LEAD_ACCEPTANCE_CONFIGS_LOAD_START: Symbol(),
  CAMPAIGN_ASSOCIATIONS_LOADED: Symbol(),
  LOADING_CAMPAIGN_ASSOCIATIONS: Symbol(),
  CAMPAIGN_CRITERIA_LOADED: Symbol(),
  CAMPAIGN_CRITERIA_SELECTED: Symbol(),
  ITEM_STATS_LOADED: Symbol(),
  ITEM_STATS_LOADING: Symbol(),
  ITEM_STATS_ERROR: Symbol(),
  CAMPAIGN_PHONE_VERIFICATION_LOADED: Symbol(),
  BUY_SHEET_START_LOADING: Symbol(),
  BUY_SHEET_LOADED: Symbol(),
  QUARANTINE_SUMMARY_STATS_LOADED: Symbol(),
  QUARANTINE_SUMMARY_STATS_ERROR: Symbol(),
}

const emailRx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
export function validEmail(email) {
  return emailRx.test(email)
}
class awsService {
  constructor() {
    AWS.config.region = "us-east-1"
    AWS.config.credentials = new AWS.Credentials("AKIAI3PMMIVMWXTSU67A", "uAjmsw11qDlm1gc2Sg0GYj0r5/UGo5onyW5u+C48")
    this.cakeCreative = ""
    this.AlertState = false
    this.bucket = "static.adquire.com"
    this.bucketPath = "CPA"
    this.s3 = new AWS.S3({ params: { Bucket: this.bucket } })
  }
  uploadS3(filePath, fileContent, name, cb) {
    this.s3.upload({ Key: filePath, Body: fileContent }, err => {
      cb(this, err)
    })
  }
}
let aws = new awsService()
export function handleResponse(response, dispatch) {
  if (response.status >= 300 || response.status < 200) {
    if (Unauthorized(response)) {
      if ((localStorage["expiration"] - (new Date().getTime() / 1000).toFixed(0)) <= 0) {
        localStorage.removeItem("state")
        localStorage.removeItem("nonce")
        localStorage.removeItem("user")
        localStorage.removeItem("jwt")
        dispatch(ShowSnack(unauthErrorMessage))
        return dispatch(login())
      }
      dispatch(ShowSnack(unauthErrorMessage))
    }
    if (response.status == 401) {
      return new Promise(() => {
        throw { error: unauthErrorMessage, records: [], recordSet: {} }
      })
    }
    return new Promise(() => {
      throw { error: response.status, records: [], recordSet: {} }
    })
  }
  return new Promise(resolve => {
    let r = {}
    try {
      r.data = JSON.parse(response.responseText)
      r.rawResp = response
      resolve(r)
    } catch (err) {
      r.data = response.responseText
      r.rawResp = response
      resolve(r)
    }
  })
}

function request(method, body, state, accept) {
  accept = accept || "application/json"
  return {
    method: method,
    headers: {
      Accept: accept,
      "Content-Type": "application/json",
      Authorization: state.jwt
    },
    body: body
  }
}

function customFetch(url, options, isProto) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    if (isProto) {
      xhr.responseType = "arraybuffer"
    }
    xhr.open(options.method, url, true)
    Object.keys(options.headers || {}).forEach(k => {
      xhr.setRequestHeader(k, options.headers[k])
    })
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(this)
      } else {
        let responseText = null
        if (xhr.responseType == "arraybuffer") {
          responseText = xhr.statusText
        }
        const errorBody = {
          status: xhr.status,
          statusText: xhr.statusText
        }
        let body = xhr.status === 500 ? "Server error" : responseText || xhr.responseText
        try {
          body = JSON.parse(xhr.responseText)
        } catch (e) { console.error(e) }
        errorBody.body = body
        reject(errorBody)
      }
    }
    xhr.onerror = function () {
      console.dir(this)
      reject({
        status: this.status,
        statusText: xhr.statusText,
        body: this.responseText || ""
      })
    }
    xhr.send(options.body)
  })
}

function fetchWithRetry(url, options, isProto, attempts) {
  return customFetch(url, options, isProto).catch(error => {
    const retry = (url, options, isProto, attempts) => {
      return new Promise((resolve, reject) => {
        return customFetch(url, options, isProto)
          .then(resolve)
          .catch(() => {
            if (attempts === 0 || error.status !== 503) {
              reject(error)
            } else {
              setTimeout(() => fetchWithRetry(url, options, isProto, attempts - 1), 1000)
            }
          })
      })
    }
    return retry(url, options, isProto, attempts)
  })
}

export function exceptionHandling(e, dispatch, source, refresh) {
  if (Unauthorized(e)) {
    if ((localStorage["expiration"] - (new Date().getTime() / 1000).toFixed(0)) <= 0) {
      return dispatch(login())
    }
    if (refresh) {
      dispatch(ShowSnack(unauthErrorMessage + ": " + source))
      refresh()
    }
    return
  }
  if (source) {
    dispatch(ShowSnack(`Error from server: ${source}`))
  }
}

function Unauthorized(res) {
  if (res.status && res.status == 401) {
    return true
  }
  return res.error && res.error == unauthErrorMessage
}

export function retrieve(url, limit, offset, uuid, dispatch, state, query) {
  url += uuid ? `${uuid}` : ""
  if (!state.jwt || !state.jwt.length || state.jwt.length <= 0) {
    return new Promise(() => {
      throw { error: unauthErrorMessage, records: [], recordSet: {} }
    })
  }
  url += `?limit=${limit > 0 ? limit : 500}&offset=${offset}`
  if (query) {
    url += `&${query}`
  }
  return customFetch(url, request("GET", null, state)).then(response => {
    let resp = handleResponse(response, dispatch)
    return resp.then(r => {
      if (uuid || r.data.records == null) {
        return r.data
      }
      r.data.records = r.data.records || []
      r.data.recordSet = r.data.recordSet || {}
      return r.data
    })
  })
}

function del(dispatch, url, uuid, body, state) {
  if (!state.jwt || !state.jwt.length || state.jwt.length <= 0) {
    return new Promise(() => {
      throw { error: unauthErrorMessage, records: [], recordSet: {} }
    })
  }
  url += uuid ? uuid : ""
  return customFetch(url, request("DELETE", JSON.stringify(body), state)).then(response =>
    handleResponse(response, dispatch)
  )
}

/*options
  dispatch, model, verb, url, action, sanitize, state, query, successMessage)
*/
export function getOpts(dispatch, model, verb, url, action, sanitize, state, query, successMessage, avoidIsDirty) {
  return {
    dispatch: dispatch,
    model: model,
    verb: verb,
    url: url,
    action: action,
    sanitize: sanitize,
    state: state,
    query: query,
    successMessage: successMessage,
    avoidIsDirty: avoidIsDirty || false
  }
}

function extractData(r) {
  return r.data
}

export function save(saveOpts) {
  ;["dispatch", "verb", "url", "state"].forEach(k => {
    if (!saveOpts[k]) {
      throw k + " required"
    }
  })
  if (!saveOpts.state.jwt || !saveOpts.state.jwt.length || saveOpts.state.jwt.length <= 0) {
    return new Promise(() => {
      throw { error: unauthErrorMessage, records: [], recordSet: {} }
    })
  }
  if (saveOpts.query) {
    saveOpts.url += `?` + saveOpts.query
  }
  saveOpts.sanitize && saveOpts.sanitize(saveOpts.model)
  return customFetch(saveOpts.url, request(saveOpts.verb, JSON.stringify(saveOpts.model), saveOpts.state))
    .then(response => handleResponse(response, saveOpts.dispatch))
    .then(response => {
      saveOpts.action && saveOpts.dispatch({ type: saveOpts.action, data: response.data })
      if (!saveOpts.avoidIsDirty) {
        saveOpts.dispatch({ type: ActionTypes.IS_DIRTY, data: false })
      }
      if (saveOpts.successMessage != DISABLE_SNACK) {
        saveOpts.dispatch(ShowSnack(saveOpts.successMessage || "Success"))
      }
      return response
    })
}

export function isNotFoundError(e) {
  return e.status === 404
}

export function uploadPriorityRanking(event) {
  return function (dispatch, getState) {
    var form = new FormData()
    form.append("hashes", event.target.files[0])
    let url = dataURL + getState().campaign.UUID
    return customFetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data"
      },
      body: form
    })
      .then(() => dispatch(ShowSnack(fileSuccess)))
      .catch(() => dispatch(ShowSnack(fileError)))
  }
}

export function deleteCampaignConfiguration(campaignCriteria) {
  return function (dispatch, getState) {
    return del(dispatch, criteriaURL, campaignCriteria.uuid, null, getState())
      .then(() => dispatch(retrieveCampaignCriteria(campaignCriteria.campaignUUID)))
      .catch(e => exceptionHandling(e, dispatch, "Deleting campaign configuration", () => dispatch(retrieveCampaignCriteria(campaignCriteria.campaignUUID))))
  }
}

//Tagging
export function saveTag(tag) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, tag, "POST", tagListURL, null, null, getState()))
      .then(() => {
        dispatch(retrieveTagMasterList())
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving tag", () => dispatch(retrieveTagMasterList())))
  }
}

export function updateTag(tag) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, tag, "PUT", tagListURL, tag.UUID, null, getState()))
      .then(() => {
        dispatch(retrieveTagMasterList())
      })
      .catch(e => exceptionHandling(e, dispatch, "Updating tag", () => dispatch(retrieveTagMasterList())))
  }
}

export function deleteTag(uuid) {
  return function (dispatch, getState) {
    return del(dispatch, tagListURL, uuid, null, getState())
      .then(() => dispatch(retrieveTagMasterList()))
      .catch(e => exceptionHandling(e, dispatch, "Deleting tag"), () => dispatch(retrieveTagMasterList()))
  }
}

export function deleteMasterTag(tagValue) {
  return function (dispatch, getState) {
    return del(dispatch, tagListURL, tagValue, null, getState())
      .then(() => dispatch(retrieveTagMasterList()))
      .catch(e => exceptionHandling(e, dispatch, "Deleting master tag"), () => dispatch(retrieveTagMasterList()))
  }
}

export function saveTagToMasterList(value) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, "", "POST", tagListURL + value, null, null, getState()))
      .then(() => {
        dispatch(retrieveTagMasterList())
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving tag to master list"), () => dispatch(retrieveTagMasterList()))
  }
}

export function retrieveCampaignCriteria(campaignUUID) {
  return function (dispatch, getState) {
    return retrieve(criteriaURL, 0, 0, campaignUUID, dispatch, getState())
      .then(res => dispatch({ type: ActionTypes.CAMPAIGN_CRITERIA_LOADED, data: res }))
      .catch(e => {
        if (isNotFoundError(e)) {
          dispatch({ type: ActionTypes.CAMPAIGN_CRITERIA_LOADED, data: { criteriaFilter: {} } })
        } else {
          exceptionHandling(e, dispatch, "Getting campaign criteria")
        }
      })
  }
}

export function retrieveCampaignStats(campaignUUID) {
  return function (dispatch) {
    dispatch(retrieveStats(campaignUUID, ""))
  }
}

export function retrievePlacementStats(placementUUID) {
  return function (dispatch) {
    dispatch(retrieveStats(NULLUUID, placementUUID))
  }
}

function retrieveStats(campaignUUID, placementUUID) {
  return function (dispatch, getState) {
    dispatch({ type: ActionTypes.ITEM_STATS_LOADING })
    const periodInHours = 72
    let endDate = moment()
    let startDate = moment().add(-1 * periodInHours, "hours")
    let params = `placement=${placementUUID}&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
    let promises = []
    promises.push(retrieve(optinCountsURL, 0, 0, campaignUUID, dispatch, getState(), params))
    promises.push(retrieve(leadCountsURL, 0, 0, campaignUUID, dispatch, getState(), params))

    return Promise.all(promises)
      .then(res => {
        let stats = {
          periodInHours: periodInHours,
          createdAt: moment(),
          optins: res[0].count,
          leads: res[1].count
        }
        dispatch({ type: ActionTypes.ITEM_STATS_LOADED, data: stats })
      })
      .catch(e => {
        dispatch({ type: ActionTypes.ITEM_STATS_ERROR })
        exceptionHandling(e, dispatch, "Getting stats")
      })
  }
}

export function retrieveTagMasterList() {
  return function (dispatch, getState) {
    return retrieve(tagListURL, 0, 0, null, dispatch, getState())
      .then(res => dispatch({ type: ActionTypes.TAGMASTERLIST_LOADED, data: res }))
      .catch(e => exceptionHandling(e, dispatch, "Getting tag master list"))
  }
}

export function loadTagsTaxonomy() {
  return function (dispatch, getState) {
    return retrieve(tagListURL, 0, 0, null, dispatch, getState())
      .then(res => dispatch({ type: ActionTypes.TAGMASTERLIST_LOADED, data: res }))
      .catch(e => exceptionHandling(e, dispatch, "Getting tags taxonomy"))
  }
}

export function loadTagChildren(parentUuid) {
  return function (dispatch, getState) {
    return retrieve(tagChildrenListURL, 0, 0, parentUuid, dispatch, getState())
      .then(res => dispatch({ type: ActionTypes.TAGCHILDRENLIST_LOADED, data: res }))
      .catch(e => exceptionHandling(e, dispatch, "Loaging tag children"))
  }
}

export function loadTag(uuid) {
  return function (dispatch, getState) {
    dispatch(loadingTag())
    return retrieve(tagListURL, 0, 0, uuid, dispatch, getState())
      .then(res => dispatch({ type: ActionTypes.ALTPARENTTAG_LOADED, data: res }))
      .catch(e => exceptionHandling(e, dispatch, "Loaging tag"))
  }
}

export function loadingTag() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.TAG_LOADING, data: null })
  }
}

export function loadAssociatedTags(itemUUID) {
  return function (dispatch, getState) {
    return retrieve(tagAssociationURL, 0, 0, itemUUID, dispatch, getState())
      .then(res => dispatch({ type: ActionTypes.ASSOCIATEDTAGS_LOADED, data: res }))
      .catch(e => exceptionHandling(e, dispatch, "Loading associated tags"))
  }
}

export function resetAltParentTags() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ALTPARENTTAG_RESET, data: null })
  }
}

export function unloadAltParent(uuid) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ALTPARENTTAG_UNLOAD, data: uuid })
  }
}

export function resetAssociatedTags() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ASSOCIATEDTAGS_RESET, data: null })
  }
}

export function saveTagsAssociation(tagAssociation) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, tagAssociation, "PUT", tagAssociationURL, null, null, getState()))
      .then(() => {
        dispatch(loadAssociatedTags(tagAssociation.itemUuid))
      })
      .catch(e => exceptionHandling(e, dispatch, "saving tag association"), () => dispatch(loadAssociatedTags(tagAssociation.itemUuid)))
  }
}

export function saveAssociatedCriteriaFilters(criteriaFilter) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, criteriaFilter, "POST", criteriaAssociationURL, null, null, getState(), "", "-1"))
      .then(() => {
        dispatch(ShowAssociatedCriteriaSaveBackendSuccessMessage("Save Success"))
        dispatch(loadAssociatedCriteria(getState().placementManager.placement.UUID))
      })
      .catch(e => {
        if (e.body.message) {
          dispatch(ShowAssociatedCriteriaSaveBackendFailureMessage(e.body.message))
        } else {
          exceptionHandling(e, dispatch, "Saving associated criteria filters", () => dispatch(loadAssociatedCriteria(getState().placementManager.placement.UUID)))
        }
      })
  }
}

export function ShowAssociatedCriteriaSaveBackendSuccessMessage(message) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.ASSOCIATEDCRITERIA_SAVE_MSG,
      data: { isSuccessful: true, message: message }
    })
  }
}

export function ShowAssociatedCriteriaSaveBackendFailureMessage(errorMessage) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.ASSOCIATEDCRITERIA_SAVE_MSG,
      data: { isSuccessful: false, message: errorMessage }
    })
  }
}

export function resetAssociatedCriteriaSaveMsg() {
  return function (dispatch) {
    setTimeout(() => dispatch({ type: ActionTypes.ASSOCIATEDCRITERIA_SAVE_MSG_RESET }), 6000)
  }
}

export function deleteAssociatedCriteriaFilters(associatedCriteria) {
  return function (dispatch, getState) {
    return del(dispatch, criteriaAssociationURL, "", associatedCriteria, getState())
      .then(() => {
        dispatch(loadAssociatedCriteria(getState().placementManager.placement.UUID))
      })
      .catch(e => exceptionHandling(e, dispatch, "Deleting associated criteria filters"), () => dispatch(loadAssociatedCriteria(getState().placementManager.placement.UUID)))
  }
}

export function loadAssociatedCriteria(placementUUID) {
  return function (dispatch, getState) {
    return retrieve(criteriaAssociationURL, 0, 0, "", dispatch, getState(), `placement_uuid=${placementUUID}`)
      .then(res => {
        dispatch({ type: ActionTypes.ASSOCIATEDCRITERIA_LOADED, data: res })
      })
      .catch(e => {
        if (isNotFoundError(e)) {
          dispatch({ type: ActionTypes.ASSOCIATEDCRITERIA_LOADED })
        } else {
          exceptionHandling(e, dispatch, "Loading associated criteria")
        }
      })
  }
}

export function savePlacementAssociatedLeadAcceptances(placementUUID, leadAcceptanceAssociations) {
  return function (dispatch, getState) {
    save(
      getOpts(
        dispatch,
        leadAcceptanceAssociations,
        "POST",
        placementLeadAcceptanceAssociationURL,
        null,
        null,
        getState(),
        "placement_uuid=" + placementUUID,
        DISABLE_SNACK
      )
    )
      .then(() => dispatch(loadAssociatedLeadAcceptance(placementUUID)))
      .then(() => dispatch(ShowSnack("Save Success")))
      .catch(e => exceptionHandling(e, dispatch, "Saving placement associated lead acceptances"), () => dispatch(loadAssociatedLeadAcceptance(placementUUID)))
    return
  }
}

export function deleteAssociatedLeadAcceptance(leadAcceptanceAssociation) {
  return function (dispatch, getState) {
    return del(dispatch, leadAcceptanceAssociationURL, "", leadAcceptanceAssociation, getState())
      .then(() => {
        dispatch(loadAssociatedLeadAcceptance(leadAcceptanceAssociation.placementUuid))
      })
      .catch(e => exceptionHandling(e, dispatch, "Deleting associated lead acceptance"), () => dispatch(loadAssociatedLeadAcceptance(leadAcceptanceAssociation.placementUuid)))
  }
}

export function loadAssociatedLeadAcceptance(placementUUID) {
  return function (dispatch, getState) {
    dispatch({ type: ActionTypes.ASSOCIATED_LEAD_ACCEPTANCE_CONFIGS_LOAD_START })
    return retrieve(leadAcceptanceAssociationURL, 0, 0, "", dispatch, getState(), `placement_uuid=${placementUUID}`)
      .then(res => {
        dispatch({ type: ActionTypes.ASSOCIATED_LEAD_ACCEPTANCE_CONFIGS_LOAD_SUCCESS, data: res })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Loading associated lead acceptance")
        }
      })
  }
}

//Buckets
export function retrieveBuckets(type) {
  return function (dispatch, getState) {
    return retrieve(bucketURL, 0, 0, null, dispatch, getState(), "type=" + type)
      .then(res => dispatch({ type: ActionTypes.BUCKETS_LOADED, data: res.records }))
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting buckets")
        }
      })
  }
}

export function deleteBucket(bucket, type) {
  return function (dispatch, getState) {
    let url = bucketURL + `${encodeURIComponent(bucket.id)}/`
    return del(dispatch, url, null, null, getState())
      .then(() => dispatch(retrieveBuckets(type)))
      .catch(e => exceptionHandling(e, dispatch, "Deleting bucket"), () => dispatch(retrieveBuckets(type)))
  }
}

export function updateBucket(bucket, type) {
  return function (dispatch, getState) {
    let url = bucketURL + `${encodeURIComponent(bucket.id)}/`
    return save(getOpts(dispatch, bucket, "PUT", url, null, null, getState()))
      .then(() => {
        dispatch(retrieveBuckets(type))
      })
      .catch(e => exceptionHandling(e, dispatch, "Updating bucket"), () => dispatch(retrieveBuckets(type)))
  }
}

export function newBucket(bucket, type) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, bucket, "POST", bucketURL, null, null, getState()))
      .then(() => {
        dispatch(retrieveBuckets(type))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving new bucket"), () => dispatch(retrieveBuckets(type)))
  }
}

//Questions
export function retrieveQuestions(campaignUUID) {
  return function (dispatch, getState) {
    return retrieve(questionURL, 0, 0, campaignUUID, dispatch, getState(), "byCampaign=true")
      .then(res => {
        dispatch({ type: ActionTypes.QUESTIONS_LOADED, data: res.records })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting questions")
        }
      })
  }
}

export function deleteQuestion(questionUUID, campaignUUID) {
  return function (dispatch, getState) {
    let cUUID = campaignUUID
    return del(dispatch, questionURL, questionUUID, null, getState())
      .then(() => dispatch(retrieveQuestions(cUUID)))
      .catch(e => exceptionHandling(e, dispatch, "Deleting question"), () => dispatch(retrieveQuestions(cUUID)))
  }
}

export function saveQuestion(question, campaignUUID) {
  return function (dispatch, getState) {
    let cUUID = campaignUUID
    delete question.element
    if (question.answers.records) {
      question.answers.records = question.answers.records.map(a => {
        delete a.element
        return a
      })
    }
    let verb = question.UUID ? "PUT" : "POST"
    let url = questionURL + (question.UUID ? question.UUID : "")
    return save(
      getOpts(
        dispatch,
        question,
        verb,
        url,
        null,
        () => {
          question.campaignUuid = cUUID || question.cUUID
          return question
        },
        getState()
      )
    )
      .then(() => {
        dispatch(retrieveQuestions(cUUID))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving question"), () => dispatch(retrieveQuestions(cUUID)))
  }
}

export function saveSurvey(cUUID, questions) {
  return function (dispatch, getState) {
    let url = surveyURL + cUUID
    return save(
      getOpts(
        dispatch,
        { records: questions },
        "PUT",
        url,
        null,
        () => { },
        getState()
      )
    )
      .then(() => {
        dispatch(retrieveQuestions(cUUID))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving questions"), () => dispatch(retrieveQuestions(cUUID)))
  }
}

export function reorderQuestions(cUUID, questions) {
  return function (dispatch, getState) {
    const questionReferences = questions.map(e => {
      return { UUID: e.UUID, campaignUuid: e.campaignUuid }
    })
    let url = reorderQuestionsURL + cUUID
    return save(
      getOpts(
        dispatch,
        { records: questionReferences },
        "PUT",
        url,
        null,
        () => { },
        getState()
      )
    )
      .then(() => {
        dispatch(retrieveQuestions(cUUID))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving questions"), () => dispatch(retrieveQuestions(cUUID)))
  }
}

//placements

export function retrievePlacementGroup(groupUUID) {
  return function (dispatch, getState) {
    if (groupUUID == NULLUUID) {
      return dispatch({
        type: ActionTypes.GROUP_SELECTED,
        data: { uuid: NULLUUID, memberUuids: [] }
      })
    }
    return retrieve(placementsGroupURL, 0, 0, groupUUID, dispatch, getState())
      .then(res =>
        dispatch({
          type: ActionTypes.GROUP_SELECTED,
          data: res
        })
      )
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting placement group")
        }
      })
  }
}

export function savePlacementToGroup(targetPlacement, group) {
  return function (dispatch, getState) {
    let placement = getState().placementManager.placement
    group.memberUuids = [...new Set([placement.UUID, targetPlacement.UUID].concat(group.memberUuids))]
    return save(getOpts(dispatch, group, "POST", placementsGroupURL, null, null, getState()))
      .then(extractData)
      .then(() => {
        const avoidRedirection = true
        dispatch(retrievePlacements(placement.UUID, avoidRedirection))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving placement to group"), () => dispatch(retrievePlacements(placement.UUID, true)))
  }
}

export function removePlacementGroupMember(uuid) {
  return function (dispatch, getState) {
    const placement = getState().placementManager.placement
    return retrieve(placementsURL, 0, 0, uuid, dispatch, getState())
      .then(res => {
        res.groupUuid = NULLUUID
        dispatch(savePlacementGroup(res))
        const avoidRedirection = true
        dispatch(retrievePlacements(placement.UUID, avoidRedirection))
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Removing placement group member", () => dispatch(retrievePlacements(placement.UUID, true)))
        }
      })
  }
}

export function newCampaignCriteria(placement) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.CAMPAIGN_CRITERIA_SELECTED, data: placement })
  }
}

export function newPlacement(isBrokered) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.PLACEMENT_SELECTED,
      data: { rankingService: [], groupUuid: NULLUUID, activated: true, isBrokered: isBrokered }
    })
    dispatch({ type: ActionTypes.ASSOCIATEDCRITERIA_LOADED })
    dispatch(redirectToPlacement(""))
  }
}

export function updatePlacement(placement) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.PLACEMENT_UPDATED, data: placement })
  }
}

export function resetPlacement() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.PLACEMENT_RESET, data: null })
  }
}

export function retrievePlacements(uuid, avoidRedirection) {
  return function (dispatch, getState) {
    avoidRedirection = avoidRedirection || false
    dispatch({ type: ActionTypes.IS_DIRTY, data: false })
    if (!uuid || uuid.length == 0) {
      dispatch({ type: ActionTypes.PLACEMENTS_START_LOADING })
      customFetch(`${placementsURL}?thin=yes&include_archived=no&include_legacy_archived=no`, request("GET", null, getState(), "application/json"), false)
        .then(response => handleResponse(response, dispatch))
        .then(response => {
          dispatch({ type: ActionTypes.PLACEMENTS_LOADED, data: response.data.records })
        }
        )
      return
    }
    return retrieve(placementsURL, 0, 0, uuid, dispatch, getState())
      .then(res => {
        dispatch(retrieveCreative(res.creativeUUID))
        dispatch(retrieveAddressValidationPlacementAssociatedConfigs(uuid))
        dispatch(loadAssociatedLeadAcceptance(uuid))
        if (uuid && res.groupUuid) {
          dispatch(retrievePlacementGroup(res.groupUuid))
        }
        dispatch({ type: ActionTypes.PLACEMENT_SELECTED, data: res })

        if (!avoidRedirection) {
          dispatch(redirectToPlacement(uuid))
        }
      })
      .catch(e => {
        if (isNotFoundError(e)) {
          dispatch({ type: ActionTypes.PLACEMENT_NOT_FOUND })
        } else {
          exceptionHandling(e, dispatch)
        }
      })
  }
}

export function setPlacementManagerValidationErrors(errors) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.SET_PLACEMENT_MANAGER_VALIDATION_ERROR, data: errors })
  }
}

export function savePlacement(p, cb, campaignUUID) {
  return function (dispatch, getState) {
    let isNew = p.UUID ? false : true
    let placement = p ? p : getState().placementManager.placement

    let size = parseInt(placement.pageSize)
    if (isNaN(size)) {
      size = 0
    }
    let limit = parseInt(placement.pageLimit)
    if (isNaN(limit)) {
      limit = 0
    }
    let verb = isNew ? "POST" : "PUT"
    placement.pageSize = size
    placement.pageLimit = limit
    placement.customerUUID = placement.customerUUID && placement.customerUUID.length > 0 ? placement.customerUUID : NULLUUID
    placement.creativeUUID = placement.creativeUUID && placement.creativeUUID.length > 0 ? placement.creativeUUID : NULLUUID

    let url = placementsURL + (!isNew ? placement.UUID : "")
    return save(
      getOpts(
        dispatch,
        placement,
        verb,
        url,
        ActionTypes.PLACEMENT_SELECTED,
        p => {
          dispatch({ type: ActionTypes.IS_DIRTY, data: false })
          p.defaultRevenueShare = (p.defaultRevenueShare || []).map(r => {
            delete r["id"]
            return r
          })
          p.brokeredRevenueShare = (p.brokeredRevenueShare || []).map(r => {
            r.revenueShare = r.revenueShare.map(rs => {
              delete rs["id"]
              return rs
            })
            return r
          })
          p.campaignOverallLeadCap = (p.campaignOverallLeadCap || []).map(r => {
            r.overallLeadCap = r.overallLeadCap.map(rs => {
              delete rs["id"]
              delete rs["isNew"]
              return rs
            })
            return r
          })
        },
        getState()
      )
    )
      .then((res) => {
        placement.UUID = res.data.UUID
        cb.forEach(c => {
          c(placement.UUID)
        })
        if (isNew) {
          dispatch(redirectToPlacement(placement.UUID))
        }
        if (campaignUUID) {
          dispatch({ type: ActionTypes.ASSOCIATION_PLACEMENT_SAVED, data: null })
          dispatch(retrieveAssociation(placement.UUID, campaignUUID))
        }
      })
      .then(() => {
        if (isNew) {
          dispatch(retrievePlacements(null, true))
        }
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)

        let errormessaage = "Saving placement"
        if (e?.body?.errors) {
          if (e.body.errors.length == 1) {
            errormessaage = `${errormessaage} - `
          }
          if (e.body.errors.length == 1) {
            errormessaage += e.body.errors[0].detail
          } else {
            e.body.errors.forEach((e, i) => {
              errormessaage = `${errormessaage} Error # ${i}. ${e.detail}`
            })
          }
        }

        exceptionHandling(e, dispatch, errormessaage, () => {
          dispatch({ type: ActionTypes.PLACEMENT_SELECTED, data: null })
          dispatch(retrievePlacements(isNew ? null : placement.UUID, true))
        })
      })
  }
}

export function openPlacementManagerLookupList() {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.PLACEMENT_MANAGER_OPEN_LOOKUP_LIST
    })
  }
}

export function closePlacementManagerLookupList() {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.PLACEMENT_MANAGER_CLOSE_LOOKUP_LIST
    })
  }
}

export function savePlacementGroup(placement) {
  return function (dispatch, getState) {
    let size = parseInt(placement.pageSize)
    if (isNaN(size)) {
      size = 0
    }
    let limit = parseInt(placement.pageLimit)
    if (isNaN(limit)) {
      limit = 0
    }
    placement.pageSize = size
    placement.pageLimit = limit
    placement.creativeUUID =
      placement.creativeUUID && placement.creativeUUID.length > 0 ? placement.creativeUUID : NULLUUID
    let url = placementsURL + (placement.UUID ? placement.UUID : "")
    return save(getOpts(dispatch, placement, "PUT", url, null, () => { }, getState())).catch(e =>
      exceptionHandling(e, dispatch, "Saving placement group")
    )
  }
}

export function redirectToPlacement(uuid) {
  return function () {
    history.push(`/placementmanager/${uuid}`)
  }
}

//Campaigns
export function loadCampaignAssociations(campaignUUID) {
  return function (dispatch, getState) {
    dispatch({
      type: ActionTypes.LOADING_CAMPAIGN_ASSOCIATIONS
    })

    return customFetch(`${placementsAssociations}${campaignUUID}?type=all`, request("GET", null, getState(), "application/json"), false)
    .then(res => handleResponse(res, dispatch))  
    .then(res => {
        dispatch({
          type: ActionTypes.CAMPAIGN_ASSOCIATIONS_LOADED,
          data: res.data.associations
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting campaign associations")
        }
      })
  }
}

export function removeCampaignGroupMember(uuid) {
  return function (dispatch, getState) {
    let campaign = getState().campaign
    return retrieve(campaignURL, 0, 0, uuid, dispatch, getState())
      .then(res => {
        res.groupUuid = NULLUUID
        dispatch(saveCampaign(res, [], true))
        dispatch(retrieveCampaigns(campaign.UUID, true))
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Removing campaign group member", () => dispatch(retrieveCampaigns(campaign.UUID)))
        }
      })
  }
}

export function saveCampaignToGroup(targetCampaign) {
  return function (dispatch, getState) {
    let campaign = getState().campaign
    let group = getState().group
    group.memberUuids = [...new Set([campaign.UUID, targetCampaign.UUID].concat(group.memberUuids))]
    return save(getOpts(dispatch, group, "POST", campaignGroupURL, null, null, getState()))
      .then(() => {
        dispatch(retrieveCampaigns(campaign.UUID, true))
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Saving campaign to group", () => dispatch(retrieveCampaigns(campaign.UUID)))
        }
      })
  }
}

export function retrieveCampaignGroup(groupUUID) {
  return function (dispatch, getState) {
    if (groupUUID == NULLUUID) {
      return dispatch({
        type: ActionTypes.GROUP_SELECTED,
        data: { uuid: NULLUUID, memberUuids: [] }
      })
    }
    return retrieve(campaignGroupURL, 0, 0, groupUUID, dispatch, getState())
      .then(res =>
        dispatch({
          type: ActionTypes.GROUP_SELECTED,
          data: res
        })
      )
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting campaign group")
        }
      })
  }
}

export function retrieveSalespeople() {
  return function (dispatch, getState) {
    return retrieve(personURL, 0, 0, null, dispatch, getState(), "type=3")
      .then(res =>
        dispatch({
          type: ActionTypes.SALESPEOPLE_LOADED,
          data: res
        })
      )
      .catch(e => {
        exceptionHandling(e, dispatch, "Getting salespeople")
      })
  }
}

export function retrieveCampaigns(uuid, avoidRedirection) {
  return function (dispatch, getState) {
    avoidRedirection = avoidRedirection || false
    dispatch({ type: ActionTypes.IS_DIRTY, data: false })
    if (!uuid || uuid.length == 0) {
      dispatch({ type: ActionTypes.CAMPAIGNS_START_LOADING })

      fetchWithRetry(
        `${campaignURL}all?thin=yes&include_archived=no&include_legacy_archived=no`,
        request("GET", null, getState(), "application/json"),
        false,
        2
      )
        .then(response => handleResponse(response, dispatch))
        .then(response => {
          let cMap = response.data.records.reduce((prev, curr) => {
            prev[curr.UUID] = curr
            return prev
          }, {})
          dispatch({ type: ActionTypes.CAMPAIGN_MAP_LOADED, data: cMap })
          dispatch({ type: ActionTypes.CAMPAIGNS_BATCH_LOADED, data: response.data.records })
          dispatch({ type: ActionTypes.CAMPAIGNS_LOADED })
        })
        .catch(e => {
          if (!isNotFoundError(e)) {
            console.log(e)
            exceptionHandling(e, dispatch, "Getting campaigns")
          }
        })
      return
    }
    dispatch({ type: ActionTypes.CREATIVEPREVIEW_UPDATED, data: { data: { renderedCreative: "" } } })
    return retrieve(campaignURL, 0, 0, uuid, dispatch, getState())
      .then(res => {
        dispatch(retrieveQuestions(uuid))
        dispatch(retrieveCreative(res.creativeUUID))
        dispatch(retrieveCampaignGroup(res.groupUuid))
        dispatch(retrieveLeadDeliveryConfigs(uuid))
        dispatch(cancelLeadDeliveryConfig())
        if (!avoidRedirection) {
          dispatch(redirectToCampaign(uuid))
        }
        dispatch({ type: ActionTypes.CAMPAIGN_SELECTED, data: res })
      })
      .catch(e => {
        if (isNotFoundError(e)) {
          dispatch({ type: ActionTypes.CAMPAIGN_NOT_FOUND })
        } else {
          exceptionHandling(e, dispatch, "Getting campaign")
        }
      })
  }
}

export function setCrud(crud) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.CRUD_UPDATED, data: crud })
  }
}

export function newCampaign(defaultValidators) {
  return function (dispatch) {
    const normalizedDefaultValidators = Object.keys(defaultValidators).map(v => {
      return { name: v }
    })
    dispatch(redirectToCampaign(""))
    dispatch({
      type: ActionTypes.CAMPAIGN_SELECTED,
      data: Object.assign({ validators: normalizedDefaultValidators }, newCampaignTemplate)
    })
    dispatch({ type: ActionTypes.EMAIL_VALIDATION_LOADED, data: getDefaultEmailConfiguration("") })
    dispatch({ type: ActionTypes.CAMPAIGN_CRITERIA_LOADED, data: { criteriaFilter: {} } })
  }
}

export function redirectToCampaign(uuid) {
  return function () {
    history.push(`/campaignmanager/${uuid}`)
  }
}

function cleanCampaign(c) {
  c.leadCounts = c.leadCounts || []
  delete c["element"]
  c.leadCounts = c.leadCounts.map(lc => {
    delete lc["active"]
    delete lc["color"]
    delete lc["scheduled"]
    delete lc["idx"]
    lc.effectiveDate = parseInt(lc.effectiveDate)
    lc.limit = parseInt(lc.limit)
    return lc
  })
  c.rates = c.rates || []
  c.rates = c.rates.map(lc => {
    delete lc["active"]
    delete lc["color"]
    delete lc["scheduled"]
    delete lc["idx"]
    lc.effectiveDate = parseInt(lc.effectiveDate)
    lc.rate = parseFloat(lc.rate)
    return lc
  })
  c.leadAcceptancePercentages = c.leadAcceptancePercentages || []
  c.leadAcceptancePercentages = c.leadAcceptancePercentages.map(lc => {
    delete lc["active"]
    delete lc["color"]
    delete lc["scheduled"]
    delete lc["idx"]
    lc.effectiveDate = parseInt(lc.effectiveDate)
    lc.value = parseFloat(lc.value)
    return lc
  })

  c.contractedLeadCaps = c.contractedLeadCaps || []
  c.contractedLeadCaps = c.contractedLeadCaps.map(lc => {
    delete lc["active"]
    delete lc["color"]
    delete lc["scheduled"]
    delete lc["idx"]
    lc.effectiveDate = parseInt(lc.effectiveDate)
    lc.value = parseFloat(lc.value)
    return lc
  })

  return c
}

export function saveCampaign(pCampaign, callBacks, avoidRedirection) {
  return function (dispatch, getState) {
    let campaign = pCampaign || getState().campaign
    let campaignUUID = (campaign.UUID ? campaign.UUID : "")
    let url = campaignURL + campaignUUID
    let verb = campaign.UUID ? "PUT" : "POST"
    let refresh = !pCampaign ? ActionTypes.CAMPAIGN_SELECTED : null
    return save(getOpts(dispatch, campaign, verb, url, refresh, c => (c = cleanCampaign(c)), getState()))
      .then(res => {
        let criteria = getState().campaignCriteria
        criteria.campaignUuid = res.data.UUID
        dispatch(saveCampaignCriteriaConfig(criteria))
        callBacks.forEach(cb => {
          cb(res.data)
        })
        if (!avoidRedirection) dispatch(redirectToCampaign(res.data.UUID))
      })

      .catch(e => exceptionHandling(e, dispatch, "Saving campaign"), () => dispatch(retrieveCampaigns(campaignUUID)))
  }
}

//BadWords
export function testBadWord(word) {
  return function (dispatch, getState) {
    dispatch({
      type: ActionTypes.TESTING_BADWORD_LOADING,
      data: { words: getState().badwords.words, testResult: getState().badwords.testResult, isLoading: true }
    })
    return retrieve(testBadwordsURL, 0, 0, null, dispatch, getState(), `word=${encodeURIComponent(word)}`)
      .then(res => {
        dispatch({
          type: ActionTypes.BADWORDS_LOADED,
          data: { words: getState().badwords.words, testResult: res, isLoading: false }
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Testing bad word")
        }
      })
  }
}

export function deleteBadWord(word) {
  return function (dispatch, getState) {
    let url = badwordsURL + `${encodeURIComponent(word)}/`
    return del(dispatch, url, null, null, getState())
      .then(() => dispatch(retrieveBadWords()))
      .catch(e => exceptionHandling(e, dispatch, "Deleting bad word"), () => dispatch(retrieveBadWords()))
  }
}

export function retrieveBadWords() {
  return function (dispatch, getState) {
    return retrieve(badwordsURL, 0, 0, null, dispatch, getState())
      .then(res => {
        dispatch({
          type: ActionTypes.BADWORDS_LOADED,
          data: { words: res, testResult: getState().badwords.testResult, isLoading: false }
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting bad words")
        }
      })
  }
}

export function addBadWord(newWordObj) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, newWordObj, "POST", badwordsURL, null, null, getState()))
      .then(() => {
        dispatch(retrieveBadWords())
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving badword"), () => dispatch(retrieveBadWords()))
  }
}

//Cake
export function uploadCakeCreative(cakeCreative) {
  if (cakeCreative === "") {
    return
  }
  let cakeFuncName = Math.random()
    .toString(16)
    .substring(2)
  let fileContent = `!function(n,d){n.PD_${cakeFuncName}=function(n){d.write(${JSON.stringify(
    cakeCreative
  )}.replace(/#url#/gi,n)),d.close()}}(window,document);`
  let filePath = `${aws.bucketPath}/${v4()}.js`
  let message = ""
  return function (dispatch) {
    aws.uploadS3(filePath, fileContent, cakeFuncName, (s, err) => {
      if (err !== "undefined") {
        message = `<script src="http://${aws.bucket}/${filePath}"></script>\r\n<script>!function(){PD_${cakeFuncName}("#url#")}();</script>`
        return
      }
      message = err
    })
    dispatch(ShowSnack(message))
  }
}

//Sorting
export function retrieveRankers(uuid) {
  return function (dispatch, getState) {
    return retrieve(rankersURL, 0, 0, uuid, dispatch, getState())
      .then(res => {
        let rankers = Object.keys(res).map(currentVal => {
          return { value: res[currentVal].id, name: res[currentVal].displayName }
        })
        dispatch({ type: ActionTypes.RANKERS_LOADED, data: rankers })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting rankers")
        }
      })
  }
}

//Validation
export function retrieveValidators(uuid) {
  return function (dispatch, getState) {
    return retrieve(valcorURL, 0, 0, uuid, dispatch, getState(), "verbose=true")
      .then(res => {
        var i = 0
        dispatch({
          type: ActionTypes.VALIDATORS_LOADED,
          data: res.map(currentVal => {
            let v = {}
            v.value = i++
            v.prevalidation = currentVal.prevalidation
            v.publisherSupported = currentVal.publisher_supported
            v.name = currentVal.campaign_validation_name
            return v
          })
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting validators")
        }
      })
  }
}

export function resync() {
  // internal-edge call
}

//Default Validation
export function retrieveDefaultValidators() {
  return function (dispatch, getState) {
    return retrieve(defaultValidationURL, 0, 0, "", dispatch, getState())
      .then(res => {
        let m = {}
        if (res.validators) {
          res.validators.forEach(v => {
            m[v.name] = true
          })
        }
        dispatch({
          type: ActionTypes.DEFAULT_VALIDATORS_LOADED,
          data: m
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting default validators")
        }
      })
  }
}

export function putDefaultValidator(validator) {
  return function (dispatch, getState) {
    let opts = getOpts(dispatch, "", "PUT", defaultValidationURL + validator, null, null, getState())
    return save(opts)
      .then(() => {
        dispatch(retrieveDefaultValidators())
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving default validator"), () => dispatch(retrieveDefaultValidators()))
  }
}

export function deleteDefaultValidator(validation) {
  return function (dispatch, getState) {
    return del(dispatch, defaultValidationURL, validation, null, getState())
      .then(() => {
        dispatch(retrieveDefaultValidators())
      })
      .catch(e => exceptionHandling(e, dispatch, "Deleting default validator"), () => dispatch(retrieveDefaultValidators()))
  }
}

export function disableValidator(validatorName) {
  return function (dispatch, getState) {
    let opts = getOpts(dispatch, "", "PUT", disableValidationURL + validatorName, null, null, getState())
    return save(opts)
      .then(() => {
        dispatch(retrieveCampaignsValidationInfo())
      })
      .catch(e => exceptionHandling(e, dispatch, "Disabling validator"), () => dispatch(retrieveDefaultValidators()))
  }
}

export function retrieveCampaignsValidationInfo() {
  return function (dispatch, getState) {
    let opts = getOpts(dispatch, { records: [] }, "POST", validationDataURL, null, null, getState(), "", DISABLE_SNACK)
    return save(opts)
      .then(response => {
        dispatch({ type: ActionTypes.VALIDATORS_DATA_LOADED, data: response.data.records })
      })
      .catch(e => {
        exceptionHandling(e, dispatch, "Retrieving campaigns validation information")
      })
  }
}

//ZipCodes
export function searchZips(queryData) {
  return function (dispatch, getState) {
    let opts = getOpts(dispatch, queryData, "POST", zipCodesURL, null, null, getState())
    opts.successMessage = DISABLE_SNACK
    return save(opts)
      .then(response => {
        return extractData(response)
      })
      .then(response => {
        dispatch({ type: ActionTypes.ZIPS_LOADED, data: response })
      })
      .catch(e => exceptionHandling(e, dispatch, "Searching zips"))
  }
}

export function clearZips() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ZIPS_LOADED, data: [] })
  }
}

export function saveCampaignCriteriaConfig(campaignCriteriaConfig) {
  return function (dispatch, getState) {
    let url = criteriaURL + (campaignCriteriaConfig.uuid || "")
    return save(getOpts(dispatch, campaignCriteriaConfig, "POST", url, null, null, getState()))
      .then(() => {
        dispatch(retrieveCampaignCriteria(campaignCriteriaConfig.campaignUuid))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving campaign criteria"), () => dispatch(retrieveCampaignCriteria(campaignCriteriaConfig.campaignUuid)))
  }
}

export function ShowSnack(msg) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.SNACK_UPDATED,
      data: { isActive: true, message: msg }
    })
  }
}


//winbacks
export function testWinback(lead, verbose) {
  return function (dispatch, getState) {
    let verboseQ = verbose ? "verbose=true" : ""
    let opts = getOpts(dispatch, lead, "POST", winbackTestURL, null, null, getState(), verboseQ, DISABLE_SNACK)
    customFetch(opts.url + "?" + opts.query, request(opts.verb, JSON.stringify(opts.model), opts.state))
      .then(
        response =>
          new Promise(resolve => {
            let r = {}
            try {
              r.data = JSON.parse(response.responseText)
              dispatch({ type: ActionTypes.WINBACK_CAMPAIGN_UPDATED, data: r.data.records })
              resolve(r)
            } catch (err) {
              r.data = response.responseText
              r.rawResp = response
              dispatch({ type: ActionTypes.WINBACK_ERROR, data: r })
              resolve(r)
            }
          })
      )
      .catch(e => {
        let r = JSON.parse(e.body)
        dispatch({ type: ActionTypes.WINBACK_ERROR, data: r })
      })
  }
}

export function retrieveWinbacks() {
  return function (dispatch, getState) {
    return retrieve(winbackURL, 0, 0, null, dispatch, getState())
      .then(res => {
        dispatch({ type: ActionTypes.WINBACK_LOADED, data: res.records })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting winbacks")
        }
      })
  }
}

export function deleteWinback(winbackUUID) {
  return function (dispatch, getState) {
    return del(dispatch, winbackURL, winbackUUID, null, getState())
      .then(() => dispatch(retrieveWinbacks()))
      .catch(e => exceptionHandling(e, dispatch, "Deleting winback"))
  }
}

export function saveWinback(winback) {
  return function (dispatch, getState) {
    let url = winbackURL + (winback.uuid || "")
    return save(getOpts(dispatch, winback, "POST", url, null, null, getState()))
      .then(response => {
        return extractData(response)
      })
      .then(response => {
        dispatch(retrieveWinbacks())
        return response
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving winback"))
  }
}

export function retrieveCampaignRequests() {
  return function (dispatch, getState) {
    return retrieve(campaignRequestURL, 9999, 0, null, dispatch, getState())
      .then(data => dispatch({ type: ActionTypes.CAMPAIGN_REQUESTS_LOADED, data: data }))
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting campaign requests")
        }
      })
  }
}

export function saveCampaignRequest(campaignRequest) {
  return function (dispatch, getState) {
    return save(
      getOpts(
        dispatch,
        campaignRequest,
        "POST",
        campaignRequestURL,
        null,
        cr => {
          cr.budget = parseFloat(cr.budget)
          cr.rate = parseFloat(cr.rate)
          cr.leadAcceptancePercentage = parseFloat(cr.leadAcceptancePercentage || 0)
          cr.maxScrubPercentage = parseFloat(cr.maxScrubPercentage || 0)
          cr.leadsInContract = parseFloat(cr.leadsInContract || 0)
          cr.clientCap = parseFloat(cr.clientCap || 0)
          cr.startDate = cr.startDate ? cr.startDate.unix() : 0
          cr.endDate = cr.endDate ? cr.endDate.unix() : 0
          cr.approvedAmount = parseFloat(cr.approvedAmount || 0)
          cr.creditRemaining = parseFloat(cr.creditRemaining || 0)
          cr.creativeUuid = cr.creativeUuid || NULLUUID
          delete cr["approvalRequired"]
          if (cr.competitorUuids && cr.competitorUuids.length && cr.competitorUuids.length == 0) {
            delete cr["competitorUuids"]
          }
          delete cr["element"]
          return cr
        },
        getState()
      )
    )
      .then(crf => {
        dispatch(retrieveCampaignRequests())
        dispatch({ type: ActionTypes.CAMPAIGN_REQUEST_SELECTED, data: crf })
        dispatch({ type: ActionTypes.IS_DIRTY, data: false })
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving campaign request"), () => dispatch(retrieveCampaignRequests()))
  }
}

export function campaignRequestSelected(campaignRequest) {
  return function (dispatch) {
    dispatch(retrieveCreative(campaignRequest.creativeUUID))
    dispatch({ type: ActionTypes.CAMPAIGN_REQUEST_SELECTED, data: campaignRequest })
  }
}

export function resetCampaignRequestSelected() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.RESET_CAMPAIGN_REQUEST, data: {} })
  }
}

//dedupe
function startSuppressionListUpload(campaignName, fileName, emailsCount) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.START_SUPPRESSION_LIST_FILE_UPLOAD,
      data: {
        campaignName: campaignName,
        fileName: fileName,
        emailsCount: emailsCount
      }
    })
  }
}

function finishSuppresionListUpload() {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.FINISH_SUPPRESSION_LIST_FILE_UPLOAD,
      data: {}
    })
  }
}

function updateProgress(value) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.UPDATE_SUPPRESSION_LIST_FILE_UPLOAD_BAR,
      data: value
    })
  }
}

export function resetSuppressionListUpload() {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.RESET_SUPPRESSION_LIST_UPLOAD
    })
  }
}

function updateSuppressionUploadError(errors) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.UPDATE_SUPPRESSION_UPLOAD_ERRORS,
      data: errors
    })
  }
}

function uploadFileByBurst(emails, campaignUUID) {
  return function (dispatch, getState) {
    dispatch(updateProgress(0))
    let url = hashLeadURL
    let payLoads = []
    const burstSize = 1500
    const burstCount = Math.round(emails.length / burstSize)
    const progressRate = burstCount > 100 ? Math.round((100 / burstCount) * 1000) / 1000 : Math.round(100 / burstCount)
    for (let i = 0; i < emails.length;) {
      payLoads.push({
        emails: emails.slice(i, i + burstSize),
        campaign_uuid: campaignUUID
      })
      i += burstSize
    }
    function runSequentially(payloads) {
      let progress = progressRate
      let sequence = Promise.resolve()
      payloads.forEach(payLoad => {
        sequence = sequence
          .then(() => {
            return save(getOpts(dispatch, payLoad, "POST", url, null, null, getState(), null, DISABLE_SNACK))
          })
          .then(result => {
            dispatch(updateProgress(progress))
            progress += progressRate
            if (result.data && result.data.errors.length > 0) {
              dispatch(updateSuppressionUploadError(result.data.errors))
            }
          })
          .catch(() => {
            dispatch(updateSuppressionUploadError(payLoad.emails))
          })
      })
      return sequence
    }
    runSequentially(payLoads).then(() => {
      dispatch(updateProgress(100))
      dispatch(finishSuppresionListUpload())
    })
  }
}

export function uploadSuppressionList(emails, campaignUUID, campaignName, fileName) {
  return function (dispatch) {
    dispatch(ShowSnack("File upload started, please don't close the tab during the file upload"))
    dispatch(startSuppressionListUpload(campaignName, fileName, emails.length))
    dispatch(uploadFileByBurst(emails, campaignUUID))
  }
}

// campaignUUIDs: []
// endDate: "2022-05-31 23:59:59"
// orderBy: "insertedAt"
// orderByDescending: false
// placementUUIDs: []
// reviewCodes: (3) [0, 1, 2]
// rowLimit: 100
// rowOffset: undefined
// startDate: "2022-04-01 00:00:00"
// testFilter: 0
// timeZone: "America/New_York"
// validationCodes: []
export function loadedOptinReviewerSummaries(criteria, reviewables) {
  return function (dispatch, getState) {
    let endDate = moment(criteria.endDate)
    let startDate = moment(criteria.startDate)
    let promises = []
    reviewables.records.map(e => {
      let retrieval = retrieve(leadCountsURL, 0, 0, e.campaignUUID, dispatch, getState(), `placement=${e.placementUUID}&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`)
      promises.push(retrieval.then(res => {
        return {
          count: res.count,
          campaignUUID: e.campaignUUID,
          placementUUID: e.placementUUID
        }
      }))
    })
    return Promise.all(promises)
      .then(res => {
        dispatch({ type: ActionTypes.QUARANTINE_SUMMARY_STATS_LOADED, data: res })
      })
      .catch(e => {
        dispatch({ type: ActionTypes.QUARANTINE_SUMMARY_STATS_ERROR })
        exceptionHandling(e, dispatch, "Getting Summary Stats")
      })
  }
}
//inspector
export function inspect(search) {
  return function (dispatch, getState) {
    let sessions = []
    sessions.addIf = i => {
      if (i.session_uuid) {
        sessions = sessions.concat(i)
      }
    }
    let leads = []
    leads.addIf = i => {
      if (i.uuid) {
        leads = leads.concat(i)
      }
    }
    let searchInput = search.trim()
    let promises = []
    if (validEmail(searchInput)) {
      promises.push(retrieve(sessionByEmailURL + search.trim(), 0, 0, null, dispatch, getState()).then(sessions.addIf))
    } else if (searchInput.length == NULLUUID.length) {
      promises.push(retrieve(leadsURL + search.trim(), 0, 0, null, dispatch, getState()).then(leads.addIf))
      promises.push(
        retrieve(sessionBySessionURL + search.trim(), 0, 0, null, dispatch, getState()).then(sessions.addIf)
      )
    }
    if (promises.length == 0) {
      dispatch(ShowSnack("Please check the format of your search and provide a valid email or UUID"))
      return
    }
    return Promise.all(promises)
      .then(() => {
        let inspector = getState().inspector
        inspector.sessions = sessions.map(s => {
          return {
            uuid: s.session_uuid,
            user: s.user,
            primaryContent: s.session_uuid,
            secondaryContent: s.user.first_name + " " + s.user.last_name + " | " + s.user.email + " | " + s.user.ip
          }
        })
        inspector.leads = leads.map(s => {
          return {
            uuid: s.session_uuid,
            user: s.user,
            primaryContent: s.session_uuid,
            secondaryContent: s.user.first_name + " " + s.user.last_name + " | " + s.user.email + " | " + s.user.ip
          }
        })
        dispatch({ type: ActionTypes.INSPECTOR_UPDATED, data: inspector })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Inspecting")
        }
      })
  }
}

export function retrieveTraces(sessionUUID) {
  return function (dispatch, getState) {
    return retrieve(traceSessionURL, 0, 0, sessionUUID, dispatch, getState())
      .then(response => {
        let inspector = getState().inspect
        inspector.traces = response.records
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting traces")
        }
      })
  }
}

//creatives
export function retrieveCreative(uuid) {
  return function (dispatch, getState) {
    if (uuid == NULLUUID || !uuid || uuid.length == 0) {
      dispatch({ type: ActionTypes.CREATIVE_UPDATED, data: {} })
      return
    }
    return retrieve(creativeURL, 0, 0, uuid, dispatch, getState())
      .then(res => {
        dispatch(retrieveCreativePreview(res))
        dispatch({ type: ActionTypes.CREATIVE_UPDATED, data: res })
      })
      .catch(e => {
        console.log(`e:`)
        console.log(e)
        
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting creative")
        }
      })
  }
}

export function updateCreative(creative) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.CREATIVE_UPDATED, data: creative })
  }
}

export function saveCreative(creative, cb) {
  return function (dispatch, getState) {
    let method = "POST"
    let url = creativeURL
    if (creative.uuid && creative.uuid.length > 0 && creative.uuid !== NULLUUID) {
      method = "PUT"
      url += creative.uuid
    }
    return save(getOpts(dispatch, creative, method, url, null, null, getState()))
      .then(response => {
        return extractData(response)
      })
      .then(res => {
        cb(res)
        return res
      })
      .then(res => dispatch(retrieveCreative(res.uuid)))
      .catch(e => {
        console.log(`e:`)
        console.log(e)
        
        exceptionHandling(e, dispatch, "Saving creative")
        dispatch(retrieveCreative(creative.uuid))
      })
  }
}

export function retrieveCreativePreview(creative) {
  return function (dispatch, getState) {
    return customFetch(testCreativeURL, request("POST", JSON.stringify(creative), getState()))
      .then(response => handleResponse(response, dispatch))
      .then(res => dispatch({ type: ActionTypes.CREATIVEPREVIEW_UPDATED, data: res }))
      .catch(e => exceptionHandling(e, dispatch))
  }
}

export function clearPreview() {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.PREPINGPREVIEW_LOADED,
      data: {}
    })
  }
}

export function testCustomValidation(lead) {
  return function (dispatch, getState) {
    lead.campaignUuid = getState().campaign.UUID
    let opts = getOpts(dispatch, lead, "POST", customValidationTestURL, null, null, getState(), "", DISABLE_SNACK)
    customFetch(opts.url, request(opts.verb, JSON.stringify(opts.model), opts.state))
      .then(
        response =>
          new Promise(resolve => {
            let r = {}
            // we want to handle unmarshalling here to default to the response text if we don't have a valid json response
            // this is to avoid exceptions and avoiding subsequent catches
            try {
              r.data = JSON.parse(response.responseText)
              r.rawResp = response
              resolve(r)
            } catch (err) {
              r.data = response.responseText
              r.rawResp = response
              resolve(r)
            }
          })
      )
      .then(res => {
        let data = res.data
        dispatch({
          type: ActionTypes.CUSTOM_VALIDATION_RESULTS_LOADED,
          data: data
        })
      })
      .catch((e) => {
        let data = { validationResult: {} }
        data.validationResult.detailColor = "red"
        data.validationResult.message = "Error testing the script"
        if (e.body.message) {
          data.validationResult.details = e.body.message
        }
        dispatch({
          type: ActionTypes.CUSTOM_VALIDATION_RESULTS_LOADED,
          data: data
        })
      })
  }
}

export function testPreping(lead) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, lead, "POST", prepingURL, null, null, getState(), "test=1", DISABLE_SNACK))
      .then(response => {
        if (response.data.validationResult) {
          response.data.validationMessage = response.data.validationResult.message
          response.data.isValid = response.data.validationResult.isValid
        }
        response.data.pinging = false
        response.data.showResults = true
        response.data.responseOK = response.rawResp.status == 200
        response.data.rawResults =
          "statusCode: " + response.rawResp.status + " \nstatusText:" + response.rawResp.statusText

        let opts = getOpts(dispatch, lead, "POST", prepingPreviewURL, null, null, getState(), "", DISABLE_SNACK)

        customFetch(opts.url, request(opts.verb, JSON.stringify(opts.model), opts.state))
          .then(
            response =>
              new Promise(resolve => {
                let r = {}
                // we want to handle unmarshalling here to default to the response text if we don't have a valid json response
                // this is to avoid exceptions and avoiding subsequent catches
                try {
                  r.data = JSON.parse(response.responseText)
                  r.rawResp = response
                  resolve(r)
                } catch (err) {
                  r.data = response.responseText
                  r.rawResp = response
                  resolve(r)
                }
              })
          )
          .then(res => {
            let data = res.data
            response.data.message = data.message
            dispatch({
              type: ActionTypes.PREPINGPREVIEW_LOADED,
              data: response.data
            })
          })
          .catch(e => {
            let data = {}
            data.pinging = false
            data.showResults = true
            data.responseOK = false
            data.rawResults = "statusCode: " + e.status + " \nstatusText:" + e.statusText
            data.message = "Error previewing the configured URL"
            dispatch({
              type: ActionTypes.PREPINGPREVIEW_LOADED,
              data: data
            })
          })
      })
      .catch(e => {
        let data = {}
        data.pinging = false
        data.showResults = true
        data.responseOK = false
        data.rawResults = "statusCode: " + e.status + " \nstatusText:" + e.statusText
        data.message = "Error connecting to the configured URL"
        dispatch({
          type: ActionTypes.PREPINGPREVIEW_LOADED,
          data: data
        })
      })
  }
}

export function login() {
  return function () {
    localStorage.removeItem("state")
    localStorage.removeItem("nonce")
    localStorage.removeItem("user")
    localStorage.removeItem("jwt")
    let clientInfo = {
      client_id: "AdquireManager",
      redirect_uri: window.location.origin || `${window.location.protocol}//${window.location.host}`,
      response_type: "code id_token+token",
      authorization_endpoint: "https://kc.adquire.com:8443/auth"
    }
    OIDC.setClientInfo(clientInfo)
    let providerInfo = OIDC.discover("https://kc.adquire.com:8443/realms/AdQuire")
    OIDC.setProviderInfo(providerInfo)
    OIDC.storeInfo(providerInfo, clientInfo)
    let loginRequest = OIDC.generateLoginRequest({
      response_type: "id_token token"
    })
    localStorage["session_ret_url"] = window.location.pathname + window.location.search
    window.location.replace(loginRequest.url)
  }
}

//checks the return URL frmo keycloak and grabs the token parts from the URL via the getValidIDToken call
export function check(history) {
  return function (dispatch) {
    let id_token = null
    OIDC.restoreInfo()
    if (localStorage["jwt"]) {
      id_token = localStorage["jwt"]
      let parts = OIDC.getIdTokenParts(id_token)
      let tokenClaims = JSON.parse(parts[1])
      localStorage["user"] = JSON.stringify(tokenClaims)
      localStorage["expiration"] = tokenClaims.exp
      localStorage["jwt"] = id_token
      dispatch({ type: ActionTypes.JWT_LOADED, data: id_token })
      dispatch({ type: ActionTypes.USER_LOADED, data: tokenClaims })
      return
    } else {
      try {
        id_token = OIDC.getValidIdToken()
      } catch (e) {
        return
      }
    }
    let parts = OIDC.getIdTokenParts(id_token)
    let tokenClaims = JSON.parse(parts[1])
    localStorage["user"] = JSON.stringify(tokenClaims)
    localStorage["expiration"] = tokenClaims.exp
    localStorage["jwt"] = id_token
    dispatch({ type: ActionTypes.JWT_LOADED, data: id_token })
    dispatch({ type: ActionTypes.USER_LOADED, data: tokenClaims })
    let redirURL = localStorage["session_ret_url"]
    if (redirURL.length <= 1) {
      history.push("/campaignmanager")
    } else {
      history.push(redirURL)
    }
  }
}

export function deleteBlockedEmailDomain(domainName) {
  return function (dispatch, getState) {
    let url = blockedEmailDomainsURL + `${encodeURIComponent(domainName)}/`
    return del(dispatch, url, null, null, getState())
      .then(() => {
        dispatch(retrieveAllBlockedEmailDomains())
      })
      .catch(e => exceptionHandling(e, dispatch, "Deleting blocked email domain"), () => dispatch(retrieveAllBlockedEmailDomains()))
  }
}

export function deleteAllBlockedEmailDomains() {
  return function (dispatch, getState) {
    let url = blockedEmailDomainsBatchDeleteURL
    return del(dispatch, url, null, null, getState())
      .then(() => dispatch(retrieveAllBlockedEmailDomains()))
      .catch(e => exceptionHandling(e, dispatch, "Deleting all blocked domains"), () => dispatch(retrieveAllBlockedEmailDomains()))
  }
}

export function updateBlockedEmailDomain(domain) {
  return function (dispatch, getState) {
    let url = blockedEmailDomainsURL + `${encodeURIComponent(domain.name)}/`
    return save(getOpts(dispatch, domain, "PUT", url, null, null, getState()))
      .then(() => {
        dispatch(retrieveAllBlockedEmailDomains())
      })
      .catch(e => exceptionHandling(e, dispatch, "Updating blocked email domain"), () => dispatch(retrieveAllBlockedEmailDomains()))
  }
}

export function retrieveAllBlockedEmailDomains() {
  return function (dispatch, getState) {
    retrieve(blockedEmailDomainsURL, 1000000, 0, null, dispatch, getState())
      .then(res => {
        let domainList = res
        domainList.Domains.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        dispatch({
          type: ActionTypes.BLOCKED_EMAIL_DOMAINS_LOADED,
          data: { data: domainList.Domains, available: domainList.Available }
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting all blocked domains")
        }
      })
  }
}

export function replaceBlockedEmailDomains(domains) {
  return function (dispatch, getState) {
    let url = blockedEmailDomainsBatchUpdateURL
    return save(getOpts(dispatch, domains, "POST", url, null, null, getState(), "replace=true"))
      .then(() => {
        dispatch(retrieveAllBlockedEmailDomains())
      })
      .catch(e => exceptionHandling(e, dispatch, "Replacing blocked email domains"), () => dispatch(retrieveAllBlockedEmailDomains()))
  }
}

export function appendBlockedEmailDomains(domains) {
  return function (dispatch, getState) {
    let url = blockedEmailDomainsBatchUpdateURL
    return save(getOpts(dispatch, domains, "POST", url, null, null, getState(), "replace=false"))
      .then(() => {
        dispatch(retrieveAllBlockedEmailDomains())
      })
      .catch(e => exceptionHandling(e, dispatch, "Appending blocked email domains"), () => dispatch(retrieveAllBlockedEmailDomains()))
  }
}

export function deleteBlockedEmail(email) {
  return function (dispatch, getState) {
    let url = blockedEmailURL + `${encodeURIComponent(email)}/`
    return del(dispatch, url, null, null, getState())
      .then(() => {
        dispatch(retrieveBlockedEmail(1))
      })
      .catch(e => exceptionHandling(e, dispatch, "Deleting blocked email"), () => dispatch(retrieveBlockedEmail(1)))
  }
}

export function deleteAllBlockedEmails() {
  return function (dispatch, getState) {
    let url = blockedEmailBatchDeleteURL
    return del(dispatch, url, null, null, getState())
      .then(() => dispatch(retrieveBlockedEmail(1)))
      .catch(e => exceptionHandling(e, dispatch, "Deleting all blocked emails"), () => dispatch(retrieveBlockedEmail(1)))
  }
}

export function updateBlockedEmail(email) {
  return function (dispatch, getState) {
    let url = blockedEmailURL + `${encodeURIComponent(email.name)}/`
    return save(getOpts(dispatch, email, "PUT", url, null, null, getState()))
      .then(() => {
        dispatch(retrieveBlockedEmail(1))
      })
      .catch(e => exceptionHandling(e, dispatch, "Updating blocked email"), () => dispatch(retrieveBlockedEmail(1)))
  }
}

export function retrieveBlockedEmail(page) {
  let emailsPerCycle = 350
  return function (dispatch, getState) {
    let offset = (page - 1) * emailsPerCycle
    retrieve(blockedEmailURL, emailsPerCycle, offset, null, dispatch, getState())
      .then(res => {
        let emailList = res
        emailList.emails.sort((a, b) => {
          return a.address.localeCompare(b.email_address)
        })
        dispatch({
          type: ActionTypes.BLOCKED_EMAILS_LOADED,
          data: { data: emailList.emails, available: emailList.available }
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting blocked email")
        }
      })
  }
}

export function validateBlockedEmail(email) {
  return function (dispatch, getState) {
    dispatch({ type: ActionTypes.BLOCKED_EMAIL_VALIDATION_START })
    customFetch(blockedEmailValidateURL, request("POST", JSON.stringify({ user: { email: email } }), getState()))
      .then(response => handleResponse(response, dispatch))
      .then(response => {
        dispatch({ type: ActionTypes.BLOCKED_EMAIL_VALIDATION_RESULT, data: response.data })
      }
      )
      .catch(e => {
        exceptionHandling(e, dispatch, "Validating email")
      })
  }
}

export function validateAreaCode(code) {
  return function (dispatch, getState) {
    customFetch(
      blockedAreaCodeValidateURL,
      request("POST", JSON.stringify({ user: { phone: parseInt(`${code}4322345`, 10) } }), getState())
    )
      .then(response => handleResponse(response, dispatch))
      .then(response =>
        dispatch({
          type: ActionTypes.BLOCKED_AREA_CODE_VALIDATION_RESULT,
          data: response.data
        })
      )
      .catch(e => exceptionHandling(e, dispatch))
  }
}

export function replaceBlockedEmail(email) {
  return function (dispatch, getState) {
    let url = blockedEmailBatchUpdateURL
    return save(getOpts(dispatch, email, "POST", url, null, null, getState(), "replace=true"))
      .then(() => {
        dispatch(retrieveBlockedEmail(1))
      })
      .catch(e => exceptionHandling(e, dispatch, "Replacing blocked email"))
  }
}

export function appendBlockedEmail(email) {
  return function (dispatch, getState) {
    let url = blockedEmailBatchUpdateURL
    return save(getOpts(dispatch, email, "POST", url, null, null, getState(), "replace=false"))
      .then(() => {
        dispatch(retrieveBlockedEmail(1))
      })
      .catch(e => exceptionHandling(e, dispatch, "Appending blocked email"), () => dispatch(retrieveBlockedEmail(1)))
  }
}

export function deleteAreaCode(areacode) {
  return function (dispatch, getState) {
    let url = blockedAreaCodeURL + `${encodeURIComponent(areacode)}/`
    return del(dispatch, url, null, null, getState())
      .then(() => {
        dispatch(retrieveAreaCodes(1))
      })
      .catch(e => exceptionHandling(e, dispatch, "Deleting areacode"), () => dispatch(retrieveAreaCodes(1)))
  }
}

export function deleteAllAreaCodes() {
  return function (dispatch, getState) {
    let url = blockedAreaCodeBatchDeleteURL
    return del(dispatch, url, null, null, getState())
      .then(() => dispatch(retrieveAreaCodes(1)))
      .catch(e => exceptionHandling(e, dispatch, "Deleting all areacodes"), () => dispatch(retrieveAreaCodes(1)))
  }
}

export function updateAreaCode(areacode) {
  return function (dispatch, getState) {
    let url = blockedAreaCodeURL + `${encodeURIComponent(areacode.areacode)}/`
    return save(getOpts(dispatch, areacode, "PUT", url, null, null, getState()))
      .then(() => {
        dispatch(retrieveAreaCodes(1))
      })
      .catch(e => exceptionHandling(e, dispatch, "Updating Areacode"), () => dispatch(retrieveAreaCodes(1)))
  }
}

export function retrieveAreaCodes(page) {
  let perCycle = 350
  return function (dispatch, getState) {
    let offset = (page - 1) * perCycle
    retrieve(blockedAreaCodeURL, perCycle, offset, null, dispatch, getState())
      .then(res => {
        let areacodeList = res
        areacodeList.AreaCodes.sort((a, b) => {
          return a.areacode.localeCompare(b.areacode)
        })
        dispatch({
          type: ActionTypes.BLOCKED_AREA_CODES_LOADED,
          data: { data: areacodeList.AreaCodes, available: areacodeList.Available }
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting Areacodes")
        }
      })
  }
}

export function replaceAreaCodes(areacodes) {
  return function (dispatch, getState) {
    let url = blockedAreaCodeBatchUpdateURL
    return save(getOpts(dispatch, areacodes, "POST", url, null, null, getState(), "replace=true"))
      .then(() => {
        dispatch(retrieveAreaCodes(1))
      })
      .catch(e => exceptionHandling(e, dispatch, "Replacing Areacodes"), () => dispatch(retrieveAreaCodes(1)))
  }
}

export function appendAreaCodes(areacodes) {
  return function (dispatch, getState) {
    let url = blockedAreaCodeBatchUpdateURL
    return save(getOpts(dispatch, areacodes, "POST", url, null, null, getState(), "replace=false"))
      .then(() => {
        dispatch(retrieveAreaCodes(1))
      })
      .catch(e => exceptionHandling(e, dispatch, "Appending Areacodes"), () => dispatch(retrieveAreaCodes(1)))
  }
}

export function isDirty(data) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.IS_DIRTY, data: data })
  }
}

function getDefaultEmailConfiguration(campaignUUID) {
  return {
    campaignUUID: campaignUUID,
    briteVerify: false,
    emailOversight: true,
    strictEmailOversight: false
  }
}

export function retrieveEmailValidation(campaignUUID) {
  return function (dispatch, getState) {
    return retrieve(emailValidationConfigurationURL, 0, 0, campaignUUID, dispatch, getState())
      .then(res => {
        dispatch({
          type: ActionTypes.EMAIL_VALIDATION_LOADED,
          data: res
        })
      })
      .catch(e => {
        if (isNotFoundError(e)) {
          dispatch(saveEmailValidation(getDefaultEmailConfiguration(campaignUUID)))
        } else {
          exceptionHandling(e, dispatch, "Getting email validation")
        }
      })
  }
}
export function clearCustomValidationPreview() {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.CUSTOM_VALIDATION_RESULTS_LOADED,
      data: {}
    })
  }
}
export function saveCustomValidationConfiguration(config) {
  return function (dispatch, getState) {
    return save(
      getOpts(
        dispatch,
        config,
        "POST",
        customValidationConfigurationURL,
        null,
        null,
        getState(),
        null,
        DISABLE_SNACK,
        AVOID_DISABLE_IS_DIRTY
      )
    )
      .then(() => {
        dispatch(retrieveCustomValidation(config.campaignUuid))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving custom validation"), () => dispatch(retrieveCustomValidation(config.campaignUuid)))
  }
}

export function retrieveCustomValidation(campaignUUID) {
  return function (dispatch, getState) {
    return retrieve(customValidationConfigurationURL, 0, 0, campaignUUID, dispatch, getState())
      .then(res => {
        dispatch({
          type: ActionTypes.CUSTOM_VALIDATION_LOADED,
          data: res
        })
      })
      .catch(e => {
        if (isNotFoundError(e)) {
          dispatch(saveCustomValidationConfiguration(getDefaultCustomConfiguration(campaignUUID)))
        } else {
          exceptionHandling(e, dispatch, "Getting custom validation")
        }
      })
  }
}

function getDefaultCustomConfiguration(campaignUUID) {
  return {
    campaignUUID: campaignUUID,
    enabled: false,
    script: ""
  }
}

export function saveEmailValidation(emailValidation) {
  return function (dispatch, getState) {
    return save(
      getOpts(
        dispatch,
        emailValidation,
        "POST",
        emailValidationConfigurationURL,
        null,
        null,
        getState(),
        null,
        DISABLE_SNACK,
        AVOID_DISABLE_IS_DIRTY
      )
    )
      .then(() => {
        dispatch(retrieveEmailValidation(emailValidation.campaignUUID))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving email validation"), () => dispatch(retrieveEmailValidation(emailValidation.campaignUUID)))
  }
}

export function retrieveAddressValidationDefaultConfig() {
  return function (dispatch, getState) {
    return retrieve(addressValidationDefaultConfigURL, 0, 0, "", dispatch, getState())
      .then(res => {
        dispatch({
          type: ActionTypes.ADDRESS_VALIDATION_DEFAULT_CONFIG_LOADED,
          data: res
        })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e)
        }
      })
  }
}

export function saveAddressValidationCampaignConfig(addressValidation) {
  return function (dispatch, getState) {
    return save(
      getOpts(
        dispatch,
        addressValidation,
        "POST",
        addressValidationCampaignConfigURL,
        null,
        null,
        getState(),
        null,
        DISABLE_SNACK,
        AVOID_DISABLE_IS_DIRTY
      )
    )
      .then(() => {
        dispatch(retrieveAddressValidationCampaignConfig(addressValidation.campaignUUID))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving address validation"), () => dispatch(retrieveAddressValidationCampaignConfig(addressValidation.campaignUUID)))
  }
}

export function retrieveAddressValidationCampaignConfig(campaignUUID) {
  return function (dispatch, getState) {
    return retrieve(addressValidationCampaignConfigURL, 0, 0, campaignUUID, dispatch, getState())
      .then(res => {
        dispatch({
          type: ActionTypes.ADDRESS_VALIDATION_CAMPAIGN_CONFIG_LOADED,
          data: res
        })
      })
      .catch(e => {
        if (isNotFoundError(e)) {
          return retrieve(addressValidationDefaultConfigURL, 0, 0, "", dispatch, getState())
            .then(res => {
              dispatch({
                type: ActionTypes.ADDRESS_VALIDATION_CAMPAIGN_CONFIG_LOADED,
                data: { campaignUUID: campaignUUID, config: res }
              })
            })
            .catch(e => {
              exceptionHandling(e, dispatch)
            })
        } else {
          exceptionHandling(e, dispatch, "Getting address validation")
        }
      })
  }
}

export function saveAddressValidationPlacementAssociatedConfigs(placementUUID, addressValidations) {
  return function (dispatch, getState) {
    return save(
      getOpts(
        dispatch,
        addressValidations,
        "POST",
        addressValidationBatchPlacementAssociatedConfigsURL,
        null,
        null,
        getState(),
        "placement_uuid=" + placementUUID,
        DISABLE_SNACK,
        AVOID_DISABLE_IS_DIRTY
      )
    )
      .then(() => {
        dispatch(retrieveAddressValidationPlacementAssociatedConfigs(placementUUID))
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)

        exceptionHandling(e, dispatch, "Saving placement address validation configurations", () => dispatch(retrieveAddressValidationPlacementAssociatedConfigs(placementUUID)))
      })
  }
}

export function retrieveAddressValidationPlacementAssociatedConfigs(placementUUID) {
  return function (dispatch, getState) {
    dispatch({
      type: ActionTypes.ADDRESS_VALIDATION_PLACEMENT_ASSOCIATED_CONFIGS_LOAD_START,
    })
    return retrieve(addressValidationBatchPlacementAssociatedConfigsURL, 0, 0, "", dispatch, getState(), "placement_uuid=" + placementUUID)
      .then(res => {
        dispatch({
          type: ActionTypes.ADDRESS_VALIDATION_PLACEMENT_ASSOCIATED_CONFIGS_LOAD_SUCCESS,
          data: res.records
        })
      })
      .catch(e => {
        exceptionHandling(e, dispatch, "Getting placement  address validation configurations")
      })
  }
}

// Darwin-groups
export function loadDarwinGroups() {
  return function (dispatch, getState) {
    return retrieve(darwinGroupListURL, 0, 0, null, dispatch, getState())
      .then(res => {
        dispatch({ type: ActionTypes.DARWIN_GROUP_LIST_LOADED, data: res })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Loading Darwin groups")
        }
      })
  }
}

export function loadDarwinGroup(groupUUID) {
  return function (dispatch, getState) {
    const url = `${darwinGroupListURL}${groupUUID}`
    return retrieve(url, 0, 0, null, dispatch, getState())
      .then(res => {
        dispatch({ type: ActionTypes.DARWIN_GROUP_LOADED, data: res })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Loading Darwin group")
        }
      })
  }
}

export function loadNewDarwinGroup() {
  return function (dispatch) {
    const group = { name: "" }
    dispatch({ type: ActionTypes.DARWIN_GROUP_LOADED, data: group })
    const emptyList = { records: [] }
    dispatch({ type: ActionTypes.DARWIN_GROUP_MEMBER_LIST_LOADED, data: emptyList })
  }
}

export function clearDarwinGroup() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.DARWIN_GROUP_LOADED, data: null })
    const emptyList = { records: [] }
    dispatch({ type: ActionTypes.DARWIN_GROUP_MEMBER_LIST_LOADED, data: emptyList })
  }
}

export function saveDarwinGroup(group, updatedBy) {
  const verb = group.uuid ? "PUT" : "POST"
  let url = darwinGroupListURL
  if (verb === "PUT") {
    url = `${url}${group.uuid}`
  }
  group.updatedBy = updatedBy
  return function (dispatch, getState) {
    return save(getOpts(dispatch, group, verb, url, null, null, getState()))
      .then(() => {
        dispatch(loadDarwinGroup(group.uuid))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving Darwin group"), dispatch(loadDarwinGroup(group.uuid)))
  }
}

export function loadDarwinGroupMembers(groupUUID) {
  return function (dispatch, getState) {
    const query = `group_uuid=${groupUUID}`
    return retrieve(darwinGroupMemberListURL, 0, 0, null, dispatch, getState(), query)
      .then(res => {
        dispatch({ type: ActionTypes.DARWIN_GROUP_MEMBER_LIST_LOADED, data: res })
      })
      .catch(e => {
        if (isNotFoundError(e)) {
          const emptyList = { records: [] }
          dispatch({ type: ActionTypes.DARWIN_GROUP_MEMBER_LIST_LOADED, data: emptyList })
        } else {
          exceptionHandling(e, dispatch, "Loading Darwin group members")
        }
      })
  }
}

export function loadDarwinAllGroupsMembers() {
  return function (dispatch, getState) {
    return retrieve(darwinGroupMemberListURL, 0, 0, null, dispatch, getState(), "")
      .then(res => {
        dispatch({ type: ActionTypes.DARWIN_ALL_GROUPS_MEMBERS_LIST_LOADED, data: res })
      })
      .catch(e => {
        if (isNotFoundError(e)) {
          const emptyList = { records: [] }
          dispatch({ type: ActionTypes.DARWIN_ALL_GROUPS_MEMBERS_LIST_LOADED, data: emptyList })
        } else {
          exceptionHandling(e, dispatch, "Loading Darwin all groups members")
        }
      })
  }
}

export function saveDarwinGroupMember(member) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, member, "POST", darwinGroupMemberListURL, null, null, getState()))
      .then(() => {
        dispatch(loadDarwinGroupMembers(member.groupUUID))
        dispatch(loadDarwinAllGroupsMembers())
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving Darwin group member", () => dispatch(loadDarwinGroupMembers(member.groupUUID))))
  }
}

export function deleteDarwinGroupMember(groupUuid, memberUuid) {
  return function (dispatch, getState) {
    return del(dispatch, darwinGroupMemberListURL, memberUuid, null, getState())
      .then(() => {
        dispatch(loadDarwinGroupMembers(groupUuid))
        dispatch(loadDarwinAllGroupsMembers())
      })
      .catch(e => exceptionHandling(e, dispatch, "Deleting Darwin group member"), () => dispatch(loadDarwinGroupMembers(groupUuid)))
  }
}

export function retrieveEmailDomainsToReview() {
  return function (dispatch, getState) {
    return retrieve(emailDomainsReviewURL, 0, 0, null, dispatch, getState(), "count=4&reviewed=false")
      .then(res => {
        dispatch({ type: ActionTypes.EMAIL_DOMAINS_LIST_LOADED, data: res.records })
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting email domains to review")
        }
      })
  }
}

export function setEmailDomainAsReviewed(domainReview) {
  return function (dispatch, getState) {
    return save(getOpts(dispatch, domainReview, "PUT", emailDomainsReviewURL, null, null, getState()))
      .then(() => {
        dispatch(retrieveEmailDomainsToReview())
      })
      .catch(e => exceptionHandling(e, dispatch, "Setting email domain as reviewed"), () => dispatch(retrieveEmailDomainsToReview()))
  }
}

export function retrieveAgentSourceURL() {
  return function (dispatch) {
    const url = `https://s3.amazonaws.com/${aws.bucket}/agent/${agentSourceURLFolder}/index.html`
    dispatch({ type: ActionTypes.AGENT_SOURCE_URL_LOADED, data: url })
  }
}
export function openAgent(placementUUID, user, campaigns) {
  return function () {
    let URL = `${agentTemplateURL}?placement_uuid=${placementUUID}&user=${JSON.stringify(
      user
    )}&campaigns=${JSON.stringify(campaigns)}&agent_url=https://s3.amazonaws.com/static.adquire.com/agent/${process.env.NODE_ENV == "production" ? "master" : "dev"
      }/agent.js`
    window.open(URL, "_blank")
  }
}

export function updateDeliverableStatus(deliverable, criteria) {
  return function (dispatch, getState) {
    let url = deliverableManagerDeliverablesURL + `update-status/`
    return save(getOpts(dispatch, deliverable, "PUT", url, null, null, getState()))
      .then(() => {
        if (criteria) dispatch(retrieveDeliverableViewer(criteria))
      })
      .catch(e => exceptionHandling(e, dispatch, "Updating deliverable status"))
  }
}

export function openDeliverableViewerSaveDialog() {
  return function (dispatch) {
    dispatch(openSaveDialogDeliverableViewer())
  }
}

export function closeDeliverableViewerSaveDialog() {
  return function (dispatch) {
    dispatch(closeSaveDialogDeliverableViewer())
  }
}

export function updateBulkDeliverableViewerStatusCodes(deliverableStatus, deliverableUUIDs) {
  return function (dispatch, getState) {
    let url = deliverableManagerDeliverablesURL + `update-status/`
    let processedCount = 0
    let failureCount = 0
    let results = {message: `${processedCount} of ${deliverableUUIDs.length} processed (${failureCount} failed) Processing ...`, records:[]}
    
    const forEachSeries = async (deliverableUUIDs) => {
      for (const deliverableUUID of deliverableUUIDs) {
        processedCount++
        
        await save(getOpts(dispatch, {deliverableStatus:deliverableStatus, deliverableUUID:deliverableUUID}, "PUT", url, null, null, getState(), "", DISABLE_SNACK))
              .then(() => {
                results.records.push({message:"", failed:false, id:deliverableUUID, details: ""})
              })
              .catch(e => {
                failureCount++
                
                let errorMessage = "Unknown error"
                if(e.body && e.body.errors && e.body.errors.length > 0){
                  errorMessage = e.body.errors[0].message
                }
                results.records.push({message:`${errorMessage}`, failed:true, id:deliverableUUID, details: ""})
              })
        results.message = `${processedCount} of ${deliverableUUIDs.length} processed (${failureCount} failed) Done`
        
        dispatch(setBulkStatusUpdateResultsDeliverableViewer(results))
      }
    }
    
    forEachSeries(deliverableUUIDs)
    
    dispatch(setBulkStatusUpdateResultsDeliverableViewer(results))
  }
}


export function updateDeliverable(deliverable, criteria) {
  return function (dispatch, getState) {
    let url = deliverableManagerDeliverablesURL + `${encodeURIComponent(deliverable.UUID)}/`
    return save(getOpts(dispatch, deliverable, "PUT", url, null, null, getState()))
      .then(() => {
        if (criteria) dispatch(retrieveDeliverableViewer(criteria))
      })
      .catch(e => exceptionHandling(e, dispatch, "Updating deliverable"))
  }
}

export function testLeadDeliveryConfig(testData, verbose) {
  return function (dispatch, getState) {
    let verboseQ = verbose ? "verbose=true" : ""
    let opts = getOpts(dispatch, testData, "POST", deliverableManagerTestURL, null, null, getState(), verboseQ, DISABLE_SNACK)
    customFetch(opts.url + "?" + opts.query, request(opts.verb, JSON.stringify(opts.model), opts.state))
      .then(
        response =>
          new Promise(resolve => {
            let r = {}
            try {
              r.data = JSON.parse(response.responseText)
              dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIG_TEST, data: r.data })
              resolve(r)
            } catch (err) {
              r.data = response.responseText
              r.rawResp = response
              dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIGS_ERROR, data: r })
              resolve(r)
            }
          })
      )
      .catch(e => {
        dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIGS_ERROR, data: e })
      })
  }
}

export function retrieveLeadDeliveryConfigs(campaignUUID, preselectConfig) {
  return function (dispatch, getState) {
    return retrieve(deliverableManagerConfigsURL, 0, 0, null, dispatch, getState(), `campaign=${campaignUUID}`)
      .then(res => {
        dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIGS_LOADED, data: res.records })
        if (preselectConfig) {
          dispatch(retrieveLeadDeliveryConfig(preselectConfig.UUID))
        }
        //else {
        //  if (res.records.length > 0) {
        //    dispatch(cancelLeadDeliveryConfig())
        //  }
        //}
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting lead delivery configs")
        }
      })
  }
}

export function deleteLeadDeliveryConfig(campaignUUID, configUUID) {
  return function (dispatch, getState) {
    return del(dispatch, deliverableManagerConfigsURL, configUUID, null, getState())
      .then(() => {
        dispatch(retrieveLeadDeliveryConfigs(campaignUUID, null))
        dispatch(cancelLeadDeliveryConfig())
      })
      .catch(e => exceptionHandling(e, dispatch, "Deleting lead delivery"), () => dispatch(retrieveLeadDeliveryConfigs(campaignUUID, null)))
  }
}

export function saveLeadDeliveryConfig(leadDeliveryConfig) {
  return function (dispatch, getState) {
    let url = deliverableManagerConfigsURL + (leadDeliveryConfig.uuid || "")
    return save(getOpts(dispatch, leadDeliveryConfig, "POST", url, null, null, getState()))
      .then(response => {
        return extractData(response)
      })
      .then(response => {
        leadDeliveryConfig = response
        dispatch(retrieveLeadDeliveryConfigs(leadDeliveryConfig.campaignUUID, leadDeliveryConfig))
      })
      .catch(e => exceptionHandling(e, dispatch, "Saving lead delivery "), () => dispatch(retrieveLeadDeliveryConfigs(leadDeliveryConfig.campaignUUID, leadDeliveryConfig)))
  }
}

export function cloneLeadDeliveryConfig(leadDeliveryConfigUUID) {
  return function (dispatch, getState) {
    let url = deliverableManagerCloneConfigURL + leadDeliveryConfigUUID
    return save(getOpts(dispatch, null, "POST", url, null, null, getState()))
      .then(response => {
        return extractData(response)
      })
      .then(response => {
        let leadDeliveryConfig = response
        dispatch(retrieveLeadDeliveryConfigs(leadDeliveryConfig.campaignUUID, leadDeliveryConfig))
      })
      .catch(e => exceptionHandling(e, dispatch, "Cloning lead delivery "))
  }
}

export function startRetrievingLeadDeliveryConfig() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIG_START_LOADING, data: {} })
  }
}

export function retrieveLeadDeliveryConfig(configUUID) {
  return function (dispatch, getState) {
    startRetrievingLeadDeliveryConfig()
    let url = deliverableManagerConfigsURL + configUUID
    return retrieve(url, 0, 0, null, dispatch, getState(), "")
      .then(res => {
        dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIG_LOADED, data: res })
        dispatch(retrieveLatestDeliveriesByConfiguration(configUUID, 5))
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting lead delivery config")
        }
      })
  }
}

export function cancelLeadDeliveryConfig() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIG_CANCEL, data: null })
  }
}

export function newLeadDeliveryConfig(config) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.LEAD_DELIVERY_CONFIG_NEW, data: config })
  }
}

export function startRetrievingLatestDeliveriesByConfiguration() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.LEAD_DELIVERY_LATEST_DELIVERIES_START_LOADING, data: {} })
  }
}

export function retrieveLatestDeliveriesByConfiguration(configUUID, limit) {
  return function (dispatch, getState) {
    startRetrievingLatestDeliveriesByConfiguration()
    let url = `${deliverableManagerDeliveriesURL}latest/`
    return retrieve(url, limit, 0, configUUID, dispatch, getState(), "")
      .then(res => {
        dispatch({ type: ActionTypes.LEAD_DELIVERY_LATEST_DELIVERIES_LOADED, data: res })
      })
      .catch(e => {
        exceptionHandling(e, dispatch, "Getting latest deliveries")
      })
  }
}

export function submitDelivery(configurationUUID, deliveryUUID, latestDeliveriesLimit) {
  return function (dispatch, getState) {
    let url = `${deliverableManagerSenderURL}submit-delivery/${deliveryUUID}`
    return save(getOpts(dispatch, {}, "POST", url, null, null, getState()))
      .then(response => {
        return extractData(response)
      })
      .then(() => {
        dispatch(retrieveLatestDeliveriesByConfiguration(configurationUUID, latestDeliveriesLimit))
      })
      .catch(e => {
        exceptionHandling(e, dispatch, "Submitting lead delivery ", () => dispatch(retrieveLatestDeliveriesByConfiguration(configurationUUID, latestDeliveriesLimit)))
        dispatch(retrieveLatestDeliveriesByConfiguration(configurationUUID, latestDeliveriesLimit))//HACK
      })
  }
}

export function createDeliveryFromQuery(queryConfigs, latestDeliveriesLimit) {
  return function (dispatch, getState) {
    let url = `${deliverableManagerDeliveriesURL}create-from-query/`
    return save(getOpts(dispatch, queryConfigs, "POST", url, null, null, getState()))
      .then(response => {
        return extractData(response)
      })
      .then(() => {
        dispatch(retrieveLatestDeliveriesByConfiguration(queryConfigs.configurationUUID, latestDeliveriesLimit))
      })
      .catch(e => {

        exceptionHandling(e, dispatch, "Crating delivery", () => dispatch(retrieveLatestDeliveriesByConfiguration(queryConfigs.configurationUUID, latestDeliveriesLimit)))
      })
  }
}

export function retrieveDeliverableViewer(criteria) {
  return function (dispatch, getState) {
    let query = ""
    query += "campaign_uuid=" + criteria.campaigns.join()
    query += "&placement_uuid=" + criteria.placements.join()
    query += "&deliverable_status=" + criteria.statusCodes.join()
    query += "&start_date=" + criteria.startDate
    query += "&end_date=" + criteria.endDate
    let url = deliverableManagerDeliverableViewerURL
    retrieve(url, criteria.limit, criteria.offset, null, dispatch, getState(), query)
      .then(res => {
        let report = res
        dispatch(loadedDeliverableViewer({
          data: report.data.records,
          available: report.data.recordSet.available,
          campaignAggregates: report.campaignAggregates.records
        }))
      })
      .catch(e => {
        if (!isNotFoundError(e)) {
          exceptionHandling(e, dispatch, "Getting deliverable viewer")
        }
      })
  }
}


export function retrieveAssociation(placementUUID, campaignUUID) {
  return function (dispatch, getState) {
    dispatch({ type: ActionTypes.ASSOCIATION_START_LOADING })

    let promises = []
    promises.push(retrieve(placementsURL, 0, 0, placementUUID, dispatch, getState()))
    promises.push(retrieve(campaignURL, 0, 0, campaignUUID, dispatch, getState()))

    return Promise.all(promises)
      .then(res => {
        let association = {
          placement: res[0],
          campaign: res[1],
        }

        dispatch({ type: ActionTypes.ASSOCIATION_LOADED, data: association })

        let dailyCap = findAssociationDailyCap(association.placement.campaignLeadCaps, campaignUUID)
        if (dailyCap) {
          dispatch(retrieveAssociationDailyCapCount(placementUUID, campaignUUID))
        }
        let currentCap = findAssociationCurrentCap(association.placement.campaignOverallLeadCap, campaignUUID)
        if (currentCap) {
          dispatch(retrieveAssociationOverallCapCount(placementUUID, campaignUUID, currentCap.effectiveDate))
        }
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)

        dispatch({ type: ActionTypes.ASSOCIATION_LOADING_ERROR, data: e })
        exceptionHandling(e, dispatch, "Getting association")
      })
  }
}

export function retrieveAssociationDailyCapCount(placementUUID, campaignUUID) {
  return function (dispatch, getState) {
    dispatch({ type: ActionTypes.ASSOCIATION_DAILY_CAP_COUNT_START_LOADING })

    let timezone = "America/New_York"
    let startDate = moment().tz(timezone).startOf('day').utc()
    let endDate = moment().tz(timezone).endOf('day').utc()

    retrieve(leadCountsURL, 0, 0, campaignUUID, dispatch, getState(), `placement=${placementUUID}&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`)
      .then(res => {
        dispatch({ type: ActionTypes.ASSOCIATION_DAILY_CAP_COUNT_LOADED, data: res.count })
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)

        dispatch({ type: ActionTypes.ASSOCIATION_DAILY_CAP_COUNT_ERROR, data: e })
        exceptionHandling(e, dispatch, "Getting association daily cap count")
      })
  }
}

export function retrieveAssociationOverallCapCount(placementUUID, campaignUUID, effectiveDateTimestamp) {
  return function (dispatch, getState) {
    dispatch({ type: ActionTypes.ASSOCIATION_CURRENT_CAP_COUNT_START_LOADING })

    let startDate = moment.unix(effectiveDateTimestamp).utc()
    let endDate = moment.utc()

    retrieve(leadCountsURL, 0, 0, campaignUUID, dispatch, getState(), `placement=${placementUUID}&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`)
      .then(res => {
        dispatch({ type: ActionTypes.ASSOCIATION_CURRENT_CAP_COUNT_LOADED, data: res.count })
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)

        dispatch({ type: ActionTypes.ASSOCIATION_CURRENT_CAP_COUNT_ERROR, data: e })
        exceptionHandling(e, dispatch, "Getting association cap count")
      })
  }
}

/*
export function saveAssociation(placement, campaign) {
  return function (dispatch, getState) {
    return save(
      getOpts(
        dispatch,
        placement,
        "PUT",
        placementsURL + placement.UUID,
        null,
        null,
        getState()
      )
    )
      .then(() => {
        dispatch(retrieveAssociation(placement.UUID, campaign.UUID))
      })
      .catch(e => {
        console.log(`e:${e}`)
        console.log(e)
        exceptionHandling(e, dispatch, `Saving associations - err:${e.message}`)
      })
  }
}
*/

export function resetAssociationEditor() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ASSOCIATION_RESET })
  }
}

export function selectAssociationPlacement(selection) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ASSOCIATION_PLACEMENT_SELECTED, data: selection })
  }
}

export function unselectAssociationPlacement() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ASSOCIATION_PLACEMENT_UNSELECTED })
  }
}

export function selectAssociationCampaign(selection) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ASSOCIATION_CAMPAIGN_SELECTED, data: selection })
  }
}

export function unselectAssociationCampaign() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.ASSOCIATION_CAMPAIGN_UNSELECTED })
  }
}

