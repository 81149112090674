const SAVE_STATE_OF_DELIVERABLE_VIEWER = 'SAVE_STATE_OF_DELIVERABLE_VIEWER'
const DELIVERABLE_VIEWER_LOADED = 'DELIVERABLE_VIEWER_LOADED'
const DELIVERABLE_VIEWER_OPENED_SAVE_DIALOG = 'DELIVERABLE_VIEWER_OPENED_SAVE_DIALOG'
const DELIVERABLE_VIEWER_CLOSED_SAVE_DIALOG = 'DELIVERABLE_VIEWER_CLOSED_SAVE_DIALOG'
const DELIVERABLE_VIEWER_SET_BULK_STATUS_UPDATE_RESULTS = 'DELIVERABLE_VIEWER_SET_BULK_STATUS_UPDATE_RESULTS'

export const saveState = data =>
    ({ type: SAVE_STATE_OF_DELIVERABLE_VIEWER, data })

export const loadedDeliverableViewer = data =>
({ type: DELIVERABLE_VIEWER_LOADED, data })

export const openSaveDialogDeliverableViewer = () =>
({ type: DELIVERABLE_VIEWER_OPENED_SAVE_DIALOG})

export const closeSaveDialogDeliverableViewer = () =>
({ type: DELIVERABLE_VIEWER_CLOSED_SAVE_DIALOG })

export const setBulkStatusUpdateResultsDeliverableViewer = data =>
({ type: DELIVERABLE_VIEWER_SET_BULK_STATUS_UPDATE_RESULTS, data })

let initState = { 
        data: [], 
        available: 0, 
        campaignAggregates: [] , 
        componentState: null,
        saveResults: null,
        isSaving: false,
        showSaveDialog: false
}

export const deliverableViewerState = (state = initState, action) => {
  switch (action.type) {
        case SAVE_STATE_OF_DELIVERABLE_VIEWER:
                return {
                        ...state,
                        componentState: action.data,
                }
        case DELIVERABLE_VIEWER_LOADED:
                return JSON.parse(JSON.stringify(action.data))
        case DELIVERABLE_VIEWER_SET_BULK_STATUS_UPDATE_RESULTS:
                return {
                        ...state,
                        saveResults: action.data
                }
        
        case DELIVERABLE_VIEWER_OPENED_SAVE_DIALOG:
                return {
                        ...state,
                        isSaving: true,
                        showSaveDialog: true,
                        saveResults: null
                }
        case DELIVERABLE_VIEWER_CLOSED_SAVE_DIALOG:
                return {
                        ...state,
                        showSaveDialog: false,
                        saveResults: null
                }
        default:
                return state
  }
}