import React from "react"
import { Card, CardTitle, Grid, Cell, List, ListItem, Switch, IconButton } from 'react-mdl'
/*
Registration Fields (enable & ordering): first name, last name, etc
Image (And available size suffixes: 80x40, 120x90, big1, etc)
Headline (HTML)
Subtext (HTML)
Survey header (HTML) (NOTE: there are today survey images, and they can be marked as optional or madatory via CustomSettings)
JS
CSS
BODY (HTML) (It can be just RegData, RegData+Questions, or pretty much anything)
Footer (HTML)
A flag to enable or disable the BODY HTML, or some way to automatically decide so (equivalent to enabling a DAQ at MI)
*/

class RegistrationField extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <ListItem style={{ height: "100px", width: "100%" }}>
      <Grid style={{ width: "100%" }}>
        <Cell col={2}>
          <Grid style={{ width: "100%" }}>
            <Cell col={12}>
              {this.props.idx}
            </Cell>
          </Grid >
        </Cell>
        <Cell col={3}>
          <Grid style={{ width: "100%" }}>
            <Cell col={12}>
              {this.props.fieldName}
            </Cell>
          </Grid >
        </Cell>
        <Cell col={4}>
          <div style={{marginTop:"10px"}}>
          <Switch
            onChange={() => {
              this.props.updateVisible(this.props.idx, !this.props.visible)
            }}
            checked={this.props.visible}
          >Visible</Switch>
          </div>
        </Cell>
        <Cell col={1}>
          <Grid style={{ width: "100%" }}>
            <Cell col={12}>
              <IconButton
                style={{ height: "15px" }}
                name="arrow_upward"
                onClick={() => this.props.MoveUp(this.props.idx)}
              />
            </Cell>
            <Cell col={12}>
              <IconButton
                style={{ height: "15px" }}
                name="arrow_downward"
                onClick={() => this.props.MoveDown(this.props.idx)}
              />
            </Cell>
          </Grid >
        </Cell>
      </Grid>
    </ListItem>
  }
}

class CreativeFields extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {
    this.props.fields.sort(function (f1, f2) {
      if (f1.order > f2.order) return 1
      if (f1.order < f2.order) return -1
      return 0
    })
    return <Card shadow={4}>
      <CardTitle>Field Ordering</CardTitle>
      <List style={{ width: '100%' }}>
        {this.props.fields.map((f, idx) => {
          return <RegistrationField
            key={idx}
            fieldName={f.displayName}
            visible={f.visible}
            idx={f.order}
            updateVisible={this.props.handleVisibleChange}
            MoveUp={this.props.handleShiftUpFields}
            MoveDown={this.props.handleShiftDownFields}
            />
        })}
      </List>
    </Card>
  }
}


export default CreativeFields
