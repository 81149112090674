import { ActionTypes,
         deleteWinback,
         retrieveWinbacks,
         saveWinback, 
         testWinback 
} from '../../services'
import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { groupBy } from '../../utils'
import DummyLead from '../../utils/dummyLead'
import FilterList from '../filterList'
import Winback from './winback'
import {Grid, Cell, Card, CardActions, CardTitle, Icon, Button, ListItem, List, Switch} from 'react-mdl'
import { Prompt } from 'react-router'


function mapDispatchToProps(dispatch) {
  return {
    test(lead, verbose) {
      dispatch(testWinback(lead, verbose))
    },
    error(msg) {
      dispatch({ type: ActionTypes.WINBACK_ERROR, data: { message: msg } })
    },
    clear() {
      dispatch({ type: ActionTypes.WINBACK_ERROR, data: { message: '' } })
      dispatch({ type: ActionTypes.WINBACK_CAMPAIGN_UPDATED, data: [] })
    },
    loadWinbacks() {
      dispatch(retrieveWinbacks())
    },
    save(wb, cb) {
      dispatch(saveWinback(wb))
      .then((response) => cb(response))
    },
    delete(value) {
      dispatch(deleteWinback(value))
    }
  }
}

class winbacks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty: false,
      selectedWinback: null,
      campaigns: groupBy(props.campaigns, c => c.UUID)
    }
    this.setGetWinback = (getWinback) => {
      this.getWinback = getWinback
    }
    this.getWinback = () => null
    this.props.setIsTabComponentDirty(() => this.state.isDirty)
  }

  componentDidMount(){
    this.props.loadWinbacks()
  }

  winbackSelected(wb) {
    this.setState({
      selectedWinback: wb,
      isDirty: false
    })
  }

  newWinback() {
    const newWinback = { title: '', interactions: {interactions:[]}, winbackCampaigns: [], logicTemplate: '', winbackIfTrue: true } 
    this.winbackSelected(newWinback)
  }

  saveWinback() {
    if (!this.state.isDirty) {
      return
    }
    this.props.save(this.getWinback(), (wb) => this.winbackSelected(wb))
  }

  deleteWinback() {
    this.props.delete(this.state.selectedWinback.uuid)
    this.clearChanges()
  }

  clearChanges() {
    this.setState({
      isDirty: false,
      selectedWinback: null
    })
  }

  updated() {
    this.setState({
      isDirty : true
    })
  }

  clear() {
    this.props.clear()
    this.setState({
      lead: null
    })
  }

  emptyLead(lead) {
    if (!lead || !lead.user) {
      return true
    }
    let check = (s) => {
      return !s || s.length == 0
    }
    if (check(lead.user.firstName) && check(lead.user.lastName) && check(lead.user.email)) {
      return true
    }
    return false
  }

  test() {
    this.setState({
      showDialog: true,
      dialogTitle: "Test Winback Response",
      dialogText: "",
      onOk: () => {
        this.props.clear()
        if (this.emptyLead(this.state.lead)) {
          return this.props.error("Please supply some lead information")
        }
        this.props.test(this.state.lead, this.state.verbose)
      },
      dialogCancel: () => {
        this.setState({
          showDialog: false
        })
        this.clear()
      }
    })
  }

  render() {
    let displayTestCampaigns = new Array()
    groupBy(this.props.winbackCampaigns, wbc => wbc.externalMappingID)
      .forEach((cG, k) => {
        displayTestCampaigns.push(<ListItem key={k}><h6>{k}</h6><div style={{ marginLeft: '15%' }}> {cG.map(wc => {
          let campaignResult = this.state.campaigns.get(wc.campaignUUID)
          if (campaignResult && campaignResult.length > 0) {
            return <div key={wc.campaignUUID}>{campaignResult[0].name}</div>
          }
          return null
        })}</div></ListItem>)
      })
    return (
      <React.Fragment>
        <Prompt
            when={this.state.isDirty}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        <section ref={t => this.contentSection = t}>
          <div className="content">
            <Modal appElement={this.contentSection}
              isOpen={this.state.showDialog}
              onRequestClose={() => this.state.dialogCancel()}
              contentLabel="">
              <Card style={{ maxWidth: "60vw", minWidth: "20vw" }} shadow={2}>
                <CardTitle>{this.state.dialogTitle}</CardTitle>
                <CardActions border>
                  <Grid>
                    <Cell col={12}>
                      {this.state.dialogText}
                    </Cell>
                    <Cell col={12}>
                      <Button
                        raised
                        colored
                        onClick={() => this.clear()}>
                        Clear
                      </Button>
                      <Button
                        style={{ marginLeft: '10px' }}
                        raised
                        colored
                        onClick={() => this.state.onOk()}>
                        Test
                      </Button>
                      <Button
                        style={{ marginLeft: '10px' }}
                        raised
                        colored
                        onClick={() => this.state.dialogCancel()}>
                        Cancel
                          </Button>
                      <Switch
                        onChange={() => {
                          this.setState({
                            verbose: !this.state.verbose
                          })
                        }}
                        checked={this.state.verbose || false}
                      >Verbose</Switch>
                      {this.props.winbackError.message.length > 0 ? <div style={{ color: 'red' }}>{this.props.winbackError.message}</div> : ''}
                      {this.props.winbackCampaigns.length > 0 ?
                        <List>{displayTestCampaigns.map(c => c)}</List>
                        : <DummyLead lead={this.state.lead || { user: {} }} update={lead => {
                          this.setState({
                            lead: lead
                          })
                        }} />}
                    </Cell>
                  </Grid>
                </CardActions>
              </Card>
            </Modal>
            <Grid>
              <Cell col={3} style={{ marginTop: '35px' }}>
                <FilterList
                  label="Winback list..."
                  primaryField="title"
                  selected={this.state.selectedWinback == null ? {} : this.state.selectedWinback}
                  additionalFieldList={['uuid']}
                  title="Winbacks"
                  selectItem={wb => this.winbackSelected(wb)}
                  items={this.props.winbacks || []}
                />
              </Cell>
              <Cell col={9}>
                <div style={{ float: 'left' }}>
                  <Button colored raised ripple onClick={(() => this.test())}>
                    Test
                </Button>
                </div>
                <div style={{ float: 'right' }}>
                  <Button
                    disabled={!this.state.isDirty}
                    style={{ marginLeft: '10px' }}
                    colored
                    raised
                    ripple
                    onClick={() => this.saveWinback()}>
                    <Icon name="save"> Save</Icon>
                  </Button>
                  <Button
                    disabled={!this.state.isDirty}
                    style={{ marginLeft: '10px' }}
                    colored
                    raised
                    ripple
                    onClick={() => this.clearChanges()}
                  >
                    <Icon name="cancel">Cancel</Icon>
                  </Button>
                  <Button
                    disabled={!this.state.selectedWinback || !this.state.selectedWinback.uuid}
                    style={{ marginLeft: '10px' }}
                    colored
                    raised
                    ripple
                    onClick={() => this.deleteWinback()}
                  >
                    <Icon name="delete">delete</Icon>
                  </Button>
                  <Button
                    style={{ marginLeft: '10px' }}
                    colored
                    raised
                    ripple
                    onClick={() => this.newWinback()}>
                    <Icon name="add_box">New</Icon> New
                  </Button>
                </div>
                {this.state.selectedWinback ? 
                  <Winback 
                    key={this.state.selectedWinback.uuid}
                    campaigns={this.props.campaigns} 
                    updated={() => this.updated()} 
                    winback={this.state.selectedWinback} 
                    setGetWinback={this.setGetWinback}
                  />
                  : null}
              </Cell>
            </Grid>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const Winbacks = connect(null, mapDispatchToProps)(winbacks)

export default Winbacks