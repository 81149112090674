import React from 'react'
import FilterList from '../filterList'
import { deepClone } from '../../utils'
import { Grid, ListItemContent, ListItem, List, Cell, Card, CardMenu, CardTitle, CardActions, Checkbox, Button, Icon, Tooltip, FABButton } from 'react-mdl'

const valueStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden", textOverflow: "ellipsis",
  marginBottom: "0px",
  fontSize: "16px",
  lineHeight: "normal",
}

export default class ValidationsByCampaign extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDirty: false,
      isLoading: false,
      selectedValidation: null,
      validationList: [],
    }
  }

  componentDidMount() {
    let list = this.convertValidationListFromPropsToState(this.props.associatedAddressValidations.validationList)
    this.setState({
      validationList: list,
      isLoading: this.props.associatedAddressValidations.isLoading,
      isDirty: false,
      selectedValidation: this.props.filter ? list.find(e=>e.campaignUuid === this.props.filter) : this.state.selectedValidation,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.associatedAddressValidations !== this.props.associatedAddressValidations || prevProps.campaigns !== this.props.campaigns || prevProps.filter !== this.props.filter) {
      let list = this.convertValidationListFromPropsToState(this.props.associatedAddressValidations.validationList)
      this.setState({
        validationList: this.props.associatedAddressValidations.validationList ? list : null,
        isLoading: this.props.associatedAddressValidations.isLoading,
        isDirty: !prevProps.associatedAddressValidations.validationList &&
          this.props.associatedAddressValidations.validationList ? false : this.state.isDirty,
        selectedValidation: this.props.filter ? list.find(e=>e.campaignUuid === this.props.filter) : this.state.selectedValidation,
      })
    }
  }

  handleNewValidation() {
    const selectedValidation = {
      id: '',
      campaignUuid: '',
      campaignName: '',
      placementUuid: '',
      suiteLevelEnabled: false,

    }
    this.setState({
      selectedValidation: selectedValidation,
    })
  }

  convertValidationListFromStateToProps(stateValidationList) {
    let propValidationList = stateValidationList.map((stateValidation) => {
      return this.convertValidationFromStateToProps(stateValidation)
    })
    return { records: propValidationList }
  }

  convertValidationFromStateToProps(validation) {
    let propsValidation = {
      campaignUUID: validation.campaignUuid,
      placementUUID: validation.placementUuid,
      config: { melissa: { suiteLevelEnabled: validation.suiteLevelEnabled } },
    }
    return propsValidation
  }

  convertValidationListFromPropsToState(propValidationList) {
    if (!propValidationList) {
      return []
    }

    let propValidationListCopy = deepClone(propValidationList)
    let stateValidationList = propValidationListCopy.map((propValidation) => {
      let camp = this.props.campaigns.find(c => c.UUID == propValidation.campaignUUID)
      let campaignName = 'NA'
      let campaignShortCode = 'NA'
      if (camp) {
        campaignName = camp.name
        campaignShortCode = camp.shortCode
      }
      return {
        id: propValidation.campaignUUID,
        suiteLevelEnabled: propValidation.config.melissa.suiteLevelEnabled,
        placementUuid: propValidation.placementUUID,
        campaignUuid: propValidation.campaignUUID,
        campaignName: campaignName,
        campaignShortCode: campaignShortCode,
      }
    })

    return stateValidationList
  }

  getCampaignsNotAlreadySelected(campaigns, validationList) {
    let selectedCampaignUuids = validationList.map(a => a.campaignUuid)
    return campaigns.filter(c => !selectedCampaignUuids.includes(c.UUID))
  }

  handleUnselect() {
    this.setState({
      selectedValidation: null,
    })
  }

  handleSelectCampaign(c) {
    let validation = {
      campaignUuid: c.UUID,
      campaignName: c.name,
      placementUuid: this.props.placement.UUID,
      suiteLevelEnabled: true,
    }
    this.handleAdd(validation)
  }

  renderCampaignSelector() {
    let {campaigns} = this.props
    if(this.props.filter)campaigns = campaigns.filter(e=>e.UUID === this.props.filter)
    return (
      <Grid>
        <Cell col={12}>
          <Tooltip label="Clear">
            <Button
              onClick={() => this.handleUnselect()}
              className="mdl-button--icon"
            >
              <Icon name="clear" />
            </Button>
          </Tooltip>
        </Cell>
        <Cell col={12}>
          <FilterList
            label="Campaign list"
            selected={this.state.selectedValidation.campaignUuid}
            additionalFieldList={['UUID', 'shortCode']}
            title="Campaigns"
            selectItem={c => this.handleSelectCampaign(c)}
            items={this.getCampaignsNotAlreadySelected(campaigns, this.state.validationList)}
          />
        </Cell>
      </Grid>
    )
  }

  handleSuiteLevelChange() {
    let selectVal = this.state.selectedValidation
    selectVal.suiteLevelEnabled = !this.state.selectedValidation.suiteLevelEnabled
    this.setState({
      isDirty: true,
      selectedValidation: selectVal,
    })
    let list = this.state.validationList.filter(
      c => c.campaignUuid != this.state.selectedValidation.campaignUuid
    )
    list = list.filter(item => item.campaignUuid == this.state.selectedValidation.campaignUuid)
    list.push(this.state.selectedValidation)
    this.setState({ validationList: list, isDirty: true })
  }


  handleAdd(validation) {
    let list = this.state.validationList.filter(
      c => c.campaignUuid != validation.campaignUuid
    )
    list.push(validation)
    this.setState({ validationList: list, isDirty: true, selectedValidation: validation })
  }

  handleRemove() {
    let list = this.state.validationList.filter(
      c => c.campaignUuid != this.state.selectedValidation.campaignUuid
    )
    this.setState({ validationList: list, isDirty: true })
    this.handleUnselect()
  }

  renderDetails() {
    return (
      <Grid style={{ width: "100%", padding: "0px" }}>
        <Cell col={4}>{this.state.selectedValidation.campaignName}</Cell>
        <Cell col={4}>
          Suite Level Validation:
          <Checkbox
            ripple
            label={this.state.selectedValidation.suiteLevelEnabled ? "ON" : "OFF"}
            checked={this.state.selectedValidation.suiteLevelEnabled}
            onChange={() => this.handleSuiteLevelChange()}
          />
        </Cell>
        <Cell col={4}>
          <Tooltip label="Remove">
            <Button className="remove-validation-button" onClick={() => this.handleRemove()} accent>
              <Icon name="remove" />
            </Button>
          </Tooltip>
          <Tooltip label="Clear">
            <Button onClick={() => this.handleUnselect()} >
              <Icon name="clear" />
            </Button>
          </Tooltip>
        </Cell>
      </Grid>
    )
  }

  handleSelectItem(validation) {
    this.setState({
      selectedValidation: validation,
    })

  }

  applyFilter(list) {
    return this.props.filter ? list.filter((c) => c.campaignUuid == this.props.filter) : list
  }

  renderValidationList() {
    const filteredList = this.applyFilter(this.state.validationList)
    return (
      <List id="validation-list">
        {filteredList.map((validation, i) => {
          if(validation.campaignShortCode == "NA"){
            return false
          }
          return <ListItem
            twoLine
            key={`validation:${i}`}
            onClick={() => this.handleSelectItem(validation)}>
            <ListItemContent subtitle={validation.campaignShortCode}>
              <Grid noSpacing={true}>
                <Cell col={3} style={valueStyle}>{validation.campaignName}</Cell>
                <Cell col={5}>
                  <div style={{ margin: "0px 15px", display: "flex", justifyContent: "space-between", minWidth: "200px" }}>
                    Suite Level Validation:{validation.suiteLevelEnabled ? "ON" : "OFF"}
                  </div>
                </Cell>
                <Cell col={4}>
                  <div style={{ display: "flex", justifyContent: "center" }} >

                  </div>
                </Cell>
              </Grid>
            </ListItemContent>
          </ListItem>
        })}
      </List>
    )
  }

  handleSaveClick() {
    console.dir(this.state.validationList)
    let list = this.convertValidationListFromStateToProps(this.state.validationList)
    console.dir(list)
    this.props.onSave(this.props.placement.UUID, list)
    this.handleUnselect()
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
          <CardTitle expand>
            <div style={{ fontSize: "22px", fontWeight: "300" }}>Validations by Campaign</div>
          </CardTitle>
          <CardMenu>
          </CardMenu>
          <CardActions border>
            <div>Loading ...</div>
          </CardActions>
        </Card>
      )
    }
    if (!this.state.validationList) {
      return (
        <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
          <CardTitle expand>
            <div style={{ fontSize: "22px", fontWeight: "300" }}>Validations by Campaign</div>
          </CardTitle>
          <CardMenu>
          </CardMenu>
          <CardActions border>
            <div>N/A</div>
          </CardActions>
        </Card>
      )
    }

    let showDetails = this.state.selectedValidation && this.state.selectedValidation.campaignUuid.length > 0
    let showCampaignSelector = this.state.selectedValidation && this.state.selectedValidation.campaignUuid.length == 0
    return (
      <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
        <CardTitle expand>
          <div style={{ fontSize: "22px", fontWeight: "300" }}>Validations by Campaign</div>
          {this.props.filter ?
            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", marginLeft: "15px", padding: "5px", color: "#009abf" }}>
              <div>Filtered</div>
              <div><Icon name="priority_hight" /></div>
            </div> : null}
        </CardTitle>
        <CardMenu>
          <Button id="save-validation-button" colored raised onClick={() => this.handleSaveClick()} disabled={!this.state.isDirty}>
            <Icon name="save" />
          </Button>
        </CardMenu>
        <CardActions border>
          <Tooltip label="Add campaign">
            <FABButton
              id="new-validation-button"
              className="fabPosition"
              onClick={() => this.handleNewValidation()}
              mini
              colored>
              <Icon name="add" />
            </FABButton>
          </Tooltip>
          {showCampaignSelector ? this.renderCampaignSelector() : null}
          {showDetails ? this.renderDetails() : null}
          {!showCampaignSelector ? this.renderValidationList() : null}
        </CardActions>
      </Card>
    )
  }
}
