import * as Json2csv from 'json2csv'

import { clearZips, searchZips } from '../../services'

import React from 'react'
import { USStates } from '../../utils/states'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Grid, Cell, Card, CardActions, CardTitle, Textfield, Icon, Button, Menu, MenuItem } from 'react-mdl'

function mapDispatchToProps(dispatch) {
  return {
    onZipSearch: function (queryData) {
      dispatch(searchZips(queryData))
    },
    clearZips: function () {
      dispatch(clearZips())
    },
  }
}

const KILOMETERS_PER_MILE = 1.609344

class zipSearchForm extends React.Component {
  constructor(props) {
    super(props)
    let distanceUnits = new Map()
    distanceUnits.set('mile', 'miles')
    distanceUnits.set('km', 'kilometers')
    this.state = {
      initState: true,
      selectedDistanceUnit: 'mile',
      defaultDistance: 15,
      zipCodes: '',
      states: new USStates().States,
      foundZipcodes: [],
      distanceUnits: distanceUnits,
      selectedState: 'ALL',
      selectedZipType: 'ALL',
      zipCodeTypes: [
        {
          name: 'Standard',
          code: 'standard',
        },
        {
          name: 'P.O. Box',
          code: 'pobox',
        },
        {
          name: 'Military',
          code: 'military',
        },
        {
          name: 'Unique',
          code: 'unique',
        },
      ],
    }
  }

  preProcess() {
    return this.state.zipCodes
      .split(/[\s,]+/)
      .map(s => s.trim())
      .filter(item => item.match(/^\d\d\d\d\d$/))
  }
  ConvertMilesToKms(miles) {
    return miles * KILOMETERS_PER_MILE
  }

  findZipCodes() {
    let distanceInKms = this.state.selectedDistanceUnit === 'mile'
      ? this.ConvertMilesToKms(this.state.selectedDistance)
      : this.state.selectedDistance
    let queryData = {
      Zips: this.preProcess(),
      Distance: distanceInKms,
      Type: this.state.selectedZipType,
      State: this.state.selectedState,
      Range: this.props.ranges,
    }
    const foundZipCodes = this.state.foundZipcodes
    foundZipCodes.splice(0, this.state.foundZipcodes.length)

    this.props.onZipSearch(queryData)
    this.setState({
      initState: false,
      foundZipCodes: foundZipCodes,
    })
  }

  download() {
    let fields = ['zipcode']
    Json2csv.default(
      {
        data: this.props.zips.map(zip => {
          return { zipcode: zip }
        }),
        fields: fields,
        quotes: "",
        hasCSVColumnTitle: false,
      },
      (err, csv) => {
        if (err) {
          console.error(err)
        } else {
          let uri = 'data:text/csv;charset=utf-8' + ',' + encodeURI(csv)
          let link = document.createElement('a')
          link.setAttribute('href', uri)
          link.setAttribute('download', 'zipCodes.csv')
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        }
      },
    )
  }

  reset() {
    this.props.clearZips()
    this.setState({
      zipCodes: "",
      selectedDistance: this.defaultDistance,
      selectedDistanceUnit: this.defaultDistanceUnit,
      selectedState: "ALL",
      selectedZipType: "ALL",
      initState: true,
    })
  }

  selectDistanceUnit(d) {
    this.setState({
      selectedDistanceUnit: d
    })
  }

  selectState(s) {
    this.setState({
      selectedState: s
    })
  }

  selectZipType(s) {
    this.setState({
      selectedZipType: s
    })
  }

  render() {
    let distanceItems = []
    this.state.distanceUnits.forEach((k, v) => {
      distanceItems.push(
        <MenuItem key={k} onClick={() => this.selectDistanceUnit(v)}>
          {k}
        </MenuItem>,
      )
    })
    let stateItems = [
      "ALL",
    ].concat(
      this.state.states.map(s => s.code),
    )
    let zipItems = [
      <MenuItem key={'ALL'} onClick={() => this.selectZipType('ALL')}>
        ALL
      </MenuItem>,
    ].concat(
      this.state.zipCodeTypes.map(zt => {
        return (
          <MenuItem key={zt.code} onClick={() => this.selectZipType(zt.code)}>
            {zt.name}
          </MenuItem>
        )
      }),
    )
    return (
      <Card shadow={2}>
        <CardTitle>
          <div style={{ textAlign: "center", width: "100%", fontSize: "24px" }}>
            Zip Search Tool
          </div>
        </CardTitle>
        <CardActions border>
          <Grid>
            <Cell col={12}>
              <div>
                <label>Zip Code List</label>
              </div>
              <Textfield
                floatingLabel
                label="Comma, space, or new-line delimited list"
                onChange={v => {
                  this.setState({
                    zipCodes: v.target.value
                  })
                }}
                value={this.state.zipCodes || ""}
                rows={4}
              />
            </Cell>
            <Cell col={2}>
              <Textfield
                pattern="-?[0-9]*(\.[0-9]+)?"
                error="Input is not a number!"
                floatingLabel
                label="Distance"
                onChange={v => {
                  this.setState({
                    selectedDistance: parseFloat(v.target.value)
                  })
                }}
                value={this.state.selectedDistance || ""}
              />
            </Cell>
            <Cell col={3}>
              <Button onClick={e => e.preventDefault()} id="distance-dropdown">
                {this.state.distanceUnits.get(this.state.selectedDistanceUnit)}
                <Icon name="expand_more" />
              </Button>
              <Menu ripple target="distance-dropdown">
                {distanceItems}
              </Menu>
            </Cell>
            <Cell col={2}>
              <Select
                placeholder={"State"}
                isMulti={false}
                value={this.state.selectedState}
                onChange={val => {
                  this.setState({
                    selectedState: val
                  })
                }}
                options={stateItems.map(e => {
                  return { label: e, value: e }
                })}
              />
            </Cell>
            <Cell col={3}>
              <label htmlFor="zipcode-dropdown" style={{ margin: "0px 20px" }}>Zip Type</label>
              <Button onClick={e => e.preventDefault()} id="zipcode-dropdown" style={{ margin: "0px 20px" }}>
                {this.state.selectedZipType}
                <Icon name="expand_more" />
              </Button>
              <Menu ripple target="zipcode-dropdown">
                {zipItems}
              </Menu>
            </Cell>
            <Cell col={2}>
              <Button
                onClick={e => {
                  e.preventDefault()
                  this.findZipCodes()
                }}
                raised
                colored
                ripple>
                Search
              </Button>
            </Cell>
            <Cell col={12}>
              {!this.state.initState && this.props.zips.length == 0 ?
                <div style={{ textAlign: "center", border: "1px solid grey", padding: "10px", backgroundColor: "#FFFFCC" }}>{"Zips not found"}</div>
                :
                <div style={{ maxHeight: "200px", overflowY: "scroll", border: "1px solid grey" }}>
                  {this.props.zips.join(", ")}
                </div>
              }
            </Cell>
          </Grid>
          {/*<switch if='!lockedRanges' label="Ranged Values" toggled="ranges"></switch>*/}
          <div style={{ display: "flex", justifyContent: "space-between", margin: "20px" }}>
            <Button
              disabled={this.props.zips.length <= 0}
              onClick={e => {
                e.preventDefault()
                this.download()
              }}
              raised
              colored
              ripple>
              Download
            </Button>
            <Button
              onClick={e => {
                e.preventDefault()
                this.reset()
              }}
              raised
              colored
              ripple>
              Reset
            </Button>
          </div>
        </CardActions>
      </Card>
    )
  }
}
const ZipSearchForm = connect(null, mapDispatchToProps)(
  zipSearchForm,
)

export default ZipSearchForm
