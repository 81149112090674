import React from 'react'
import { Icon, Button, Spinner} from "react-mdl"


class stats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const {stats} = this.props.stats
    const {error} = this.props.stats
    return (
      <div style={{ width: "100%", minHeight: "51px", border: "1px solid #6084af", padding: "5px 8px", boxShadow: "1px 2px 4px rgba(0,0,0,.5)"}} id="stats-card">
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <div>
          <h2 className="mdl-card__title-text">
            <strong>Stats</strong>
          </h2>

          </div>
          <div style={{ margin: "5px" }}>
            {stats ?  <div>
              <div>
                <strong>Optins:</strong><span style={{marginLeft: "5px", color: "blue"}}>{stats.optins}</span>
                <span style={{marginLeft: "5px"}}><strong>Leads:</strong><span style={{marginLeft: "5px", color: "blue"}}>{stats.leads}</span></span>
              </div>
            </div> : error ? <div style={{color: "red"}}>Error retrieving stats</div> : <div style={{marginTop: "-7px"}}><Spinner singleColor /></div> }
          </div>
          <Button onClick={() => {this.props.retrieve()}}>
            <Icon name="refresh" />
          </Button>
        </div>
        {stats ?
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <div className="subtext">{`Last ${stats.periodInHours} hours`}</div>
          <div className="subtext">{`(from ${stats.createdAt.local().format("dddd, MMMM Do YYYY, h:mm:ss a")})`}</div>
        </div>
        : null
        }
      </div>
    )
  }
}

export default stats
