import HttpInteractionManager from '../requestBuilder/interaction'
import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import {Grid, Cell, Card, CardActions, CardTitle, Textfield, Switch} from 'react-mdl'
import SentStatus from "./statusMap"


class HttpSender extends React.Component {
  constructor(props) {
    super(props)
    let defaultHttp = {
      interactionConfig:{
        interactions:[]
      }
    }
    props.leadDeliveryConfig.sender.http = props.leadDeliveryConfig.sender.http || defaultHttp
    this.state = {}
  }

  handleChange(callback){
    callback()
    this.setState(this.state)
  }

  render() {
    return <HttpInteractionManager 
      {...this.props}
      interactionConfig={this.props.leadDeliveryConfig.sender.http.interactionConfig}></HttpInteractionManager>
  }
}


class FtpSender extends React.Component {
  constructor(props) {
    super(props)
    let defaultFtp = {
        URL: "",
        secure: false,
        user: "",
        password: "",
        directory: "",
    }
    props.leadDeliveryConfig.sender.ftp = props.leadDeliveryConfig.sender.ftp || defaultFtp
    this.state = {}
  }

  handleChange(callback){
    callback()
    this.setState(this.state)
  }

  render() {
    return <Grid>
          <Cell col={4}>
            <Textfield
              floatingLabel
              label="URL"
              onChange={(e)=>this.handleChange(()=>this.props.handleFTPIteractionURLChange(e.target.value))}
              value={this.props.leadDeliveryConfig.sender.ftp.URL || ''}
            />
          </Cell>
          <Cell col={4}>
            <Textfield
              floatingLabel
              label="Directory"
              onChange={(e)=>this.handleChange(()=>this.props.handleFTPInteractionDirectoryChange(e.target.value))}
              value={this.props.leadDeliveryConfig.sender.ftp.directory || ''}
            />
          </Cell>
          <Cell col={4}>
            <Switch
              onChange={()=>this.handleChange(this.props.handleFTPInteractionSecureChange)}
              checked={this.props.leadDeliveryConfig.sender.ftp.secure == 1}
            >
              Secure
            </Switch>
          </Cell>
          <Cell col={6}>
            <Textfield
              floatingLabel
              label="User Name"
              onChange={(e)=>this.handleChange(()=>this.props.handleFTPInteractionUserChange(e.target.value))}
              value={this.props.leadDeliveryConfig.sender.ftp.user || ''}
            />
          </Cell>
          <Cell col={6}>
            <Textfield
              floatingLabel
              label="Password"
              onChange={(e)=>this.handleChange(()=>this.props.handleFTPInteractionPasswordChange(e.target.value))}
              value={this.props.leadDeliveryConfig.sender.ftp.password || ''}
            />
          </Cell>
        </Grid>
  }
}

class EmailSender extends React.Component {
  constructor(props) {
    super(props)
    let defaultEmail = {
      toAddress: "",
      replyToAddress: "",
  }
  props.leadDeliveryConfig.sender.email = props.leadDeliveryConfig.sender.email || defaultEmail
    this.state = {}
  }

  handleChange(callback){
    callback()
    this.setState(this.state)
  }

  render() {
    return <Grid>
          <Cell col={12}>
            <Textfield
              floatingLabel
              label="Reply-To Address"
              style={{ width: "100%" }}
              onChange={(e)=>this.handleChange(()=>this.props.handleEmailInteractionReplyAddressChange(e.target.value))}
              value={this.props.leadDeliveryConfig.sender.email.replyToAddress || "ops@adquire.com"}
            />
          </Cell>
          <Cell col={12}>
            <Textfield
              floatingLabel
              label="To Address"
              style={{ width: "100%" }}
              onChange={(e)=>this.handleChange(()=>this.props.handleEmailInteractionToAddressChange(e.target.value))}
              value={this.props.leadDeliveryConfig.sender.email.toAddress || ''}
            />
          </Cell>
        </Grid>
  }
}

class sender extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange(callback){
    callback()
    this.setState(this.state)
  }

  render() {
    let sender = "Invalid selection"
    switch (this.props.senderType.code) {
      case "http":
        sender = <HttpSender {...this.props} />
        break
      case "ftp":
        sender = <FtpSender {...this.props} />
        break
      case "email":
        sender = <EmailSender {...this.props} />
        break
    }

    return <section>
      <div className="content">
        <Card shadow={2} style={{ overflow: "visible" }}>
          <CardTitle>Sender</CardTitle>
          <CardActions border>
            <Grid>
              <Cell col={4}>
                <Select
                  placeholder={"Sender Type"}
                  isMulti={false}
                  value={this.props.senderType}
                  onChange={(s)=>this.handleChange(()=>this.props.handleSenderTypeChange(s.value))}
                  options={this.props.senderTypes}
                />
              </Cell>
              <Cell col={8}></Cell>
              <Cell col={12}>
                {sender}
              </Cell>
                {this.props.senderType.code == 'http' &&<Cell col={12}>
                <SentStatus
                  configuration={this.props.leadDeliveryConfig}
                  handleStatusChange={this.props.handleStatusChange}
                />
              </Cell>}
            </Grid>
          </CardActions>
        </Card>
      </div>
    </section>
  }
}

const Sender = connect(null, null)(sender)

export default Sender

