import { saveTagsAssociation } from "../../services"
import React from "react"
import { connect } from "react-redux"
import TagTree from "./tree"
import { deepClone, getTagFamily } from "../../utils"
import { Grid, Cell, List, ListItem, Card, CardTitle, Button, Icon } from "react-mdl"
import { Prompt } from 'react-router'

function mapDispatchToProps(dispatch) {
  return {
    saveTagsAssociation: function(tagAssociation) {
      dispatch(saveTagsAssociation(tagAssociation))
    }
  }
}

const selectedTagStyles = {
  display: "list-item",
  height: "20px",
  marginTop: "10px",
  marginLeft: "20px"
}

const findFamilyTags = function(tags, selectedTags) {
  const selectedTagParentsUUIDs = selectedTags.map(t => t.parent).filter(t => t.length > 0)
  const selectedFamilyTags = []
  selectedTagParentsUUIDs.forEach(uuid => {
    selectedFamilyTags.push(uuid)
    const parentTag = tags.filter(t => t.UUID === uuid)[0]
    if (parentTag.parent && parentTag.parent !== "") {
      const familyTag = tags.filter(t => t.UUID === parentTag.parent)[0]
      selectedFamilyTags.push(familyTag.UUID)
    }
  })
  return selectedFamilyTags
}

function normalizeAssociatedTags(associatedTags, tags) {
  associatedTags.map(t => {
    if (t.altParent && t.altParent !== "") {
      const altParentTag = tags.filter(tag => tag.UUID === t.altParent)[0]
      t.altParentName = altParentTag.name
    }
    if (t.parent && t.parent !== "") {
      const familyTag = getTagFamily(tags, t)
      t.familyName = familyTag.name
    }
    return t
  })
  return deepClone(associatedTags)
}

class tagManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTags: normalizeAssociatedTags(this.props.associatedTags.records, this.props.tags.records),
      selectedFamilyTags: findFamilyTags(this.props.tags.records, this.props.associatedTags.records),
      isDirty: false
    }
    this.props.setIsTabComponentDirty(() => this.state.isDirty)
    this.handleSaveTagButton = this.handleSaveTagButton.bind(this)
  }

  handleSaveTagButton() {
    const selectedTagUUIDs = this.state.selectedTags.map(t => t.UUID)
    const tagAssociation = { itemUuid: this.props.item.UUID, itemType: this.props.itemType, tags: selectedTagUUIDs }
    this.props.saveTagsAssociation(tagAssociation)
    this.setState({
      isDirty: false,
    })
  }

  selectTag(tag) {
    if (!this.state.selectedTags.map(t => t.UUID).includes(tag.UUID)) {
      const { selectedTags } = this.state
      if (tag.altParent && tag.altParent !== "") {
        const altParentTag = this.props.tags.records.filter(t => t.UUID === tag.altParent)[0]
        tag.altParentName = altParentTag.name
      }
      if (tag.parent && tag.parent !== "") {
        const familyTag = getTagFamily(this.props.tags.records, tag)
        tag.familyName = familyTag.name
      }
      selectedTags.push(tag)
      this.setState({
        isDirty: true,
        selectedTags: selectedTags
      })
      return
    }
    const selectedTags = this.state.selectedTags.filter(t => t.UUID !== tag.UUID)
    this.setState({
      isDirty: true,
      selectedTags: selectedTags
    })
  }

  removeTag(tag) {
    const selectedTags = this.state.selectedTags.filter(t => t.UUID !== tag.UUID)
    this.setState({
      isDirty: true,
      selectedTags: selectedTags
    })
  }

  isNodeSelected(tagUUID) {
    return this.state.selectedTags.map(t => t.UUID).includes(tagUUID)
  }

  isNodeOpen(tagUUID) {
    return this.state.selectedFamilyTags.includes(tagUUID)
  }

  resetForm() {
    this.setState({
      selectedTags: this.props.associatedTags.records,
      isDirty: false
    })
  }

  render() {
    return (
      <React.Fragment>
        <Prompt
        when={this.state.isDirty}
        message='You have unsaved changes, are you sure you want to leave?'
        />
        <Grid style={{ width: "100%" }}>
          <Cell col={4} style={{ padding: "20px", marginTop: "30px", marginBottom: "150px" }}>
            <Card shadow={1}>
              <CardTitle>Tags</CardTitle>
              <div style={{margin:"25px"}}>
                <TagTree
                  tags={this.props.tags}
                  selectTag={t => this.selectTag(t)}
                  isNodeSelected={nodeUUID => this.isNodeSelected(nodeUUID)}
                  isNodeOpen={nodeUUID => this.isNodeOpen(nodeUUID)}
                />
              </div>
            </Card>
          </Cell>
          <Cell col={8}>
            {this.state.selectedTags.length > 0 || this.state.isDirty ? <Grid style={{ width: "100%" }}>
              <Cell col={6}>
                <h3>Selected Tags</h3>
              </Cell>
              <Cell col={5} style={{ textAlign: "right" }}>
                <Button disabled={!this.state.isDirty || !this.props.item.UUID} accent onClick={this.handleSaveTagButton}>
                  <Icon name="save" />
                  Save
                </Button>
                <Button disabled={!this.state.isDirty} accent onClick={() => this.resetForm()}>
                  <Icon name="cancel" />
                  Cancel
                </Button>
              </Cell>
            </Grid> : null}
            <Cell col={12}>
              <List>
                {this.state.selectedTags.map((tag, index) => {
                  return (
                    <ListItem key={index} style={selectedTagStyles}>
                      <Grid>
                        <Cell col={6}>
                          <span style={{ fontSize: "20px" }}>{tag.name}</span>
                          {tag.familyName ? (
                            <span style={{ fontSize: "17px", color: "#009abf", marginLeft: "5px" }}>
                              ({tag.familyName})
                            </span>
                          ) : null}
                        </Cell>
                        <Cell col={3}>
                          {tag.altParent ? (
                            <div>
                              Alt Parent
                              <span style={{ fontSize: "17px", marginLeft: "10px", color: "#00556f" }}>
                                {tag.altParentName}
                              </span>
                            </div>
                          ) : null}
                        </Cell>
                        <Cell col={2}>
                          <Button style={{marginTop: "-15px"}} onClick={() => this.removeTag(tag)}>
                            <Icon name="clear" />
                          </Button>
                        </Cell>
                      </Grid>
                    </ListItem>
                  )
                })}
              </List>
            </Cell>
          </Cell>
        </Grid>
      </React.Fragment>
    )
  }
}

const TagManager = connect(
  null,
  mapDispatchToProps
)(tagManager)

export default TagManager
