import Modal from 'react-modal'
import React from 'react'
import { CardTitle, Card, Button, CardActions, Grid, Cell } from 'react-mdl'

export default class ConfirmModal extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return (
          <Modal
            appElement={this.props.contentSection}
            isOpen={this.props.showDialog}
            onRequestClose={() => this.props.dialogCancel()}
            contentLabel="">
            <Card style={{ maxWidth: "25%" }} shadow={2}>
              <CardTitle>{this.props.dialogTitle}</CardTitle>
              <CardActions border>
                <Grid>
                  <Cell col={12}>{this.props.dialogText}</Cell>
                  <Cell col={12}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", borderTop:"10px"}}>
                      <Button id="ok" raised colored onClick={() => this.props.onOk()}>
                      OK
                      </Button>
                      <Button id="cancel" raised colored onClick={() => this.props.dialogCancel()}>
                        Cancel
                      </Button>
                    </div>
                  </Cell>
                </Grid>
              </CardActions>
            </Card>
          </Modal>
        )
    }
}