import React from 'react'
import { connect } from 'react-redux'
import FilterList from '../filterList'
import { deepClone } from '../../utils'
import { RemovableItem } from '../listItems'
import {Grid, Cell, Card, CardActions, CardTitle, Textfield, Icon, Button, IconButton, Tooltip, CardMenu} from 'react-mdl'

class formGroup extends React.Component{

    constructor(props){
        super(props)
        this.state = {
          group : deepClone(props.group),
          members: this.normalizeMembers(props.members, this.props.placements),
          isPlacementListDisplayed : false
        }
        this.props.setGetGroup(() => {
          return this.state.group
        })
        this.props.setGetMembers(() => {
          return this.state.members
        })
    }

    handleNameChange(name){
      this.handlePropertyChange('name', name)
    }
    
    handleMaxDaysChange(days){
      if (days.length != 0){
        this.handlePropertyChange('maxDays', parseInt(days))
      }
    }
    
    handleMaxSessionsChange(sessions){
      if (sessions.length != 0){
        this.handlePropertyChange('maxSubscriptions', parseInt(sessions))
      }
    }
    
    handleMaxImpressionsChange(impressions){
      if (impressions.length != 0){
        this.handlePropertyChange('impressionThreshold', parseInt(impressions))
      }
    }

    handlePropertyChange(propertyName, value){
      this.props.isDirty()
      const group = this.state.group
      group[propertyName] = value
      this.setState({
        group: group
      })
    }

    handleAddMember(member){
      this.state.members.push(member)
      this.props.isDirty()
      this.setState(this.state)
    }

    showPlacementList(){
      this.setState({
        isPlacementListDisplayed: true
      })
    }

    hidePlacementList(){
      this.setState({
        isPlacementListDisplayed: false
      })
    }

    placementSelected(placement){
      const member = {uuid: placement.UUID, name: placement.name}
      this.handleAddMember(member)
      this.hidePlacementList()
    }

    removeMember(index){
      this.props.isDirty()
      const members = this.state.members
      members.splice(index, 1)
      this.setState({
        members: members
      })
    }

    normalizePlacementList(placements, allMembers, currentMembers){
      const allUuids = allMembers.map(m => m.placementUUID)
      const currrentUuids = currentMembers.map(m => m.uuid)
      const allPlacementUuids = allUuids.concat(currrentUuids)
      return placements.filter(p => !allPlacementUuids.includes(p.UUID))
    }

    normalizeMembers(members, placements){
      if (members.length > 0 && placements.length > 0){
        return members.map((m) => { return {name: placements.find(p => p.UUID === m.placementUUID).name, uuid: m.placementUUID}})
      }
      return []
  }

    render(){
        return (
          <Card shadow={2} style={{ minWidth: "300px", width: "98%", height: "100%" }}>
          <CardActions border>
              <Grid>
              <Cell col={12}>
                  <Textfield
                  label="Group name"
                  floatingLabel
                  style={{ width: "100%" }}
                  value={this.state.group.name}
                  onChange={ evt => this.handleNameChange(evt.target.value) }
                  />
              </Cell>
              </Grid>
              <Grid>
              <Cell col={4}>
                  <Textfield
                  label="Max days"
                  pattern="-?[0-9]*(\.[0-9]+)?"
                  error="Must be a number"
                  floatingLabel
                  onChange={ ev => this.handleMaxDaysChange(ev.target.value) }
                  value={this.state.group.maxDays}
                  maxLength={3}
                  />
              </Cell>
              <Cell col={4}>
                  <Textfield
                  label="Max sessions"
                  pattern="-?[0-9]*(\.[0-9]+)?"
                  error="Must be a number"
                  floatingLabel
                  onChange={ ev => this.handleMaxSessionsChange(ev.target.value) }
                  value={this.state.group.maxSubscriptions}
                  />
              </Cell>
              <Cell col={4}>
                  <Textfield
                  label="Impression threshold"
                  pattern="-?[0-9]*(\.[0-9]+)?"
                  error="Must be a number"
                  floatingLabel
                  onChange={ ev => this.handleMaxImpressionsChange(ev.target.value) }
                  value={this.state.group.impressionThreshold}
                  />
              </Cell>
              </Grid>
              <Grid>
                  <Cell col={8}><strong>Members</strong>
                    <Button
                      onClick={ () => this.showPlacementList() }>
                      <Icon name="add_circle" />
                    </Button>
                  </Cell>
                  <Cell col={6}>
                    {this.state.members ?
                      this.state.members.map((member, index) => {
                        return (
                          <RemovableItem
                            key={member.uuid}
                            UUID={member.uuid}
                            name={member.name}
                            onRemove={() => this.removeMember(index)}/>
                    )}): null}
                  </Cell>
                  <Cell col={6}>
                  {this.state.isPlacementListDisplayed ?
                    <Card shadow={2} >
                    <CardMenu>
                        <Tooltip label={<span>Close</span>}>
                        <IconButton
                            name="close"
                            onClick={() => this.hidePlacementList()}
                        />
                        </Tooltip>
                    </CardMenu>
                    <CardTitle>Placement List</CardTitle>
                    <CardActions border>
                      <FilterList
                        label="Placement list..."
                        primaryField="name"
                        additionalFieldList={['UUID']}
                        title="Placements"
                        selectItem={p => this.placementSelected(p)}
                        items={this.normalizePlacementList(this.props.placements, this.props.allMembers, this.state.members)}
                      />
                    </CardActions>
                    </Card>
                      : null
                  }
                  </Cell>
              </Grid>
          </CardActions>
          </Card>
        )
    }
}

const FormGroup = connect(null, null)(
    formGroup,
)

export default FormGroup