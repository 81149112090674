import React from 'react'
import HttpInteractionManager from '../requestBuilder/interaction'
import WinbackDetail from './detail'
import WinbackLogic from './logic'
import WinbackCampaigns from './campaignAssociation'
import { deepClone } from '../../utils'
import {Grid, Cell, Tab, Tabs} from 'react-mdl'


export default class Winback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      winback: deepClone(this.props.winback)
    }
    this.props.setGetWinback(() => this.state.winback)
    this.handleHttpIntereactionAdd = this.handleHttpIntereactionAdd.bind(this)
    this.handleInteractionPreauthKeyChange = this.handleInteractionPreauthKeyChange.bind(this)
    this.handleInteractionDelete = this.handleInteractionDelete.bind(this)
    this.handleInteractionPreauthKeyExpiration = this.handleInteractionPreauthKeyExpiration.bind(this)
    this.handleInteractionVerbChange = this.handleInteractionVerbChange.bind(this)
    this.handleInteractionUrlChange = this.handleInteractionUrlChange.bind(this)
    this.handleInteractionBodyChange = this.handleInteractionBodyChange.bind(this)
    this.handleInteractionHeadersUpdate = this.handleInteractionHeadersUpdate.bind(this)
    this.handleInteractionHeadersDelete = this.handleInteractionHeadersDelete.bind(this)
    this.handleInteractionHeadersSave = this.handleInteractionHeadersSave.bind(this)
    this.handleInteractionOrder = this.handleInteractionOrder.bind(this)
  }

  handleWinbackTitleChange(title){
    const {winback} = this.state
    winback.title = title
    this.updated()
    this.setState({
      winback : winback
    })
  }

  handleWinbackIfTrueToggle(){
    this.updated()
    this.setState({
      winbackIfTrue: !this.state.winback.winbackIfTrue
    })
  }

  handleWinbackLogicTemplateChange(content){
    const {winback} = this.state
    winback.logicTemplate = content
    this.updated()
    this.setState({
      winback : winback
    })
  }

  handleHttpIntereactionAdd(interaction){
    this.state.winback.interactions.interactions.push(interaction)
    this.updated()
  }

  handleInteractionDelete(index){
    this.state.winback.interactions.interactions.splice(index, 1)
    this.state.winback.interactions.interactions.forEach((i, index)=>i.order = index + 1)
    this.updated()
  }

  handleInteractionPreauthKeyChange(interactionIndex, key){
    this.state.winback.interactions.interactions.map((interaction, index)=>{
        if (index === interactionIndex){
            interaction.preauth.key = key
        }
    })
    this.updated()
  }

  handleInteractionPreauthKeyExpiration(interactionIndex, keyExpiration){
    this.state.winback.interactions.interactions.map((interaction, index)=>{
        if (index === interactionIndex){
            interaction.preauth.expiration = keyExpiration
        }
    })
    this.updated()
  }

  handleInteractionVerbChange(interactionIndex, mode, submode, verb){
    this.state.winback.interactions.interactions.map((interaction, index)=>{
        if (index === interactionIndex){
            if (mode){
              interaction[mode][submode].verb = verb
            }else{
              interaction[submode].verb = verb
            }
        }
    })
    this.updated()
  }

  handleInteractionUrlChange(interactionIndex, mode, submode, url){
    this.state.winback.interactions.interactions.map((interaction, index)=>{
        if (index === interactionIndex){
            if (mode){
              interaction[mode][submode].url = url
            }else{
              interaction[submode].url = url
            }
        }
    })
    this.updated()
  }

  handleInteractionBodyChange(interactionIndex, mode, submode, body){
    this.state.winback.interactions.interactions.map((interaction, index)=>{
        if (index === interactionIndex){
            interaction.preauth.request.body = body
            if (mode){
              interaction[mode][submode].body = body
            }else{
              interaction[submode].body = body
            }
        }
    })
    this.updated()
  }

  handleInteractionHeadersUpdate(interactionIndex, mode, submode, header){
    this.state.winback.interactions.interactions.map((interaction, index)=>{
        if (index === interactionIndex){
            const headers = (mode) ? interaction[mode][submode].headers : interaction[submode].headers
            headers.forEach((h)=>{
              if (h.key === header.Key){
                h.value = header.Value
              }else{
                headers.push(
                  {key: header.Key, value: header.Value}
                )
              }
            })
        }
    })
    this.updated()
  }

  handleInteractionHeadersDelete(interactionIndex, mode, submode, header){
    let idx = 0
    this.state.winback.interactions.interactions.map((interaction, index)=>{
      if (index === interactionIndex){
        const headers = (mode) ? interaction[mode][submode].headers : interaction[submode].headers
        headers.forEach(h=>{
          if (h.key === header.Key){
            headers.splice(idx, 1)
          }
          idx++
        })
      }
    })
    this.updated()
  }

  handleInteractionHeadersSave(interactionIndex, mode, submode, header){
    this.state.winback.interactions.interactions.map((interaction, index)=>{
      if (index === interactionIndex){
        let headers = (mode) ? interaction[mode][submode].headers : interaction[submode].headers
        headers = headers || []
        headers.push(
          {key: header.Key, value: header.Value}
        )
        if (mode){
          interaction[mode][submode].headers = headers
        }else{
          interaction[submode].headers = headers
        }
      }
    })
    this.updated()
  }

  handleInteractionOrder(interactionIndex, order){
    this.state.winback.interac.interactions.map((interaction, index)=>{
        if (index === interactionIndex){
            interaction.order = order
        }
    })
    this.updated()
  }

  updated() {
    this.props.updated()
    this.setState(this.state)
  }

  render() {
    this.elements = [
      <Grid key={1}>
        <Cell col={12}>
          <WinbackDetail
            winback={this.state.winback} 
            updated={() => this.props.updated()} 
            handleWinbackTitleChange={(value) => this.handleWinbackTitleChange(value)}
          />
        </Cell>
        <Cell col={12}>
          <WinbackLogic
            winback={this.state.winback} 
            updated={() => this.props.updated()} 
            handleWinbackIfTrueToggle={() => this.handleWinbackIfTrueToggle()}
            handleWinbackLogicTemplateChange={(c) => this.handleWinbackLogicTemplateChange(c)}
          />
        </Cell>
        <Cell col={12}>
          <HttpInteractionManager
            interactions={this.state.winback.interactions}
            updated={() => this.props.updated()}
            handleHttpIntereactionAdd={this.handleHttpIntereactionAdd}
            handleInteractionPreauthKeyChange={this.handleInteractionPreauthKeyChange}
            handleInteractionDelete={this.handleInteractionDelete}
            handleInteractionPreauthKeyExpiration={this.handleInteractionPreauthKeyExpiration}
            handleInteractionVerbChange={this.handleInteractionVerbChange}
            handleInteractionUrlChange={this.handleInteractionUrlChange}
            handleInteractionBodyChange={this.handleInteractionBodyChange}
            handleInteractionHeadersUpdate={this.handleInteractionHeadersUpdate}
            handleInteractionHeadersDelete={this.handleInteractionHeadersDelete}
            handleInteractionHeadersSave={this.handleInteractionHeadersSave}
            handleInteractionOrder={this.handleInteractionOrder}
          />
        </Cell>,
      </Grid>,
      <WinbackCampaigns
        key={2}
        campaigns={this.props.campaigns} 
        winbackCampaigns={this.state.winback.winbackCampaigns} 
        updated={() => this.props.updated()} 
        newCampaignGroup={() => this.newCampaignGroup()}
        removeGroupMember={(c) => this.removeGroupMember(c)}
        add={(wbc) => this.add(wbc)}
      />]
    return <section>
      <Grid style={{ marginTop: "-25px" }}>
        <Cell col={6}>
          <h3>{this.state.winback.title || 'New Winback'}</h3>
        </Cell>
      </Grid>
      <Tabs
        activeTab={this.state.activeTab}
        onChange={tabId => this.setState({ activeTab: tabId })}
        ripple
        style={{ width: '100%' }}
      >
        <Tab>DETAILS</Tab>
        <Tab>CAMPAIGN ASSOCIATIONS</Tab>
      </Tabs>
      <section>
        <div className="content">
          {this.elements[this.state.activeTab]}
        </div>
      </section>
    </section>
  }
}