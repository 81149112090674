import React from 'react'
import { connect } from 'react-redux'
import Editor from '../editor'
import {Grid, Cell, Card, CardActions, CardTitle, Icon, Button, Menu, ListItem, CardMenu, Tabs, Tab} from 'react-mdl'

const templateTab = 0
const cssTab = 1
const jsTab = 2
const previewTab = 3

class CustomCreative extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: templateTab,
      quickStarts: [
        { name: 'Headline With Subtext', creative: this.HeadlineWithSubtext() },
        { name: 'Headline Only', creative: this.HeadlineOnly() },
        { name: 'Headline With More Info', creative: this.HeadlineWithMoreInfoLink() },
      ],
    }
  }
  HeadlineWithSubtext() {
    let t = {}
    t.templatePattern = `<div>
  <div class="logo_box">
      <img src="{{index .Images "logo"}}" class="logo_image"/>
  </div>
  <div class="copy_box">
    <div class="header">{{index .Strings "header"}}</div>
    <div>{{index .Strings "body"}}</div>
    <a href="{{index .URLs "terms"}}">{{index .Strings "tandc"}}</a>
  </div>
</div>`
    t.css = `.header {
    font-weight: bold;
}

.logo_box {
	float: left;
	width: 20%;
}

.logo_image {
	max-width: 100%;
}

.copy_box {
	float: left;
	width: 80%;
}

`
    t.textStrings = {
      tandc: 'Terms & Conditions',
      header: 'This is the headline',
      body: 'This is the body of the creative',
    }
    t.imageUrls = {
      logo: 'http://www.google.com/logos/doodles/2015/googles-new-logo-5078286822539264.2-hp.gif',
    }
    t.linkUrls = { terms: 'http://www.permissiondata.com' }
    return t
  }

  HeadlineOnly() {
    let t = {}
    t.templatePattern = `<div>
<div class="logo_box">
  <img src="{{index .Images "logo"}}" class="logo_image"/>
</div>
<div class="copy_box">
  <div class="header">{{index .Strings "header"}}</div>
</div>
</div>`
    t.css = `.header {
  font-weight: bold;
}

.logo_box {
  float: left;
  width: 20%;
}

.logo_image {
  max-width: 100%;
}

.copy_box {
  float: left;
  width: 80%;
}

`
    t.textStrings = { header: 'This is the headline' }
    t.imageUrls = {
      logo: 'http://www.google.com/logos/doodles/2015/googles-new-logo-5078286822539264.2-hp.gif',
    }
    return t
  }

  HeadlineWithMoreInfoLink() {
    let t = {}
    t.templatePattern = `<div>
<div class="logo_box">
  <img src="{{index .Images "logo"}}" class="logo_image"/>
</div>
<div class="copy_box">
  <div class="header">{{index .Strings "header"}}</div>
  <a href="{{index .URLs "more"}}">{{index .Strings "more"}}</a>
</div>
</div>`
    t.css = `.header {
  font-weight: bold;
}

.logo_box {
  float: left;
  width: 20%;
}

.logo_image {
  max-width: 100%;
}

.options_container{
	text-align: center;
}

.copy_box {
  float: left;
  width: 80%;
}

`
    t.textStrings = {
      more: 'More info.',
      header: 'This is the headline',
      body: 'This is the body of the creative',
    }
    t.imageUrls = {
      logo: 'http://www.google.com/logos/doodles/2015/googles-new-logo-5078286822539264.2-hp.gif',
    }
    t.linkUrls = { more: 'http://www.permissiondata.com' }
    return t
  }

  render() {
    let frameSrc = 'data:text/html,<html><body style="margin:0px;">' + this.props.creativePreview
    let content = <div />
    switch (this.state.activeTab) {
      case templateTab:
        content = (
          <Editor
            style={{ width: "99%" }}
            code={this.props.creative.templatePattern || ""}
            onChange={c => {
              this.props.handleChange('templatePattern', c)
              this.setState(this.state)
            }}
            onBlur={() => {
              this.setState(this.state)
            }}
          />
        )
        break
      case cssTab:
        content = (
          <Editor
            style={{ width: "99%" }}
            code={this.props.creative.css || ""}
            onChange={c => {
              this.props.handleChange('css', c)
              this.setState(this.state)
            }}
            onBlur={() => {
              this.setState(this.state)
            }}
          />
        )
        break
      case jsTab:
        content = (
          <Editor
            style={{ width: "99%" }}
            code={this.props.creative.js || ""}
            onChange={c => {
              this.props.handleChange('js', c)
              this.setState(this.state)
            }}
            onBlur={() => {
              this.setState(this.state)
            }}
          />
        )
        break
      case previewTab:
        content = <iframe src={frameSrc} style={{ width: "99%" }} />
        break
    }
    return <Grid key={this.props.creative.uuid} style={{ width: "100%" }}>
      <Cell col={12}>
        <Card style={{ width: '99%' }} shadow={2}>
          <CardMenu>
            <Button id="quickstartmenu">
              <Icon name="more_vert" />
            </Button>
            <Menu ripple target="quickstartmenu">
              {this.state.quickStarts.map(c => {
                return (
                  <ListItem
                    key={c.name}
                    onClick={() => {
                      this.props.handleChange('templatePattern', c.creative.templatePattern)
                      this.props.handleChange('css', c.creative.css)
                      this.props.handleChange('js', c.creative.js)
                      this.setState(this.state)
                    }}
                  >
                    {c.name}
                  </ListItem>
                )
              })}
            </Menu>
          </CardMenu>
          <CardTitle>
            <Tabs
              activeTab={this.state.activeTab}
              onChange={tabId => this.setState({activeTab: tabId})}
              ripple
              style={{ width: '100%' }}
            >
              <Tab>TEMPLATE</Tab>
              <Tab>CSS</Tab>
              <Tab>JAVASCRIPT</Tab>
              <Tab>PREVIEW</Tab>
            </Tabs>
          </CardTitle>
          {content}
          <CardActions border>
            <Button
              disabled={!this.props.isDirty}
              accent
              onClick={() => {
                this.props.saveCreative()
                this.setState(this.state)
              }}
            >
              <Icon name="save" />Save
        </Button>
            <Button
              disabled={!this.props.isDirty}
              accent
              onClick={() => {
                this.props.reloadCreative()
                this.setState(this.state)
              }}
            >
              <Icon name="cancel" />Cancel
        </Button>
            <Button
              accent
              onClick={() => {
                this.setState({activeTab: 3})
                this.props.preview(this.state.creative)
              }}
            >
              <Icon name="open_in_new" />Preview
        </Button>
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  }
}

const Custom = connect()(CustomCreative)

export default Custom
