import React from 'react'
import moment from 'moment'
import FilterList from '../filterList'
import { deepClone } from '../../utils'
import { Grid, ListItemContent, ListItem, List, Textfield, Card, CardMenu, CardTitle, CardActions, Cell, Button, Icon } from 'react-mdl'

const MAX_LIST_ITEMS_TO_DISPLAY = 3

export default class DailyCapByCampaign extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newItem: false,
      isListRolledUp: true,
      showDetails: props.filter ? true:false,
      selectedItem: props.filter ? this.convertScheduleListFromPropsToState(deepClone(props.placement.campaignLeadCaps)).find(e=>e.campaignUuid === props.filter) : null,
    }
    this.maxListItemsToDisplay = MAX_LIST_ITEMS_TO_DISPLAY
  }

  convertScheduleListFromStateToProps(list) {
    let propList = list.map((rs) => {
      delete rs["id"]
      delete rs["campaignName"]
      delete rs["campaignShortCode"]
      return rs
    })
    return propList
  }

  convertScheduleListFromPropsToState(propList) {
    if (!propList) {
      return []
    }
    let list = propList.map((rs, idx) => {
        let camp = this.props.campaigns.find(c => c.UUID === rs.campaignUuid)
        rs.id = "daily-cap:" + idx
        if (camp) {
          rs.campaignName = camp.name
          rs.campaignShortCode = camp.shortCode
        } else {
          rs.campaignName = "NA"
          rs.campaignShortCode = "NA"
        }
        return rs
      })
      return list
    }

  getCampaignsNotAlreadySelected(campaigns, list) {
    let selectedCampaignUuids = list.map(a => a.campaignUuid)
    return campaigns.filter(c => !selectedCampaignUuids.includes(c.UUID))
  }

  handleCloseButton(){
    this.setState({
      selectedItem: null,
    })
  }

  handleSelectCampaign(campaign){
    const {selectedItem} = this.state
    selectedItem.campaignUuid = campaign.UUID
    selectedItem.campaignName = campaign.name
    selectedItem.campaignShortCode = campaign.shortCode
    this.setState({
      selectedItem: selectedItem,
    })
  }

  renderCampaignSelector(list) {
    let {campaigns} = this.props
    if(this.props.filter)campaigns = campaigns.filter(e=>e.UUID === this.props.filter)
    return (
      <Grid>
        <Cell col={12}>
          <Button
            onClick={() => this.handleCloseButton()}>
            <Icon name="close">Close</Icon>
          </Button>
        </Cell>
        <Cell col={12}>
          <FilterList
            label="Campaign list"
            selected={this.state.selectedItem.campaignUuid}
            additionalFieldList={["UUID", "shortCode"]}
            title="Campaigns"
            selectItem={(c) => this.handleSelectCampaign(c)}
            items={this.getCampaignsNotAlreadySelected(campaigns, list)}
          />
        </Cell>
      </Grid>
    )
  }

  handleCapChange(value){
    const {selectedItem} = this.state
    selectedItem.cap = value
    this.setState({
      selectedItem: selectedItem,
    })
  }

  handleUpdateClick(list, campaignUuid){
    let rsList = deepClone(list)
    let dailyCapClone = deepClone(this.state.selectedItem)
    
    let idx = rsList.findIndex(c => c.id == dailyCapClone.id)
    if (idx > -1) {
      rsList[idx] = dailyCapClone
    } else {
      rsList.unshift(dailyCapClone)
    }
    this.props.onSave(this.convertScheduleListFromStateToProps(rsList))
    this.props.retrieveAssociationDailyCapCount(campaignUuid)
    this.setState(this.state)
  }

  handleNewButton(){
    this.setState({
      newItem: false,
      selectedItem: null,
    })
  }

  handleDeleteClick(list, campaignUuid){
    let rsList = deepClone(list)
    let idx = rsList.findIndex(c => c.id == this.state.selectedItem.id)
    rsList.splice(idx, 1)
    this.props.onDelete(this.convertScheduleListFromStateToProps(rsList))
    this.props.retrieveAssociationDailyCapCount(campaignUuid)
    this.setState(this.state)
  }

  renderDetails(list) {
    return (
      <Grid>
        <Cell col={12}>{this.state.selectedItem.campaignName}</Cell>
        <Cell col={12}>
          <Grid>
            <Cell col={4}>
              <Textfield
                pattern="^(?![\s\S])|([0-9]+)"
                error="Input is not a number!"
                style={{ width: "100%" }}
                floatingLabel
                label="Daily Cap"
                onChange={(e) => this.handleCapChange(e.target.value)}
                value={this.state.selectedItem.cap}
              />
            </Cell>
            <Cell col={4}>
              <Button
                id="daily-cap-save-button"
                onClick={() => this.handleUpdateClick(list, this.state.selectedItem.campaignUuid)}>
                <Icon name="save">Save</Icon>
              </Button>
              {!this.state.newItem ? (
                <Button
                  onClick={() => this.handleDeleteClick(list, this.state.selectedItem.campaignUuid)}>
                  <Icon name="delete">Delete</Icon>
                </Button>
              ) : null}
              <Button
                onClick={() => this.handleNewButton()}>
                <Icon name="close">Close</Icon>
              </Button>
            </Cell>
            <Cell col={4}>
              {this.state.selectedItem.warning100PercentTimestamp > 0 && 
              <div>Last warning sent on:<br></br>{moment.unix(parseInt(this.state.selectedItem.warning100PercentTimestamp)).format('MM/DD/YYYY, h:mm a')}</div>}
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    )
  }

  handleSelectItem(rs){
    this.setState({
      newItem: false,
      selectedItem: deepClone(rs)
    })
  }

  applyFilter(list){
    return this.props.filter ? list.filter((c) => c.campaignUuid == this.props.filter) : list
  }

  renderScheduleList(list) {
    const filteredList = this.applyFilter(list)
    let shortList = filteredList.slice(
      0,
      this.state.isListRolledUp ? this.maxListItemsToDisplay : list.length
    )
    return (
      <List id="daily-cap-by-campaign-list">
        {shortList.map((rs) => {
          if(rs.campaignShortCode == "NA"){
            return false
          }

          let dailyCapCount = this.props.dailyCapCounts[rs.campaignUuid]

          let subtitle = <div>
            {`${rs.campaignName} - ${rs.campaignShortCode}`}
            {dailyCapCount && <>
              {dailyCapCount.error && ` (Err:${dailyCapCount.error})`}
              {!dailyCapCount.error && ` (Count:${dailyCapCount.count} Left:${rs.cap - dailyCapCount.count})`}
              <Button onClick={(e) => {e.stopPropagation(); this.props.retrieveAssociationDailyCapCount(rs.campaignUuid)}}>
                <Icon name="refresh" />
              </Button>
            </>}
          </div>

          return (
            <ListItem
              twoLine
              key={rs.id}
              onClick={() => this.handleSelectItem(rs)}>
              <ListItemContent icon="chevron_right" subtitle={subtitle}>
                 {rs.cap}
              </ListItemContent>
            </ListItem>
          )
        })}
      </List>
    )
  }

  handleRollUpAndDownButton(e){
    e.preventDefault()
    this.setState(prevState => ({
      ...prevState,
      isListRolledUp: !prevState.isListRolledUp
    }))
  }

  renderRollUpAndDownButton() {
    return (
      <Button
        onClick={e => this.handleRollUpAndDownButton(e)}>
        {" "}
        <Icon name={this.state.isListRolledUp ? "keyboard_arrow_down" : "keyboard_arrow_up"} />
      </Button>
    )
  }

  //handleAddButton(){
  //  if(this.props.filter){
  //    let campaign = this.props.campaigns.find(e=>e.UUID == this.props.filter)
  //    this.setState({
  //      newItem: true,
  //      selectedItem: {cap: 0, campaignUuid: campaign.UUID, campaignName: campaign.name, campaignShortCode: campaign.shortCode}
  //    })
  //  }else{
  //    this.setState({
  //      newItem: true,
  //      selectedItem: {cap: 0}
  //    })
  //  }
  //}

  handleAddButton(){
    this.setState({
      newItem: true,
      selectedItem: {cap: 0}
    })
  }

  render() {
    let showDetails = this.state.selectedItem && this.state.selectedItem.campaignUuid
    let showCampaignSelector = this.state.selectedItem && !this.state.selectedItem.campaignUuid
    const campaignLeadCaps = this.props.placement.campaignLeadCaps
      ? this.convertScheduleListFromPropsToState(deepClone(this.props.placement.campaignLeadCaps))
      : []
    return (
      <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
        <CardTitle expand>
          <div style={{fontSize: "22px", fontWeight: "300"}}>Daily Cap by Campaign</div>
          {this.props.filter ?
            <div style={{display:"flex", justifyContent: "left", alignItems: "center", marginLeft: "15px", padding: "5px", color: "#009abf"}}>
              <div>Filtered</div>
              <div><Icon name="priority_hight" /></div>
            </div> : null}
        </CardTitle>
        <CardMenu>
          <Button
            id="new-daily-cap-button"
            onClick={() => this.handleAddButton()}>
            <Icon name="add_box">New</Icon>
          </Button>
        </CardMenu>
        <CardActions border>
          {showCampaignSelector ? this.renderCampaignSelector(campaignLeadCaps) : null}
          {showDetails ? this.renderDetails(campaignLeadCaps) : null}
          {!showCampaignSelector ? this.renderScheduleList(campaignLeadCaps) : null}
          {campaignLeadCaps.length > this.maxListItemsToDisplay ? (
            this.renderRollUpAndDownButton()
          ) : (
            <div></div>
          )}
        </CardActions>
      </Card>
    )
  }
}
