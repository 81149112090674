import React from 'react'
import Select, { components } from 'react-select'
import { Grid, Cell } from "react-mdl"

export class PlacementSelector extends React.Component {
  constructor(props) {
    super(props)

    this.isMulti = this.props.isMulti == undefined ? true : this.props.isMulti

    this.state = {
      placementFilter: props.placementFilter || "all",
      showOnlyActiveFilter: props.showOnlyActiveFilter ? props.showOnlyActiveFilter : false,
    }
  }

  onSelectedHandler(s) {
    this.props.onChange(s)
  }

  onFilterChangeHandler(e) {
    this.setState({
      placementFilter: e.target.value,
    })
  }

  render() {
    let { placementFilter, showOnlyActiveFilter } = this.state
    let filteredPlacements = this.props.placements.filter(e => {
      if (showOnlyActiveFilter && !e.activated) return false
      if (placementFilter == "all") return true
      if (placementFilter == "brokered_only") return e.isBrokered
      if (placementFilter == "nonbrokered_only") return !e.isBrokered
      return true
    })

    let placementOptions = filteredPlacements.map(e => {
      return { value: e.UUID, label: e.name + " - " + e.legacyID }
    })
    placementOptions.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : 0)


    const Menu = props => {
      return (
        <React.Fragment>
          <div onMouseDown={(e) => { e.preventDefault(); e.stopPropagation() }}>
            <Grid>
              <Cell col={4}>
                <Grid style={{ padding: "0px", margin: "0px" }}>
                  <Cell col={12} style={{ whiteSpace: "nowrap" }}>
                    <input
                      type="checkbox" name="placement_is-active-filter"
                      checked={this.state.showOnlyActiveFilter}
                      onChange={() => {
                        this.setState({
                          showOnlyActiveFilter: !this.state.showOnlyActiveFilter,
                        })
                      }}
                    /> Is Active
                  </Cell>
                </Grid>
              </Cell>
              <Cell col={8}>
                <Grid style={{ padding: "0px", margin: "0px" }}>
                  <Cell col={4} style={{ whiteSpace: "nowrap" }}>
                    <input
                      type="radio" name="placement_filter" value="all"
                      checked={this.state.placementFilter === "all"}
                      onChange={(e) => this.onFilterChangeHandler(e)}
                    /> All Types
                  </Cell>
                  <Cell col={4} style={{ whiteSpace: "nowrap" }}>
                    <input
                      type="radio" name="placement_filter" value="brokered_only"
                      checked={this.state.placementFilter === "brokered_only"}
                      onChange={(e) => this.onFilterChangeHandler(e)}
                    /> Brokered
                  </Cell>
                  <Cell col={4} style={{ whiteSpace: "nowrap" }}>
                    <input
                      type="radio" name="placement_filter" value="nonbrokered_only"
                      checked={this.state.placementFilter === "nonbrokered_only"}
                      onChange={(e) => this.onFilterChangeHandler(e)}
                    /> Non-Brokered
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </div>
          <components.Menu {...props}>{props.children}</components.Menu>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Select
          isMulti={this.isMulti}
          closeMenuOnSelect={this.isMulti ? false : true}
          value={this.props.selectedPlacements || []}
          onChange={s => this.onSelectedHandler(s)}
          options={placementOptions}
          components={{ Menu }}
        />
      </React.Fragment>
    )
  }
}
