
import moment from "moment"

export function unixToDisplay(val) {
  let date = new Date(val * 1000)
  return date.getDate() +
    '/' +
    (parseInt(date.getMonth()) + 1) +
    '/' +
    date.getFullYear()
}

export function displayToUnix(val) {
  return new Date(val * 1000).getTime() / 1000
}

export const debounce = (me, func, delay) => {
  return function () {
    const context = me
    const args = arguments
    clearTimeout(me.inDebounce)
    me.inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

export function normalizeRevenueShare(isAbsolute, value) {
  return (value / (isAbsolute ? 1000 : 10))
}

export const throttle = (me, func, limit) => {
  return function () {
    const context = me
    const args = arguments
    if (!me.lastRan) {
      func.apply(context, args)
      me.lastRan = Date.now()
    } else {
      clearTimeout(me.lastFunc)
      me.lastFunc = setTimeout(function () {
        if ((Date.now() - me.lastRan) >= limit) {
          func.apply(context, args)
          me.lastRan = Date.now()
        }
      }, limit - (Date.now() - me.lastRan))
    }
  }
}

export function UUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function uuidToColor(uuid) {
  if (!uuid || uuid.length < 6) {
    return 'initial'
  }

  let r = Math.round((parseInt(uuid.substring(0, 2), '16') + 512) / 3)
  let g = Math.round((parseInt(uuid.substring(2, 4), '16') + 512) / 3)
  let b = Math.round((parseInt(uuid.substring(4, 6), '16') + 512) / 3)

  let ret = '#' + r.toString('16') + g.toString('16') + b.toString('16')

  return ret
}

export function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function deepClone(obj) {
  if (obj) {
    return JSON.parse(JSON.stringify(obj))
  }
  return obj
}

export function getTagFamily(nodes, node) {
  if (node.parent === '') {
    return node
  }
  const parentNode = nodes.filter((n) => n.UUID === node.parent)[0]
  return getTagFamily(nodes, parentNode)
}

export function makeRandomString(length) {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function activeDates(item) {
  return ((item.startDate && (!item.endDate || item.endDate == "0")) || parseInt(item.endDate) > moment().unix() || !item.startDate)
}