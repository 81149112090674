import React from 'react'
import FilterList from '../filterList'
import { deepClone } from '../../utils'
import LeadCapItem from './leadCap.js'
import moment from 'moment'
import 'moment-timezone'
import { Grid, Cell, ListItemContent, ListItem, List, Card, CardMenu, CardTitle, CardActions, Button, Icon, FABButton } from 'react-mdl'
import { Collapsible } from "../common/components"
import { compareEffectiveDate, mapActive, findAssociationCurrentCap } from '../../utils/entityLedgers'
import { makeRandomString } from '../../utils'


const MAX_LIST_ITEMS_TO_DISPLAY = 5

class OverallCapByCampaign extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: null,
      showCampaignSelector: false,
      newItem: false,
      isListRolledUp: true,
      selectedItem: null,
      showDetails: props.filter ? true : false,
    }
  }

  componentDidMount() {
    let list = this.convertListFromPropsToState(this.props.list) || []
    let selectedItem = null
    if(this.props.filter){
      let s = list.find(e => e.campaignUuid === this.props.filter)
      if(s){
        selectedItem = this.makeNewShare(s.campaignUuid, s.campaignName)
      }else{
        selectedItem = null
      }
    }else{
      selectedItem = this.state.selectedItem
    }

    this.setState({
      list: list,
      selectedItem:  selectedItem,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list || prevProps.campaigns !== this.props.campaigns || prevProps.filter !== this.props.filter) {
      let list = this.convertListFromPropsToState(this.props.list)
      let selectedItem = null
      if(this.props.filter){
        let s = list.find(e => e.campaignUuid === this.props.filter)
        selectedItem = this.makeNewShare(s.campaignUuid, s.campaignName)
      }else{
        selectedItem = this.state.selectedItem
      }
      this.setState({
        list: this.props.list ? list : null,
        selectedItem: selectedItem,
      })
    }
  }

  resetState() {
    if(this.props.filter){
      const campaign = this.retrieveCampaignData(this.props.filter, this.props.campaigns)
      this.setState({
        selectedItem: this.makeNewShare(this.props.filter, campaign.name),
        showCampaignSelector: false,
      })
    }else{
      this.setState({
        selectedItem: null,
        showCampaignSelector: false,
      })
    }
  }

  handleDateChange(date) {
    const { selectedItem } = this.state
    selectedItem.effectiveDate = date ? `${date.unix()}` : '0'
    this.setState({
      selectedItem: selectedItem,
    })
  }

  handleItemClick(share) {
    const kk = deepClone(share)

    this.setState({
      newItem: false,
      selectedItem: kk
    })
  }

  getCampaignsNotAlreadySelected(campaigns, selectedList) {
    let selectedCampaignUuids = selectedList.map(a => a.campaignUuid)
    return campaigns.filter(c => !selectedCampaignUuids.includes(c.UUID))
  }

  makeNewShare(campaignUUID, campaignName) {
    return {
      value: 0.0,
      effectiveDate: `${moment().unix()}`,
      campaignUuid: campaignUUID,
      campaignName: campaignName,
      id: makeRandomString(5),
      isNew: true,
    }
  }

  retrieveCampaignData(campaignUUID, campaigns) {
    const campaign = campaigns.filter(c => c.UUID === campaignUUID)
    if (campaign.length) {
      return { name: campaign[0].name, shortCode: campaign[0].shortCode }
    }
    return { name: 'NA', shortCode: 'NA' }
  }

  convertListFromPropsToState(list) {
    let resultList = deepClone(list)

    resultList = resultList.map((brs) => {
      const campaign = this.retrieveCampaignData(brs.campaignUuid, this.props.campaigns)

      let item = {
        ...brs,
        currentItem: null,
        campaignName: campaign.name,
        campaignShortCode: campaign.shortCode,
      }

      let sublist = brs.overallLeadCap.sort(compareEffectiveDate).map(mapActive())
      sublist = sublist.map((rs) => {
        let subItem = {
          ...rs,
          id: makeRandomString(5),
          isNew: rs.isNew,
          campaignUuid: brs.campaignUuid,
          campaignName: campaign.name,
          campaignShortCode: campaign.shortCode,
        }
        if (rs.active || rs.scheduled) {
          item.currentItem = rs
        }
        return subItem
      })

      item.overallLeadCap = sublist
      return item
    })

    resultList = resultList.sort((a, b) => {
      if (a.campaignName < b.campaignName) {
        return -1
      }
      if (a.campaignName > b.campaignName) {
        return 1
      }
      return 0
    })

    return resultList
  }

  convertListFromStateToProps(stateList) {
    let propList = stateList.map(brs => {
      return {
        campaignUuid: brs.campaignUuid,
        isLegacy: brs.isLegacy,
        overallLeadCap: brs.overallLeadCap.map(rs => {
          return {
            isNew: rs.isNew,
            value: rs.value,
            effectiveDate: rs.effectiveDate,
            warningCustomPercentTimestamp: rs.warningCustomPercentTimestamp,
            warningConfigs: rs.warningConfigs,
          }
        })
      }
    })
    return propList
  }

  handleAddListItemClick(campaignUuid, newItem) {
    let list = this.state.list
    let campaignAlreadyExists = (list.filter((e) => { return e.campaignUuid === campaignUuid }).length > 0)
    //add campaign if necessary
    if (!campaignAlreadyExists) {
      const campaign = this.retrieveCampaignData(campaignUuid, this.props.campaigns)
      const newCampaign = {
        campaignUuid: campaignUuid,
        currentItem: null,
        campaignName: campaign.name,
        campaignShortCode: campaign.shortCode,
        overallLeadCap: [],
      }
      list.push(newCampaign)
    }
    if (newItem) {
      list.map(crs => {
        if (crs.campaignUuid === campaignUuid) {
          crs.overallLeadCap.push(newItem)
        }
      })
    }

    let propList = this.convertListFromStateToProps(this.state.list)
    this.props.onChange(propList)
    this.resetState()
    this.setState({
      list: list,
    })
  }

  handleListItemSave(capItem) {
    let campaignUuid = this.state.selectedItem.campaignUuid
    let list = this.state.list

    //effective date - set the time of the future dates to midnight ET
    let timezone = "America/New_York"
    let tomorrowBeginningOfDay = moment().tz(timezone).add(1,'days').startOf('day').unix()
    
    if(capItem.effectiveDate >= tomorrowBeginningOfDay){
      capItem.effectiveDate = moment.unix(capItem.effectiveDate).tz(timezone).startOf('day').unix()
    }else if (capItem.effectiveDate < moment().unix()){
      capItem.effectiveDate = moment().unix()
    }

    //validation
    {
      const selectedCampaign = list.find(e => e.campaignUuid === campaignUuid)
      let singleCamapignCapList = [...selectedCampaign.overallLeadCap]
      let index = singleCamapignCapList.findIndex(e => e.id == capItem.id)

      if (index == -1) {
        singleCamapignCapList.push(capItem)
      } else {
        singleCamapignCapList[index] = capItem
      }

      const requiredMinimumEffectiveDate = moment().unix()
      const currentItems = singleCamapignCapList.filter(e => {
        return parseInt(e.effectiveDate) <= requiredMinimumEffectiveDate
      })

      if (currentItems.length == 0) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            errorMessage: "Current cap is required (only future caps are specified).",
          },
        })
        return
      }
    }

    //update
    list.map(crs => {
      if (crs.campaignUuid === campaignUuid) {
        let index = crs.overallLeadCap.findIndex(e => e.id == capItem.id)
        if (index == -1) {
          crs.overallLeadCap.push(capItem)
        } else {
          crs.overallLeadCap[index] = capItem
        }
      }
    })

    let propList = this.convertListFromStateToProps(list)

    this.props.onChange(propList)
    let currentCap = findAssociationCurrentCap(propList, campaignUuid)
    if(currentCap){
      this.props.retrieveAssociationOverallCapCount(campaignUuid, currentCap.effectiveDate)
    }
    this.resetState()
  }

  handleListItemDelete() {
    let item = this.state.selectedItem
    let list = this.state.list
    list.map(crs => {
      if (crs.campaignUuid === item.campaignUuid) {
        let index = crs.overallLeadCap.findIndex(e => e.id == item.id)
        if (index >= 0) {
          crs.overallLeadCap.splice(index, 1)
        }
      }
    })
    let propList = this.convertListFromStateToProps(list)
    this.props.onChange(propList)
    let currentCap = findAssociationCurrentCap(propList, item.campaignUuid)
    if(currentCap){
      this.props.retrieveAssociationOverallCapCount(item.campaignUuid, currentCap.effectiveDate)
    }
    this.resetState()
  }

  handleListItemCancel() {
    this.resetState()
  }

  renderDetails() {
    return (
      <Grid>
        <Cell col={12}>{this.state.selectedItem.campaignName}</Cell>
        {this.state.selectedItem.errorMessage && <Cell col={12} className="errorMessage">{this.state.selectedItem.errorMessage}</Cell>}
        <Cell col={12}>
          <LeadCapItem
            share={this.state.selectedItem}
            onCancel={() => this.handleListItemCancel()}
            onSave={(value, effectiveDate, warningCustomPercentTimestamp, warningConfigs) => {
              const listItem = {
                ...this.state.selectedItem,
                value: value,
                effectiveDate: effectiveDate,
                warningCustomPercentTimestamp: warningCustomPercentTimestamp,
                warningConfigs: warningConfigs,
              }
              this.handleListItemSave(listItem)
            }}
            onDelete={() => this.handleListItemDelete()}
          />
        </Cell>
      </Grid>)
  }

  applyFilter(list) {
    return this.props.filter ? list.filter((c) => c.campaignUuid == this.props.filter) : list
  }

  normalizeDate(date) {
    let resultDate = parseInt(date)
    if (!resultDate || isNaN(resultDate)) {
      return null
    }
    return moment.unix(resultDate)
  }

  formatValue(item) {
    return item.value
  }

  toggleHandler(f, campaignItem) {
    let s = this.makeNewShare(campaignItem.campaignUuid, campaignItem.campaignName)

    this.setState({
      selectedItem: f ? s : null,
    })
  }

  renderScheduleList() {
    const filteredList = this.applyFilter(this.state.list)
    const list = filteredList.slice(0, (this.state.isListRolledUp ? MAX_LIST_ITEMS_TO_DISPLAY : this.state.list.length))

    return list.map((item) => {
      if(item.campaignShortCode == "NA"){
        return false
      }
      
      let showDetails = this.state.selectedItem && this.state.selectedItem.campaignUuid === item.campaignUuid
      let itemColor = ""
      let itemValue = "N/A"
      let itemDate = "N/A"
      if (item.currentItem) {
        itemColor = item.currentItem.color
        itemValue = this.formatValue(item.currentItem)
        itemDate = moment.unix(item.currentItem.effectiveDate).format('l')
      }

      let currentCapCount = this.props.currentCapCounts[item.campaignUuid]

      let itemTitle = <div>
        {itemValue}
        {item.isLegacy && ` (legacy)`}
        {` at ${itemDate} -- ${item.campaignName} - ${item.campaignShortCode}`}
        {currentCapCount && <div>
          {currentCapCount.error && ` Err:${currentCapCount.error}`}
          {!currentCapCount.error && ` Count:${currentCapCount.count} Left:${itemValue - currentCapCount.count}`}
          <Button onClick={(e) => {e.stopPropagation(); this.props.retrieveAssociationOverallCapCount(item.campaignUuid, item.currentItem.effectiveDate)}}>
            <Icon name="refresh" />
          </Button>
        </div>}
      </div>

      return (<Collapsible
        key={`cap-share-by-campaign_${item.campaignUuid}`}
        title={itemTitle}
        color={itemColor}
        defaultOpen={showDetails}
        onToggle={(e) => this.toggleHandler(e, item)}
      >
        {!showDetails && <FABButton
          id="add-cap-by-campaign-button"
          onClick={() => {
            this.handleAddListItemClick(item.campaignUuid, null)
            this.setState({
              selectedItem: this.makeNewShare(item.campaignUuid, item.campaignName),
            })
          }}
          colored
        >
          <Icon name="add" />
        </FABButton>}
        {showDetails && this.renderDetails()}
        <List id={`"cap-share-by-campaign-sublist_"${item.campaignUuid}`}>
          {item.overallLeadCap.map((subitem, i) => {
            
            let timezone = "America/New_York"
            let startDate = moment.unix(subitem.effectiveDate).tz(timezone)
            
            return <ListItem
              twoLine
              style={{ color: subitem.color }}
              key={i}
              onClick={(e) => {e.stopPropagation(); this.handleItemClick(subitem) }}>
              <ListItemContent>
                <Grid style={{ width: '100%' }}>
                  <Cell col={6} style={{ margin: 0, lineHeight: "24px" }}>
                    <span>{this.formatValue(subitem)}</span>
                  </Cell>
                  <Cell col={5} style={{ margin: 0, lineHeight: "24px" }}>
                    <span className="mdl-data-table__cell--non-numeric">
                    {startDate.format('YYYY-MM-DD hh:mm A z')}
                    </span>
                  </Cell>
                  <Cell col={1} style={{ margin: 0, lineHeight: "24px" }}>
                    <span>
                      {subitem.active
                        ? <Icon name="alarm_on" alt={"Active"} title={"Active"} />
                        : subitem.scheduled
                          ? <Icon name="alarm" alt={"Scheduled"} title={"Scheduled"} />
                          : <Icon name="alarm_off" alt={"Past date"} title={"Past date"} />}
                    </span>
                  </Cell>
                </Grid>
              </ListItemContent>
            </ListItem>
          })}
        </List>
      </Collapsible>)

    })
  }

  hanleRollUpAndDownButton(e) {
    e.preventDefault()
    this.setState(prevState => ({
      ...prevState,
      isListRolledUp: !prevState.isListRolledUp
    }))
  }

  renderRollUpAndDownButton() {
    return (
      <Button
        onClick={e => this.hanleRollUpAndDownButton(e)}>
        {" "}
        <Icon name={this.state.isListRolledUp ? "keyboard_arrow_down" : "keyboard_arrow_up"} />
      </Button>
    )
  }

  renderCampaignSelector() {
    let { campaigns } = this.props
    if (this.props.filter) campaigns = campaigns.filter(e => e.UUID === this.props.filter)
    return (
      <Grid>
        <Cell col={12}>
          <Button onClick={() => this.resetState()}>
            <Icon name="close">Close</Icon>
          </Button>
        </Cell>
        <Cell col={12}>
          <FilterList
            label="Campaign list"
            additionalFieldList={['UUID', 'shortCode']}
            title="Campaigns"
            selectItem={c => {
              this.handleAddListItemClick(c.UUID, null)
              this.setState({
                showCampaignSelector: false,
                selectedItem: this.makeNewShare(c.UUID, c.name),
                isListRolledUp: false,
              })
            }}
            items={this.getCampaignsNotAlreadySelected(campaigns, this.state.list)}
          />
        </Cell>
      </Grid>
    )
  }

  render() {
    if (!this.state.list) {
      return (
        <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
          <CardTitle expand>
            <div style={{ fontSize: "22px", fontWeight: "300" }}>Overall Cap by Campaign</div>
          </CardTitle>
          <CardMenu>
          </CardMenu>
          <CardActions border>
            <div>N/A</div>
          </CardActions>
        </Card>
      )
    }

    return (
      <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
        <CardTitle expand>
          <div style={{ fontSize: "22px", fontWeight: "300" }}>Overall Cap by Campaign</div>
          {this.props.filter ? <div style={{ display: "flex", justifyContent: "left", alignItems: "center", marginLeft: "15px", padding: "5px", color: "#009abf" }}>
            <div>Filtered</div>
            <div><Icon name="priority_hight" /></div>
          </div> : null}
        </CardTitle>
        <CardMenu>
          <Button
            id="add-cap-by-campaign-button"
            onClick={() => {
              this.setState({ showCampaignSelector: true })
            }}
          >
            <Icon name="add_box">New</Icon>
          </Button>
        </CardMenu>
        <CardActions border>
          <div>
            {this.state.showCampaignSelector ? this.renderCampaignSelector() : null}
            {!this.state.showCampaignSelector ? this.renderScheduleList() : null}
            {!this.state.showCampaignSelector && this.state.list.length > MAX_LIST_ITEMS_TO_DISPLAY ? this.renderRollUpAndDownButton() : <div></div>}
          </div>
        </CardActions>
      </Card>
    )
  }
}

export default OverallCapByCampaign
