import React from 'react'
import FilterList from '../filterList'
import { RemovableItem } from '../listItems'
import {Grid, Cell, Card, CardActions, CardTitle, Icon, Button, CardMenu} from 'react-mdl'

class InitialCampaign extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showCampaignList : false,
    }
  }

  displayCampaignList(){
    this.setState({
      showCampaignList: true,
    })
  }

  hideCampaignList(){
    this.setState({
      showCampaignList: false,
    })
  }

  normalizeCampaignItem(campaignUUID){
    const selectedCampaign = this.props.campaigns.find((c) => c.UUID === campaignUUID)
    if (!selectedCampaign) {
      return <div></div>
    }
    return (<div>
             <div><strong>{selectedCampaign.name}</strong><span>&nbsp;-&nbsp;{selectedCampaign.shortCode}</span></div>
             <div style={{marginTop: "10px", color: "grey"}}>{selectedCampaign.UUID}</div>
           </div>)
  }

  render(){
    return (
      <Card shadow={2} style={{ overflow: 'visible', width: '100%', paddingBottom: "122px" }}>
        <CardTitle expand>Initial campaign</CardTitle>
        <CardMenu>
          <Button
            id="add-initial-campaign-button"
            onClick={()=>this.displayCampaignList()}>
            <Icon name="add_box">Add</Icon>
          </Button>
        </CardMenu>
        <CardActions border>
          { this.state.showCampaignList ? 
          <Grid>
              <Cell col={12}>
                <Button onClick={() => this.hideCampaignList()}>
                  <Icon name="close">Close</Icon>
                </Button>
              </Cell>
              <Cell col={12}>
                <FilterList
                  label="Campaign list"
                  selected={this.props.initialCampaignUUID}
                  additionalFieldList={['UUID', 'shortCode']}
                  title="Campaigns"
                  selectItem={ (c)=> this.props.selectInitialCampaign(c.UUID) }
                  items={this.props.campaigns}
                />
              </Cell>
            </Grid>
          : null}
          {
            this.props.initialCampaignUUID ?
              <Cell col={12} id="initial-campaign-cell">
                <RemovableItem
                  key={this.props.initialCampaignUUID}
                  UUID={this.props.initialCampaignUUID}
                  name={this.normalizeCampaignItem(this.props.initialCampaignUUID)}
                  onRemove={() => this.props.removeInitialCampaign()}
                />
              </Cell>
            : 
            <Grid>
              <Cell col={12} id="empty-initial-campaign-cell">
                <div>No initial campaign added yet</div>
              </Cell>
            </Grid>
          }
        </CardActions>
      </Card>
    )
  }
}

export default InitialCampaign
