import React from 'react'
import FilterList from '../filterList'
import { deepClone } from '../../utils'
import RevShareItem from './revShare.js'
import moment from 'moment'
import { Grid, Cell, ListItemContent, ListItem, List, Card, CardMenu, CardTitle, CardActions, Button, Icon, FABButton } from 'react-mdl'
import { normalizeRevenueShare } from '../../utils'
import { Collapsible } from "../common/components"
import { compareEffectiveDate, mapActive } from '../../utils/entityLedgers'
import { makeRandomString } from '../../utils'


const MAX_LIST_ITEMS_TO_DISPLAY = 5

class RevenueShareByCampaign extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      list: null,
      showCampaignSelector: false,
      newItem: false,
      isListRolledUp: true,
      selectedItem: null,
      showDetails: props.filter ? true : false,
    }
  }

  componentDidMount() {
    let list = this.convertListFromPropsToState(this.props.list)
    let selectedItem = null
    if(this.props.filter){
      let s = list.find(e => e.campaignUuid === this.props.filter)
      if(s){
        selectedItem = this.makeNewShare(s.campaignUuid, s.campaignName)
      }else{
        selectedItem = null
      }
    }else{
      selectedItem = this.state.selectedItem
    }
    this.setState({
      list: list,
      selectedItem:  selectedItem,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list || prevProps.campaigns !== this.props.campaigns || prevProps.filter !== this.props.filter) {
      let list = this.convertListFromPropsToState(this.props.list)
      let selectedItem = null
      if(this.props.filter){
        let s = list.find(e => e.campaignUuid === this.props.filter)
        selectedItem = this.makeNewShare(s.campaignUuid, s.campaignName)
      }else{
        selectedItem = this.state.selectedItem
      }
      this.setState({
        list: this.props.list ? list : null,
        selectedItem: selectedItem,
      })
    }
  }

  resetState() {
    if(this.props.filter){
      const campaign = this.retrieveCampaignData(this.props.filter, this.props.campaigns)
      this.setState({
        selectedItem: this.makeNewShare(this.props.filter, campaign.name),
        showCampaignSelector: false,
      })
    }else{
      this.setState({
        selectedItem: null,
        showCampaignSelector: false,
      })
    }
  }

  handleIsAbsoluteChange() {
    const { selectedItem } = this.state
    selectedItem.isAbsolute = !selectedItem.isAbsolute
    const absValue = Number.parseInt((selectedItem.tenthOfCent * (selectedItem.isAbsolute ? 1000 : 10)).toFixed(0))
    if (absValue !== selectedItem.tenthOfCent) {
      selectedItem.tenthOfCent = absValue
    }
    selectedItem.tenthOfCent = (selectedItem.tenthOfCent / (selectedItem.isAbsolute ? 1000 : 10)).toFixed(selectedItem.isAbsolute ? 3 : 1)
    this.setState({
      selectedItem: selectedItem,
    })
  }

  handleDateChange(date) {
    const { selectedItem } = this.state
    selectedItem.effectiveDate = date ? `${date.unix()}` : '0'
    this.setState({
      selectedItem: selectedItem,
    })
  }

  handleUpdateShare(share) {
    const kk = deepClone(share)
    this.setState({
      newItem: false,
      selectedItem: kk
    })
  }

  getCampaignsNotAlreadySelected(campaigns, selectedList) {
    let selectedCampaignUuids = selectedList.map(a => a.campaignUuid)
    return campaigns.filter(c => !selectedCampaignUuids.includes(c.UUID))
  }

  makeNewShare(campaignUUID, campaignName) {
    return {
      tenthOfCent: 0.0,
      isAbsolute: true,
      effectiveDate: `${moment().startOf('day').unix()}`,
      campaignUuid: campaignUUID,
      campaignName: campaignName,
      id: makeRandomString(5),
    }
  }

  retrieveCampaignData(campaignUUID, campaigns) {
    const campaign = campaigns.filter(c => c.UUID === campaignUUID)
    if (campaign.length) {
      return { name: campaign[0].name, shortCode: campaign[0].shortCode }
    }
    return { name: 'NA', shortCode: 'NA' }
  }

  convertListFromPropsToState(list) {
    let resultList = deepClone(list)

    resultList.forEach((brs) => {
      const campaign = this.retrieveCampaignData(brs.campaignUuid, this.props.campaigns)
      brs.currentItem = null
      brs.campaignName = campaign.name
      brs.campaignShortCode = campaign.shortCode

      let sublist = brs.revenueShare.sort(compareEffectiveDate).map(mapActive())

      sublist.forEach((rs) => {
        rs.id = makeRandomString(5)
        rs.campaignUuid = brs.campaignUuid
        rs.campaignName = campaign.name
        rs.campaignShortCode = campaign.shortCode
        if (rs.active || rs.scheduled) {
          brs.currentItem = rs
        }
      })
      brs.revenueShare = sublist
    })

    resultList = resultList.sort((a, b) => {
      if (a.campaignName < b.campaignName) {
        return -1
      }
      if (a.campaignName > b.campaignName) {
        return 1
      }
      return 0
    })

    return resultList
  }

  convertListFromStateToProps(stateList) {
    let propList = stateList.map(brs => {
      return {
        campaignUuid: brs.campaignUuid,
        revenueShare: brs.revenueShare.map(rs => {
          return {
            tenthOfCent: rs.tenthOfCent,
            isAbsolute: rs.isAbsolute,
            effectiveDate: rs.effectiveDate
          }
        })
      }
    })
    return propList
  }

  handleAddListItemClick(campaignUuid, newItem) {
    let list = this.state.list
    let campaignAlreadyExists = (list.filter((e) => { return e.campaignUuid === campaignUuid }).length > 0)
    //add campaign if necessary
    if (!campaignAlreadyExists) {
      const campaign = this.retrieveCampaignData(campaignUuid, this.props.campaigns)
      const newCampaign = {
        campaignUuid: campaignUuid,
        currentItem: null,
        campaignName: campaign.name,
        campaignShortCode: campaign.shortCode,
        revenueShare: [],
      }
      list.push(newCampaign)
    }
    if (newItem) {
      list.map(crs => {
        if (crs.campaignUuid === campaignUuid) {
          crs.revenueShare.push(newItem)
        }
      })
    }

    let propList = this.convertListFromStateToProps(this.state.list)
    this.props.onChange(propList)
    this.resetState()
    this.setState({
      list: list,
    })
  }

  handleListItemSave(share) {
    let campaignUuid = this.state.selectedItem.campaignUuid
    let list = this.state.list

    list.map(crs => {
      if (crs.campaignUuid === campaignUuid) {
        let index = crs.revenueShare.findIndex(e => e.id == share.id || e.effectiveDate == share.effectiveDate)
        if (index == -1) {
          crs.revenueShare.push(share)
        } else {
          crs.revenueShare[index] = share
        }
      }
    })

    let propList = this.convertListFromStateToProps(list)
    this.props.onChange(propList)
    this.resetState()
  }

  handleListItemDelete() {
    let item = this.state.selectedItem
    let list = this.state.list
    list.map(crs => {
      if (crs.campaignUuid === item.campaignUuid) {
        let index = crs.revenueShare.findIndex(e => e.id == item.id)
        if (index >= 0) {
          crs.revenueShare.splice(index, 1)
        }
      }
    })
    let propList = this.convertListFromStateToProps(list)
    this.props.onChange(propList)
    this.resetState()
  }

  handleListItemCancel() {
    this.resetState()
  }

  renderDetails() {
    return (
      <Grid>
        <Cell col={12}>{this.state.selectedItem.campaignName}</Cell>
        <Cell col={12}>
          <RevShareItem
            isBrokered={this.props.placement.isBrokered}
            share={this.state.selectedItem}
            onCancel={() => this.handleListItemCancel()}
            onSave={(tenthOfCent, effectiveDate, isAbsolute) => {
              const listItem = {
                ...this.state.selectedItem,
                tenthOfCent: tenthOfCent,
                effectiveDate: effectiveDate,
                isAbsolute: isAbsolute,
              }
              this.handleListItemSave(listItem)
            }}
            onDelete={() => this.handleListItemDelete()}
          />
        </Cell>
      </Grid>)
  }

  applyFilter(list) {
    return this.props.filter ? list.filter((c) => c.campaignUuid == this.props.filter) : list
  }

  normalizeDate(date) {
    let resultDate = parseInt(date)
    if (!resultDate || isNaN(resultDate)) {
      return null
    }
    return moment.unix(resultDate)
  }

  formatValue(item) {
    return `${(item.isAbsolute ? '$' : '')}${normalizeRevenueShare(item.isAbsolute, item.tenthOfCent)}${(item.isAbsolute ? '' : '%')}`
  }

  toggleHandler(f, campaign) {
    this.setState({
      selectedItem: f ? campaign : null,
    })
  }

  renderScheduleList() {
    const filteredList = this.applyFilter(this.state.list)
    const list = filteredList.slice(0, (this.state.isListRolledUp ? MAX_LIST_ITEMS_TO_DISPLAY : this.state.list.length))
    
    return list.map((item) => {
      if(item.campaignShortCode == "NA"){
        return false
      }
      
      let showDetails = this.state.selectedItem && this.state.selectedItem.campaignUuid === item.campaignUuid
      let itemColor = ""
      let itemValue = "N/A"
      let itemDate = "N/A"
      if (item.currentItem) {
        itemColor = item.currentItem.color
        itemValue = this.formatValue(item.currentItem)
        itemDate = moment.unix(item.currentItem.effectiveDate).format('l')
      }
      let itemTitle = `${itemValue} at ${itemDate} - ${item.campaignName} - ${item.campaignShortCode}`
      if (item.currentItem && item.currentItem.scheduled) {
        itemColor = item.currentItem.color
        itemValue = this.formatValue(item.currentItem)
        itemDate = moment.unix(item.currentItem.effectiveDate).format('l')
        itemTitle = `${itemValue} at ${itemDate} -- ${item.campaignName} - ${item.campaignShortCode}`
      }

      return (<Collapsible
        key={`revenue-share-by-campaign_${item.campaignUuid}`}
        title={itemTitle}
        color={itemColor}
        defaultOpen={showDetails}
        onToggle={(e) => this.toggleHandler(e, item)}
      >
        {!showDetails && <FABButton
          id="add-revenue-by-campaign-button"
          onClick={() => {
            this.handleAddListItemClick(item.campaignUuid, null)
            this.setState({
              selectedItem: this.makeNewShare(item.campaignUuid, item.campaignName),
            })
          }}
          colored
        >
          <Icon name="add" />
        </FABButton>}
        {showDetails && this.renderDetails()}
        <List id={`"revenue-share-by-campaign-sublist_"${item.campaignUuid}`}>
          {item.revenueShare.map((subitem, i) => {
            return <ListItem
              twoLine
              style={{ color: subitem.color }}
              key={i}
              onClick={() => this.handleUpdateShare(subitem)}>
              <ListItemContent>
                <Grid style={{ width: '100%' }}>
                  <Cell col={6} style={{ margin: 0, lineHeight: "24px" }}>
                    <span>{this.formatValue(subitem)}</span>
                  </Cell>
                  <Cell col={5} style={{ margin: 0, lineHeight: "24px" }}>
                    <span className="mdl-data-table__cell--non-numeric">
                      {moment.unix(subitem.effectiveDate).format('l')}
                    </span>
                  </Cell>
                  <Cell col={1} style={{ margin: 0, lineHeight: "24px" }}>
                    <span>
                      {subitem.active
                        ? <Icon name="alarm_on" alt={"Active"} title={"Active"} />
                        : subitem.scheduled
                          ? <Icon name="alarm" alt={"Scheduled"} title={"Scheduled"} />
                          : <Icon name="alarm_off" alt={"Past date"} title={"Past date"} />}
                    </span>
                  </Cell>
                </Grid>
              </ListItemContent>
            </ListItem>
          })}
        </List>
      </Collapsible>)

    })
  }

  hanleRollUpAndDownButton(e) {
    e.preventDefault()
    this.setState(prevState => ({
      ...prevState,
      isListRolledUp: !prevState.isListRolledUp
    }))
  }

  renderRollUpAndDownButton() {
    return (
      <Button
        onClick={e => this.hanleRollUpAndDownButton(e)}>
        {" "}
        <Icon name={this.state.isListRolledUp ? "keyboard_arrow_down" : "keyboard_arrow_up"} />
      </Button>
    )
  }

  renderCampaignSelector() {
    let { campaigns } = this.props
    if (this.props.filter) campaigns = campaigns.filter(e => e.UUID === this.props.filter)
    return (
      <Grid>
        <Cell col={12}>
          <Button onClick={() => this.resetState()}>
            <Icon name="close">Close</Icon>
          </Button>
        </Cell>
        <Cell col={12}>
          <FilterList
            label="Campaign list"
            additionalFieldList={['UUID', 'shortCode']}
            title="Campaigns"
            selectItem={c => {
              this.handleAddListItemClick(c.UUID, null)
              this.setState({
                showCampaignSelector: false,
                selectedItem: this.makeNewShare(c.UUID, c.name),
                isListRolledUp: false,
              })
            }}
            items={this.getCampaignsNotAlreadySelected(campaigns, this.state.list)}
          />
        </Cell>
      </Grid>
    )
  }

  render() {
    if (!this.state.list) {
      return (
        <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
          <CardTitle expand>
            <div style={{ fontSize: "22px", fontWeight: "300" }}>Placement Rate by Campaign</div>
          </CardTitle>
          <CardMenu>
          </CardMenu>
          <CardActions border>
            <div>N/A</div>
          </CardActions>
        </Card>
      )
    }

    return (
      <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
        <CardTitle expand>
          <div style={{ fontSize: "22px", fontWeight: "300" }}>Placement Rate by Campaign</div>
          {this.props.filter ? <div style={{ display: "flex", justifyContent: "left", alignItems: "center", marginLeft: "15px", padding: "5px", color: "#009abf" }}>
            <div>Filtered</div>
            <div><Icon name="priority_hight" /></div>
          </div> : null}
        </CardTitle>
        <CardMenu>
          <Button
            id="add-revenue-by-campaign-button"
            onClick={() => {
              this.setState({ showCampaignSelector: true })
            }}
          >
            <Icon name="add_box">New</Icon>
          </Button>
        </CardMenu>
        <CardActions border>
          <div>
            {this.state.showCampaignSelector ? this.renderCampaignSelector() : null}
            {!this.state.showCampaignSelector ? this.renderScheduleList() : null}
            {!this.state.showCampaignSelector && this.state.list.length > MAX_LIST_ITEMS_TO_DISPLAY ? this.renderRollUpAndDownButton() : <div></div>}
          </div>
        </CardActions>
      </Card>
    )
  }
}

export default RevenueShareByCampaign
