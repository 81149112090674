import React from 'react'
import { deepClone } from '../../utils'
import RevShareItem from './revShare.js'
import moment from "moment"
import { ListItemContent, ListItem, List, Card, CardMenu, CardTitle, CardActions, Button, Icon } from 'react-mdl'
import {normalizeRevenueShare} from '../../utils'

const DEFAULT_MAX_LIST_ITEMS_TO_DISPLAY = 3

class RevenueShare extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isListRolledUp: true,
      share: null,
      newRevenueShare: false,
      selectedIndex: null,
      absoluteByDefault: this.props.absoluteByDefault
    }
  }

  handleNewButton(){
    const share = { tenthOfCent: 0.0, isAbsolute: false, effectiveDate: `${moment().startOf('day').unix()}`}
    if (this.state.absoluteByDefault) {
      share.isAbsolute = true
    }
    this.setState({
      selectedIndex: null,
      newRevenueShare: true,
      share: share,
    })
  }

  handleUpdateShare(share, index){
    this.setState({
      newRevenueShare: false,
      selectedIndex: index,
      share: deepClone(share),
    })
  }

  handleIsAbsoluteChange(){
    const {share} = this.state
    share.isAbsolute = !share.isAbsolute
    this.setState({
      share: share,
    })
  }

  handleDateChange(date){
    const {share} = this.state
    share.effectiveDate = date ? `${date.unix()}` : '0'
    this.setState({
      share: share,
    })
  }

  handleListItemCancel(){
    this.setState({
      share: null,
    })
  }

  addNewShare(share){
    this.props.onAdd(share)
    this.resetState()
  }

  updateShare(share, index){
    this.props.onUpdate(share, index)
    this.resetState()
  }

  handleListItemSave(revenueShare){
    if(this.state.selectedIndex){
      this.updateShare(revenueShare, this.state.selectedIndex)
    }else{
      this.addNewShare(revenueShare)
    }
  }

  handleListItemDelete(){
    this.props.onDelete(this.state.selectedIndex)
    this.resetState()
  }

  resetState(){
    this.setState({
      share: null,
      selectedIndex: null,
    })
  }

  renderDetails(){
    if (this.state.share){
      return (
        <RevShareItem
              share={this.state.share}
              onCancel={() => this.handleListItemCancel()}
              onSave={(tenthOfCent, effectiveDate, isAbsolute) => {
                const listItem = {
                  ...this.state.share,
                    tenthOfCent: tenthOfCent,
                    effectiveDate: effectiveDate,
                    isAbsolute: isAbsolute,
                }
                this.handleListItemSave(listItem)
              }}
              onDelete={() => this.handleListItemDelete()}
            />
      )
    }
  }

  renderList(){
    let list = this.props.list.sort((a, b) => {
      return a.effectiveDate < b.effectiveDate
    }).slice(0, (this.state.isListRolledUp ? DEFAULT_MAX_LIST_ITEMS_TO_DISPLAY: this.props.list.length))
    return (<List id="revenue-share-list">
      {list.map((rs, i) => {
        let date = ""
        if (!rs.effectiveDate || rs.effectiveDate == 0) {
          date = "No Date Set"
        } else {
          date = new moment.unix(rs.effectiveDate).format('MM/DD/YYYY')
        }
        return (
        <ListItem twoLine
          key={i}
          onClick={()=>this.handleUpdateShare(rs, i)}
        >
          <ListItemContent icon="chevron_right" subtitle={date}>
          {(rs.isAbsolute ? '$' : '%') + normalizeRevenueShare(rs.isAbsolute, rs.tenthOfCent)}
          </ListItemContent>
        </ListItem>)
      })}
    </List>)
  }

  handleRollUpAndDownButton(e){
    e.preventDefault()
    this.setState(prevState => ({
            ...prevState,
            isListRolledUp: !prevState.isListRolledUp,
          }))
  }

  renderRollUpAndDownButton(){
    return (<Button onClick={e => this.handleRollUpAndDownButton(e)}>
             <Icon name={this.state.isListRolledUp ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
          </Button>)
  }

  render(){
    return (
      <Card shadow={2} style={{ overflow: 'visible', width: '100%', minHeight: "254px" }}>
        <CardActions border>
          <CardTitle expand>Revenue Share </CardTitle>
          <CardMenu>
            <Button id="add-revenue-button" onClick={() => this.handleNewButton()}>
              <Icon name="add_box">New</Icon>
            </Button>
          </CardMenu>
          {this.renderDetails()}
          {this.renderList()}
          {this.props.list.length > DEFAULT_MAX_LIST_ITEMS_TO_DISPLAY ? this.renderRollUpAndDownButton() : <div></div>}
        </CardActions>
      </Card>
    )
  }
}

export default RevenueShare
