import moment from 'moment'

export function compareEffectiveDate(a, b) {
  if (a.effectiveDate < b.effectiveDate) {
    return 1
  }
  if (a.effectiveDate > b.effectiveDate) {
    return -1
  }
  return 0
}

export function mapActive() {
  var found = false
  return function (e, i, a) {
    let scheduled = e.effectiveDate > moment().unix()
    var active = !found &&
      !scheduled &&
      (i + 1 === a.length || compareEffectiveDate(a[i], a[i + 1]) < 1)
    found = found || active
    e.active = active
    e.scheduled = scheduled
    e.color = active ? '#5E8000' : e.scheduled ? '#CEA500' : '#8E8E8E'
    return JSON.parse(JSON.stringify(e))
  }
}

export function findCampaignCurrentRate(ledger) {
  let sortedLedger = ledger.sort(compareEffectiveDate)
  let now = moment().unix()

  for(let i=0;i<sortedLedger.length;i++){
    let e = sortedLedger[i]
  
    if(e.effectiveDate < now){
      return e
    }
  }
  return null
}

export function findAssociationDailyCap(ledger, campaignUUID) {
  return ledger.find(e=>e.campaignUuid == campaignUUID)
}

export function findAssociationCurrentCap(ledger, campaignUUID) {
  let campaignEntry = ledger.find(e=>e.campaignUuid == campaignUUID)
  if(!campaignEntry){
    return null
  }
  let capList = campaignEntry.overallLeadCap.sort(compareEffectiveDate)
  let now = moment().unix()

  for(let i=0;i<capList.length;i++){
    let e = capList[i]
    if(e.effectiveDate <= now){
      return e
    }
  }
  return null
}

export function findAssociationCurrentRate(ledger, campaignUUID) {
  let campaignEntry = ledger.find(e=>e.campaignUuid == campaignUUID)
  if(!campaignEntry){
    return null
  }
  let list = campaignEntry.revenueShare.sort(compareEffectiveDate)
  let now = moment().unix()

  for(let i=0;i<list.length;i++){
    let e = list[i]
  
    if(e.effectiveDate < now){
      return e
    }
  }
  return null
}

export function calculateAssociationRate(campaignCap, associationCap) {
  if (associationCap.IsAbsolute) {
		return associationCap.TenthOfCent / 1000
	} else {
		return campaignCap.Rate * associationCap.TenthOfCent / 10
	}
}