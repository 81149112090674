import {
    setCrud,
    isDirty,
    saveDarwinGroup,
    loadDarwinGroupMembers,
    retrievePlacements,
    saveDarwinGroupMember,
    deleteDarwinGroupMember,
    loadDarwinGroup,
    loadNewDarwinGroup,
    clearDarwinGroup,
  } from '../../services'

  import React from 'react'
  import { connect } from 'react-redux'
  import FilterList from '../filterList'
  import GroupForm from './groupForm'
  import {Grid, Cell, Card, CardActions, CardTitle} from 'react-mdl'
  import { Prompt } from 'react-router'
  
  function mapDispatchToProps(dispatch) {
    return {
      setCrud: function (crud) {
        dispatch(setCrud(crud))
      },
      isDirty: function () {
        dispatch(isDirty(true))
      },
      isNotDirty: function () {
        dispatch(isDirty(false))
      },
      saveDarwinGroup(group, userName) {
        dispatch(saveDarwinGroup(group, userName))
      },
      saveDarwinGroupMember(member) {
        dispatch(saveDarwinGroupMember(member))
      },
      loadDarwinGroupMembers(groupUuid, cb){
        dispatch(loadDarwinGroupMembers(groupUuid, cb))
      },
      deleteDarwinGroupMember(groupUuid, memberUuid){
        dispatch(deleteDarwinGroupMember(groupUuid, memberUuid))
      },
      retrievePlacements(){
        dispatch(retrievePlacements())
      },
      loadDarwinGroup(groupUUID) {
        dispatch(loadDarwinGroup(groupUUID))
      },
      loadNewDarwinGroup() {
        dispatch(loadNewDarwinGroup())
      },
      clearDarwinGroup() {
        dispatch(clearDarwinGroup())
      },
    }
  }

  
  class darwinGroups extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
      }
      this.props.isNotDirty()
      this.props.clearDarwinGroup()
      this.setGetMembers = (getMembers) => {
        this.getMembers = getMembers
      }
      this.getMembers = () => null
      this.setGetGroup = (getGroup) => {
        this.getGroup = getGroup
      }
      this.getGroup = () => null
    }
    
    componentDidMount() {
      let crudOptions = {
        onSave: () => this.saveDarwinGroup(),
        onCancel: () => {
          this.handleOnCancel()
        },
        onNew: () => {
          this.props.loadNewDarwinGroup()
        },
        visible: true,
      }
      this.props.setCrud(crudOptions)
    }

    saveDarwinGroup(){
      const stateMembers = this.getMembers()
      const storeMemberUuids = this.props.members.map(m => m.placementUUID)
      const stateMemberUuids = stateMembers.map(m => m.uuid)
      const group = this.getGroup()
      this.props.saveDarwinGroup(group, this.props.user.name)
      const membersToAdd = stateMembers.filter(m => !storeMemberUuids.includes(m.uuid))
      const membersToRemove = this.props.members.filter(m => !stateMemberUuids.includes(m.placementUUID))
      membersToAdd.map((m) =>{
          const member = {placementUUID: m.uuid, groupUUID: group.uuid, updatedBy: this.props.user.name}
          this.props.saveDarwinGroupMember(member)
      })
      membersToRemove.map((m) =>{
          this.props.deleteDarwinGroupMember(group.uuid, m.placementUUID)
      })
    }

    handleOnCancel(){
      if (this.props.darwinGroup.uuid) {
        const uuid = this.props.darwinGroup.uuid
        const selectedGroup = this.props.darwinGroups.find((g)=> g.uuid === uuid)
        this.groupSelected(selectedGroup)
      } else {
        this.props.isNotDirty()
        this.props.clearDarwinGroup()
      }
    }

    groupSelected(group){
      this.props.retrievePlacements()
      this.props.loadDarwinGroupMembers(group.uuid)
      this.props.loadDarwinGroup(group.uuid)
    }

    buildGroupKey(group, members){
      if (group){
        return `${group.uuid}-${members.length}-${Math.random()}`
      }
    }
  
    render() {
      let header = this.props.darwinGroup ? this.props.darwinGroup.uuid ? '' : 'New Darwin group' : 'Please select a Darwin group'
      return (
        <React.Fragment>
          <Prompt
              when={this.props.isDirtyValue}
              message='You have unsaved changes, are you sure you want to leave?'
          />
          <section ref={t => this.contentSection = t}>
            <div className="content">
              <Grid style={{ width: "100%" }}>
                  <Cell col={4}>
                    <Card shadow={2} className="mdl-cell mdl-cell--12-col" >
                    <CardTitle>
                        <h2 className="mdl-card__title-text">Darwin group List</h2>
                    </CardTitle>
                    <CardActions border>
                        <FilterList
                        label="Darwin groups list..."
                        selected={this.state.selectedGroup ? this.state.selectedGroup.uuid : null}
                        uuidLabel={'uuid'}
                        additionalFieldList={['uuid']}
                        title="Darwin groups"
                        selectItem={group => this.groupSelected(group)}
                        items={this.props.darwinGroups}
                        />
                    </CardActions>
                    </Card>
                  </Cell>
                  <Cell col={8}>
                    <Grid>
                      <Cell col={6}>
                      <h3>{header}</h3>
                      </Cell>
                    </Grid>
                    {this.props.darwinGroup ?
                      <section>
                        <GroupForm 
                          key={this.buildGroupKey(this.props.darwinGroup, this.props.members)}
                          group={this.props.darwinGroup}
                          members={this.props.members}
                          allMembers={this.props.darwinAllGroupsMembers}
                          placements={this.props.placements}
                          isDirty={this.props.isDirty}
                          setGetGroup={this.setGetGroup}
                          setGetMembers={this.setGetMembers}
                        /> 
                      </section>
                    : null
                    }
                  </Cell>
              </Grid>
              </div>
          </section>
        </React.Fragment>
      )
    }
  }
  
  const DarwinGroups = connect(null, mapDispatchToProps)(
    darwinGroups,
  )
  
  export default DarwinGroups
  