import React from 'react'
import FilterList from '../filterList'
import { deepClone } from '../../utils'
import LeadAcceptanceByCampaignEdit from './leadAcceptanceByCampaignEdit.js'
import moment from 'moment'
import { Grid, Cell, ListItemContent, ListItem, List, Card, CardMenu, CardTitle, CardActions, Button, Icon, FABButton, Tooltip } from 'react-mdl'
import { Collapsible } from "../common/components"
import { compareEffectiveDate, mapActive } from '../../utils/entityLedgers'
import { makeRandomString } from '../../utils'


const MAX_LIST_ITEMS_TO_DISPLAY = 5

class LeadAcceptanceByCampaign extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty: false,
      showCampaignSelector: false,
      newItem: false,
      isListRolledUp: true,
      selectedItem: null,
      isLoading: false,
      list: null,
    }
  }

  componentDidMount() {
    let list = this.convertListFromPropsToState(this.props.associatedLeadAcceptance.records) || []
    this.setState({
      list: list,
      isLoading: this.props.associatedLeadAcceptance.isLoading,
      isDirty: false,
      selectedItem: this.props.filter ? list.find(e=>e.campaignUuid === this.props.filter) : this.state.selectedItem,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.associatedLeadAcceptance !== this.props.associatedLeadAcceptance ||
      prevProps.campaigns !== this.props.campaigns) {
      let list = this.convertListFromPropsToState(this.props.associatedLeadAcceptance.records) || []
      this.setState({
        list: list,
        isLoading: this.props.associatedLeadAcceptance.isLoading,
        isDirty: !prevProps.associatedLeadAcceptance.records && this.props.associatedLeadAcceptance.records ? false : this.state.isDirty,
        selectedItem: this.props.filter ? list.find(e=>e.campaignUuid === this.props.filter) : this.state.selectedItem,
      })
    }
  }

  retrieveCampaignData(campaignUUID, campaigns) {
    const campaign = campaigns.filter(c => c.UUID === campaignUUID)
    if (campaign.length) {
      return { name: campaign[0].name, shortCode: campaign[0].shortCode }
    }
    return { name: 'NA', shortCode: 'NA' }
  }

  convertListFromPropsToState(list) {
    if (list == null) {
      return null
    }
    let resultList = deepClone(list)

    resultList.forEach((brs) => {
      const campaign = this.retrieveCampaignData(brs.campaignUuid, this.props.campaigns)
      brs.currentItem = null
      brs.campaignName = campaign.name
      brs.campaignShortCode = campaign.shortCode

      brs.LeadAcceptancePercentages.forEach((rs) => {
        rs.id = makeRandomString(5)
        rs.placementUuid = brs.placementUuid
        rs.campaignUuid = brs.campaignUuid
        rs.campaignName = campaign.name
        rs.campaignShortCode = campaign.shortCode
      })
    })

    resultList = this.resetCurrentItemsInList(resultList)

    resultList = resultList.sort((a, b) => {
      if (a.campaignName < b.campaignName) {
        return -1
      }
      if (a.campaignName > b.campaignName) {
        return 1
      }
      return 0
    })

    return resultList
  }

  resetCurrentItemsInList(l) {
    let list = l.map((brs) => {
      brs.LeadAcceptancePercentages = brs.LeadAcceptancePercentages
        .sort(compareEffectiveDate)
        .map(mapActive())
        .map((rs) => {
          if (rs.active || rs.scheduled) {
            brs.currentItem = rs
          }
          return rs
        })
      return brs
    })
    return list
  }

  convertListFromStateToProps(stateList) {
    let propList = stateList.map(item => {
      return {
        placementUuid: item.placementUuid,
        campaignUuid: item.campaignUuid,
        LeadAcceptancePercentages: item.LeadAcceptancePercentages.map(subItem => {
          return {
            value: subItem.value,
            effectiveDate: subItem.effectiveDate
          }
        })
      }
    })
    return propList
  }

  handleAddListItemClick(placementUuid, campaignUuid, share) {
    let list = this.state.list
    let campaignAlreadyExists = (list.filter((e) => { return e.campaignUuid === campaignUuid }).length > 0)
    //add campaign if necessary
    if (!campaignAlreadyExists) {
      const campaign = this.retrieveCampaignData(campaignUuid, this.props.campaigns)
      const newCampaign = {
        placementUuid: placementUuid,
        campaignUuid: campaignUuid,
        campaignName: campaign.name,
        campaignShortCode: campaign.shortCode,
        LeadAcceptancePercentages: []
      }
      list.push(newCampaign)
    }
    if (share) {
      list.map(crs => {
        if (crs.campaignUuid === campaignUuid) {
          crs.LeadAcceptancePercentages.push(share)
        }
      })
    }
    this.setState({ list: list, isDirty: true })
  }

  handleUpdate(share, shareId, campaignUuid) {
    let list = this.state.list
    list.map(crs => {
      if (crs.campaignUuid === campaignUuid) {
        let index = crs.LeadAcceptancePercentages.findIndex(e => e.id == shareId)
        if (index == -1) {
          crs.LeadAcceptancePercentages.push(share)
        } else {
          crs.LeadAcceptancePercentages[index] = share
        }
      }
    })
    this.setState({ list: list, isDirty: true })
  }

  handleDelete(shareId, campaignUuid) {
    let list = this.state.list
    list.map(crs => {
      if (crs.campaignUuid === campaignUuid) {
        let index = crs.LeadAcceptancePercentages.findIndex(e => e.id == shareId)
        crs.LeadAcceptancePercentages.splice(index, 1)
      }
    })
    this.setState({ list: list, isDirty: true })
  }

  handleDateChange(date) {
    const { selectedItem } = this.state
    selectedItem.effectiveDate = date ? `${date.unix()}` : '0'
    this.setState({
      selectedItem: selectedItem,
    })
  }

  handleUpdateShare(share) {
    const kk = deepClone(share)
    this.setState({
      newItem: false,
      selectedItem: kk
    })
  }

  getCampaignsNotAlreadySelected(campaigns, selectedList) {
    let selectedCampaignUuids = selectedList.map(a => a.campaignUuid)
    return campaigns.filter(c => !selectedCampaignUuids.includes(c.UUID))
  }

  makeNewShare(campaignUUID, campaignName) {
    return {
      value: 0,
      effectiveDate: `${moment().startOf('day').unix()}`,
      campaignUuid: campaignUUID,
      campaignName: campaignName,
      id: makeRandomString(5),
    }
  }

  handleListItemSave(share) {
    let campaignUuid = this.state.selectedItem.campaignUuid
    let list = this.state.list

    list.map(crs => {
      if (crs.campaignUuid === campaignUuid) {
        let index = crs.LeadAcceptancePercentages.findIndex(e => e.id == share.id)
        if (index == -1) {
          crs.LeadAcceptancePercentages.push(share)
        } else {
          crs.LeadAcceptancePercentages[index] = share
        }
        crs.currentItem = share
      }
    })

    list = this.resetCurrentItemsInList(list)

    this.setState({
      list: list,
      isDirty: true,
      selectedItem: null,
    })
  }

  handleListItemDelete() {
    let item = this.state.selectedItem
    let list = this.state.list
    list.map(crs => {
      if (crs.campaignUuid === item.campaignUuid) {
        let index = crs.LeadAcceptancePercentages.findIndex(e => e.id == item.id)
        if (index >= 0) {
          crs.LeadAcceptancePercentages.splice(index, 1)
        }
      }
    })
    list = this.resetCurrentItemsInList(list)

    this.setState({
      list: list,
      isDirty: true,
      selectedItem: null,
    })
  }

  handleListItemCancel() {
    this.setState({
      selectedItem: null,
    })
  }

  renderDetails() {
    return (
      <Grid>
        <Cell col={12}>{this.state.selectedItem.campaignName}</Cell>
        <Cell col={12}>
          <LeadAcceptanceByCampaignEdit
            share={this.state.selectedItem}
            onCancel={() => this.handleListItemCancel()}
            onSave={(value, effectiveDate) => {
              const listItem = {
                ...this.state.selectedItem,
                value: value,
                effectiveDate: effectiveDate,
              }
              this.handleListItemSave(listItem)
            }}
            onDelete={() => this.handleListItemDelete()}
          />
        </Cell>
      </Grid>)
  }


  applyFilter(list) {
    return this.props.filter ? list.filter((c) => c.campaignUuid == this.props.filter) : list
  }

  normalizeDate(date) {
    let resultDate = parseInt(date)
    if (!resultDate || isNaN(resultDate)) {
      return null
    }
    return moment.unix(resultDate)
  }

  formatValue(item) {
    return parseInt(item.value * 100) + `%`
  }

  toggleHandler(f, campaign){
    this.setState({
      selectedItem: f ? campaign: null,
    })
  }

  renderScheduleList() {
    const filteredList = this.applyFilter(this.state.list)
    const list = filteredList.slice(0, (this.state.isListRolledUp ? MAX_LIST_ITEMS_TO_DISPLAY : this.state.list.length))

    return list.map((item) => {
      if(item.campaignShortCode == "NA"){
        return false
      }

      let showDetails = this.state.selectedItem && this.state.selectedItem.campaignUuid === item.campaignUuid
      let itemColor = ""
      let itemValue = "N/A"
      let itemDate = "N/A"
      if (item.currentItem) {
        itemColor = item.currentItem.color
        itemValue = this.formatValue(item.currentItem)
        itemDate = moment.unix(item.currentItem.effectiveDate).format('l')
      }

      let itemTitle = `${itemValue} at ${itemDate} -- ${item.campaignName} - ${item.campaignShortCode}`
      return (<Collapsible
        key={`lead-acceptance-by-campaign_${item.campaignUuid}`}
        title={itemTitle}
        color={itemColor}
        defaultOpen={showDetails}
        onToggle={(e) => this.toggleHandler(e, item)}
      >
        {!showDetails && <Tooltip label="New entry"><FABButton
          id="add-lead-acceptance-by-campaign-button"
          onClick={() => {
            this.handleAddListItemClick(item.placementUuid, item.campaignUuid, null)
            this.setState({
              selectedItem: this.makeNewShare(item.campaignUuid, item.campaignName),
            })
          }}
          disabled={this.props.filter}
          colored
        >
          <Icon name="add" />
        </FABButton></Tooltip>}
        {showDetails && this.renderDetails()}
        <List id={`"lead-acceptance-by-campaign-sublist_"${item.campaignUuid}`}>
          {item.LeadAcceptancePercentages.map((subitem, i) => {
            return <ListItem
              twoLine
              style={{ color: subitem.color }}
              key={i}
              onClick={() => this.handleUpdateShare(subitem)}>
              <ListItemContent>
                <Grid style={{ width: '100%' }}>
                  <Cell col={6} style={{ margin: 0, lineHeight: "24px" }}>
                    <span>{this.formatValue(subitem)}</span>
                  </Cell>
                  <Cell col={5} style={{ margin: 0, lineHeight: "24px" }}>
                    <span className="mdl-data-table__cell--non-numeric">
                      {moment.unix(subitem.effectiveDate).format('l')}
                    </span>
                  </Cell>
                  <Cell col={1} style={{ margin: 0, lineHeight: "24px" }}>
                    <span>
                      {subitem.active
                        ? <Icon name="alarm_on" alt={"Active"} title={"Active"} />
                        : subitem.scheduled
                          ? <Icon name="alarm" alt={"Scheduled"} title={"Scheduled"} />
                          : <Icon name="alarm_off" alt={"Past date"} title={"Past date"} />}
                    </span>
                  </Cell>
                </Grid>
              </ListItemContent>
            </ListItem>
          })}
        </List>
      </Collapsible>)

    })
  }

  hanleRollUpAndDownButton(e) {
    e.preventDefault()
    this.setState(prevState => ({
      ...prevState,
      isListRolledUp: !prevState.isListRolledUp
    }))
  }

  renderRollUpAndDownButton() {
    return (
      <Button
        onClick={e => this.hanleRollUpAndDownButton(e)}>
        {" "}
        <Icon name={this.state.isListRolledUp ? "keyboard_arrow_down" : "keyboard_arrow_up"} />
      </Button>
    )
  }

  renderCampaignSelector() {
    let {campaigns} = this.props
    if(this.props.filter)campaigns = campaigns.filter(e=>e.UUID === this.props.filter)
    return (
      <Grid>
        <Cell col={12}>
          <Button onClick={() => {
            this.setState({
              showCampaignSelector: false,
            })
          }}>
            <Icon name="close">Close</Icon>
          </Button>
        </Cell>
        <Cell col={12}>
          <FilterList
            label="Campaign list"
            additionalFieldList={['UUID', 'shortCode']}
            title="Campaigns"
            selectItem={c => {
              this.handleAddListItemClick(this.props.placement.UUID, c.UUID, null)
              this.setState({
                showCampaignSelector: false,
                selectedItem: this.makeNewShare(c.UUID, c.name),
                isListRolledUp: false,
              })
            }}
            items={this.getCampaignsNotAlreadySelected(campaigns, this.state.list)}
          />
        </Cell>
      </Grid>
    )
  }

  handleSaveClick(list) {
    this.props.savePlacementAssociatedLeadAcceptances(
      this.props.placement.UUID, { records: this.convertListFromStateToProps(list) })
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
          <CardTitle expand>
            <div style={{ fontSize: "22px", fontWeight: "300" }}>Lead Acceptance by Campaign</div>
          </CardTitle>
          <CardMenu>
          </CardMenu>
          <CardActions border>
            <div>Loading ...</div>
          </CardActions>
        </Card>
      )
    }
    if (!this.state.list) {
      return (
        <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
          <CardTitle expand>
            <div style={{ fontSize: "22px", fontWeight: "300" }}>Lead Acceptance by Campaign</div>
          </CardTitle>
          <CardMenu>
          </CardMenu>
          <CardActions border>
            <div>N/A</div>
          </CardActions>
        </Card>
      )
    }

    return (
      <Card shadow={2} style={{ overflow: "visible", paddingBottom: "100px" }}>
        <CardTitle expand>
          <div style={{ fontSize: "22px", fontWeight: "300" }}>Lead Acceptance by Campaign</div>
          {this.props.filter ? <div style={{ display: "flex", justifyContent: "left", alignItems: "center", marginLeft: "15px", padding: "5px", color: "#009abf" }}>
            <div>Filtered</div>
            <div><Icon name="priority_hight" /></div>
          </div> : null}
        </CardTitle>
        <CardMenu>
          <Button id="save-validation-button" colored raised onClick={() => this.handleSaveClick(this.state.list)} disabled={!this.state.isDirty}>
            <Icon name="save" />
          </Button>
        </CardMenu>
        <CardActions border>
          <div>
            <Tooltip label="Add campaign">
              <FABButton
                id="add-lead-acceptance-by-campaign-button"
                className="fabPosition"
                onClick={() => {
                  this.setState({
                    showCampaignSelector: true,
                  })
                }}
                mini
                colored>
                <Icon name="add" />
              </FABButton>
            </Tooltip>
            {this.state.showCampaignSelector ? this.renderCampaignSelector() : null}
            {!this.state.showCampaignSelector ? this.renderScheduleList() : null}
            {!this.state.showCampaignSelector && this.state.list.length > MAX_LIST_ITEMS_TO_DISPLAY ? this.renderRollUpAndDownButton() : <div></div>}
          </div>
        </CardActions>
      </Card>
    )
  }
}

export default LeadAcceptanceByCampaign
