import React from 'react'
import moment from 'moment'
import ConfirmModal from './modal'
import {Grid, Icon, Button, IconButton} from 'react-mdl'


const rowColumnStyleTemplate = {
      display:"flex",
      overflow: "hidden",
      paddingLeft: "10px",
}

const rowStyle = {
      marginRight: "10px",
      width: "100%",
      padding: "15px 0",
      display: "flex",
      justifyContent: "space-between",
}

const headerStyle = {
      width: "100%",
      marginRight: "10px",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      borderTop: "1px solid grey",
      borderBottom: "1px solid grey",
      padding: "10px 0"
}

const valueStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden", textOverflow: "ellipsis",
  marginBottom: "0px",
  fontSize: "16px",
  lineHeight: "normal",
}

export default class PaginatedTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialog : false,
      valueToDelete: '',
    }
  }

  buildRowColumnStyle(columnsLength){
    const widthPercentage = 90 / columnsLength
    const newTemplate = Object.assign(
      {minWidth: `${widthPercentage}%`, maxWidth: `${widthPercentage}%`},
      rowColumnStyleTemplate
    )
    return newTemplate
  }

  handleDeleteValue(index){
    const item = this.props.data[index]
    const value = item[this.props.dataPoints[0]]
    this.setState({
        valueToDelete : value,
        showDialog: true,
    })
  }

  handleModalClose(){
    this.setState({
        indexToDelete : -1,
        showDialog: false,
    })
  }

  handleModalOk(){
    this.props.onDelete(this.state.valueToDelete)
    this.handleModalClose()
  }

  render() {
    const columnElementStyle = this.buildRowColumnStyle(this.props.columns.length)
    return <div ref={t => this.contentSection = t}>
        <ConfirmModal
          showDialog={this.state.showDialog}
          contentSection={this.contentSection}
          dialogTitle={"Are you sure?"}
          dialogText={`Delete ${this.state.valueToDelete} ${this.props.itemName}`}
          onOk={ () => this.handleModalOk() }
          dialogCancel={ () => this.handleModalClose() }
        />
      <div style={{display: "flex", justifyContent: "space-between", margingBottom: "10px"}}>
        <Button accent onClick={() => this.props.pageChange(1)}>
          <Icon name="first_page" />
        </Button>
        <Button accent onClick={() => {
          this.props.pageChange(this.props.page - (this.props.page <= 1 ? 0 : 1))
        }}>
          <Icon name="chevron_left" />
        </Button>
        <span style={{paddingTop: "10px"}}>
        ({this.props.page} | {this.props.numOfPages})
        </span>
        <Button accent onClick={() => {
          this.props.pageChange(this.props.page + (this.props.page >= this.props.numOfPages ? 0 : 1))
        }}>
          <Icon name="chevron_right" />
        </Button>
        <Button accent onClick={() => this.props.pageChange(this.props.numOfPages)}>
          <Icon name="last_page" />
        </Button>
      </div>
      <Grid style={{ width: "100%"}}>
        <div style={headerStyle}>
          {this.props.columns.map((c) => {
            return <div key={c} style={columnElementStyle}>{c}</div>
          })}
          <span style={{ height: "15px", minWidth: "32px" }} ></span>
        </div>
        {this.props.data.map((d, i) => {
          const style = (i % 2 == 0) ? Object.assign({backgroundColor: "white"}, rowStyle) : Object.assign({backgroundColor: "#F0F8FF"}, rowStyle)
          return <div key={i} style={style}>
            {this.props.dataPoints.map((dp, idx) => {
              return <div title={d[dp]} key={`${d[dp]}.${i}.${dp}.${idx}`} style={columnElementStyle}>
               <p style={valueStyle}>{(dp == "createdAt" ? moment.unix(d[dp]).format('MM/DD/YYYY HH:mm:ss') : d[dp])}</p>
              </div>
            })}
            <div style={{float: "right"}}>
                <IconButton
                style={{ height: "15px" }}
                name="delete"
                onClick={() => this.handleDeleteValue(i)}
              />
            </div>
          </div>
        })}
      </Grid>
    </div>
  }
}
