import {
  replaceAreaCodes,
  appendAreaCodes,
  deleteAreaCode,
  deleteAllAreaCodes,
  updateAreaCode,
  retrieveAreaCodes,
  validateAreaCode,
} from '../../services'

import React from 'react'
import { connect } from 'react-redux'
import Blocklist from '../blocklist'


function mapDispatchToProps(dispatch) {
  return {
    addAreaCodes: function (newAreaCodes, replace) {
      if (replace) {
        dispatch(replaceAreaCodes(newAreaCodes))
      } else {
        dispatch(appendAreaCodes(newAreaCodes))
      }
    },
    deleteAreaCode: function (ac) {
      dispatch(deleteAreaCode(ac))
    },
    deleteAllAreaCodes: function () {
      dispatch(deleteAllAreaCodes())
    },
    validate: function (code) {
      dispatch(validateAreaCode(code))
    },
    updateAreaCode: function (ac) {
      dispatch(updateAreaCode(ac))
    },
    retrieveAreaCodes: function (page) {
      dispatch(retrieveAreaCodes(page))
    },
  }
}

class AreaCodes extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount(){
    this.props.retrieveAreaCodes(1)
  }

  render() {
    return (<Blocklist
      setName="Area Code"
      addValues={this.props.addAreaCodes}
      updateValue={this.props.updateAreaCode}
      deleteAll={this.props.deleteAllAreaCodes}
      deleteValue={this.props.deleteAreaCode}
      colName="areacode"
      colDisplay="Code"
      user={this.props.user}
      values={this.props.areaCodes}
      validate={(code) => {
        this.props.validate(code)
      }}
      retrievePage={(page) => {
        this.props.retrieveAreaCodes(page)
      }}
      validationResult={this.props.blockedAreaCodeResult || {}}
      setIsTabComponentDirty={this.props.setIsTabComponentDirty}
    />)
  }
}

const BlockedAreaCodes = connect(null, mapDispatchToProps)(AreaCodes)

export default BlockedAreaCodes
