
import React from 'react'
import { Grid, Cell, Card, CardActions, Icon, Button, CardTitle, Switch, List, ListItem } from 'react-mdl'
import Select from 'react-select'
import { UUID } from '../../utils'
import MapList from '../maplist'
import FieldMapping from './fieldMapping'

const checkTypes = ['BODY', 'STATUS', 'HEADER']

export default class HttpResponse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            response: this.props.response || { checkType: 0, checkHeader: [], checkBody: [], checkStatus: [], responseFieldMappings: [] }
        }
        this.state.response.checkHeader = this.state.response.checkHeader || []
        this.state.response.checkBody = this.state.response.checkBody || []
        this.state.response.checkStatus = this.state.response.checkStatus || []
        this.state.response.responseFieldMappings = this.state.response.responseFieldMappings || []
        this.verbs = [
            'GET',
            'POST',
            'PUT',
            'HEAD',
            'TRACE',
            'OPTIONS',
            'CONNECT',
            'PATCH',
        ]
    }

    componentWillReceiveProps(nextProps) {
        let response = nextProps.response
        response.checkHeader = this.state.response.checkHeader || []
        response.checkBody = this.state.response.checkBody || []
        response.checkStatus = this.state.response.checkStatus || []
        response.responseFieldMappings = this.state.response.responseFieldMappings || []
        this.setState({ response })
    }

    update() {
        this.props.updated(this.state.response)
    }

    valueCheckList(set, valCurry, title) {
        return <MapList
            update={h => {
                let response = this.state.response
                response[set] = this.state.response[set].map(e => {
                    if (e == h.Value) {
                        e = h.Value
                    }
                    return e
                })
                this.setState({ response })
                this.update()
            }}
            hideKey={true}
            delete={h => {
                let idx = this.state.response[set].indexOf(h.Value)
                this.state.response[set].splice(idx, 1)
                this.update()
            }}
            save={h => {
                this.state.response[set].push(valCurry(h))
                this.update()
            }}
            title={title}
            items={this.state.response[set].map(e => { return { Value: e, Key: e } })}
        />
    }

    keyValueCheckList(set, valCurry, title) {
        return <MapList
            update={h => {
                let response = this.state.response
                response[set] = this.state.response[set].map(e => {
                    if (e.key == h.Key) {
                        e.value = h.Value
                    }
                    return e
                })
                this.update()
            }}
            hideKey={false}
            delete={h => {
                let idx = this.state.response[set].indexOf(h.Key)
                this.state.response[set].splice(idx, 1)
                this.update()
            }}
            save={h => {
                this.state.response[set].push(valCurry(h))
                this.update()
            }}
            title={title}
            items={this.state.response[set].map(e => { return { Key: e.key, Value: e.value } })}
        />
    }

    selectMapping(idx) {
        if (this.state.response.responseFieldMappings.length <= idx) {
            this.setState({ selectedMapping: null })
            return
        }
        this.setState({ selectedMapping: this.state.response.responseFieldMappings[idx] })
    }

    removeFieldMapping(fm) {
        let idx = this.state.response.responseFieldMappings.findIndex(rfm => rfm.uuid == fm.uuid)
        if (idx < 0) {
            this.setState({ selectedMapping: null })
            return
        }
        let response = this.state.response
        response.responseFieldMappings.splice(idx, 1)
        this.setState({ response })
        this.update()
    }

    newFieldMapping() {
        let len = this.state.response.responseFieldMappings.push({
            uuid: UUID(),
            jpath: '$.user.favoriteBook.title',
            fieldName: "BOOK_TITLE",
            required: true
        })
        this.selectMapping(len - 1)
        this.update()
    }

    saveFieldMapping() {
        this.selectMapping(-1)
        this.update()
    }

    render() {
        let checkTypeComponent = <div />
        switch (this.state.response.checkType) {
            case 0:
                checkTypeComponent = this.valueCheckList("checkBody", v => v.Value, "Body Strings")
                break
            case 1:
                checkTypeComponent = this.valueCheckList("checkStatus", v => parseInt(v.Value), "Response Codes")
                break
            case 2:
                checkTypeComponent = this.keyValueCheckList("checkHeader", v => { return { key: v.Key, value: v.Value } }, "Response Headers")
                break
        }
        return <Card shadow={this.props.shadow} style={{ overflow: "visible" }}>
            <CardTitle>HTTP Response</CardTitle>
            <CardActions border>
                <Grid>
                    <Cell col={6}>
                        <Select
                            placeholder={"Response Type"}
                            isMulti={false}
                            value={checkTypes[this.state.response.checkType] ? { label: checkTypes[this.state.response.checkType], value: this.state.response.checkType } : null}
                            onChange={(s) => {
                                let response = this.state.response
                                response.checkType = s.value
                                this.setState({ response })
                                this.update()
                            }}
                            options={checkTypes.map((e, idx) => {
                                return { label: e, value: idx }
                            })}
                        />
                    </Cell>
                    <Cell col={6}>
                        <div>
                            <Switch
                                onChange={() => {
                                    let response = this.state.response
                                    response.validWhenFound = !this.state.response.validWhenFound
                                    this.setState({ response })
                                    this.update()
                                }}
                                checked={this.state.response.validWhenFound}>
                                {this.state.response.validWhenFound ? "Valid" : "Invalid"}
                            </Switch>
                        </div>
                    </Cell>
                    <Cell col={12}>
                        <div>
                            {checkTypeComponent}
                        </div>
                    </Cell>
                    <Cell col={12}>
                        {this.state.selectedMapping ?
                            <Button
                                colored
                                raised
                                ripple
                                onClick={() => this.saveFieldMapping()}>
                                <Icon name="save">Save</Icon>
                            </Button> :
                            <Button
                                colored
                                raised
                                ripple
                                onClick={() => this.newFieldMapping()}>
                                <Icon name="add_box">New</Icon> New Field Mapping
                            </Button>}
                        {this.state.selectedMapping
                            ? <FieldMapping fieldMapping={this.state.selectedMapping} updated={() => this.update(this.state.selectedMapping)} interaction={this.state.selectedMapping} />
                            : <List>
                                {this.state.response.responseFieldMappings.map(fm => {
                                    return <ListItem key={fm.uuid} onClick={() => {
                                        this.setState({ selectedMapping: fm })
                                    }}>{`${fm.fieldName} - ${fm.jpath}`}
                                        <Button
                                            colored
                                            raised
                                            ripple
                                            onClick={() => this.removeFieldMapping(fm)}>
                                            <Icon name="delete">delete</Icon>
                                        </Button> </ListItem>
                                })}
                            </List>}
                    </Cell>
                </Grid>
            </CardActions>
        </Card>
    }
}
