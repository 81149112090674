import {
  removePlacementGroupMember,
  savePlacementToGroup,
} from '../../services'
import React from 'react'
import { connect } from 'react-redux'
import Groups from '../common/groups'
import {Grid, Cell} from 'react-mdl'


function mapDispatchToProps(dispatch) {
  return {
    removeGroupMember: function (uuid) {
      dispatch(removePlacementGroupMember(uuid))
    },
    savePlacementToGroup: function (targetPlacement, group) {
      dispatch(savePlacementToGroup(targetPlacement, group))
    },
  }
}

class placementGroups extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  updatePlacementCampaignWhitelist(uuids){
    this.props.placement.whitelistCampaignAssociations = uuids
    this.props.savePlacement(this.props.placement)
    this.setState({})
  }

  handleRemoveGroupMember(uuid){
    this.props.removeGroupMember(uuid)
  }

  render() {
    return (
        <Grid style={{ width: '100%' }}>
          <Cell col={12}>
            <Groups
              itemName={"Placement"}
              item={this.props.placement}
              group={this.props.group}
              items={this.props.placements}
              saveItemToGroup={this.props.savePlacementToGroup}
              removeGroupMember={(uuid) => this.handleRemoveGroupMember(uuid)}
            />
          </Cell>
        </Grid>
    )
  }
}

const PlacementGroups = connect(null, mapDispatchToProps)(placementGroups)
export default PlacementGroups
