import React from 'react'
import moment from 'moment'
import DatePicker from "react-datepicker"
import ConfirmModal from '../modal'
import { CardActions, Button, Checkbox, Card, CardTitle, Spinner, CardMenu, Icon, Textfield, Tooltip } from "react-mdl"
import { Prompt } from 'react-router'
import { activeDates } from "../../utils"

const rowStyle = {
  padding: "5px",
  display: "flex",
  justifyContent: "space-between",
  border: "1px solid #ddd",
  alignItems: "center",
}

const inactiveIconStyle = {
  cursor: "pointer",
  color: "#ff000069",
}

const activeIconStyle = {
  cursor: "pointer",
  color: "green",
}

const inactiveName = {
  width: "55%",
  fontStyle: "italic",
  color: "#80808075",
}

export default class CampaignWhitelist extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCheckedIncludeAllCheckbox: false,
      isCheckedRemoveAllCheckbox: false,
      filter: "",
      removedCampaigns: [],
      campaignsToRemove: [],
      includedCampaigns: [],
      checkedCampaigns: [],
      updated: false,
      initState: true,
      showDialog: false,
      endDate: null,
    }
    this.props.setIsTabComponentDirty(() => this.state.updated)
  }

  handleCheckCampaign(uuid) {
    const checkedCampaigns = this.state.checkedCampaigns
    if (this.state.checkedCampaigns.includes(uuid)) {
      const index = this.state.checkedCampaigns.indexOf(uuid)
      checkedCampaigns.splice(index, 1)
    } else {
      checkedCampaigns.push(uuid)
    }
    this.setState({
      checkedCampaigns
    })
  }

  handleAddSelectedCampaigns() {
    const normalizedCheckedCampaigns = this.state.checkedCampaigns.map((uuid) => {
      return { campaignUuid: uuid, inactive: false, endDate: this.state.endDate ? this.state.endDate.unix() : null }
    })
    const includedCampaigns = this.state.includedCampaigns.concat(normalizedCheckedCampaigns)
    this.setState({
      includedCampaigns: includedCampaigns,
      checkedCampaigns: [],
      updated: true,
      isCheckedIncludeAllCheckbox: false,
      initState: false,
      enddate: null,
    })
  }

  normalizeDate(date) {
    let resultDate = parseInt(date)
    if (!resultDate || isNaN(resultDate)) {
      return null
    }
    return moment.unix(resultDate)
  }

  normalizeIncludedCampaigns(whitelist, campaigns) {
    const campaignsWhiteList = whitelist.concat(this.state.includedCampaigns)
    let displayList = []
    campaignsWhiteList.forEach((whitelistItem) => {
      const campaign = campaigns.find((c) => c.UUID === whitelistItem.campaignUuid)
      
      if (campaign !== undefined) {
        displayList.push({
          UUID: whitelistItem.campaignUuid,
          name: campaign.name,
          shortCode: campaign.shortCode,
          endDate: this.normalizeDate(whitelistItem.endDate),
          inactive: whitelistItem.inactive ? true : false,
          isLegacy: whitelistItem.isLegacy,
        })
      }
    })
    return displayList
  }

  handleSaveButton() {
    const allIncludedCampaigns = this.props.whitelist.concat(this.state.includedCampaigns).map((c) => {
      return {
        campaignUuid: c.campaignUuid,
        startDate: c.startDate ? c.startDate : 0,
        endDate: c.endDate ? c.endDate : 0,
        inactive: c.inactive,
        screenShot: c.screenShot,
        ignoreLocalTimeExpiration: c.ignoreLocalTimeExpiration,
        testLead: c.testLead,
        live: c.live
      }
    })
    this.props.update(allIncludedCampaigns)
    this.setState({
      includedCampaigns: [],
      updated: false,
      initState: false,
      endDate: null,
    })
  }

  handleSelectCampaignToRemove(uuid) {
    const campaignsToRemove = this.state.campaignsToRemove
    if (campaignsToRemove.includes(uuid)) {
      const index = campaignsToRemove.indexOf(uuid)
      campaignsToRemove.splice(index, 1)
    } else {
      campaignsToRemove.push(uuid)
    }
    this.setState({
      campaignsToRemove
    })
  }

  handleSaveWhiteListWhenRemoving() {
    const allIncludedCampaigns = this.props.whitelist.concat(this.state.includedCampaigns).filter((c) => !this.state.campaignsToRemove.includes(c.campaignUuid))
    this.props.update(allIncludedCampaigns)
    this.setState({
      includedCampaigns: [],
      campaignsToRemove: [],
      updated: false,
      initState: false,
      showDialog: false,
      endDate: null,
    })
  }

  getNotIncludedCampaigns(campaigns, whitelist) {
    const activeCampaigns = this.getActiveCampaigns(campaigns)
    const uuidWhitelist = whitelist.map((c) => c.campaignUuid)
    const uuidIncludedCampaigns = this.state.includedCampaigns.map((c) => c.campaignUuid)
    let notIncludedCampaigns = activeCampaigns.filter((c) => !uuidWhitelist.includes(c.UUID) && !uuidIncludedCampaigns.includes(c.UUID))

    let finalList = notIncludedCampaigns.filter((c) => this.predicate(c, this.state.filter))
    return finalList
  }

  getActiveCampaigns(campaigns) {
    const filterByActive = (item) => { return !item.isArchived && !item.isPaused && activeDates(item) }
    return campaigns.filter(filterByActive)
  }

  predicate(item, filter) {
    if (filter.length == 0) return true
    const data = ["UUID", "name", "shortCode"].map(f => item[f])
    return data.some(i => i ? i.toLowerCase().includes(filter.trim().toLowerCase()) : false)
  }

  handleFilterChange(value) {
    this.setState({
      filter: value,
    })
  }

  handleIncludeAllCheckbox(whitelist) {
    if (this.state.isCheckedIncludeAllCheckbox) {
      this.setState({
        isCheckedIncludeAllCheckbox: false,
        checkedCampaigns: [],
      })
      return
    }
    const notIncludedCampaigns = this.getNotIncludedCampaigns(this.props.campaigns, whitelist)
    const uuids = notIncludedCampaigns.map((c) => c.UUID)
    this.setState({
      checkedCampaigns: uuids,
      isCheckedIncludeAllCheckbox: true,
    })
  }

  handleRemoveAllCheckbox(whitelist) {
    if (this.state.isCheckedRemoveAllCheckbox) {
      this.setState({
        isCheckedRemoveAllCheckbox: false,
        campaignsToRemove: [],
      })
      return
    }
    const normalizedIncludedCampaigns = this.normalizeIncludedCampaigns(whitelist, this.props.campaigns)
    const uuids = normalizedIncludedCampaigns.map(c => c.UUID)
    this.setState({
      campaignsToRemove: uuids,
      isCheckedRemoveAllCheckbox: true,
    })
  }

  dialogCancel() {
    this.setState({
      showDialog: false,
    })
  }

  handleShowDeleteDialog() {
    this.setState({
      showDialog: true,
    })
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: moment(date),
    })
  }

  handleIncludedEndDateChange(date, campaignUuid) {
    date = moment(date)
    let itemToUpdate = this.props.whitelist.find((c) => c.campaignUuid === campaignUuid)
    if (!itemToUpdate) {
      itemToUpdate = this.state.includedCampaigns.find((c) => c.campaignUuid === campaignUuid)
    }
    itemToUpdate.endDate = date ? date.unix() : 0
    this.setState({
      updated: true,
    })
  }

  handleActiveInactiveToggle(campaignUuid) {
    let itemToUpdate = this.props.whitelist.find((c) => c.campaignUuid === campaignUuid)
    if (!itemToUpdate) {
      itemToUpdate = this.state.includedCampaigns.find((c) => c.campaignUuid === campaignUuid)
    }
    itemToUpdate.inactive = !itemToUpdate.inactive
    this.setState({
      updated: true,
    })
  }
  
  
  handleIgnoreExpirationToggle(campaignUuid) {
    let itemToUpdate = this.props.whitelist.find((c) => c.campaignUuid === campaignUuid)
    if (!itemToUpdate) {
      itemToUpdate = this.state.includedCampaigns.find((c) => c.campaignUuid === campaignUuid)
    }
    itemToUpdate.ignoreLocalTimeExpiration = !itemToUpdate.ignoreLocalTimeExpiration
    this.setState({
      updated: true,
    })
  }
  
  handleScreenshotToggle(campaignUuid) {
    let itemToUpdate = this.props.whitelist.find((c) => c.campaignUuid === campaignUuid)
    if (!itemToUpdate) {
      itemToUpdate = this.state.includedCampaigns.find((c) => c.campaignUuid === campaignUuid)
    }
    itemToUpdate.screenShot = !itemToUpdate.screenShot
    this.setState({
      updated: true,
    })
  }

  handleTestLeadToggle(campaignUuid) {
    let itemToUpdate = this.props.whitelist.find((c) => c.campaignUuid === campaignUuid)
    if (!itemToUpdate) {
      itemToUpdate = this.state.includedCampaigns.find((c) => c.campaignUuid === campaignUuid)
    }
    itemToUpdate.testLead = !itemToUpdate.testLead
    this.setState({
      updated: true,
    })
  }

  handleLiveToggle(campaignUuid) {
    let itemToUpdate = this.props.whitelist.find((c) => c.campaignUuid === campaignUuid)
    if (!itemToUpdate) {
      itemToUpdate = this.state.includedCampaigns.find((c) => c.campaignUuid === campaignUuid)
    }
    itemToUpdate.live = !itemToUpdate.live
    this.setState({
      updated: true,
    })
  }

  render() {
    const { whitelist } = this.props
    const notIncludedCampaigns = this.getNotIncludedCampaigns(this.props.campaigns, whitelist)
    let normalizedIncludedCampaigns = []
    if (this.props.campaigns.length > 0) {
      normalizedIncludedCampaigns = this.normalizeIncludedCampaigns(whitelist, this.props.campaigns)
    }
    return (
      <React.Fragment>
        <Prompt
          when={this.state.updated}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <section ref={t => (this.contentSection = t)}>
          <ConfirmModal
            showDialog={this.state.showDialog}
            contentSection={this.contentSection}
            dialogTitle={`Campaign whitelist`}
            dialogText={`Are you sure you want to remove?`}
            onOk={() => this.handleSaveWhiteListWhenRemoving()}
            dialogCancel={() => this.dialogCancel()}
          />
          <Card style={{ width: '100%' }} shadow={2}>
            <CardTitle>Campaign whitelist</CardTitle>
            <CardMenu>
              <Button
                disabled={!this.state.updated}
                onClick={() => this.handleSaveButton()}
              >
                <Icon name="save" />
              </Button>
            </CardMenu>
            <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ marginLeft: "10px", width: "40%", border: "1px solid grey" }}>
                <div style={{ margin: "0 20px", padding: "5px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Textfield
                    floatingLabel
                    label="Filter"
                    onChange={e => this.handleFilterChange(e.target.value)}
                    value={this.state.filter}
                  />
                  <div style={{ marginLeft: "0px", width: "8%", textAlign: "right" }}>
                    <Checkbox
                      id={"checkAllNotIncluded"}
                      ripple
                      checked={this.state.isCheckedIncludeAllCheckbox}
                      onChange={() => this.handleIncludeAllCheckbox(whitelist)}
                    />
                  </div>
                </div>
                <div id={"notIncluded"} style={{ paddingBottom: "10px", minHeight: "350px", maxHeight: "350px", overflowY: "scroll", borderBottom: "1px solid #ddd" }}>
                  {this.props.campaigns.length === 0 ? <div style={{ margin: "20px" }}><Spinner singleColor /></div>
                    : null
                  }
                  {
                    notIncludedCampaigns.map((c, idx) => {
                      const style = (idx % 2 == 0) ? Object.assign({ backgroundColor: "white" }, rowStyle) : Object.assign({ backgroundColor: "#F0F8FF" }, rowStyle)
                      return <div key={idx} style={style}>
                        <div style={{ width: "90%" }}>{c.name} - <span style={{ color: "grey" }}>{c.shortCode}</span></div>
                        <div style={{ width: "9%" }}>
                          <Checkbox
                            key={idx}
                            ripple
                            checked={this.state.checkedCampaigns.includes(c.UUID)}
                            onChange={() => this.handleCheckCampaign(c.UUID)}
                          />
                        </div>
                      </div>
                    })
                  }
                </div>
                <div style={{ color: "#009abf", padding: "20px 10px", height: "20px" }}>
                  {this.state.checkedCampaigns.length > 0 ?
                    <div>{this.state.checkedCampaigns.length} campaign{this.state.checkedCampaigns.length === 1 ? "" : "s"} selected</div>
                    : <div>{""}</div>}
                </div>
              </div>
              <div>
                <label className="floatLabel">End Date</label>
                <DatePicker
                  disabled={this.state.checkedCampaigns.length === 0}
                  isClearable={true}
                  dateFormat="MM/dd/yyyy"
                  selected={!this.state.endDate ? null : this.state.endDate.toDate()}
                  withPortal
                  onChange={dateMoment => this.handleEndDateChange(dateMoment)}
                />
                <div style={{ "textAlign": "center" }}>
                  <Button disabled={this.state.checkedCampaigns.length === 0} onClick={() => this.handleAddSelectedCampaigns()}>
                    <Icon name="arrow_forward" />
                  </Button>
                </div>
              </div>
              <div style={{ margin: "10px", width: "42%", border: "1px solid grey" }}>
                <div style={{ minHeight: "67px", padding: "5px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ width: "70%", textAlign: "left" }}><strong>Whitelist</strong></div>
                  <div style={{ width: "131px" }}>&nbsp;</div>
                  <div style={{ rightMargin: "24px" }}>
                    <Checkbox
                      id={"checkAllIncluded"}
                      ripple
                      checked={this.state.isCheckedRemoveAllCheckbox}
                      onChange={() => this.handleRemoveAllCheckbox(whitelist)}
                    />
                  </div>
                  <div style={{ width: "15px" }}>&nbsp;</div>
                </div>
                <div id={"included"} style={{ minHeight: "431px", maxHeight: "431px", overflowY: "scroll" }}>
                  {
                    normalizedIncludedCampaigns.map((c, idx) => {
                      let whiteList = this.props.whitelist.find((wc) => wc.campaignUuid === c.UUID)
                      let screenShotToggled = false
                      let testLeadToggled = false
                      let liveToggled = false
                      let ignoreLocalTimeExpirationToggle = false
                      if (whiteList) {
                        screenShotToggled = whiteList.screenShot
                        ignoreLocalTimeExpirationToggle = whiteList.ignoreLocalTimeExpiration
                        testLeadToggled = whiteList.testLead
                        liveToggled = whiteList.live
                      }
                      const style = (idx % 2 == 0) ? Object.assign({ backgroundColor: "white" }, rowStyle) : Object.assign({ backgroundColor: "#F0F8FF" }, rowStyle)
                      const title = c.inactive ? "inactive" : "active"
                      const iconStyle = c.inactive ? inactiveIconStyle : activeIconStyle
                      const nameStyle = c.inactive ? inactiveName : { width: "55%" }
                      return <React.Fragment key={idx}> <div key={idx} style={style}>
                        <Tooltip label={c.isLegacy ? "Legacy association" : "New-system association"}>
                          <div style={nameStyle}>
                            {c.name} - <span style={{ color: "grey" }}>{c.shortCode}</span>
                          </div>
                        </Tooltip>
                        <div style={{ width: "15%", height: "20px" }}>
                          <Icon onClick={() => this.handleActiveInactiveToggle(c.UUID)} title={title} style={iconStyle} name="power_settings_new" />
                        </div>
                        <DatePicker
                          isClearable={true}
                          dateFormat="MM/dd/yyyy"
                          selected={!c.endDate ? null : c.endDate.toDate()}
                          withPortal
                          onChange={dateMoment => this.handleIncludedEndDateChange(dateMoment, c.UUID)}
                        />
                        <div style={{ paddingLeft: "5px" }}>
                          <Checkbox
                            key={idx}
                            ripple
                            checked={this.state.campaignsToRemove.includes(c.UUID)}
                            onChange={() => this.handleSelectCampaignToRemove(c.UUID)}
                          />
                        </div>
                      </div>
                        <div style={style}>
                          <div>
                            <Checkbox
                              key={idx}
                              ripple
                              checked={ignoreLocalTimeExpirationToggle}
                              onChange={() => this.handleIgnoreExpirationToggle(c.UUID)}
                            /> Ignore Expiration
                          </div>
                          <div>
                            <Checkbox
                              key={idx}
                              ripple
                              checked={screenShotToggled}
                              onChange={() => this.handleScreenshotToggle(c.UUID)}
                            /> Screen shot
                          </div>
                          <div>
                            <Checkbox
                              key={idx}
                              ripple
                              checked={testLeadToggled}
                              onChange={() => this.handleTestLeadToggle(c.UUID)}
                            /> Test Lead
                          </div>
                          <div>
                            <Checkbox
                              key={idx}
                              ripple
                              checked={liveToggled}
                              onChange={() => this.handleLiveToggle(c.UUID)}
                            /> Live
                          </div>
                        </div>
                      </React.Fragment>
                    })
                  }
                </div>
              </div>
            </div>
            <CardActions style={{ maxHeight: '240px', overflowY: 'scroll' }}>
            </CardActions>
          </Card>
        </section>
      </React.Fragment>
    )
  }
}
