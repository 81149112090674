import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Textfield, Button, Icon, Grid, Cell } from 'react-mdl'

export default class LeadAcceptanceByCampaignEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getStateFromProps(props)
  }

  getStateFromProps(props) {
    let epoch = props.share ? props.share.effectiveDate : moment().startOf('day').unix().toString()
    let epochNum = parseInt(epoch)
    let effectiveDate = moment().startOf('day')
    if (props.share && props.share.effectiveDate && !isNaN(epochNum) && epochNum != 0) {
      effectiveDate = moment.unix(epochNum)
    }

    let state = {
      leadAcceptanceValue: (!props.share || isNaN(props.share.value)) ? '' : parseInt(props.share.value * 100),
      effectiveDate: effectiveDate,
    }
    return state
  }

  handleChange(callback) {
    callback()
  }

  componentDidMount() {
    let state = this.getStateFromProps(this.props)
    this.setState({
      ...state
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.share !== this.props.share) {
      let state = this.getStateFromProps(prevProps)
      this.setState({
        ...state
      })
    }
  }

  render() {
    return (
      <div>
        <Grid style={{ width: "100%" }}>
          <Cell col={6} id="lead-acceptance-by-campaign-unit-switch-cell">

          </Cell>
          <Cell col={6}>
            <Button
              id="save-lead-acceptance-by-campaign-button"
              disabled={this.state.effectiveDate < moment().startOf('day')}
              onClick={() => {
                const normalizedValue = parseInt(this.state.leadAcceptanceValue) / 100
                this.props.onSave(normalizedValue, this.state.effectiveDate.startOf('day').unix().toString())
              }}>
              <Icon name="save" />
            </Button>

            {!this.props.isNew && <Button
              id="delete-lead-acceptance-by-campaign-item-button"
              disabled={this.state.effectiveDate < moment().startOf('day')}
              onClick={() => {
                this.props.onDelete()
              }}>
              <Icon name="delete" />
            </Button>}

            <Button onClick={this.props.onCancel}>
              <Icon name="close">Close</Icon>
            </Button>

          </Cell>
          <Cell col={6}>
            <span style={{ display: 'inline-block', width: '40px' }}>{' '}</span>
            <Textfield
              value={this.state.leadAcceptanceValue}
              pattern={"(^[0-9]+)"} // eslint-disable-line
              error={`Must be an integer`}
              onChange={(e) => {
                this.setState({ leadAcceptanceValue: e.target.value })
              }}
              label="Percentage"
              floatingLabel
              style={{ width: '100px' }}
            />
            <span>{'%'}</span>
          </Cell>
          <Cell col={6} id="lead-acceptance-by-campaign-effective-date-cell">
            <span className="floatLabel">Effective Date</span>
            <DatePicker
              className="thin-date-picker"
              isClearable={false}
              dateFormat="MM/dd/yyyy"
              selected={!this.state.effectiveDate ? null : this.state.effectiveDate.toDate()}
              start_date={!this.state.effectiveDate ? null : this.state.effectiveDate.toDate()}
              onChange={(d) => {
                this.setState({ effectiveDate: moment(d) })
              }}
              withPortal
            />
          </Cell>
        </Grid>
      </div>
    )
  }
}
