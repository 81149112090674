import {
  replaceBlockedEmailDomains,
  appendBlockedEmailDomains,
  deleteBlockedEmailDomain,
  deleteAllBlockedEmailDomains,
  updateBlockedEmailDomain,
  retrieveAllBlockedEmailDomains,
  setEmailDomainAsReviewed,
} from '../../services'

import React from 'react'
import { connect } from 'react-redux'
import Blocklist from '../blocklist'
import EmailDomainReview from './domainReview'
import { deepClone } from '../../utils'
import {Grid, Cell} from 'react-mdl'


function mapDispatchToProps(dispatch) {
  return {
    addDomains: function (newDomains, replace) {
      if (replace) {
        dispatch(replaceBlockedEmailDomains(newDomains))
      } else {
        dispatch(appendBlockedEmailDomains(newDomains))
      }
    },
    deleteDomain: function (domainName) {
      dispatch(deleteBlockedEmailDomain(domainName))
    },
    deleteAllDomains: function () {
      dispatch(deleteAllBlockedEmailDomains())
    },
    updateDomain: function (domain) {
      dispatch(updateBlockedEmailDomain(domain))
    },
    retrieveAllBlockedEmailDomains: function() {
      dispatch(retrieveAllBlockedEmailDomains())
    },
    setEmailDomainAsReviewed: function(domain){
      dispatch(setEmailDomainAsReviewed(domain))
    }
  }
}

const DEFAULT_PAGE_NUMBER = 1

class blockedEmailDomains extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: DEFAULT_PAGE_NUMBER,
      blockedEmailDomainResult : null,
    }
  }

  componentDidMount(){
    this.props.retrieveAllBlockedEmailDomains()
  }

  validateSubstring(domain, blockedEmailDomainResult){
    const blockedDomains = this.props.domains.data.filter((d) => d.name.indexOf(domain) >= 0)
    if (blockedDomains.length > 0){
      blockedEmailDomainResult.validationResult.isValid =  false
      const domains = blockedDomains.map((d) => d.name)
      blockedEmailDomainResult.validationResult.substring = domains
    }
    return blockedEmailDomainResult
  }

  validate(domain){
    const blockedDomain = this.props.domains.data.find((d) => d.name === domain)
    let blockedEmailDomainResult = {createdDate: Date.now(), validationResult: {isValid: true}}
    if (blockedDomain){
      blockedEmailDomainResult.validationResult.isValid =  false
    }else {
      blockedEmailDomainResult = this.validateSubstring(domain, blockedEmailDomainResult)
    }
    this.setState({
      blockedEmailDomainResult
    })
  }

  retrieveBlockedEmailDomainsPage(page){
    this.setState({
      page
    })
  }

  slideByPage(){
    const perCycle = 350
    const start = (this.state.page-1) * perCycle
    const domains = deepClone(this.props.domains)
    const data = domains.data.splice(start, perCycle)
    domains.data = data
    return domains
  }

  render() {
    const domains = this.slideByPage()
    return (
      <Grid>
        <Cell col={3} style={{paddingTop: "22px"}}>
          <EmailDomainReview
              emailDomainsReview={this.props.emailDomainsReview}
              onReviewed={this.props.setEmailDomainAsReviewed}
              onBlock={(domain) => this.props.addDomains(domain)}
              user={this.props.user}
          />
        </Cell>
        <Cell col={9}>
          <Blocklist
            setName="Domain"
            addValues={this.props.addDomains}
            updateValue={this.props.updateDomain}
            deleteAll={this.props.deleteAllDomains}
            deleteValue={this.props.deleteDomain}
            colName="name"
            colDisplay="Name"
            user={this.props.user}
            values={domains}
            validate={domain => this.validate(domain)}
            retrievePage={page => this.retrieveBlockedEmailDomainsPage(page)}
            validationResult={this.state.blockedEmailDomainResult || {}}
            setIsTabComponentDirty={this.props.setIsTabComponentDirty}
          />
        </Cell>
      </Grid>
    )
  }
}

const BlockedEmailDomains = connect(null, mapDispatchToProps)(blockedEmailDomains)

export default BlockedEmailDomains
