import React from 'react'
import FilterList from '../filterList'
import Select from 'react-select'
import { deepClone } from '../../utils'
import { Grid, Checkbox, ListItemContent, ListItem, List, Cell, Card, CardMenu, CardTitle, CardActions, Button, Icon } from 'react-mdl'

const valueStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden", textOverflow: "ellipsis",
  marginBottom: "0px",
  fontSize: "16px",
  lineHeight: "normal",
}

const WEEKDAY_CODES = [1, 2, 3, 4, 5, 6, 0]
const WEEKDAYS_LABELS = {1:"Mon", 2:"Tue", 3:"Wed", 4:"Thu", 5:"Fri", 6:"Sat", 0:"Sun"}
const TIME_OPTIONS = [{label: "Midnight", value: 0},
                     {label: "1:00", value: 1},
                     {label: "2:00", value: 2},
                     {label: "3:00", value: 3},
                     {label: "4:00", value: 4},
                     {label: "5:00", value: 5},
                     {label: "6:00", value: 6},
                     {label: "7:00", value: 7},
                     {label: "8:00", value: 8},
                     {label: "9:00", value: 9},
                     {label: "10:00", value: 10},
                     {label: "11:00", value: 11},
                     {label: "12:00", value: 12},
                     {label: "13:00", value: 13},
                     {label: "14:00", value: 14},
                     {label: "15:00", value: 15},
                     {label: "16:00", value: 16},
                     {label: "17:00", value: 17},
                     {label: "18:00", value: 18},
                     {label: "19:00", value: 19},
                     {label: "20:00", value: 20},
                     {label: "21:00", value: 21},
                     {label: "22:00", value: 22},
                     {label: "23:00", value: 23},]

export default class TimeOfDayAvailabilityByCampaign extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        selectedSchedule: null,
      }
    }

    componentDidMount() {
      let list = this.convertScheduleListFromPropsToState(this.props.placement.campaignDaysTimeAvailability) || []
        this.setState({
          selectedSchedule: this.props.filter ? list.find(e=>e.campaignUuid === this.props.filter) : this.state.selectedSchedule,
        })
    }

    componentDidUpdate(prevProps) {
      if (prevProps.campaignDaysTimeAvailability !== this.props.campaignDaysTimeAvailability || prevProps.filter !== this.props.filter) {
        let list = this.convertScheduleListFromPropsToState(this.props.placement.campaignDaysTimeAvailability) || []
        this.setState({
          selectedSchedule: this.props.filter ? list.find(e=>e.campaignUuid === this.props.filter) : this.state.selectedSchedule,
        })
      }
    }

    handleNewSchedule(){
      const selectedSchedule = {
        id: '',
        campaignUuid: '',
        campaignName: '',
        weekdays: [{checked:true, value:1}, {checked:true, value:2}, {checked:true, value:3}, {checked:true, value:4},
                    {checked:true, value:5}, {checked:true, value:6}, {checked:true, value:0}],
        timeRange: {start: {label: "Midnight", value: 0}, end: {label: "Midnight", value: 0}}
      }
      this.setState({
        selectedSchedule: selectedSchedule,
      })
    }

    convertScheduleFromStateToProps(schedule){
      let weekdays = schedule.weekdays.filter((day)=> day.checked).map((day)=> {
        return day.value
      })
      let startTime = {hours: schedule.timeRange.start.value}
      let stateEndTime = schedule.timeRange.end.value
      let endTime = (stateEndTime == 0) ? {hours: 23, minutes: 59, seconds: 59} : {hours: stateEndTime}
      let dayTimeRange = {start:startTime, end:endTime}
      let propsSchedule = {campaignUuid: schedule.campaignUuid, timeOfDayAvailability: {weekdays:weekdays, dayTimeRange: dayTimeRange}}
      return propsSchedule
    }

    convertScheduleListFromPropsToState(propScheduleList){
      if(!propScheduleList){
              return []
      }
      let propScheduleListCopy = deepClone(propScheduleList)
      let stateScheduleList = propScheduleListCopy.map((propSchedule) => {
        let camp = this.props.campaigns.find(c => c.UUID == propSchedule.campaignUuid)
        let campaignName = 'NA'
        let campaignShortCode = 'NA'
        if (camp) {
          campaignName = camp.name
          campaignShortCode = camp.shortCode
        }
        let dayTimeRange = propSchedule.timeOfDayAvailability.dayTimeRange
        let timeRangeEnd = (dayTimeRange.end.hours == 23 && dayTimeRange.end.minutes == 59) ? 0 : dayTimeRange.end.hours
        const startLabel = TIME_OPTIONS[dayTimeRange.start.hours].label
        const endLabel = TIME_OPTIONS[dayTimeRange.end.hours].label
        let timeRange = {start:{label:startLabel ,value:dayTimeRange.start.hours}, end: {label:endLabel, value:timeRangeEnd}}
        let weekdays = []
        WEEKDAY_CODES.forEach((dayCode) => {
        let day = propSchedule.timeOfDayAvailability.weekdays.find(d => d == dayCode)
        let checked = (day!=undefined) ? true : false
        weekdays.push({checked:checked, value: dayCode})
        })
        return {
          id: propSchedule.campaignUuid,
          weekdays:weekdays,
          timeRange: timeRange,
          campaignUuid: propSchedule.campaignUuid,
          campaignName: campaignName,
          campaignShortCode: campaignShortCode,
        }
      })

      return stateScheduleList
    }

    getCampaignsNotAlreadySelected(campaigns, scheduleList){
      let selectedCampaignUuids = scheduleList.map(a=> a.campaignUuid)
      return campaigns.filter(c => !selectedCampaignUuids.includes(c.UUID))
    }

    getEndTimeOptions(startTime){
      let options = TIME_OPTIONS.filter(o => o.value > startTime.value)
      options.push(TIME_OPTIONS[0])
      return options
    }

    handleUnselect(){
      this.setState({
        selectedSchedule: null,
      })
    }

    handleSelectCampaign(c){
      const {selectedSchedule} = this.state
      selectedSchedule.campaignUuid = c.UUID
      selectedSchedule.campaignName = c.name
      this.setState({
        selectedSchedule: selectedSchedule
      })
    }

    renderCampaignSelector(scheduleList){
      let {campaigns} = this.props
      if(this.props.filter)campaigns = campaigns.filter(e=>e.UUID === this.props.filter)
      return (
        <Grid>
          <Cell col={12}>
            <Button onClick={() => this.handleUnselect()}>
              <Icon name="close">Close</Icon>
            </Button>
          </Cell>
          <Cell col={12}>
            <FilterList
              label="Campaign list"
              selected={this.state.selectedSchedule.campaignUuid}
              additionalFieldList={['UUID', 'shortCode']}
              title="Campaigns"
              selectItem={c => this.handleSelectCampaign(c)}
              items={this.getCampaignsNotAlreadySelected(campaigns, scheduleList)}
            />
          </Cell>
        </Grid>
      )
    }

    handleWeekdaysCheckboxChange(i){
      const {selectedSchedule} = this.state
      selectedSchedule.weekdays[i].checked = !selectedSchedule.weekdays[i].checked
      this.setState({
        selectedSchedule: selectedSchedule,
      })
    }

    handleStartTimeChange(selectedOption){
      const {selectedSchedule} = this.state
      selectedSchedule.timeRange.start=selectedOption
      this.setState({
        selectedSchedule: selectedSchedule,
      })
    }

    handleEndTimeChange(selectedOption){
      const {selectedSchedule} = this.state
      selectedSchedule.timeRange.end=selectedOption
      this.setState({
        selectedSchedule: selectedSchedule,
      })
    }

    handleSaveButton(){
      this.props.onSave(this.convertScheduleFromStateToProps(this.state.selectedSchedule))
      this.setState({
        selectedSchedule: null,
      })
    }

    handleDelete(){
      this.props.onDelete(this.convertScheduleFromStateToProps(this.state.selectedSchedule))
      this.handleUnselect()
    }

    renderDetails(){
      return (
        <Grid style={{width: "100%", padding: "0px"}}>
          <Cell col={12}>{this.state.selectedSchedule.campaignName}</Cell>
          <Cell col={this.state.selectedSchedule.id.length > 0 ? 3 : 4}>
            <Grid noSpacing={true}>
            {this.state.selectedSchedule.weekdays.map((day, i)=>{
              return (
                <Cell key={i} col={4}>
                      <Checkbox
                      style={{boder: "1px solid red"}}
                      key={day.value}
                      label={WEEKDAYS_LABELS[day.value]}
                      checked={day.checked}
                      onChange={()=> this.handleWeekdaysCheckboxChange(i)}
                      ripple  />
                </Cell>
              )
            })}
            </Grid>
          </Cell>
          <Cell col={3}>
            <Grid noSpacing={true}>
              <Cell col={4}>From:</Cell>
              <Cell col={8}>
                  <Select
                    isSearchable={false}
                    placeholder={"Start time"}
                    isMulti={false}
                    value={this.state.selectedSchedule.timeRange.start}
                    onChange={(selectedOption) => this.handleStartTimeChange(selectedOption)}
                    options={TIME_OPTIONS}
                  />
              </Cell>
            </Grid>
          </Cell>
          <Cell col={3}>
            <Grid noSpacing={true}>
              <Cell col={3} offset={1}>To:</Cell>
              <Cell col={8} >
                  <Select
                    placeholder={"End time"}
                    isSearchable={false}
                    isMulti={false}
                    value={this.state.selectedSchedule.timeRange.end}
                    onChange={(selectedOption) => this.handleEndTimeChange(selectedOption)}
                    options={this.getEndTimeOptions(this.state.selectedSchedule.timeRange.start)}
                  />
              </Cell>
            </Grid>
          </Cell>
          <Cell col={this.state.selectedSchedule.id.length > 0 ? 3 : 2}>
            <Button onClick={() => this.handleSaveButton()}>
              <Icon name="save">Save</Icon>
            </Button>
          {this.state.selectedSchedule.id.length > 0 ?
            <Button onClick={() => this.handleDelete()}>
              <Icon name="delete">Delete</Icon>
            </Button> : null}
            <Button onClick={() => this.handleUnselect()} >
              <Icon name="close">Close</Icon>
            </Button>
          </Cell>
      </Grid>
      )
    }

    handleSelectItem(schedule){
      this.setState({
        selectedSchedule: schedule,
      })
    }

    applyFilter(list){
      return this.props.filter ? list.filter((c) => c.campaignUuid == this.props.filter) : list
    }

    renderScheduleList(scheduleList){
      const filteredList = this.applyFilter(scheduleList)
      return (
        <List>
          {filteredList.map((schedule, i) => {
                if(schedule.campaignShortCode == "NA"){
                  return false
                }
                
                return  <ListItem
                  twoLine
                  key={`schedule:${i}`}
                  onClick={() => this.handleSelectItem(schedule)}>
                <ListItemContent subtitle={schedule.campaignShortCode}>
                <Grid noSpacing={true}>
                  <Cell col={3} style={valueStyle}>{schedule.campaignName}</Cell>
                  <Cell col={5}>
                    <div style={{margin: "0px 15px", display: "flex", justifyContent: "space-between", minWidth: "200px"}}>
                    {schedule.weekdays.map((day, i) => {
                        return (
                          <div key={i} style={(day.checked) ? {color:'green'}:{color:'gray'}}>
                            {WEEKDAYS_LABELS[day.value]}
                          </div>
                        )
                  })}
                    </div>
                </Cell>
                <Cell col={4}>
                  <div style={{display: "flex", justifyContent: "center"}} >
                    <div>{`From: ${schedule.timeRange.start.value}:00`}</div>
                    <div style={{margin: "0px 2px"}}>{"-"}</div>
                    <div>{`To:${schedule.timeRange.end.value}:00`}</div>
                  </div>
                </Cell>
                </Grid>
                </ListItemContent>
              </ListItem>
          })}
        </List>
      )
    }

    render(){
      let showDetails = this.state.selectedSchedule && this.state.selectedSchedule.campaignUuid.length > 0
      let showCampaignSelector = this.state.selectedSchedule && this.state.selectedSchedule.campaignUuid.length == 0
      const scheduleList = this.props.placement.campaignDaysTimeAvailability ?
          this.convertScheduleListFromPropsToState(this.props.placement.campaignDaysTimeAvailability) : []
      return (
        <Card shadow={2} style={{overflow: "visible", paddingBottom: "100px"}}>
          <CardTitle expand>
            <div style={{fontSize: "22px", fontWeight: "300"}}>Time of day availability by Campaign</div>
            {this.props.filter ?
            <div style={{display:"flex", justifyContent: "left", alignItems: "center", marginLeft: "15px", padding: "5px", color: "#009abf"}}>
              <div>Filtered</div>
              <div><Icon name="priority_hight" /></div>
            </div> : null}
          </CardTitle>
          <CardMenu>
            <Button
              onClick={() => this.handleNewSchedule()}
            >
              <Icon name="add_box">New</Icon>
            </Button>
          </CardMenu>
          <CardActions border>
            {showCampaignSelector ? this.renderCampaignSelector(scheduleList) : null}
            {showDetails ? this.renderDetails() : null}
            {!showCampaignSelector ? this.renderScheduleList(scheduleList): null}
          </CardActions>
        </Card>
      )
    }
}
