import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Grid, Cell, Card, CardActions, CardMenu, CardTitle, Textfield, Button, Icon } from 'react-mdl'
import { DateRange } from '../date-range'
import { PlacementSelector } from '../placement-selector'
import moment from 'moment'
import Modal from "react-modal"
import {
  submitDelivery,
  createDeliveryFromQuery,
  retrieveLatestDeliveriesByConfiguration
} from "../../services"

const datasourceTypes = [
  { label: "STREAM", value: 0, code: "stream" },
  { label: "QUERY", value: 1, code: "query" },
]

const latestDeliveryLimits = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
]



function mapStateToProps(state) {
  return {
    latestDeliveriesLoading: state.leadDeliveryConfig.latestDeliveriesLoading,
    latestDeliveries: state.leadDeliveryConfig.latestDeliveries,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    //ShowSnack(msg) {
    //  dispatch(ShowSnack(msg))
    //},
    submitDelivery(configurationUUID, deliveryUUID, limit) {
      dispatch(submitDelivery(configurationUUID, deliveryUUID, limit))
    },
    createDeliveryFromQuery(queryConfigs, limit) {
      dispatch(createDeliveryFromQuery(queryConfigs, limit))
    },
    retrieveLatestDeliveriesByConfiguration(configUUID, limit) {
      dispatch(retrieveLatestDeliveriesByConfiguration(configUUID, limit))
    },
  }
}

class datasource extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPreviewModal: false,
      selectedLatestDeliveryLimit: latestDeliveryLimits[4],
    }
  }

  unixToLocalTime(unixTime) {
    if (unixTime == 0) return ""
    return moment.unix(unixTime).local().format("YYYY-MM-DD hh:mm A")
  }

  handleChange(callback) {
    callback()
    this.setState(this.state)
  }

  handleClosePreviewModal() {
    this.setState({
      showPreviewModal: false
    })
  }

  handleOpenPreviewClick() {
    this.setState({
      showPreviewModal: true
    })
  }

  renderPreviewModalContent() {
    return <div>Preview ...</div>
  }

  handleSendDeliveryClick(configurationUUID, deliveryUUID, limit) {
    this.props.submitDelivery(configurationUUID, deliveryUUID, limit)
  }

  handleRefreshDeliveryListClick(limit) {
    this.props.retrieveLatestDeliveriesByConfiguration(this.props.leadDeliveryConfig.UUID, limit)
  }

  handleChangeLatestDeliveryLimitClick(limit) {
    this.props.retrieveLatestDeliveriesByConfiguration(this.props.leadDeliveryConfig.UUID, limit)
  }

  render() {
    let datasourceType = datasourceTypes.find(e => e.value == this.props.leadDeliveryConfig.datasourceType)

    let datasource = "Invalid selection"
    switch (datasourceType.code) {
      case "query":
        datasource = <QueryDatasource
          campaigns={this.props.campaigns}
          placements={this.props.placements}
          leadDeliveryConfig={this.props.leadDeliveryConfig}
          createDeliveryFromQuery={this.props.createDeliveryFromQuery}
          handleScheduleLocationChange={this.props.handleScheduleLocationChange}
          selectedLatestDeliveryLimit={this.state.selectedLatestDeliveryLimit}
        />
        break
      case "stream":
        datasource = <StreamDatasource />
        break
    }

    return <section>
      <Modal
        ariaHideApp={false}
        isOpen={this.state.showPreviewModal}
        onRequestClose={this.handlePreviewPopupClose}
        contentLabel="Delivery preview">
        <Card shadow={2}>
          <CardActions style={{ textAlign: "right" }}>
            <Button onClick={() => this.handleClosePreviewModal()}>
              <Icon name="close" />
            </Button>
          </CardActions>
          <div>{this.state.showPreviewModal && this.renderPreviewModalContent()}</div>
        </Card>
      </Modal>
      <div className="content">
        <Card shadow={2} style={{ overflow: "visible" }}>
          <CardTitle>Data</CardTitle>
          <CardActions border>
            <Grid>
              <Cell col={4}>
                <Select
                  placeholder={"Datasource Type"}
                  isMulti={false}
                  value={datasourceType}
                  onChange={(s) => this.handleChange(() => this.props.handleDatasourceTypeChange(s.value))}
                  options={datasourceTypes}
                />
              </Cell>
              <Cell col={8}></Cell>
              {this.props.leadDeliveryConfig.UUID.length > 0 && <Cell col={12}>
                {datasource}
              </Cell>}
              {this.props.leadDeliveryConfig.UUID.length > 0 && <Cell col={12}>
                <Card shadow={2} style={{ overflow: "visible" }}>
                  <CardTitle>Most recent deliveries</CardTitle>
                  <CardMenu>
                    <Grid>
                      <Cell col={6}>
                        <Select
                          isMulti={false}
                          value={this.state.selectedLatestDeliveryLimit}
                          onChange={(s) => {
                            this.handleChangeLatestDeliveryLimitClick(s.value)
                            this.setState({
                              selectedLatestDeliveryLimit: s
                            })
                          }}
                          options={latestDeliveryLimits}
                        />
                      </Cell>
                      <Cell col={6}>
                        <Button
                          id="run-button"
                          colored
                          raised
                          ripple
                          onClick={() => this.handleRefreshDeliveryListClick(this.state.selectedLatestDeliveryLimit.value)}
                          disabled={this.state.isRunButtonDisabled}>
                          <Icon name="refresh" />
                        </Button>
                      </Cell>
                    </Grid>
                  </CardMenu>
                  <CardActions border>
                    <table width={"100%"}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>UUID</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Delivery (with retries)<br></br>finished?</th>
                          <th>Send</th>
                          <th>All attempts</th>
                          <th>Successful attempts</th>
                        </tr>
                      </thead>
                      {this.props.latestDeliveries &&
                        <tbody>
                          {this.props.latestDeliveries.records.map((d) => {
                            return (
                              <tr key={d.UUID}>
                                <td>{d.name}</td>
                                <td align={"center"}>{d.UUID}</td>
                                <td align={"center"}>{this.unixToLocalTime(d.createdAt)}</td>
                                <td align={"center"}>{this.unixToLocalTime(d.updatedAt)}</td>
                                <td align={"center"}>{d.finished && "Yes"}</td>
                                <td align={"center"}>
                                  <Button
                                    id="run-button"
                                    colored
                                    raised
                                    ripple
                                    onClick={() => this.handleSendDeliveryClick(d.configurationUUID, d.UUID, this.state.selectedLatestDeliveryLimit.value)}
                                    disabled={this.state.isRunButtonDisabled}>
                                    Send
                                  </Button>
                                </td>
                                <td align={"center"}>{d.attempts.length}</td>
                                <td align={"center"}>{d.attempts.filter(e => e.status === 1).length}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      }
                    </table>
                  </CardActions>
                </Card>
              </Cell>}
            </Grid>
          </CardActions>
        </Card>
      </div>
    </section>
  }
}

const Datasource = connect(mapStateToProps, mapDispatchToProps)(datasource)

export default Datasource





class QueryDatasource extends React.Component {
  constructor(props) {
    super(props)
    let defaultDatasource = {
      timezone: "",
      startDate: "",
      endDate: "",
      campaignUUIDs: [],
      placementUUIDs: [],
    }
    props.leadDeliveryConfig.datasource.query = props.leadDeliveryConfig.datasource.query || defaultDatasource
    this.state = {
      startDate: moment().startOf("day"),
      endDate: moment(),
      isRunButtonDisabled: false,
      isDownloadButtonDisabled: false,
      selectedCampaigns: [],
      selectedPlacements: [],
      newValues: '',
      newValuesReplace: false,
      page: 1,
      records: [],
      allRowsExpanded: false,
      isLoading: false,
      deliveryName: "",
    }
    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  handleDateRangeCallback(startDate, endDate) {
    this.props.leadDeliveryConfig.datasource.query.startDate = startDate
    this.props.leadDeliveryConfig.datasource.query.endDate = endDate
    this.setState({
      startDate: startDate,
      endDate: endDate
    })
  }

  handleRunQueryButtonClick() {
    let queryConfigs = {
      deliveryName: this.state.deliveryName,
      configurationUUID: this.props.leadDeliveryConfig.UUID,
      leadQuery: {
        timezone: this.state.timezone,
        startDate: this.state.startDate.format("YYYY-MM-DD HH:mm:ss"),
        endDate: this.state.endDate.format("YYYY-MM-DD HH:mm:ss"),
        //campaignUUIDs: this.state.selectedCampaigns.map(e => e.value),
        campaignUUIDs: [this.props.leadDeliveryConfig.campaignUUID],
        placementUUIDs: this.state.selectedPlacements.map(e => e.value),
      },
    }
    this.props.createDeliveryFromQuery(queryConfigs, this.props.selectedLatestDeliveryLimit)
  }

  render() {
    return <Grid style={{ padding: "1px" }}>
      <Cell col={3}>
        <label className="floatLabel">Date Range</label>
        <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
      </Cell>
      <Cell col={3}>
        <label className="floatLabel">Timezone</label>
        <Select
          placeholder={"Time Zone"}
          isMulti={false}
          value={
            this.props.leadDeliveryConfig.datasource.query.timezone
              ? {
                label: this.props.leadDeliveryConfig.datasource.query.timezone,
                value: this.props.leadDeliveryConfig.datasource.query.timezone
              }
              : null
          }
          onChange={s => this.handleChange(() => this.props.handleScheduleLocationChange(s.value))}
          options={[
            { label: "America/New_York", value: "America/New_York" },
            { label: "UTC", value: "UTC" }
          ]}
        />
      </Cell>
      <Cell col={6}>
        <label className="floatLabel">Placements</label>
        <PlacementSelector
          selectedPlacements={this.state.selectedPlacements}
          placements={this.props.placements}
          onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
        ></PlacementSelector>
      </Cell>
      <Cell col={6}>
        <Textfield
          required
          label={"Delivery name"}
          value={this.state.deliveryName}
          onChange={e => {
            this.setState({ deliveryName: e.target.value })
          }}
          floatingLabel
          style={{ width: "200px" }}
        />
      </Cell>
      <Cell col={6}>
        <Button
          id="run-button"
          colored
          raised
          ripple
          onClick={() => this.handleRunQueryButtonClick()}
          disabled={this.state.isRunButtonDisabled}>
          Create Delivery
        </Button>
      </Cell>
    </Grid>
  }
}


class StreamDatasource extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return null
  }
}




