import React from 'react'
import { connect } from 'react-redux'
import Tree from 'react-animated-tree'

const treeStyles = {
  color: '#00556f',
  fill: '#00556f',
  width: '100%'
}

const typeStyles = {
  fontSize: '1.5em',
  verticalAlign: 'middle',
  cursor: 'pointer'
}

const childStyles = {
  verticalAlign: 'middle',
}
    
class tagTree extends React.Component{
  
  constructor(props){
      super(props)
      this.state = {}
  }

  handleOpen(tag){
    if (this.props.isNodeOpen){
      return this.props.isNodeOpen(tag.UUID)
    }
    return false
  }
  
  selectClassName(defaultClassName, tagUUID){
    if (this.props.isNodeSelected && this.props.isNodeSelected(tagUUID)){
      return `${defaultClassName} node_selected`
    }
    return defaultClassName
  }
    
  buildParentTags(parentUUID, tags){
    const childTags = tags.records.filter((t)=> t.parent === parentUUID)
    if (childTags.length === 0){
      return null
    }
    return (
      <div>
      {childTags.map((t)=>{
        const open = this.handleOpen(t)
        return (
          <Tree 
            open={open}
            key={t.UUID} 
            content={<span className={this.selectClassName("child_node",t.UUID)} onClick={()=>this.props.selectTag(t)} style={childStyles}>{t.name}</span>}>
            {this.buildParentTags(t.UUID, tags)}
          </Tree>)
      })}
      </div>
    )
  }

  render(){
    const {tags} = this.props
    const familyTags = tags.records.filter((t)=> t.parent === '')
    return (
      <div>
      {familyTags.map((t)=>{
        const open = this.handleOpen(t)
        return (
          <Tree 
            open={open}
            key={t.UUID} 
            paddingLeft={60}
            content={<span className={this.selectClassName('node_tag',t.UUID)} onClick={()=>this.props.selectTag(t)} style={typeStyles}>{t.name}</span>}
            type="family" 
            style={treeStyles} >
              {this.buildParentTags(t.UUID, tags)}
          </Tree>)
      })}
      </div>
    )
  }

}

const TagTree = connect(null, null)(tagTree)

    
export default TagTree

