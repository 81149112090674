import React from 'react'
import moment from "moment"
import { Button, Icon } from "react-mdl"
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'

export class DateRange extends React.Component {
  constructor(props) {
    super(props)
    this.handleApplyCallback = this.handleApplyCallback.bind(this)
  }

  handleApplyCallback(start, end) {
    this.props.applyCallback(start, end)
  }

  render() {
    let now = new Date()
    let start = moment(now).startOf("day")
    let end = moment(now).endOf("day")

    let ranges = {
      "Today": [moment(start), moment(end)],
      "Yesterday": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
      "This Week": [moment(start).startOf('week'), end],
      "Last Week": [moment(start).subtract(7, "days").startOf('week'), moment(end).subtract(7, "days").endOf('week')],
      "This Month": [moment(start).startOf('month'), end],
      "Last Month": [
        moment(start).startOf('month').subtract(1, "days").startOf('month'),
        moment(start).startOf('month').subtract(1, "days").endOf('month')],
      "7 Days": [moment(start).subtract(7, "days"), moment(end)],
      "30 Days": [moment(start).subtract(30, "days"), moment(end)]

    }
    let maxDate = moment(now).endOf("day")
    return (
      <div>
        <DateTimeRangeContainer
          smartMode={true}
          pastSearchFriendly={true}
          descendingYears={true}
          years={[now.getFullYear() - 5, now.getFullYear()]}
          ranges={ranges}
          start={this.props.start}
          end={this.props.end}
          local={{
            "format": "MM/DD/YYYY hh:mm A",
            "sundayFirst": true
          }}
          maxDate={maxDate}
          applyCallback={this.handleApplyCallback}
        >
          <Button
            raised
            className="clickable"
          >
            <div
              style={{
                fontSize: "14px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Icon
                style={{
                  marginLeft: "-5px",
                  paddingRight: "8px"
                }}
                name="calendar_today">CalendarToday</Icon>
              {`${this.props.start.format("MM/DD/YYYY hh:mm A")} - ${this.props.end.format("MM/DD/YYYY hh:mm A")}`}
            </div>
          </Button>
        </DateTimeRangeContainer>
      </div>
    )
  }
}
