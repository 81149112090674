
import { v4 as uuidv4 } from 'uuid'

function buildUser1() {
    const dob = new Date()
    dob.setDate(dob.getDate() - 9000)
    const dobTimestamp = parseInt(dob.getTime() / 1000)
    return {
        address1: "45 Test Pl",
        address2: "Apt T",
        city: "Testigo",
        country: "Testan",
        dob: dobTimestamp,
        email: "Test@testmail.net",
        firstName: "Tee",
        lastName: "Testerson",
        phone: 5361113333,
        state: "FL",
        title: "Mr",
        zipcode: "12412",
    }
}

function buildUser2() {
    const dob = new Date()
    dob.setDate(dob.getDate() - 12333)
    const dobTimestamp = parseInt(dob.getTime() / 1000)
    return {
        address1: "15 Test Ave",
        address2: "Apt T2",
        city: "Tistigo",
        country: "Tistan",
        dob: dobTimestamp,
        email: "Tist@tistmail.net",
        firstName: "Tie",
        lastName: "Tisterson",
        phone: 5362223333,
        state: "FL",
        title: "Mr",
        zipcode: "14442",
    }
}

function buildLead(campaignUUID, user, createdAfterDays) {
    const createdDate = new Date()
    createdDate.setDate(createdDate.getDate() - createdAfterDays)
    const createdTimestamp = parseInt(createdDate.getTime() / 1000)
    return {
        uuid:uuidv4(),
        campaignUuid: campaignUUID,
        optIn: { uuid: uuidv4() },
        placementUuid: uuidv4(),
        sessionUuid: uuidv4(),
        user: user,
        created: createdTimestamp,
    }
}

export function buildTestLeads(campaignUUID) {
    return [
        buildLead(campaignUUID, buildUser1(), 90),
        buildLead(campaignUUID, buildUser2(), 60),
    ]
}
