import React from 'react'
import { connect } from 'react-redux'
import Editor from '../editor'
import {Grid, Cell, Card, CardActions, CardTitle, Textfield, Icon, Button} from 'react-mdl'
import { Prompt } from 'react-router'

const Headline = "Headline"
const Subtext = "Subtext"
const Header = "Header"
const Body = "Body"
const Footer = "Footer"

const textStrings = [Headline, Subtext, Header,Body, Footer]

class placementCreative extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty: false,
    }
    this.props.reloadCreative()
    this.props.setIsTabComponentDirty(() => this.state.isDirty)
  }

  render() {
    return (
      <React.Fragment>
        <Prompt
        when={this.state.isDirty}
        message='You have unsaved changes, are you sure you want to leave?'
        />
        <Card>
          <CardActions>
            <Button raised
              disabled={!this.state.isDirty}
              colored
              onClick={() => {
                  this.props.saveCreative(this.props.creative)
                  this.setState({
                    isDirty: false,
                  })
              }}
            ><Icon name="save" /></Button>
            <Button raised
              disabled={!this.state.isDirty}
              colored
              onClick={() => {
                  this.props.reloadCreative(this.props.creative.uuid)
                  this.setState({
                    isDirty: false,
                  })
              }}
            ><Icon name="restore" /></Button>
          </CardActions>
          <Grid style={{ width: "100%" }}>
            <Cell col={4}>
              <Card shadow={3}>
                <CardTitle>Text Strings</CardTitle>
                {textStrings.map(t => (
                    <Textfield floatingLabel
                      style={{"width":"100%"}}
                      key={t}
                      label={t}
                      onChange={(e) => {
                        this.props.handleTextStringChange(this.props.creative, t, e.target.value)
                        this.setState({
                          isDirty: true,
                        })
                      }}
                      value={this.props.creative.textStrings[t] || ""} />
                ))}
              </Card>
            </Cell>
            <Cell col={8}>
              <Button raised colored onClick={this.props.previewAgent}>View in Agent</Button>
              <Card shadow={3}>
                <CardTitle>Site CSS</CardTitle>
                <Editor
                mode="css"
                style={{ width: "100%" }}
                code={this.props.creative.css || ""}
                onChange={c => {
                  this.props.handleCssChange(this.props.creative, c)
                  this.setState({ isDirty: true })
                }}
                onBlur={() => {
                  this.setState({ isDirty: true })
                }}
              />
              </Card>
            </Cell>
          </Grid>
        </Card>
      </React.Fragment>
    )
  }
}

const PlacementCreative = connect()(placementCreative)

export default PlacementCreative
