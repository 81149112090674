import React, { PureComponent } from "react"
import { ListItem } from "react-mdl"
import Collapse from "@material-ui/core/Collapse"
import { ListItemText, ListItemIcon } from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

export class Collapsible extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <React.Fragment>
        <ListItem
          style={{ color: this.props.color, backgroundColor: this.props.backgroundColor, fontSize: this.props.fontSize }}
          button="true"
          onClick={() => {
            if (this.props.onToggle) this.props.onToggle(!this.props.defaultOpen)
          }}>
          <React.Fragment>
            {!this.props.icon ? null : (
              <ListItemIcon>
                <Icon style={{ marginRight: "12px" }}>{this.props.icon}</Icon>
              </ListItemIcon>
            )}
            <ListItemText primary={this.props.title} />
            {this.props.defaultOpen ? <ExpandLess /> : <ExpandMore />}
          </React.Fragment>
        </ListItem>
        <Collapse in={this.props.defaultOpen} timeout="auto" unmountOnExit>
          {this.props.children}
        </Collapse>
      </React.Fragment>
    )
  }
}
