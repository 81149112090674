import React from 'react'
import {Card, CardActions, CardTitle, Textfield} from 'react-mdl'

export default class WinbackDetail extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <Card shadow={2}>
      <CardTitle>{this.props.winback.uuid}</CardTitle>
      <CardActions border>
        <Textfield
          floatingLabel
          label="Winback Title"
          onChange={(e) => this.props.handleWinbackTitleChange(e.target.value)}
          value={this.props.winback.title}
        />
      </CardActions>
    </Card>
  }
}