import React from 'react'
import { connect } from 'react-redux'
import { RemovableItem } from '../listItems'
import FilterList from '../filterList'
import { Card, CardMenu, CardTitle, CardActions, FABButton, Icon, List } from 'react-mdl'


class grouping extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      adding: false,
    }
  }

  itemSelected(c) {
    this.props.saveItemToGroup(c, this.props.group)
    this.setState({
      adding: false
    })
  }

  render() {
    let groupMembers = this.props.group.memberUuids
      .filter(id => id != this.props.item.UUID)
      .map(id => this.props.items.find(x => x.UUID == id))
      .filter(g => g)
    console.dir(groupMembers)
    return (
      <Card style={{ width: '100%' }} shadow={2}>
        <CardTitle>{`${this.props.itemName} Group`}</CardTitle>
        <CardMenu>
          <FABButton
            onClick={() => {
              this.setState({
                adding: true
              })
            }}
            mini
            colored
            className="fabPos"
          >
            <Icon name="add" />
          </FABButton>
        </CardMenu>
        <CardActions style={{ maxHeight: '240px', overflowY: 'scroll' }}>
          <List className="fullWidth">
            {groupMembers.map(groupMember => {
              return (<RemovableItem
                key={groupMember.UUID}
                UUID={groupMember.UUID}
                name={groupMember.name}
                secondary={groupMember.shortCode}
                onRemove={() => this.props.removeGroupMember(groupMember.UUID)}
              />)
            })}
          </List>
        </CardActions>
        {this.state.adding
          ? <CardActions border>
            <FilterList
              label={`${this.props.itemName} list...`}
              selected={this.props.item.UUID}
              additionalFieldList={['UUID', 'shortCode']}
              title={`${this.props.itemName}s`}
              selectItem={c => this.itemSelected(c)}
              items={this.props.items.filter(
                c => c.UUID != this.props.item.UUID &&
                  !this.props.group.memberUuids.some(
                    mem => mem == c.UUID,
                  ),
              )}
            />
          </CardActions>
          : <div />}
      </Card>
    )
  }
}

const Groups = connect(null, null)(grouping)
export default Groups
