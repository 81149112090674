const { union, derivations } = require('folktale/adt/union')

const LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG = 'LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG'
const LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG_FAILURE = 'LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG_FAILURE'
const LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG_SUCCESS = 'LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG_SUCCESS'

export const loadPhoneVerificationCampaignConfig = () =>
    ({ type: LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG })

export const loadPhoneVerificationCampaignConfigSuccess = data =>
    ({ type: LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG_SUCCESS, data })

export const loadPhoneVerificationCampaignConfigFailure = error =>
    ({ type: LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG_FAILURE, error })

export const PhoneVerificationCampaignConfigState = union('PhoneVerificationCampaignConfigState', {
    LoadingPhoneVerificationCampaignConfig() { return {} }
    , PhoneVerificationCampaignConfigLoaded(data) { return { data } }
    , PhoneVerificationCampaignConfigError(error) { return { error } }
})
    .derive(derivations.debugRepresentation)
export const {
    LoadingPhoneVerificationCampaignConfig,
    PhoneVerificationCampaignConfigLoaded,
    PhoneVerificationCampaignConfigError
} = PhoneVerificationCampaignConfigState

export const phoneVerificationCampaignConfig = (state = LoadingPhoneVerificationCampaignConfig(), action) => {
    switch (action.type) {
        case LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG:
            return LoadingPhoneVerificationCampaignConfig()
        case LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG_FAILURE:
            return PhoneVerificationCampaignConfigError(action.error)
        case LOAD_PHONE_VERIFICATION_CAMPAIGN_CONFIG_SUCCESS:
            if (action.data.length == 0) {
                action.data = { config: {} }
            }
            return PhoneVerificationCampaignConfigLoaded(action.data)
        default:
            return state
    }
}
