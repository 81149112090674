import {
  updateDeliverableStatus, 
  retrieveDeliverableViewer, 
  openDeliverableViewerSaveDialog,
  closeDeliverableViewerSaveDialog, 
  updateBulkDeliverableViewerStatusCodes
} from '../../services'

import { saveState } from "../../reducers/deliverableViewerReducer"

import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import moment from 'moment'
import { DateRange } from '../date-range'
import Select from 'react-select'
import { Grid, Cell, Card, CardActions, CardTitle, Icon, Button, Switch } from 'react-mdl'
import { PlacementSelector } from '../placement-selector'
import { CampaignSelector } from '../campaign-selector'
import { Collapsible } from "../common/components"

function mapStateToProps(state) {
  return {
    deliverableViewer: state.deliverableViewer,
    campaigns: state.campaignData.records,
    placements: state.placementData.records,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    retrieveDeliverables: function (criteria) {
      dispatch(retrieveDeliverableViewer(criteria))
    },
    updateDeliverableStatus: function (deliverable, criteria) {
      dispatch(updateDeliverableStatus(deliverable, criteria))
    },
    saveState: function (state) {
      dispatch(saveState(state))
    },
    updateBulkStatusCodes: function (status, deliveryUUIDs) {
      dispatch(openDeliverableViewerSaveDialog())
      dispatch(updateBulkDeliverableViewerStatusCodes(status, deliveryUUIDs))
    },
    closeSaveDialog: function () {
      dispatch(closeDeliverableViewerSaveDialog())
    },
  }
}

class deliverableViewer extends React.Component {
  constructor(props) {
    super(props)

    this.deliveryAttemptStatusCodesMap = {
      0: 'IN_PROGRESS',
      1: 'SUCCEEDED',
      2: 'FAILED',
      3: 'CANCELLED',
    }

    this.state = {
      selectedCampaigns: [],
      selectedPlacements: [],
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      newValues: '',
      page: 1,
      offset: 0,
      limit: 20,
      newValuesReplace: false,
      isLoading: false,

      records: [],
      allRowsExpanded: false,
      isCriteriaCollapsed: false,
      
      //statusCodes: [
      //  {label: "In-Progress", value: 0, active: true}, 
      //  {label: "Succeeded", value: 1, active: true}, 
      //  {label: "Failed", value: 2, active: true}, 
      //  {label: "Cancelled", value: 3, active: true}, 
      //],
      statusCodes: [
        {label: "On-Hold", value: 0, active: true}, 
        {label: "Processing", value: 1, active: true}, 
        {label: "To-Send", value: 2, active: true}, 
        {label: "Sent-Accepted", value: 3, active: true}, 
        {label: "Sent-Rejected", value: 4, active: true}, 
        {label: "Failed", value: 5, active: true}, 
      ],
    }

    this.dialogCancel = this.dialogCancel.bind(this)

    this.refreshData()
    if (props.deliverableViewer.componentState) {
      this.setState(props.deliverableViewer.componentState)
    }

    this.handleDateRangeCallback = this.handleDateRangeCallback.bind(this)
  }

  componentWillUnmount() {
    this.props.saveState(this.state)
  }

  componentDidUpdate(prevProps){
    if (prevProps.deliverableViewer.data !== this.props.deliverableViewer.data) {
        let records = []
        records = this.props.deliverableViewer.data.map((e) => {
          e.rowExpanded = false
          return e
          })
        this.setState({
          records: records,
        })
    }
  }

  handleRunButtonClick() {
    this.refreshData()
    this.setState({
      isCriteriaCollapsed: true,
    })
  }

  onToggle(){
    this.setState({
      isCriteriaCollapsed: !this.state.isCriteriaCollapsed,
    })
  }

  handleDateRangeCallback(startDate, endDate) {
    this.setState({
      startDate: startDate,
      endDate: endDate
    })
  }

  refreshData() {
    let criteria = this.getCriteria()
    this.props.retrieveDeliverables(criteria)
  }

  getCriteria() {
    return {
      offset: this.state.offset,
      limit: this.state.limit,
      startDate: Math.trunc(this.state.startDate.valueOf() / 1000),
      endDate: Math.trunc(this.state.endDate.valueOf() / 1000),
      campaigns: this.state.selectedCampaigns.map(e => e.value),
      placements: this.state.selectedPlacements.map(e => e.value),
      statusCodes: this.state.statusCodes.filter(e => e.active == true).map(e => e.value),
    }
  }

  dialogCancel() {
    this.setState({
      showDialog: false,
      dialogTitle: '',
      dialogText: '',
      emailName: ''
    })
  }

  componentDidMount() {
    Modal.setAppElement('body')
  }

  placementSelectorOnChangeHandler(placements) {
    this.setState({
      selectedPlacements: placements,
    })
  }

  batchUpdateOnSelectHandler(status, deliveryUUIDs) {
    this.props.updateBulkStatusCodes(status, deliveryUUIDs)
  }

  handleCloseSaveDialog() {
    this.props.closeSaveDialog()
    this.refreshData()
  }

  renderSaveResultsModal() {
    if(!this.props.deliverableViewer.saveResults){
      return (
        <Grid>
          <Cell col={12}>Processing ...</Cell>
        </Grid>)
    }

    var message = this.props.deliverableViewer.saveResults.message
    return (
      <Grid>
        <Cell col={12}>
          <h3>{message}</h3>
        </Cell>
        <Cell col={12}>
            <table className="report">
              <thead>
                <tr className="th" key={`viewer.saveResult.header`}>
                  <td className="th dimension">Deliverable Id</td>
                  <td className="th dimension">Message</td>
                </tr>
              </thead>
              <tbody>
                {this.props.deliverableViewer.saveResults.records.map((e, i) => {
                  return <tr key={`viewer.saveResult.${i}`} className="td">
                    <td className="td dimension">
                      {e.failed ? <div style={{ color: "red" }}>{e.id}</div>:<div style={{ color: "green" }}>{e.id}</div>}
                    </td>
                    <td className="td dimension">
                      {e.failed ? <div style={{ color: "red" }}>{e.message}</div>:<div style={{ color: "green" }}>{e.message}</div>}
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
        </Cell>
      </Grid>
    )
  }


  render() {
    if (this.state.isLoading) return "loading ..."
    return (
      <section ref={t => this.contentSection = t}>
        <div className="content">
        <Modal
            appElement={this.props.parent}
            isOpen={this.props.deliverableViewer.showSaveDialog}
            onRequestClose={this.handleSaveDialogCancel}
            contentLabel="Save Dialog">
            <Card shadow={2}>
              <CardActions style={{ textAlign: "right" }}>
                <Button onClick={() => this.handleCloseSaveDialog()}>
                  <Icon name="close" />
                </Button>
              </CardActions>
              {this.props.deliverableViewer.showSaveDialog && this.renderSaveResultsModal()}
            </Card>
          </Modal>
          <Grid>
            <Cell col={12}>
              <Collapsible title="Deliverable Viewer" defaultOpen={!this.state.isCriteriaCollapsed} onToggle={(flag) => this.onToggle(flag)} onClick={() => this.onToggle(this.state.isCriteriaCollapsed)}>
              <Grid>
                <Cell col={11}>
                  <Grid>
                    <Cell col={4}>
                      <label className="floatLabel">Date Range</label>
                      <DateRange start={this.state.startDate} end={this.state.endDate} applyCallback={this.handleDateRangeCallback}></DateRange>
                    </Cell>
                    <Cell col={8}>
                      <label className="floatLabel">Status</label>
                      <Grid>
                        {this.state.statusCodes.map((e, i) => {
                          return <Cell key={`viewer.${i}.statusCodes`} col={3}>
                            <Switch
                              onChange={() => {
                                const statusCodes = this.state.statusCodes
                                statusCodes[i].active = !this.state.statusCodes[i].active
                                this.setState({
                                  statusCodes: statusCodes
                                })
                              }}
                              checked={e.active}
                            >{e.label}</Switch>
                          </Cell>
                        })}
                      </Grid>
                    </Cell>
                    <Cell col={6}>
                      <label className="floatLabel">Campaigns</label>
                      <CampaignSelector
                        selectedCampaigns={this.state.selectedCampaigns}
                        campaigns={this.props.campaigns}
                        onChange={(campaigns) => {
                          this.setState({
                            selectedCampaigns: campaigns,
                          })
                        }}
                      ></CampaignSelector>
                    </Cell>
                    <Cell col={6}>
                      <label className="floatLabel">Placements</label>
                      <PlacementSelector
                        selectedPlacements={this.state.selectedPlacements}
                        placements={this.props.placements}
                        onChange={(placements) => this.placementSelectorOnChangeHandler(placements)}
                      ></PlacementSelector>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell col={1}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button
                            id="run-button"
                            colored
                            raised
                            ripple
                            onClick={() => this.handleRunButtonClick()}
                          >
                            RUN
                  </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Cell>
              </Grid>
              </Collapsible>
            </Cell>
            <Cell col={12}>
              <Card shadow={2}>
                <CardActions border>
                  {"Campaign aggregates:"}
                  <Grid>
                    <Cell col={6}>{"Campaign"}</Cell>
                    <Cell col={4}>{"Status"}</Cell>
                    <Cell col={2}>{"Count"}</Cell>
                    {this.props.deliverableViewer.campaignAggregates.map((row, i) => {
                      let status = this.state.statusCodes.find(d => d.value == row.status.toString())
                      let statusLabel = status ? status.label:'NA'
                      return <React.Fragment key={i}>
                        <Cell key={`${i}.campaignUUID`} col={6}>{row.campaignUUID}</Cell>
                        <Cell key={`${i}.label`} col={4}>{statusLabel}</Cell>
                        <Cell key={`${i}.count`} col={2}>{row.count}</Cell>
                      </React.Fragment>
                    })}
                  </Grid>
                </CardActions>
              </Card>
            </Cell>
            <Cell col={12}>
              <Grid>
                <Card shadow={2}>
                  <CardTitle> {"Deliverable Viewer"}</CardTitle>
                  <CardActions border>
                    {this.renderPaginatedTable()}
                  </CardActions>
                </Card>
              </Grid>
            </Cell>
          </Grid>
        </div>
      </section>
    )
  }


  pageChange(page) {
    this.props.deliverableViewer.data = []
    this.refreshData()
    this.setState({
      page: page,
      offset: (page - 1) * this.state.limit
    })
  }

  handleRowToggleClick(i){
    const records = this.state.records
    records[i].rowExpanded = !records[i].rowExpanded
    this.setState({
      records: records,
    })
  }

  handleAllRowsToggleClick(){
    let flag = !this.state.allRowsExpanded
    const records = this.state.records.map((e) => {
      e.rowExpanded = flag
      return e
    })
    this.setState({
      records: records,
      allRowsExpanded: flag,
    })
  }

  renderPaginatedTable() {
    let numOfPages = Math.ceil(this.props.deliverableViewer.available / this.state.limit)
    let page = this.state.page
    let data = this.state.records || []
    return <React.Fragment>
      <Button accent onClick={() => {
        this.pageChange(1)
      }}>
        <Icon name="first_page" />
      </Button>
      <Button accent onClick={() => {
        this.pageChange(this.state.page - (this.state.page <= 1 ? 0 : 1))
      }}>
        <Icon name="chevron_left" />
      </Button>
              ({page} | {numOfPages})
              <Button accent onClick={() => {
        this.pageChange(this.state.page + (this.state.page >= numOfPages ? 0 : 1))
      }}>
        <Icon name="chevron_right" />
      </Button>
      <Button accent onClick={() => {
        this.pageChange(numOfPages)
      }}>
        <Icon name="last_page" />
      </Button>
      <table className="report" style={{ "width": "100%" }}>
        <thead>
          <tr>
            <th className="th dimension">
              <div onClick={() => this.handleAllRowsToggleClick()}>
              {!this.state.allRowsExpanded && <Icon name="keyboard_arrow_down" />}
              {this.state.allRowsExpanded && <Icon name="keyboard_arrow_up" />}
              </div>
            </th>
            <th className="th dimension">{"Deliverable"}</th>
            <th className="th dimension">{"Configuration"}</th>
            <th className="th dimension">{"Campaign"}</th>
            <th className="th dimension">{"Placement"}</th>
            <th className="th dimension">
              <div style={{ "color": "black", "textAlign": "left" }}>
                <Select
                    isMulti={false}
                    value={status}
                    onChange={(s) => {
                      this.batchUpdateOnSelectHandler(parseInt(s.value), data.map(e=>e.deliverable.UUID))
                    }}
                    options={this.state.statusCodes.filter(e=>e.label != "Processing")}
                  />
              </div>
              {"Status"}
            </th>
            <th className="th dimension">{"Created"}</th>
            <th className="th dimension">{"Delivered"}</th>
          </tr>
        </thead>
        <tbody>
        {data.map((e, i) => {
          let lead = e.deliverable["lead"]
          let campaignUuid = e.deliverable["lead"].campaignUuid
          let status = this.state.statusCodes.find(d => d.value == e.deliverable.status.toString())
          let createdAt = moment.unix(e.deliverable["createdAt"]).local().format("YYYY-MM-DD h:mm a")
          let deliveryAttempts = []
          e.deliveries.map(delivery => {
            deliveryAttempts = deliveryAttempts.concat(delivery.attempts)
          })
          deliveryAttempts.sort((a, b) => (parseInt(a.startedAt) > parseInt(b.startedAt)) ? 1 : 0)
          let delivered = false //last delivery attempt finished without a failure TODO: should it be based on deliverable status?
          let deliveryDate = ""
          if (deliveryAttempts.length > 0 &&
            deliveryAttempts[deliveryAttempts.length - 1] &&
            deliveryAttempts[deliveryAttempts.length - 1].result &&
            deliveryAttempts[deliveryAttempts.length - 1].result.failed == false &&
            deliveryAttempts[deliveryAttempts.length - 1].finishedAt > 0) {
            delivered = true
            deliveryDate = moment.unix(deliveryAttempts[deliveryAttempts.length - 1].finishedAt).local().format("YYYY-MM-DD h:mm a")
          }

          return (
            <React.Fragment key={`viewer.brokeredLeadViewer.wrapper.${i}`}>
              <tr className="td" key={i} style={{ "width": "100%" }}>
            <td className="td dimension">
              <div onClick={() => this.handleRowToggleClick(i)}>
              {!e.rowExpanded && <Icon name="keyboard_arrow_down" />}
              {e.rowExpanded && <Icon name="keyboard_arrow_up" />}
              </div>
            </td>
            <td title={"Deliverable"}>
              {e.deliverable.UUID}
            </td>
            <td title={"Config"}>
              {e.configuration ? <div><div>{e.configuration.name}</div><div>{e.configuration.UUID}</div></div> : 'NA'}
            </td>
            <td title={"Campaign"}>
              {campaignUuid}
            </td>
            <td title={"Placement"}>
              {campaignUuid}
            </td>
            <td title={"Status"} style={{width: '200px'}}>
              <Select
                isMulti={false}
                value={status}
                onChange={(s) => {
                  this.props.updateDeliverableStatus({deliverableUUID: e.deliverable.UUID, deliverableStatus: parseInt(s.value)}, this.getCriteria())
                }}
                options={this.state.statusCodes}
              />
            </td>
            <td title={"Created"}>
              {createdAt}
            </td>
            <td title={"Delivered"}>
              {delivered &&
                <span>{deliveryDate}</span>
              }
            </td>
          </tr>
          {!e.rowExpanded && <tr><td colSpan={8}></td></tr>}
          {e.rowExpanded && <tr>
            <td colSpan={8}>
              <table style={{ "width": "100%" }}>
                <thead>
                  <th>Lead</th>
                  <th>Delivery Attempts</th>
                </thead>
                <tbody>
                  <tr className="td">
                    <td className="td dimension">
                      {this.renderLead(lead)}
                    </td>
                    <td className="td dimension">
                      <Grid>
                      {deliveryAttempts.length == 0 && <Cell col={12}>{"None"}</Cell>}
                      {deliveryAttempts.map((attempt, i) => {
                        return <Cell key={i} col={12}>
                          <Grid>
                            <Cell col={6}>{moment.unix(attempt.startedAt).local().format("YYYY-MM-DD h:mm a")}</Cell>
                            <Cell col={6}>{this.deliveryAttemptStatusCodesMap[attempt.status]}</Cell>
                            <Cell col={12}>
                              <div className="mdl-card__subtitle-text" style={{ fontSize: '75%' }}>
                                Total Latency: {parseInt(attempt.finishedAt) - parseInt(attempt.startedAt) + "s"}
                                <br />
                                {attempt.result && <React.Fragment>
                                  Delivery UUID: {attempt.result.deliveryUUID}
                                  <br />
                                              Failed: {attempt.result.failed.toString()}
                                  <br />
                                              Client Accepted: {attempt.result.accepted.toString()}
                                  <br />
                                              Client Latency: {parseInt(attempt.result.finishedAt) - parseInt(attempt.result.startedAt) + "s"}
                                  <br />
                                              Result Message:
                                              <pre>
                                    {attempt.result.message}
                                  </pre>
                                  <br />
                                              Details:
                                              <pre>
                                    {attempt.result.debugMessage}
                                  </pre>
                                  <br />
                                </React.Fragment>}
                              </div>
                            </Cell>
                          </Grid>
                        </Cell>
                      })}
                    </Grid>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>}
          </React.Fragment>)
        })}
        </tbody>
      </table>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </React.Fragment>
  }

  renderLead(lead) {
    return <div className="mdl-card__subtitle-text" style={{ fontSize: '75%' }}>
      {lead.uuid}
      {Object.keys(lead.user).map((d, i) => {
        return <div key={i} col={12}>{d} {lead.user[d]}</div>
      })}
    </div>
  }
}


const DeliverableViewer = connect(mapStateToProps, mapDispatchToProps)(deliverableViewer)

export default DeliverableViewer